import React, { createContext, useState } from 'react';
import { LocalStorage } from '../../scripts/LocalStorage';

interface ISponsorPortalContext { 
    selectedModule: 'login' | 'form' | 'audience' | 'icp' | 'tasks' | 'formFieldEdit';
    setSelectedModule: React.Dispatch<React.SetStateAction<'login' | 'form' | 'audience' | 'icp' | 'tasks' | 'formFieldEdit'>>;
}

export const SponsorPortalContext = createContext<ISponsorPortalContext>(null);

const SponsorPortalProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element => 
{

    const sponsorDetails = LocalStorage.get('@SponsorDetails');

    const [selectedModule, setSelectedModule] = useState<'login' | 'form' | 'audience' | 'icp' | 'tasks' | 'formFieldEdit'>(sponsorDetails ? 'form' : 'login');

    return (
        <SponsorPortalContext.Provider value={{ selectedModule, setSelectedModule }}>
            {children}
        </SponsorPortalContext.Provider>
    )

};

export default SponsorPortalProvider;