import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import moment from 'moment';
import CustomSpinner from '../../../../common/CustomSpinner';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import CreateRegistrationDetails from '../../../../components/Events/EventRegistrations/CreateRegistrationDetails';
import { Event } from '../../interfaces';
import './styles.scss';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';

const RegistrationDetailsSection: React.FC<{eventDetails: Event; spinner: boolean;}> = (props: {eventDetails: Event; spinner: boolean;}): React.JSX.Element => 
{
    const { eventDetails, spinner } = props;

    const handleEditRegistrationDetails = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Registration Details',
            hideCloseButton: true,
            component: <CreateRegistrationDetails eventDetails={eventDetails} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const registrationDetails: {name: string; value: string | number;}[] = [
        {
            name: 'Closing Date',
            value: `${eventDetails?.closingDateTime !== null ? moment.unix(eventDetails?.closingDateTime).format('DD/MM/YYYY') : '-'} ${eventDetails?.closingDateTime !== null ? moment.unix(eventDetails?.closingDateTime).format('hh:mm A') : ''}`
        },
        {
            name: 'Waitlist',
            value: `${Number(eventDetails.enableWaitlistStatus) === 1 ? 'On' : 'Off'}`
        },
        {
            name: 'Maximum Registrations',
            value: `${eventDetails.maximumRegistration ? eventDetails.maximumRegistration : '-'}`
        },
        {
            name: 'Upon successful submission',
            value: `${eventDetails?.registerOptions?.redirectionUrl ? eventDetails?.registerOptions.redirectionUrl : eventDetails?.registerOptions?.message ? eventDetails?.registerOptions.message : '-'}`
        },
    ];

    return (
        <>
            {spinner ? <CustomSpinner height={'100%'} /> :
                <Box id="details-container">
                    <Box className="cont-heading">
                        {'Registration Page'}
                    </Box>
                    <Grid container spacing={2}>
                        {
                            registrationDetails?.map((item, index) => {
                                return (
                                    <Grid key={index} item xl={3} lg={3} md={6} sm={12} xs={12}>
                                        <CardComponent 
                                            header={item.name}
                                            contentHeading={<p className='cont-heading-sub' style={{ marginTop: '4px', fontWeight: 500 }}>{item.value}</p>}
                                            // contentHeadingBold
                                            cardMinHeight='80px'
                                            onClick={handleEditRegistrationDetails}
                                        />
                                    </Grid>
                                )
                            })
                        }

                        {/* <Grid item xl={3} lg={3} md={3} sm={3}>
                            <Box className="details-cont" onClick={handleEditRegistrationDetails}>
                                <Typography className="value-faded-label">{'Closing Date'}</Typography>
                                <Box className="date-time-cont">
                                    <Box className="number-cont">
                                        {eventDetails?.closingDateTime !== null ? moment.unix(eventDetails?.closingDateTime).format('DD/MM/YYYY') : '-'}
                                    </Box>
                                    <Box className="number-cont">
                                        {eventDetails?.closingDateTime !== null ? moment.unix(eventDetails?.closingDateTime).format('hh:mm A') : ''}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={3}>
                            <Box className="details-cont" onClick={handleEditRegistrationDetails}>
                                <Typography className="value-faded-label">{'Waitlist'}</Typography>
                                <Box className="number-cont" >
                                    {Number(eventDetails.enableWaitlistStatus) === 1 ? 'On' : 'Off'}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={3}>
                            <Box className="details-cont" onClick={handleEditRegistrationDetails}>
                                <Typography className="value-faded-label">{'Maximum Registrations'}</Typography>
                                <Box className="number-cont" >
                                    {eventDetails.maximumRegistration ? eventDetails.maximumRegistration : '-'}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xl={3} lg={3} md={3} sm={3}>
                            <Box className="details-cont" onClick={handleEditRegistrationDetails}>
                                <Typography className="value-faded-label">{eventDetails?.registerOptions?.redirectionUrl ? 'Redirect to another page' : 'Success Message'}</Typography>
                                <Box className="message-cont" >
                                    {eventDetails?.registerOptions?.redirectionUrl ? eventDetails?.registerOptions.redirectionUrl : eventDetails?.registerOptions?.message ? eventDetails?.registerOptions.message : '-'}
                                </Box>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            }
        </>

    );
};

export default RegistrationDetailsSection;