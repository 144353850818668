import React, { useEffect, useState } from 'react';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { Box, Grid } from '@mui/material';
import { getBudgetGraphData } from '../../scripts/apis/budget';
import { getEventsByCategory, getTopAndLeastRoiEvents } from '../../scripts/apis/events';
import './styles.scss';
import { dashboardBudgetSummaryGraphDatainterface, dashboardDatainterface, dashboardTableReadyinterface } from './dashboard_interface';
import DashboardEventsTable from './Table/DashboardEventsTable';
import DashboardBudgetSummaryGraph from './Charts/DashboardBudgetSummaryGraph';
import TopRoiEventsTable from './Table/TopRoiEventsTable';
import LeastRoiEventsTable from './Table/LeastRoiEventsTable';
import HeaderBar from '../../common/Headerbar';

const DashboardPage: React.FC = (): React.JSX.Element => 
{

    const [dashboardData, setDashboardData] = useState<dashboardDatainterface>({
        eventsByCategory: [],
        topEventsByRoi: [],
        leastEventsByRoi: [],
    });

    // const [customerHealthScoreData, setCustomerHealthScoreData] = useState([]);
    const [chartReady, isChartReady] = useState<boolean>(false);
    const [budgetSummaryData, setBudgetSummaryData] = useState({
        budget: 0,
        remaining: 0,
        actualSpent: 0,
    });
    // const [trendsCount, setTrendsCount] = useState({
    //     events: 0,
    //     audience: 0
    // });
    const [tableReady, setTableReady] = useState<dashboardTableReadyinterface>({
        mainTable: false,
        topEventsTable: false,
        bottomEventsTable: false,
    });

    // const DashboardOverview = (): React.ReactElement => 
    // {
    //     return (
    //         <Box className="trends-container">
    //             <TrendsComponent
    //                 Trends={[{
    //                     title: 'Number of Events',
    //                     value: `${trendsCount.events}`,
    //                 },
    //                 {
    //                     title: 'RoI',
    //                     value: '1.5',
    //                 },
    //                 {
    //                     title: 'Audience',
    //                     value: `${trendsCount.audience}`,
    //                 }
    //                 ]}
    //             />
    //         </Box>
    //     );
    // };

    // const CustomerHealthScore = (): React.ReactElement => 
    // {
    //     const customerHealthScoreColors = ['#C9E4DE', '#007AFF33', '#DBCDF0', '#F2C6DE'];
    //     const customerHealthScoreOptions = {
    //         tooltip: {
    //             trigger: 'item'
    //         },
    //         legend: {
    //             show: true,
    //             orient: 'vertical',
    //             left: 'left',
    //             padding: [0, 0, 40, 16],
    //         },
    //         series: [
    //             {
    //                 type: 'pie',
    //                 radius: ['40%', '45%'],
    //                 avoidLabelOverlap: false,
    //                 color: customerHealthScoreColors,
    //                 label: {
    //                     show: false,
    //                     position: 'center'
    //                 },
    //                 emphasis: {
    //                     label: {
    //                         show: false,
    //                         fontSize: 40,
    //                         fontWeight: 'bold'
    //                     }
    //                 },
    //                 labelLine: {
    //                     show: false
    //                 },
    //                 data: customerHealthScoreData.map((item) => 
    //                 {
    //                     return {
    //                         value: item.value,
    //                         name: item.name,
    //                         label: {
    //                             position: 'outside',
    //                             margin: '-20%',
    //                             rich: {
    //                                 custom: {
    //                                     lineHeight: 15,
    //                                     align: 'center',
    //                                 },
    //                             },
    //                             formatter: `{custom|$${NumberFormatter(item.value)}}`,
    //                             show: true,
    //                         },
    //                         labelLine: {
    //                             show: true,
    //                             length: 20,
    //                             length2: 10,
    //                             smooth: true,
    //                             lineStyle: {
    //                                 color: customerHealthScoreColors[customerHealthScoreColors.length],
    //                                 width: 1,
    //                                 type: 'solid',
    //                             },
    //                         },
    //                     }; 
    //                 }),
    //             }
    //         ]
    //     };
    //     return (
    //         <Box className="customer-health-chart-container">
    //             <Box className="title-container">
    //                 <Typography className="graph-title">{'Customer Health Score'}</Typography>
    //             </Box>
    //             <Box className="overall-health-score-data-container">
    //                 <Stack direction={'column'}>
    //                     <Typography>{'Portfolio Value'}</Typography>
    //                     <Typography>{`$ ${NumberFormatter(158000000)}`}</Typography>
    //                 </Stack>
    //                 <Stack direction={'column'}>
    //                     <Typography>{'Accounts'}</Typography>
    //                     <Typography>{132}</Typography>
    //                 </Stack>
    //             </Box>
    //             <Charts chartOption={customerHealthScoreOptions} height={'430px'} />
    //         </Box>
    //     );
    // };

    const fetchEventData = async (): Promise<void> => 
    {
        try 
        {
            const dashboardEventsData = await getEventsByCategory();
            if (dashboardEventsData) 
            {
                setDashboardData((prevState): dashboardDatainterface => 
                {
                    return {
                        ...prevState, eventsByCategory: [...dashboardEventsData] 
                    }; 
                });
                setTableReady((prevItems): dashboardTableReadyinterface => 
                {
                    return {
                        ...prevItems,
                        mainTable: true
                    }; 
                });
            }
            else 
            {
                setTableReady((prevItems): dashboardTableReadyinterface => 
                {
                    return {
                        ...prevItems,
                        mainTable: true
                    }; 
                });
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchBudgetGraphData = async (): Promise<void> => 
    {
        try 
        {
            const budgetSummaryData = await getBudgetGraphData();
            if (budgetSummaryData.trend) 
            {
                setBudgetSummaryData((prevItems): dashboardBudgetSummaryGraphDatainterface  => 
                {
                    return {
                        ...prevItems,
                        budget: Number(budgetSummaryData.trend.spent + budgetSummaryData.trend.remaining),
                        actualSpent: Number(budgetSummaryData.trend.spent),
                        remaining: Number(budgetSummaryData.trend.remaining),
                    }; 
                });
                isChartReady(true);
            }
            else 
            {
                isChartReady(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchTopAndLeastRoiEventsData = async (): Promise<void> => 
    {
        try 
        {
            const topAndLeastRoiEventData = await getTopAndLeastRoiEvents();

            if (topAndLeastRoiEventData) 
            {
                setTableReady((prevItems): dashboardTableReadyinterface => 
                {
                    return {
                        ...prevItems,
                        topEventsTable: true,
                        bottomEventsTable: true
                    }; 
                });

                if (topAndLeastRoiEventData.topData.length > 0) 
                {
                    setDashboardData((prevState): dashboardDatainterface => 
                    {
                        return {
                            ...prevState, topEventsByRoi: [...topAndLeastRoiEventData.topData] 
                        }; 
                    });
                }
                if (topAndLeastRoiEventData.bottomData.length > 0) 
                {
                    setDashboardData((prevState): dashboardDatainterface => 
                    {
                        return {
                            ...prevState, leastEventsByRoi: [...topAndLeastRoiEventData.bottomData] 
                        }; 
                    });
                }
            }
            else 
            {
                setTableReady((prevItems): dashboardTableReadyinterface => 
                {
                    return {
                        ...prevItems,
                        topEventsTable: true,
                        bottomEventsTable: true
                    }; 
                });
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect((): void => 
    {
        fetchEventData();
        fetchTopAndLeastRoiEventsData();
        fetchBudgetGraphData();
    }, []);

    return (
        <Box id="dashboardPage">
            <HeaderBar 
                title='Dashboard'
            />
            <div className="dashboard-graph-table-cont">
                <DashboardBudgetSummaryGraph data={budgetSummaryData} isChartReady={chartReady} />
                <DashboardEventsTable data={dashboardData.eventsByCategory} isTableReady={tableReady.mainTable} />
            </div>
            <div className="roi-table-cont">
                <TopRoiEventsTable data={dashboardData.topEventsByRoi} isTableReady={tableReady.topEventsTable} />
                <LeastRoiEventsTable data={dashboardData.leastEventsByRoi} isTableReady={tableReady.bottomEventsTable} />
            </div>
            {/* <Grid container className="dashboard_data_content" spacing={2}>
                <Grid  item xl={5} lg={5} sm={12} md={12}>
                </Grid>
                <Grid item xl={7} lg={7} sm={12} md={12}>
                    <Box display={'flex'} flexDirection={'column'} gap={'16px'}>
                        <Box className="top-roi-table">
                            <TopRoiEventsTable data={dashboardData.topEventsByRoi} isTableReady={tableReady.topEventsTable} />
                        </Box>
                        <Box className="least-roi-table">
                            <LeastRoiEventsTable data={dashboardData.leastEventsByRoi} isTableReady={tableReady.bottomEventsTable} />
                        </Box>
                    </Box>
                </Grid>
            </Grid> */}
        </Box>
    );
};

export const calculateDashboardEventsTableHeight = (props): number => 
{

    const screenHeight = props.windowHeight;

    let containerHeight = 0;

    const availableHeight = 0.7 * screenHeight;

    containerHeight = screenHeight - availableHeight;

    return containerHeight;
};

export default DashboardPage;