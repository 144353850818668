import './styles.scss';

const SponsorPortalAppBar: React.FC<{ title: string, buttons?: React.JSX.Element[] }> = (props): React.JSX.Element =>
{
    const { title, buttons } = props;

    return (
        <div id="sponsorsAppBar">
            <div className="sponsor-app-bar">
                <h3 className="title">
                    {title}
                </h3>
                <div className="buttons-container">
                    {
                        buttons && buttons.map((button, index) => (
                            <div key={index}>
                                {button}
                            </div>
                        )) 
                    }
                </div>
            </div>
        </div>
    )
}; 

export default SponsorPortalAppBar;