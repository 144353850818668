import { ICP } from '../../pages/Settings/ICP/interfaces';
import APP_CONSTANTS from '../constants';
import { triggerApi } from '../helpers';

export const icpCount = async ():Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/icp/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllICP = async (pageSize: number, offset: number):Promise<ICP[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/icp?' + '&pageSize=' + pageSize + '&offset=' + offset;
    return triggerApi(API_URL, 'GET', '');
};

export const getICPById = async (ICP_ID):Promise<ICP> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/icp/' + ICP_ID;
    return triggerApi(API_URL, 'GET', '');
};

export const createICP = async (ICP_DATA, csrfToken: string):Promise<ICP> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/icp';
    return triggerApi(API_URL, 'POST', ICP_DATA, csrfToken);
};

export const updateICP = async (ICP_ID, ICP_DATA):Promise<ICP> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/icp/' + ICP_ID;
    return triggerApi(API_URL, 'PUT', ICP_DATA);
};

export const deleteICP = async (ICP_ID):Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/icp/' + ICP_ID;
    return triggerApi(API_URL, 'DELETE', '');
};