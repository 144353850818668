import SponsorPortalAppBar from "../../Components/Common/SponsorPortalAppbar";
import SponsorsBPPage from "./SponsorsBPPage";
import ICPPage from "./SponsorsICPPage";

const ICPAndBPPage: React.FC = (): React.JSX.Element => 
{ 
    return (
        <div id="icpAndBpPage">
            <div>
                <SponsorPortalAppBar title="ICP and BP" />
            </div>

            <ICPPage />
            <SponsorsBPPage />
        </div>
    )
};

export default ICPAndBPPage;