import React, { createContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import { RegistrationForm } from '../../pages/Registration/interfaces/registration-form_interface';
import { CustomField } from '../../pages/Registration/interfaces/custom-field_interface';
import { getCustomField, getCustomFieldCount } from '../../scripts/apis/customField';
import { CustomFieldDisabled, CustomFieldLocations } from '../../pages/Registration/enum/custom-field.enum';
import { getRegistrationFormById } from '../../scripts/apis/registrationForm';
import { addRegistrationForms } from '../../redux/registrationsForms/RegistrationForms';
import { addFormComponent, resetFormComponents } from '../../redux/registrationsForms/FormComponents';
import { getComponentCategory } from '../../pages/Registration/RegistrationSubPages/FormComponents/ComponentsCategories';
import { getLeadCaptureForm } from '../../scripts/apis/sponsorPortal/sponsorPortal';

export interface IFormComponentsContext {
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    formName: string | null;
    formId: string | undefined;
    registrationData: RegistrationForm | null;
    customFields: CustomField[];
    setCustomFields: React.Dispatch<React.SetStateAction<CustomField[]>>;
    sponsorPortalFormId?: string | number;
}

export const SponsorPortalFormsContext = createContext<IFormComponentsContext>(null);

const SponsorPortalFormsProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element => 
{

    const dispatch = useDispatch();
    const [formName, setFormName] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [registrationData, setRegistrationData] = useState(null);
    const [customFields, setCustomFields] = useState<CustomField[]>([]);

    const fetchCustomFields = async (): Promise<void> =>
    {
        try 
        {
            const count = await getCustomFieldCount();
            if(count)
            {
                const customFieldData = await getCustomField(25, 0, CustomFieldLocations.REGISTRATION_FORM);
                if(customFieldData)
                {
                    setCustomFields(customFieldData);
                }
            }
        } 
        catch (error) 
        {
            
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const registrationFormData = await getLeadCaptureForm();
            if (registrationFormData) 
            {
                setFormName(registrationFormData?.name);

                if(registrationFormData && registrationFormData?.formFields && registrationFormData?.formFields?.length > 0)
                {
                    const filteredFields = registrationFormData?.formFields?.filter((field) => !(['firstName', 'lastName', 'email']?.includes(field?.default)));
                    setRegistrationData({
                        ...registrationFormData,
                        formFields: filteredFields,
                    });
                }

                if (!(_.isEmpty(registrationFormData.options))) 
                {
                    dispatch(addRegistrationForms({
                        name: registrationFormData.name,
                        description: registrationFormData.description,
                        link: registrationFormData.link,
                        // options: {
                        //     message: registrationFormData.options.message,
                        //     redirectionUrl: registrationFormData.options.redirectionUrl,
                        // },
                        componentPosition: registrationFormData.componentPosition,
                        captcha: registrationFormData.captcha,
                    }));
                }

                setRefresh(false);
            }

        }
        catch (error) 
        {
            console.log(error);
        }

    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
            // fetchCustomFields();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        fetchData();
        // fetchCustomFields();
    }, []);

    return (
        <SponsorPortalFormsContext.Provider value={{
            setRefresh, formName, registrationData
        }}>
            {children}
        </SponsorPortalFormsContext.Provider>
    );
};

export default SponsorPortalFormsProvider;
