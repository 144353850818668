import AnalyticsPageTabs from "../components/Common/AnalyticsPageTabs";
import RevenueAnalytics from "./RevenueAnalytics";
import './styles.scss';

const EventAnalyticsPage: React.FC<{ eventId: string | number }> = ({ eventId }): React.JSX.Element =>
{
    return (
        <div id="eventAnalyticsPage">
            <div id='inside-event-module-name-section'>{'Analytics'}</div>
            <AnalyticsPageTabs eventId={eventId} />
            <div>
                <RevenueAnalytics eventId={eventId} />
            </div>
        </div>
    )
};

export default EventAnalyticsPage;