/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { NumberFormatter } from '../../scripts/helpers';
import Charts from '../../common/ECharts/Charts';
import CustomSpinner from '../../common/CustomSpinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PopUpView from '../../common/PopupView';
// import { constructObjectForPieChart } from '../../common/ECharts/helpers/charts.helper';
import APP_CONSTANTS from '../../scripts/constants';
import TopPageAnalyticsContainer from '../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';
import TrendsComponent from '../../common/TrendsComponent';

const BudgetsCharts = (props): React.JSX.Element => 
{
    const [totalExpenditure, setTotalExpenditure] = useState(0);
    // const [graphContainerHeight, setgraphContainerHeight] = useState(0);
    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        expenditureGraph: false,
        comparisonGraph: false
    });

    const expenditure = props.expenditure;
    const actualSpendPerEvent = props.actualSpendPerEvent;
    const chartReady = props.chartReady;

    const budgetCounts = props.budgetCounts;

    useEffect((): void => 
    {
        if (expenditure) 
        {
            let expenditureCount = 0;
            expenditure.map((item): void => 
            {
                expenditureCount += Number(item.value);
            });
            setTotalExpenditure(expenditureCount);
        }
    }, [expenditure]);

    
    const expenditureLabels = [];

    expenditure.map((item): void => 
    {
        if (item.name) 
        {
            expenditureLabels.push(item.name);
        }
    });

    const expenditureGraphData = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            type: 'scroll',
            show: true,
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 8],
            data: expenditure,
            icon: 'circle'
        },
        series: [
            {
                type: 'pie',
                radius: ['50%','72%'],
                avoidLabelOverlap: false,
                color: APP_CONSTANTS.GRAPH_COLORS.PIE_CHART.DEFAULT,
                itemStyle: {
                    borderRadius: 3,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                data: expenditure && expenditure.length > 0
                    ? expenditure.map((expense, index) => 
                    {
                        return {
                            value: expense.percentage,
                            name: expense.name,
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    position: 'inside',
                                },
                                labelLine: {
                                    show: false
                                }
                            },
                            label: {
                                position: 'outside',
                                margin: '-20%',
                                rich: {
                                    custom: {
                                        lineHeight: 15,
                                        align: 'center',
                                    },
                                },
                                formatter: `${expense.percentage}%`,
                                show: false,
                            },
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                emphasis: {
                                    //borderWidth: 5,
                                    color: APP_CONSTANTS.GRAPH_COLORS.PIE_CHART.HOVER[index]
                                },
                                formatter: `${expense.percentage}%`,
                                show: true,
                            },
                        };
                    })
                    : null,
            },
        ],
    };

    const allExpenditureGraphData = {
        tooltip: {
            trigger: 'item',
        },
        legend: {
            type: 'scroll',
            orient: 'vertical',
            left: 0,
            top: 20,
            bottom: 20,
            data: expenditure,
            padding: [10, 56, 10, 26],
            itemHeight: 24,
            icon: 'circle',
        },
        series: [
            {
                type: 'pie',
                radius: ['50%','72%'],
                avoidLabelOverlap: false,
                color: APP_CONSTANTS.GRAPH_COLORS.PIE_CHART.DEFAULT,
                itemStyle: {
                    borderRadius: 3,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                data: expenditure && expenditure.length > 0
                    ? expenditure.map((expense, index) => 
                    {
                        return {
                            value: expense.percentage,
                            name: expense.name,
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                    position: 'inside',
                                },
                                labelLine: {
                                    show: false
                                }
                            },
                            label: {
                                position: 'outside',
                                // margin: '-20%',
                                rich: {
                                    custom: {
                                        lineHeight: 15,
                                        align: 'center',
                                    },
                                },
                                formatter: `${expense.percentage}%`,
                                show: false,
                            },
                            labelLine: {
                                show: false
                            },
                            itemStyle: {
                                emphasis: {
                                    //borderWidth: 5,
                                    color: APP_CONSTANTS.GRAPH_COLORS.PIE_CHART.HOVER[index]
                                },
                                formatter: `${expense.percentage}%`,
                                show: true,
                            },
                        };
                    })
                    : null,
            },
        ],
    };

    const allActualVsSpendChartOptions = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value) => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        dataset: {
            source: actualSpendPerEvent
        },
        calculable: true,
        legend: {
            show: true,
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 4],
            icon: 'circle',
        },
        xAxis: {
            type: 'category',
            data: actualSpendPerEvent.map((item) => 
            {
                return (item.name);
            }),
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value) 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            name: 'Budget',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params) 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: actualSpendPerEvent.map((item) => 
            {
                return item['budget'];
            }),
        }, {
            name: 'Expense',
            type: 'line',
            barWidth: 30,
            smooth : true,
            showSymbol: false,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[1]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params) 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: actualSpendPerEvent.map((item) => 
            {
                return item['actualSpend'];
            }),
        }]
    };

    const actualVsSpendChartOptions = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis',
            valueFormatter: (value): string => 
            {
                return `$${NumberFormatter(value)}`;
            },
        },
        dataset: {
            source: actualSpendPerEvent,
        },
        legend: {
            type: 'scroll',
            show: false,
            orient: 'horizontal',
            left: 'left',
            padding: [0, 16, 10, 4],
            icon: 'circle',
        },
        xAxis: {
            type: 'category',
            data: actualSpendPerEvent.map((item) => 
            {
                return (item.name);
            }),
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value) 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            name: 'Budget',
            type: 'line',
            smooth: true,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params): string 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: actualSpendPerEvent.map((item) => 
            {
                return item['budget'];
            }),
        }, {
            name: 'Expense',
            type: 'line',
            smooth: true ,
            showSymbol: false,
            barWidth: 30,
            seriesLayoutBy: 'row',
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[1]
                }
            },
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                    formatter: function (params) 
                    {
                        return `$${NumberFormatter(params?.value)}`;
                    },
                },
            },
            data: actualSpendPerEvent.map((item) => 
            {
                return item['actualSpend'];
            }),
        }]
    };

    return (
        <>
            <div id="budgetsCharts">
                <TopPageAnalyticsContainer
                    TrendsComponent={
                        <TrendsComponent
                            Trends={
                                [
                                    {
                                        title: 'Event Categories',
                                        value: `${budgetCounts.event ? budgetCounts.event : '-'}`
                                    },
                                    {
                                        title: 'Budget',
                                        value: `${budgetCounts.budget ? '$' + NumberFormatter(budgetCounts.budget ? budgetCounts.budget : 0) : '-'}`
                                    },
                                    {
                                        title: 'Expense',
                                        value: `${budgetCounts.actualSpent ? '$' + NumberFormatter(budgetCounts.actualSpent ? budgetCounts.actualSpent : 0) : '-'}`
                                    },
                                    {
                                        title: 'Remaining',
                                        value: `${budgetCounts.remaining ? '$' + NumberFormatter(budgetCounts.remaining ? budgetCounts.remaining : 0) : '-'}`
                                    }
                                ]
                            }
                        />
                    }
                    GraphComponentsArray={
                        [
                            {
                                GraphComponent: <Box className="expenditure-chart-container">
                                    <Box className="title-container">
                                        <Typography className="graph-title">{'Expense'}</Typography>
                                        {expenditure.length > 0 &&
                                            <Box className="enlarge-view" onClick={() => 
                                            {
                                                setOpenEnlargeGraphView({
                                                    expenditureGraph: true, comparisonGraph: false
                                                });
                                            }}>
                                                <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                            </Box>
                                        }
                                    </Box>
                                    {chartReady ? budgetCounts.budget ? <Charts height={'100%'} chartOption={expenditureGraphData} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" /> : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : <CustomSpinner height={'100%'} />}
                                </Box>
                            },
                            {
                                GraphComponent: <Box className="budget-actual-chart-container">
                                    <Box className="title-container">
                                        <Typography className="graph-title">{'Budget Vs Expense'}</Typography>
                                        {actualSpendPerEvent.length > 0 &&
                                            <Box className="enlarge-view" onClick={() => 
                                            {
                                                setOpenEnlargeGraphView({
                                                    expenditureGraph: false, comparisonGraph: true
                                                });
                                            }}>
                                                <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                            </Box>}
                                    </Box>
                                    {chartReady ? budgetCounts.budget ? <Charts height={'100%'} chartOption={actualVsSpendChartOptions} chartHeight={'100%'} chartWidth={'100'} boxMargin="1px" /> : (<div className="noData"><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : <CustomSpinner height={'100%'} />}
                                </Box>,
                                GraphSpacingBlockCSS: {
                                    flex: '2 !important'
                                }
                            }

                        ]
                    }
                />
                {/* <Box className="budget-summary-chart-container">
                    <Box className="title-container">
                        <Typography className="graph-title">{'Budget Summary'}</Typography>
                        <Box className="graph-sub-title-container">
                            <Typography>{budgetCounts.budget ? "$" + NumberFormatter(budgetCounts.budget ? budgetCounts.budget : 0) : "-"}</Typography>
                        </Box>
                    </Box>
                    {chartReady ? budgetCounts.budget ? (<Charts height={`${calculateContainerHeight()}px`} chartOption={budgetSummaryGraphData} chartHeight={`${calculateContainerHeight()}px`} chartWidth={`100`} boxMargin="16px" />) : (<Typography className="noData">{"No data available"}</Typography>) : <CustomSpinner height={`${calculateContainerHeight()}px`} />}
                </Box> */}


                {openEnlargeGraphView.expenditureGraph ? (
                    <PopUpView onClose={() => 
                    {
                        setOpenEnlargeGraphView({
                            expenditureGraph: false, comparisonGraph: false
                        });
                    }}>
                        <Box className="title-container2">
                            <Typography className="graph-title">{'Expense'}</Typography>
                        </Box>
                        {chartReady ? <Charts height={'100%'} chartOption={allExpenditureGraphData} chartHeight={'100%'} chartWidth={'100'} /> : <CustomSpinner height={'100%'} />}
                    </PopUpView>
                ) : null}
                {openEnlargeGraphView.comparisonGraph ? (
                    <PopUpView onClose={() => 
                    {
                        setOpenEnlargeGraphView({
                            expenditureGraph: false, comparisonGraph: false
                        });
                    }}>
                        <Box className="title-container2">
                            <Typography className="graph-title">{'Budget Vs Expense'}</Typography>
                        </Box>
                        {chartReady ? <Charts height={'100%'} chartOption={allActualVsSpendChartOptions} chartHeight={'100%'} chartWidth={'100'} /> : <CustomSpinner height={'100%'} />}
                    </PopUpView>
                ) : null}
            </div>
        </>
    );
};

export default BudgetsCharts;
