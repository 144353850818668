export enum TicketType 
    {
    FREE = 1,
    PAID = 2
}

export enum TicketTAvailability 
    {
    OPEN = 1,
    CLOSE = 2
}

export enum PrivateTicket 
    {
    PRIVATE = 1,
    PUBLIC = 2
}

export enum EventTicketStatus 
    {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}

export enum EventTicketGroupStatus 
    {
    ACTIVE = 1,
    INACTIVE = 2,
    DELETED = 3
}

export enum EnableTicketWaitlistStatus 
    {
    ENABLE = 1,
    DISABLE = 2
}

export enum EnableApprovalStatus 
    {
    ENABLE = 1,
    DISABLE = 2
}

export enum EnableInvoiceStatus 
    {
    ENABLE = 1,
    DISABLE = 2
}

