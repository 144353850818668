import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
// eslint-disable-next-line import/named
import CustomSpinner from '../../../common/CustomSpinner';
import { NumberFormatter } from '../../../scripts/helpers';
import { dashboardEventsCategoryProps } from '../dashboard_interface';
import { calculateDashboardEventsTableHeight } from '../DashboardPage';
import MuiChip from '../../../common/FormComponents/MuiChip';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import NoDataComponent from '../../Analytics/Common/NoDataComponent';

import './styles.scss';

const DashboardEventsTable: React.FC<dashboardEventsCategoryProps> = (props: dashboardEventsCategoryProps): React.JSX.Element => {

    const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
    const [dashboardEventsTableHeight, setDashboardEventsTableHeight] = useState<number>(null);

    const columnHelper = createColumnHelper();

    const columns =
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 180,
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('count', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Count',
            size: 100,
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('avgCost', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue() ? `$${NumberFormatter(Number(row.getValue()?.toFixed(2)))}` : '-'}</p>)
            },
            header: 'Average Cost',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('avgRoi', {
            cell: (row) => {
                const roiChipColor = row.getValue() && row.getValue() >= 1 ? 'green' : row.getValue() && row.getValue() < 1 ? 'red' : 'null';
                return (
                    <Box className="avg-roi-container">
                        <MuiChip
                            chipColor={roiChipColor}
                            label={row.getValue() && row.getValue() !== null ? row?.getValue() : '-'}
                        />
                    </Box>
                );
            },
            header: 'Average RoI',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('pipeline', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue() ? `$${NumberFormatter(Number(row?.getValue()?.toFixed(2)))}` : '-'}</p>)
            },
            header: 'Pipeline Generated',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('revenueRecognized', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue() ? `$${NumberFormatter(Number(row?.getValue()?.toFixed(2)))}` : '-'}</p>)
            },
            header: 'Revenue Recognized',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive'
        }),
    ];

    useEffect((): () => void => {
        const handleResize = (): void => {
            setWindowHeight(window.innerHeight);
            setDashboardEventsTableHeight(calculateDashboardEventsTableHeight({
                windowHeight: windowHeight
            }));
        };
        handleResize();

        window.addEventListener('resize', handleResize);
        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowHeight]);

    return (
        <Box className="dashboard-events-container">
            {props.isTableReady ?
                props.data?.length > 0 ?
                <>
                    <Box height={`${dashboardEventsTableHeight}px`} className="dashboard-events-table">
                        <TanstackTable
                            initialColumns={columns}
                            data={props.data}
                            rowCount={props.data?.length}
                            showSpinner={false}
                            hidePagination={true}
                        />
                    </Box>
                </>
                :
                <Box className="onload-dashboard-events-table-container">
                    <Box className="onload-dashboard-events-table-title-cont">
                        <Typography className="onload-dashboard-events-table-title">Event Category </Typography>
                    </Box>
                    <NoDataComponent height='264px' />
                </Box>
                :
                <Box className="onload-dashboard-events-table-container">
                    <Box className="onload-dashboard-events-table-title-cont">
                        <Typography className="onload-dashboard-events-table-title">Event Category </Typography>
                    </Box>
                    <CustomSpinner height={`${dashboardEventsTableHeight}px`} />
                </Box>
            }
        </Box>
    );
};

export default DashboardEventsTable;