import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import eventBus from '../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../scripts/constants';
import CreateTickets from '../../../../../components/Events/Tickets/CreateTicket';
import { getEventTicketById } from '../../../../../scripts/apis/eventTickets';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resetTicket } from '../../../../../redux/events/eventRegistrations/Tickets';
import moment from 'moment';
import { CONTENT } from '../../../../../scripts/i18n';
import TableEmptyComponent from '../../../../../common/TableEmptyComponent';
import { TicketsPageContext } from '../../../../../contexts/EventDetailsRegistration/TicketsPageContext';
import { getEventById } from '../../../../../scripts/apis/events';
import { addEvent } from '../../../../../redux/events/Events';
import { ActionsDropdown } from '../../../EventBudget/EventBudgetDataGrid';
import { EventTicket } from '../../../interfaces';
import { IEventReduxValues, IEventsDispatch } from '../../../../../components/Events/interfaces/create-events_interface';
import { NumberFormatter } from '../../../../../scripts/helpers';
import MuiChip from '../../../../../common/FormComponents/MuiChip';
import ticketsEmpty from '../../../../../assets/icons/empty-state/ticketsEmpty.svg';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CardComponent from '../../../../../components/Events/Registrations/CardComponent';
import { EnableApprovalStatus, EnableWaitlistStatus, PrivateTicket, TicketType } from '../../../enum';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../../../../common/TanstackTable/TanstackTable';
import HeaderBar from '../../../../../common/Headerbar';
import DeletePopup from '../../../../../common/DeletePopup';
import InvoiceSettings from '../../../../../components/Events/Tickets/InvoiceSettings';
import toast from 'react-hot-toast';
import EventsCardHeaderComponent from '../../../../../components/Events/EventComponents/EventsCardHeaderComponent';

import './styles.scss';
import TanstackToolbarWithServerFilter from '../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';

export interface TicketComponentProps {
    eventId: number;
    tab?: string;
    subTab?: string
}

export interface TicketComponentProps {
    eventId: number;
    tab?: string;
    subTab?: string
}

export enum RegistrationTypes {
    OPEN = 1,
    INVITE_ONLY = 2,
}

const Ticket: React.FC<TicketComponentProps> = (): React.JSX.Element => 
{

    const { ticketId } = useParams();

    const { 
        isEmpty, 
        ticketRows, 
        setRefresh, 
        removeTicketFromTable, 
        eventId, 
        pageSize,
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner, 
        eventTicketsCount, 
        presentTicketsName, 
        tickets, 
        isTableView,
        selectedTicket,
        setSelectedTicket, 
        showDeletePopup, 
        setShowDeletePopup,
        setCurrentUrl
    } = useContext(TicketsPageContext);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const isInvoiceDetailsPresent = (eventReduxData?.invoiceDetails && eventReduxData?.invoiceDetails?.invoiceNumber !== null && eventReduxData?.invoiceDetails?.invoicePrefix !== null);

    const handleEditTicket = (data: EventTicket, routeFromId?: boolean): void => 
    {
        dispatch(resetTicket());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_MODULE.TICKET.SIDE_DRAWER.TICKET.HEADING.EDIT,
            hideCloseButton: true,
            component: <CreateTickets isTableView={isTableView} eventId={eventId} ticketData={data} setRefresh={setRefresh} eventReduxData={eventReduxData} routeFromId={routeFromId} presentTicketsName={presentTicketsName} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important',
        });
    };

    const ticketType = (row: EventTicket): React.ReactElement =>
    {
        const ticketTypeChipLabel = row.privateTicket === 1 ? 'Private' : 'Public';
        const ticketTypeChipColor = row.privateTicket === 1 ? 'violet' : 'green';

        return (
            <Box className="ticket-type-container">
                <MuiChip label={ticketTypeChipLabel} chipColor={ticketTypeChipColor} />
            </Box>
        );
    };

    const ticketPricing = (row: EventTicket): React.ReactElement =>
    {
        const ticketPricingTypeChipLabel = row.type === 1 ? 'Free' : 'Paid';
        const ticketPricingTypeChipColor = row.type === 1 ? 'pink' : 'orange';

        return (
            <Box className="ticket-pricing-container">
                <MuiChip label={ticketPricingTypeChipLabel} chipColor={ticketPricingTypeChipColor} />
            </Box>
        );
    };

    const saleStartDateTime = (row: EventTicket): React.ReactElement => 
    {
        let saleStartDateTime = String(row.saleStartDateTime);
        saleStartDateTime = moment.unix(Number(saleStartDateTime)).format('MMM DD YYYY');
        return (
            <p className="cellContent">{saleStartDateTime}</p>
        );
    };

    const saleEndDateTime = (row: EventTicket): React.ReactElement => 
    {
        let saleCloseDateTime = String(row.saleCloseDateTime);
        saleCloseDateTime = moment.unix(Number(saleCloseDateTime)).format('MMM DD YYYY');
        return (
            <Typography className="cellContent">{saleCloseDateTime}</Typography>
        );
    };

    const ticketActions = (row: EventTicket): React.ReactElement => 
    {
        const editClick = (): void => 
        {
            handleEditTicket(row);
        };

        const deleteClick = (): void => 
        {
            setSelectedTicket(row);
            setShowDeletePopup(true);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const renderInvoiceSettingsPopup = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
            heading: 'Invoice Settings',
            component: <InvoiceSettings eventId={eventId} isInvoiceDetailsPresent={isInvoiceDetailsPresent} />,
            componentWidth: '512px',
            componentHeight: isInvoiceDetailsPresent ? '164px' : '216px'
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
            open: true
        });
    }

    const openDrawer = (): void => 
    {
        dispatch(resetTicket());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_MODULE.TICKET.SIDE_DRAWER.TICKET.HEADING.CREATE,
            hideCloseButton: true,
            component: <CreateTickets isTableView={isTableView} eventId={eventId} setRefresh={setRefresh} eventReduxData={eventReduxData} presentTicketsName={presentTicketsName} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (ticketId) 
        {
            try 
            {
                const ticketDataById = await getEventTicketById(eventId, Number(ticketId));
                if (ticketDataById) 
                {
                    try 
                    {
                        const eventData = await getEventById(eventId);
                        if (eventData) 
                        {
                            dispatch(addEvent({
                                eventStartDateTime: eventData.eventStartDateTime,
                                eventEndDateTime: eventData.eventEndDateTime,
                                closingDateTime: eventData.closingDateTime,
                            }));
                            handleEditTicket(ticketDataById, true);
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        toast.error('Error fetching Event Data');
                    }
                }
                else 
                {
                    toast.error('The Ticket you\'re trying to access doesn\'t exist');
                    navigate('/events/' + eventId + '/registrations/tickets');
                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error((error as Error).message);
            }
        }
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const columnHelper = createColumnHelper<EventTicket>();
    const ticketColumns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Ticket Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('privateTicket', {
            cell: (row) => {
                return ticketType(row.row.original);
            },
            header: 'Ticket Type',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'privateTicket'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return ticketPricing(row.row.original);
            },
            header: 'Ticket Pricing',
            size: 150,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('totalTicketsAvailable', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Allocated Tickets',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'totalTicketsAvailable'
        }),
        columnHelper.accessor('ticketPrice', {
            cell: (row) => {
                return (<p className="cellContent">{(row.row.original?.ticketPrice && row.row.original?.ticketPrice !== 0 && row.row.original?.ticketPrice !== '0') ? `₹${NumberFormatter(Number(row.getValue()))}` : '-'}</p>)
            },
            header: 'Ticket Price',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'ticketPrice'
        }),
        columnHelper.accessor('saleStartDateTime', {
            cell: (row) => {
                return saleStartDateTime(row.row.original);
            },
            header: 'Sale Start',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'saleStartDateTime'
        }),
        columnHelper.accessor('saleCloseDateTime', {
            cell: (row) => {
                return saleEndDateTime(row.row.original);
            },
            header: 'Sale End',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'saleCloseDateTime'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return ticketActions(row.row.original);
            },
            header: '',
            size: 40,
            maxSize: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(ticketColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Allocated Tickets',
            id: 'ticketsAvailable',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Ticket Price',
            id: 'ticketPrice',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Ticket Pricing',
            id: 'type',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    {
                        name: 'Paid', value: TicketType.PAID,
                    },
                    {
                        name: 'Free', value: TicketType.FREE,
                    }
                ]
            }
        },
        {
            header: 'Ticket Type',
            id: 'privateTicket',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    {
                        name: 'Private', value: PrivateTicket.PRIVATE,
                    },
                    {
                        name: 'Public', value: PrivateTicket.PUBLIC,
                    }
                ]
            }
        },
        {
            header: 'Sale Start',
            id: 'ticketStartDateTime',
            meta: {
                type: 'date'
            }
        },
        {
            header: 'Sale End',
            id: 'ticketEndDateTime',
            meta: {
                type: 'date'
            }
        }
    ];

    const customFilterFunctions = {
        privateTicket: (obj: EventTicket, inputValue: string | number): boolean => {
            const ticketType = obj.privateTicket === PrivateTicket.PRIVATE ? 'Private' : 'Public';
            return ticketType.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        type: (obj: EventTicket, inputValue: string | number): boolean => {
            const ticketType = obj.type === TicketType.PAID ? 'Paid' : 'Free';
            return ticketType.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        totalTicketsAvailable: (obj: EventTicket, inputValue: string | number): boolean => {
            return String(obj.totalTicketsAvailable).toLowerCase().includes(String(inputValue).toLowerCase());
        },
        saleStartDateTime: (obj: EventTicket, inputValue: string | number): boolean => { 
            const saleStartDateTime = moment.unix(Number(obj.saleStartDateTime)).format('MMM DD YYYY');
            return saleStartDateTime.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        saleCloseDateTime: (obj: EventTicket, inputValue: string | number): boolean => {
            const saleCloseDateTime = moment.unix(Number(obj.saleCloseDateTime)).format('MMM DD YYYY');
            return saleCloseDateTime.toLowerCase().includes(String(inputValue).toLowerCase());
        },
    };

    useEffect((): void => 
    {
        if(presentTicketsName)
        {
            setAllColumns(ticketColumns);
        }
    }, [presentTicketsName]);

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, []);

    return (
        <div id="tickets" style={{ height: isTableView ? 'calc(100vh - 60px)' : '', overflow: isTableView ? 'hidden' : '' }}>
            {isTableView &&
                <>
                    <HeaderBar 
                        title={<p><FontAwesomeIcon icon={['fal', 'arrow-left']} cursor="pointer" style={{ marginRight: '8px' }} onClick={() => navigate('/events/' + eventId + '/registrations')} /> Tickets</p>}
                        buttons={[
                            <CustomButton btnType='secondary' name='Invoice Settings' onClick={() => renderInvoiceSettingsPopup()} />,
                            <CustomButton btnType='secondary' name='Create Ticket' onClick={openDrawer} />
                        ]}
                    />

                    <Box sx={{
                        flex: '1',
                        height: 'calc(100% - 60px)', 
                    }}>
                        {isEmpty ? (<TableEmptyComponent emptyImg={ticketsEmpty} openDrawer={openDrawer} infoText={'No Tickets yet'} subInfoText={'Create your first Ticket'} buttonName={'Create Ticket'} />) :
                        (
                            <div className="h-100">
                                {/* <TanstackToolbar 
                                    columns={ticketColumns}
                                    rows={tickets}
                                    setColumns={setAllColumns}
                                    setRows={setTicketRows}
                                    tabs={[
                                        {
                                            tabName: 'All',
                                            count: eventTicketsCount as number,
                                            navigation: () => {},
                                            selectedTab: true
                                        }
                                    ]}
                                    customFilterFunctions={customFilterFunctions}
                                /> */}
                                <TanstackToolbarWithServerFilter 
                                    columns={ticketColumns}
                                    setColumns={setAllColumns}
                                    tabs={[
                                        {
                                            tabName: 'All',
                                            count: eventTicketsCount as number,
                                            navigation: () => {},
                                            selectedTab: true
                                        }
                                    ]}
                                    setCurrentUrl={setCurrentUrl}
                                    handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                    filterColumns={filterColumns}
                                />
                                <TanstackTable 
                                    data={ticketRows}
                                    initialColumns={allColumns}
                                    rowCount={eventTicketsCount as number}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updatePageSize={updatePageSize}
                                    updateCurrentPage={updateCurrentPage as any}
                                    height='calc(100% - 128px)'
                                    showSpinner={showSpinner}
                                    rightPinnedColumns={['actions']}
                                    onRowClick={(row) => handleEditTicket(row)}
                                />
                            </div>
                        )}
                    </Box>

                    {
                        showDeletePopup &&
                            <DeletePopup 
                                acceptBtn='Delete' 
                                acceptClick={() => removeTicketFromTable(selectedTicket as EventTicket)} 
                                cancelClick={() => { 
                                    setShowDeletePopup(false);
                                    setSelectedTicket(null);
                                }} 
                                modalContent={`Are you sure you want to delete ${selectedTicket?.name}?`}
                                modalTitle='Delete Ticket'
                                show={showDeletePopup}
                                rejectBtn='Cancel'
                            />
                    }
                </>
            }

            {!isTableView && <div className="tickets-container">

                <EventsCardHeaderComponent extraActions={<CustomButton btnType='secondary' name='Invoice Settings' onClick={() => renderInvoiceSettingsPopup()} />} heading='Tickets' count={eventTicketsCount || undefined} buttonAction={() => navigate('/events/' + eventId + '/tickets')} />

                <Grid container spacing={2}>
                    {
                        tickets?.map((ticket: EventTicket, index: number) => {

                            const ticketTiming = `${moment.unix(Number(ticket.saleStartDateTime)).format('DD MMM')} - ${moment.unix(Number(ticket.saleCloseDateTime)).format('DD MMM')}`;
                            const ticketCount = `${ticket?.ticketsCount || '-'} / ${ticket?.totalTicketsAvailable}`;
                            const header = ticket?.name;
                            const contentHeading = ticket?.type === TicketType.PAID ? `₹${Number(ticket?.ticketPrice)?.toFixed(2)}` : 'Free';
                            const leftIcons = [ticket?.enableWaitlistStatus === EnableWaitlistStatus.ENABLE && <Tooltip title='Wailist Enabled'><FontAwesomeIcon color='#F79009' width={16} height={16} icon={['fal', 'user-clock']} /></Tooltip>, ticket?.enableApproval === EnableApprovalStatus.ENABLE && <Tooltip title='Approval Required'><FontAwesomeIcon color='#17B26A' width={16} height={16} icon={['fal', 'user-check']} /></Tooltip>];
                            const rightIcons = [<MuiChip label={ticket?.privateTicket === PrivateTicket.PUBLIC ? 'Public' : 'Private'} chipColor={ticket?.privateTicket === PrivateTicket.PUBLIC ? "violet" : "blue"}></MuiChip>];

                            return (
                                <Grid key={index} item xl={3} lg={4} md={4} sm={12} xs={12}>
                                    <CardComponent 
                                        header={header}
                                        contentHeading={contentHeading}
                                        contentHeadingBold
                                        iconsLeft={leftIcons}
                                        iconsRight={rightIcons}
                                        footerLeft={ticketTiming}
                                        footerRight={ticketCount}
                                        key={index}
                                        onClick={() => handleEditTicket(ticket)}
                                    />
                                </Grid>
                            )
                        })
                    }
                    <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                        <CardComponent 
                            emptyText='+ Add Ticket'
                            onClick={openDrawer}
                        />
                    </Grid>
                </Grid>
            </div>}

        </div>
    );
};

export default Ticket;