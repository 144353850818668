import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import APP_CONSTANTS from '../../../../../scripts/constants';
import { CONTENT } from '../../../../../scripts/i18n';
import eventBus from '../../../../../scripts/event-bus';
import { deleteUtm, getAllUtm, getAllUtmCount } from '../../../../../scripts/apis/utm';
import { useTablePagination } from '../../../../../contexts/TablePaginationContext';
import TableEmptyComponent from '../../../../../common/TableEmptyComponent';
import CreateUtm from '../../../../../components/Events/EventShareLink/CreateUTM';
import { LocalStorage } from '../../../../../scripts/LocalStorage';
import { EventUtm } from '../../../interfaces';
import { ActionsDropdown } from '../../../EventBudget/EventBudgetDataGrid';
import utmEmptyImg from '../../../../../assets/icons/empty-state/ICPEmptyState.svg';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { orgMe } from '../../../../../scripts/apis/organisation';
import { useSelector } from 'react-redux';
import { IEventReduxValues, IEventsDispatch } from '../../../../../components/Events/interfaces/create-events_interface';
import CardComponent from '../../../../../components/Events/Registrations/CardComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../../../../common/TanstackTable/TanstackTable';
import TanstackToolbar from '../../../../../common/TanstackTable/TanstackToolbar';
import HeaderBar from '../../../../../common/Headerbar';
import DeletePopup from '../../../../../common/DeletePopup';
import toast from 'react-hot-toast';
import EventsCardHeaderComponent from '../../../../../components/Events/EventComponents/EventsCardHeaderComponent';

import './styles.scss';
import TanstackToolbarWithServerFilter from '../../../../../common/TanstackTable/TanstackToolbarWithServerFilter';

const UtmComponent: React.FC<{eventId: string | number}> = (props: {eventId: string | number}): React.JSX.Element => 
{
    const navigate = useNavigate();

    const searchParams = new URLSearchParams();
    const currentpath = useLocation().pathname;
    const isTableView = currentpath.includes('share-links');

    const orgLinkLocalStorage = LocalStorage.get('@OrgLink');

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const eventLink = eventReduxData?.link;

    const [Utmrows, setUtmRows] = useState<EventUtm[]>([]);
    const [utmData, setUtmData] = useState<EventUtm[]>([]);
    const [UtmCount, setUtmCount] = useState<number>(0);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [refersh, setRefresh] = useState<boolean>(false);
    const [orgLink, setOrgLink] = useState<string>('');
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedUtm, setSelectedUtm] = useState<EventUtm | null>(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();

    useEffect((): void => 
    {
        if(!isTableView)
        {
            fetchUtmData(pageSize, currentPage - 1);
        }
        fetchOrgData();

        eventBus.on('utm_data_refresh', (data: any): void => 
        {
            setRefresh(data.refresh);
        });

    }, []);

    useEffect((): void => 
    {
        if (refersh) 
        {
            fetchOrgData();
            fetchUtmData(pageSize, currentPage - 1);
        }
    }, [refersh]);

    useEffect((): void => 
    {
        if (currentPage > 0 && pageSize) 
        {
            fetchUtmData(pageSize, currentPage - 1);
        }
    }, [pageSize, currentPage, currentUrl]);


    const fetchOrgData = async (): Promise<void> =>
    {
        try 
        {
            if(orgLinkLocalStorage)
            {
                setOrgLink(orgLinkLocalStorage);
            }
            else
            {
                const orgData = await orgMe();
                if(orgData)
                {
                    setOrgLink(orgData.link);
                }
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchUtmData = async (pageSizeValue?: number, offsetValue?: number): Promise<void> => 
    {
        try 
        {
            const utmCount = await getAllUtmCount(props.eventId);
            if (utmCount) 
            {
                setUtmCount(utmCount);
                setIsEmpty(utmCount == 0);
            }

            if(isTableView)
            {
                const params: [string, any][] = [];
                const query = new URLSearchParams(currentUrl.split('?')[1]);
                query?.forEach((value, key) => {
                    params.push([key, value]);
                });

                const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                const utmData = await getAllUtm(pageSizeValue, offsetValue, props.eventId, encodedParams);
                if (utmData) 
                {
                    setUtmRows(utmData);
                    setUtmData(utmData);
                    setRefresh(false);
                }
            }
            else
            {
                const utmData = await getAllUtm(6, 0, props.eventId);
                if (utmData) 
                {
                    setUtmRows(utmData);
                    setRefresh(false);
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const columnHelper = createColumnHelper<EventUtm>();

    const generateColumns = () => [
        columnHelper.accessor('name', {
            cell: (row) => <p className="bolded-cellContent">{row.getValue()}</p>,
            header: 'Name',
            size: 300,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('utmLink', {
            cell: (row) => <p className="cellContent">{row.getValue()}</p>,
            header: 'UTM Link',
            size: 250,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'utmLink'
        }),
        columnHelper.accessor('submissionCount', {
            cell: (row) => <p className="cellContent">{row.getValue()}</p>,
            header: 'Count',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'submissionCount'
        }),
        columnHelper.accessor('copyLink' as any, {
            cell: (row) => utmLinkCopy(row.row.original),
            header: '',
            size: 50,
            enableResizing: false,
            enableSorting: false,
            id: 'copyLink'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => utmRowActions(row.row.original),
            header: '',
            size: 50,
            enableResizing: false,
            enableSorting: false,
            id: 'actions'
        })
    ];

    useEffect(() => {
        setAllColumns(generateColumns);
    }, [orgLink, eventReduxData?.link]);

    const [allColumns, setAllColumns] = useState(generateColumns());

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Link',
            id: 'utmLink',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Count',
            id: 'submissionCount',
            meta: {
                type: 'string'
            }
        },
    ];

    const handleCopyLink = (utmLink: string): void => 
    {
        navigator.clipboard.writeText(`${APP_CONSTANTS.PROTOCOL}://${orgLink}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventReduxData?.link}?utm_source=${utmLink}`);

        toast.success(CONTENT.EVENTS_MODULE.OVERVIEW.ALERT_CONTENT.SUCCESS);
    };

    const utmLinkCopy = (utmInfo: EventUtm): React.ReactElement => 
    {
        return (
            <Box className="copyUtmLink" onClick={(): void => 
            {
                handleCopyLink(utmInfo.utmLink);
            }} >
                <FontAwesomeIcon icon={['fal', 'copy']} className="copy-utm-text" />
            </Box>
        );
    };

    const utmRowActions = (utmInfo: EventUtm): React.ReactElement => 
    {
        const editClick = (): void => 
        {
            editUtmData(utmInfo);
        };
    
        const deleteClick = (): void => 
        {
            setSelectedUtm(utmInfo);
            setShowDeletePopup(true);
        };
    
        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const editUtmData = (utmInfo: EventUtm): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Update UTM',
            component: <CreateUtm setRefresh={setRefresh} isTableView={isTableView} utmData={utmInfo} eventId={Number(props.eventId)} createUtm={false} updateUtm={true} utmId={Number(utmInfo.id)} orgLink={orgLink} eventLink={eventLink} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const createUtmData = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create UTM',
            component: <CreateUtm isTableView={isTableView} eventId={Number(props.eventId)} createUtm={true} updateUtm={false} orgLink={orgLink} eventLink={eventLink}  />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    // const handleDelete = (utmInfo: EventUtm): void => 
    // {
    //     const utmName = utmInfo.name;
    //     deleteUtmFromTable();
    // };

    const deleteUtmFromTable = async (utmInfo: EventUtm): Promise<void> => 
    {
        try 
        {
            const utmDeleted = await deleteUtm(Number(utmInfo?.id), props.eventId);
            if (utmDeleted) 
            {
                setShowDeletePopup(false);
                setSelectedUtm(null);
                toast.success('Share Link Deleted Successfully');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }           
    };

    // const editUTMFromTable = async (utmData: EventUtm): Promise<void> => 
    // {
    //     const utmDataInfo = {
    //         name: utmData.name,
    //         type: utmData.type ? utmData.type : 1,
    //         utmLink: utmData.utmLink,
    //     };
    //     try 
    //     {
    //         const updateUtmData = await updateUtm(utmDataInfo, Number(utmData.id), props.eventId);
    //         if (updateUtmData) 
    //         {
    //             eventBus.dispatch('utm_data_refresh', {
    //                 refresh: true 
    //             });
    //         }
    //     }
    //     catch (error) 
    //     {
    //         console.log(error);
    //         eventBus.dispatch('utm_data_refresh', {
    //             refresh: true 
    //         });
    //         if (error.statusCode === 400) 
    //         {
    //             eventBus.dispatch(APP_CONSTANTS.EVENTS.ALERT.OPEN, {
    //                 open: true,
    //                 message: error.message,
    //                 severity: 'error',
    //                 positionVertical: 'top',
    //                 positionHorizontal: 'right',
    //             });
    //         }
    //     }
    // };

    return (
        <Box id="utmContainer" style={{ height: isTableView ? 'calc(100vh - 60px)' : '', overflow: isTableView ? 'hidden' : '' }}>
            {isTableView &&
                <>
                    <HeaderBar 
                        title={<p><FontAwesomeIcon icon={['fal', 'arrow-left']} cursor="pointer" style={{ marginRight: '8px' }} onClick={() => navigate('/events/' + props?.eventId + '/registrations')} /> Share Links</p>}
                        buttons={[
                            <CustomButton 
                                btnType="secondary"
                                name='Create Share Link'
                                onClick={() => createUtmData()} 
                            />
                        ]}
                    />
                    
                    <Box className="utmTableContent">
                        {isEmpty ? (<TableEmptyComponent emptyImg={utmEmptyImg} openDrawer={createUtmData} infoText={'No Existing Share Links yet'} subInfoText={'Create your first Share Link'} buttonName={'Add Share Link'} />) : 
                        (
                            <div className="h-100">
                                {/* <TanstackToolbar 
                                    columns={generateColumns()}
                                    rows={Utmrows}
                                    setColumns={setAllColumns}
                                    setRows={setUtmData}
                                    tabs={[
                                        {
                                            tabName: 'All',
                                            count: UtmCount as number,
                                            navigation: () => {},
                                            selectedTab: true
                                        }
                                    ]}
                                /> */}
                                <TanstackToolbarWithServerFilter 
                                    columns={generateColumns()}
                                    filterColumns={filterColumns}
                                    setColumns={setAllColumns}
                                    setCurrentUrl={setCurrentUrl}
                                    tabs={[{
                                        tabName: 'All',
                                        count: UtmCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }]}
                                    handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                />
                                <TanstackTable 
                                    initialColumns={allColumns}
                                    data={utmData}
                                    rowCount={UtmCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage - 1}
                                    updatePageSize={updatePageSize}
                                    updateCurrentPage={updateCurrentPage as any}
                                    showSpinner={showSpinner}
                                    height='calc(100% - 124px)'
                                    onRowClick={(row) => editUtmData(row)}
                                    rightPinnedColumns={['actions']}
                                />
                            </div>
                        )}

                    </Box>

                    {
                        showDeletePopup &&
                            <DeletePopup 
                                acceptBtn='Delete' 
                                acceptClick={() =>deleteUtmFromTable(selectedUtm as EventUtm)} 
                                cancelClick={() => { 
                                    setShowDeletePopup(false);
                                    setSelectedUtm(null);
                                }} 
                                modalContent={`Are you sure you want to delete ${selectedUtm?.name}?`}
                                modalTitle='Delete Share Link'
                                show={showDeletePopup}
                                rejectBtn='Cancel'
                            />
                    }
                </>
            }

            {!isTableView && <div className="utm-container">

                <EventsCardHeaderComponent heading='Share Links' count={UtmCount} buttonAction={() => navigate('/events/' + props?.eventId + '/share-links')} />

                <Grid container spacing={2}>
                    {
                        Utmrows?.map((utm: EventUtm, index: number) => {

                            const header = utm?.name;
                            const rightIcon = [<FontAwesomeIcon icon={['fal', 'copy']} onClick={(event) => { event?.stopPropagation(); handleCopyLink(utm.utmLink); }} />];
                            const ticketNames = utm?.tickets?.map((ticket) => ticket.name).join(', ');
                            const footerLeft = `Registrants: ${utm?.submissionCount}`;

                            return (
                                <Grid key={index} item xl={3} lg={4} md={4} sm={12} xs={12}>
                                    <CardComponent 
                                        header={header}
                                        headerBold={true}
                                        iconsRight={rightIcon}
                                        contentHeading={ticketNames}
                                        onClick={(): void => editUtmData(utm)}
                                        footerLeft={footerLeft}
                                        key={index}
                                    />
                                </Grid>
                            )
                        })
                    }
                    <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                        <CardComponent 
                            emptyText='+ Add Share Link'
                            onClick={createUtmData}
                        />
                    </Grid>
                </Grid>
            </div>}
        </Box>
    );
};

export default UtmComponent;