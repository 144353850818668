import React, { useEffect, useMemo, useState } from 'react';
import { CONTENT } from '../../scripts/i18n';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { Box } from '@mui/material';
import _ from 'lodash';
import { count, deleteAudience, getAllAudience, getAudienceById } from '../../scripts/apis/audience';
import ViewAudienceInfo from './ViewAudienceInfo';
import { useTablePagination } from '../../contexts/TablePaginationContext';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AudienceGraph from './AudienceGraph';
import { Audience } from './interfaces';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { userMe } from '../../scripts/apis/users';
import { LocalStorage } from '../../scripts/LocalStorage';
import { User } from '../../interfaces/settings/user_interface';
import { TableActions } from '../../common/TableActions';
import DeletePopup from '../../common/DeletePopup';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { generateAudienceColumnsForTanstackTable } from './audienceColumn.helper';
import { createColumnHelper } from '@tanstack/react-table';
import toast from 'react-hot-toast';
import HeaderBar from '../../common/Headerbar';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';

import './styles.scss';

/**
 * @returns
 * Functional component to render the audience page
 * Variable to store the props data of the rows
 * Variable to store the column data
 * Variable to store the state of the audience rows
 * Function to handle the crud operations in the audience data grid row
 */

const AudiencePage = (): React.JSX.Element => 
{

    const navigate = useNavigate();
    const searchParams = new URLSearchParams();
    const currentpath = useLocation().pathname;

    const [rows, setRows] = useState<Audience[]>([]);
    const [audienceInfo, setAudienceInfo] = useState<Audience[]>([]);
    const [audCount, setAudCount] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [showSpinner, setShowSpinner] = useState(true);
    const [chartVisibility, setChartVisibility] = useState(false);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [selectedAudience, setSelectedAudience] = useState<Audience | null>(null);

    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const [userData, setUserData] = useState<User | undefined>(LocalStorage.get('@UserMe') || undefined);

    const { audienceId } = useParams();

    const { pageSize, currentPage, updatePageSize, updateCurrentPage } = useTablePagination();

    const columnHelper = createColumnHelper<Audience>();
    const generateInitialColumns = () => [
        columnHelper.accessor('actions', {
            cell: ({ row }) => (
                <TableActions 
                        actions={[
                            { title: 'View', onClick: () => openDrawer(row.original) },
                            { title: 'Delete', onClick: () => {
                                setSelectedAudience(row.original);
                                setShowDeletePopup(true);
                            } }
                        ]}
                    />
            ),
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        })
    ];

    let audienceColumns = [...generateAudienceColumnsForTanstackTable()];

    if (userData?.role === 3) {
        audienceColumns = [...audienceColumns, ...generateInitialColumns()];
    }
    const [allColumns, setAllColumns] = useState(audienceColumns);

    const filterColumns = [
        {
            header: 'First Name',
            id: 'firstName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Last Name',
            id: 'lastName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'email',
            meta: {
                type: 'string'
            }
        },
    ];

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    const openDrawer = (data, routeFromId?: string): void => 
    {
        const clearbitFName = data?.clearbitData?.givenName;
        const clearbitLName = data?.clearbitData?.familyName;

        const name = clearbitFName && clearbitLName ? clearbitFName + ' ' + clearbitLName : data?.firstName;

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: name,
            // event: 'edit-event',
            hideCloseButton: true,
            component: <ViewAudienceInfo audienceData={data} routeFromId={routeFromId} name={name} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.REQUIRED_SHOW, {
            hideRequiredField: true,
        });
    };

    const fetchTotalAudienceCount = async (): Promise<void> => 
    {
        try 
        {
            const audienceCount = await count();
            if (audienceCount) 
            {
                setAudCount(audienceCount);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchUserData = async (): Promise<void> =>
    {
        try 
        {
            const user = await userMe();
            if(user)
            {
                setUserData(user);
            }
        } 
        catch (error) 
        {
            console.log('Error in fetching user data');
        }
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (audienceInfo && audienceInfo?.length && audienceId) 
        {
            try 
            {
                const audienceDataFromId = await getAudienceById(Number(audienceId));
                if (audienceDataFromId) 
                {
                    openDrawer(audienceDataFromId, 'true');
                }
                else if (!audienceDataFromId) 
                {
                    toast.error('The audience you\'re trying to access doesn\'t exist');
                    navigate('/audiences');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            setShowSpinner(true);
            if (audCount) 
            {
                try 
                {
                    const params: [string, any][] = [];
                    const query = new URLSearchParams(currentUrl.split('?')[1]);
                    query?.forEach((value, key) => {
                        params.push([key, value]);
                    });

                    const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                    const audienceData = await getAllAudience(pageSize, currentPage - 1, encodedParams);

                    if (audienceData) 
                    {
                        setAudienceInfo([...audienceData]);
                        setIsEmpty(audienceData?.length === 0);
                        setRows([...audienceData]);
                        setShowSpinner(false);

                        // const initialColumnKeys = ['firstName', 'email', 'lastName'];
                        // const initialColumns = initialColumnKeys.map((field) => ({
                        //     field,
                        //     headerName: _.startCase(field),
                        //     // hide: !fieldsToShow.includes(field),
                        //     disableColumnMenu: true,
                        // }));

                        // try 
                        // {
                        //     const properties = await getAudienceColumnProperties();
                        //     if (properties) 
                        //     {
                        //         const updatedProperties = properties?.map(property => ({
                        //             ...property,
                        //             clearbit: true
                        //         }));

                        //         const filteredBaseColumnProperties = updatedProperties?.filter((property):boolean => 
                        //         {
                        //             return property.field !== 'email' && property.field !== 'firstName' && property?.field !== 'lastName';
                        //         })
                        //         setBaseColumnProperties([...initialColumns, ...filteredBaseColumnProperties]);
                        //     }
                        // }
                        // catch (error) 
                        // {
                        //     console.log(error);
                        // }
                    }

                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const deleteAudienceFn = async (): Promise<void> =>
    {
        try 
        {
            const couponDeleted = await deleteAudience(selectedAudience?.id as string);
            if (couponDeleted) 
            {
                toast.success(`${selectedAudience?.firstName} ${selectedAudience?.lastName || ''} has been removed successfully!`);
                setRefresh(true);
                setShowDeletePopup(false);
                setSelectedAudience(null);
            }
        }
        catch (error) 
        {
            toast.error((error as Error)?.message || 'Error deleting audience');
            console.log(error);
        }
    };

    useEffect((): void => 
    {
        fetchData();
        fetchTotalAudienceCount();

        if(!LocalStorage.get('@UserMe'))
        {
            fetchUserData();
        }
    }, []);

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, [audienceInfo]);

    useEffect((): void => 
    {

        if (currentPage > 0 && pageSize) 
        {
            fetchData();
        }

        eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
            heading: CONTENT.AUDIENCE_PAGE.HEADING,
            listCount: audCount,
            closedChartsButton: true,
            closedChartClickEventName: 'close-audience-page-charts',
        });
        eventBus.on('close-audience-page-charts', (data): void => 
        {
            if (data.closeChart) 
            {
                setChartVisibility(false);
            }
            else 
            {
                setChartVisibility(true);
            }
        });
    }, [pageSize, currentPage, audCount, currentUrl]);

    // useEffect(() => 
    // {
    //     const delayDebounceFn = setTimeout(async (): Promise<void> => 
    //     {
    //         if ((tableSearch && tableSearch.length >= 2 && (searchBy === 1 || searchBy === 2))) 
    //         {
    //             try 
    //             {
    //                 const audienceData = await searchAudience(
    //                     tableSearch?.includes('@') ? tableSearch : undefined,
    //                     !tableSearch?.includes('@') ? tableSearch : undefined,
    //                 );

    //                 if (audienceData) 
    //                 {
    //                     setRows(audienceData);
    //                 }
    //             }
    //             catch (error) 
    //             {
    //                 console.log(error);
    //             }
    //         }
    //         else if (tableSearch === '') 
    //         {
    //             fetchData();
    //             setShowSpinner(false);
    //         }
    //     }, 300);
    //     return (): void => 
    //     {
    //         return clearTimeout(delayDebounceFn);
    //     };
    // }, [tableSearch, searchBy]);

    useMemo(() => {
        if(refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <Box id="audiencePage">
            <HeaderBar 
                title='Audience'
                buttons={[
                    // <Box className="top-bar-search-container">
                    //     <FormControlComponent startIcon='search' key='search' clearable value={tableSearch} placeholder='Search' type='text' onChange={(event) => {
                    //             setTableSearch(event.target.value);
                    //         }} />
                    // </Box>,
                    <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                ]}
            />
            {chartVisibility && <AudienceGraph audCount={audCount} trendsTitle={'Total Audience'}></AudienceGraph>}
            <Box key={chartVisibility ? 'audience-page-chart-table' : 'audience-page-table'} sx={chartVisibility?{
                flex: 1,
                height: '56% !important',
            }:{
                height: 'calc(100vh - 60px)',
                maxHeight: 'calc(100vh - 60px)',
            }}>
                {/* <AudienceToolbar 
                    audienceCount={audCount}
                    columns={audienceColumns}
                    setColumns={setAllColumns}
                    rows={audienceInfo}
                    setRows={setRows}
                /> */}
                <TanstackToolbarWithServerFilter 
                    columns={audienceColumns}
                    setColumns={setAllColumns}
                    setCurrentUrl={setCurrentUrl}
                    tabs={[{
                        tabName: 'All',
                        count: audCount,
                        navigation: () => {},
                        selectedTab: true
                    }]}
                    handleApplyFilters={(filters) => {
                        handleApplyFilters(filters);
                    }}
                    filterColumns={filterColumns}
                />
                <TanstackTable 
                    data={rows}
                    initialColumns={allColumns}
                    rowCount={audCount}
                    pageSize={pageSize}
                    currentPage={currentPage - 1}
                    updateCurrentPage={updateCurrentPage as any}
                    updatePageSize={updatePageSize}
                    showSpinner={showSpinner}
                    // hidePagination={tableSearch?.length >= 2}
                    height={chartVisibility ? `calc(100% - 120px)` : `calc(100% - 124px)`}
                    rightPinnedColumns={['actions']}
                    onRowClick={(row) => openDrawer(row)}
                />
            </Box>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={deleteAudienceFn} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedAudience(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedAudience?.firstName} ${selectedAudience?.lastName || ''}?`}
                        modalTitle='Delete Audience'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </Box>
    );
};
export default AudiencePage;
