import React, { useContext, useState } from 'react';
import { Box } from '@mui/material';
import eventBus from '../../../scripts/event-bus';
import APP_CONSTANTS from '../../../scripts/constants';
import { CONTENT } from '../../../scripts/i18n';
import { resetBuyerPersona } from '../../../redux/settings/BuyerPersona';
import { useDispatch } from 'react-redux';
import TableEmptyComponent from '../../../common/TableEmptyComponent';
import { BuyerPersonaPageContext } from '../../../contexts/Settings/BuyerPersonaPageContext';
import { ActionsDropdown } from '../../Events/EventBudget/EventBudgetDataGrid';
import _ from 'lodash';
import buyerPersonaEmptyImg from '../../../assets/icons/empty-state/ICPEmptyState.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { BuyerPersona as IBuyerPersona } from '../interface/buyer-persona_interface';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';
import TanstackToolbar from '../../../common/TanstackTable/TanstackToolbar';
import DeletePopup from '../../../common/DeletePopup';

import './styles.scss';
import HeaderBar from '../../../common/Headerbar';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import BuyerPersona from '../../../components/BuyerPersona/AddBuyerPersona';

const BuyerPersonaPage = ():React.JSX.Element => 
{
    const dispatch = useDispatch();
    const { 
        isEmpty, 
        rows,
        setRows, 
        buyerPersonaDataCount, 
        buyerPersona, 
        deleteBuyerPersonaFromTable, 
        setRefresh, 
        pageSize, 
        currentPage, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner,
        showDeletePopup,
        setShowDeletePopup,
        selectedBuyerPersona,
        setSelectedBuyerPersona, 
    } = useContext(BuyerPersonaPageContext);

    const columnHelper = createColumnHelper<IBuyerPersona>();
    const columns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('role', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Role',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'role'
        }),
        columnHelper.accessor('subRole', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Sub Role',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'subRole'
        }),
        columnHelper.accessor('seniority', {
            cell: (row) => {
                const value = row.getValue();
                return (<p className="cellContent">{Array.isArray(value) ? value.join(', ') : value}</p>);
            },
            header: 'Seniority',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'seniority'
        }),
        columnHelper.accessor('description', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Description',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'description'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return buyerPersonaActions(row.row.original);
            },
            header: '',
            size: 40,
            enableSorting: false,
            enableResizing: false,
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(columns);

    const openDrawer = ():void => 
    {
        
        dispatch(resetBuyerPersona());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.BUYER_PERSONA.SIDE_DRAWER.HEADING.CREATE,
            component: < BuyerPersona setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const buyerPersonaActions = (buyerPersonaInfo: IBuyerPersona):React.JSX.Element => 
    {

        const editClick = ():void => 
        {
            handleViewEdit(buyerPersonaInfo);
        };

        const deleteClick = ():void => 
        {
            setSelectedBuyerPersona(buyerPersonaInfo);
            setShowDeletePopup(true);
        };

        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    const handleViewEdit = (buyerPersonaInfo: IBuyerPersona):void => 
    {

        dispatch(resetBuyerPersona());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SETTINGS_PAGE.BUYER_PERSONA.SIDE_DRAWER.HEADING.EDIT,
            component: <BuyerPersona buyerPersonaData={buyerPersonaInfo} is-editable={true} setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    return (
        <Box id="buyerPersonaPage">
            <HeaderBar 
                title={'Buyer Persona (BP)'}
                buttons={[
                    <CustomButton btnType="secondary" name="Create Buyer Persona" onClick={() => openDrawer()} />
                ]}
            />
            <Box sx={{
                flex: 1,
                height: 'calc(100% - 64px)', 
            }}>
                {isEmpty ? (<TableEmptyComponent emptyImg={buyerPersonaEmptyImg} openDrawer={openDrawer} infoText={'No Buyer Persona'} subInfoText={'Add your first Buyer Persona'} buttonName={'Add Buyer Persona'} />) :
                    (
                        <div className="h-100">
                            <TanstackToolbar 
                                columns={columns}
                                setColumns={setAllColumns}
                                rows={buyerPersona}
                                setRows={setRows}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: buyerPersonaDataCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={rows}
                                showSpinner={showSpinner}
                                pageSize={pageSize}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                rowCount={buyerPersonaDataCount}
                                currentPage={currentPage - 1}
                                rightPinnedColumns={['actions']}
                                onRowClick={(row) => handleViewEdit(row)}
                                height='calc(100% - 124px)'
                            />
                        </div>
                    )}
            </Box>

            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteBuyerPersonaFromTable(selectedBuyerPersona as IBuyerPersona)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedBuyerPersona(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedBuyerPersona?.name}?`}
                        modalTitle='Delete Buyer Persona'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </Box>
    );
};
export default BuyerPersonaPage;