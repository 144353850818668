/* eslint-disable import/named */
import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import { orgMe } from '../../scripts/apis/organisation';
import { decompressImage } from '../../common/ImageProcessing';
import { updateOrgInfo } from '../../redux/organization/Organization';
import _ from 'lodash';
import { Organization } from '../../interfaces/settings/organization_interface';

export const OrganisationPageContext = createContext(null);

export interface organization {
    organization: {
        value: IOrganizationReduxValue;
    }
}

export interface IOrganizationReduxValue {
    id: string;
    name: string;
    link: string;
}

const OrganisationPageProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element => 
{

    const orgObject = {
        name: '',
        link: '',
        additionalInfo: {
            image: null
        },
    };

    const dispatch = useDispatch();

    // const [org, setOrg] = useState(null);
    const [organisation, setOrganisation] = useState<Organization | null>(null);
    const [orgImageFile, setOrgImageFile] = useState(null);
    const [orgImage, setOrgImage] = useState('');
    const [orgLink, setOrgLink] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [orgLinkSpinner, setOrgLinkSpinner] = useState(false);
    const [orgLinkAvailable, isOrgLinkAvailable] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [isEditing, setIsEditing] = useState(true);
    const initialSocialOptions = [
        {
            name: 'Website', value: 'website'
        },
        {
            name: 'LinkedIn', value: 'linkedIn'
        },
        {
            name: 'Twitter', value: 'twitter'
        },
    ];

    const [socialOptionsObj, setSocialOptionsObj] = useState(initialSocialOptions);
    const [selectedSocial, setSelectedSocial] = useState(null);
    const [socialsInputFields, setSocialsInputFields] = useState([]);

    const orgReduxData = useSelector((state: organization): IOrganizationReduxValue => 
    {
        return state.organization.value;
    });

    eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
        heading: CONTENT.SETTINGS_PAGE.HEADING,
    });

    const fetchOrgData = async (): Promise<void> => 
    {
        const org = await orgMe();
        // setOrg(org);
        setOrganisation(org);
        dispatch(updateOrgInfo({
            name: org.name,
            link: org.link,
            additionalInfo: org.additionalInfo,
            gstin: org.gstin,
            address: org.address,
        }));
        // setValidateLink((org.link.split('qlorganisation')[0] === '') || (org.link.split('scorganisation')[0] === '') ? '' : org.link);
        // const organisationDetails = {

        // };
        // organisationDetails['name'] = org.name;
        // organisationDetails['link'] = org.link;
        // organisationDetails['additionalInfo'] = org.additionalInfo ? org.additionalInfo : new Object();
        setOrgLink(org.link);
        if (org.additionalInfo && org.additionalInfo?.image) 
        {
            const x = await decompressImage(org.additionalInfo?.image, 1500, 1500);
            setOrgImage(x);
        }
        else 
        {
            setOrgImage(null);
        }
        setOrgImageFile(org.additionalInfo && org.additionalInfo.image ? org.additionalInfo.image : null);
        if (org?.social) 
        {
            const socialInputs = Object.entries(org?.social)
                ?.filter(([, value]): boolean => 
                {
                    return value !== null;
                })
                ?.map(([name, value]): {name: string; value: string} => 
                {
                    return {
                        name,
                        value
                    };
                });

            if (socialInputs) 
            {
                const filteredOptions = _.filter(initialSocialOptions, function (elem): boolean 
                {
                    const stringedArr = _.map(socialInputs, 'name');
                    return !(stringedArr.includes(elem.value));
                });
                const socialsSortOrder = ['website', 'linkedIn', 'twitter'];
                const sortedOptionsArr = _.sortBy(filteredOptions, (obj): number => 
                {
                    return _.indexOf(socialsSortOrder, obj.value);
                });
                setSocialOptionsObj(sortedOptionsArr);
                setSocialsInputFields(_.sortBy(socialInputs, (obj): number => 
                {
                    return _.indexOf(socialsSortOrder, obj.name);
                }));
            }
        }

        setRefresh(false);
    };

    useEffect((): void => 
    {
        fetchOrgData();
    }, []);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchOrgData();
        }
    }, [refresh]);

    return (
        <OrganisationPageContext.Provider value={{
            orgObject, orgImage, setOrgImage, orgImageFile, setOrgImageFile, orgReduxData, orgLink, setOrgLink, setRefresh, orgLinkSpinner, setOrgLinkSpinner, orgLinkAvailable, isOrgLinkAvailable, organisation, spinner, setSpinner, socialOptionsObj, setSocialOptionsObj, selectedSocial, setSelectedSocial, socialsInputFields, isEditing, setIsEditing, setSocialsInputFields
        }}>
            {children}
        </OrganisationPageContext.Provider>
    );

};

export default OrganisationPageProvider;
