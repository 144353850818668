import { useState } from "react";
import FormTabs from "../../common/FormTabs";
import TableFilter from "../../common/TanstackTable/TableFilter";
import ColumnVisibilityDropdown from "../../common/TanstackTable/ColumnVisibilityDropdown";
import { User } from "../../interfaces/settings/user_interface";
import { CONTENT } from "../../scripts/i18n";
import { UserOrgStatus } from "../../pages/Settings/enum/users.enum";
import moment from "moment";

import './styles.scss';

const MembersPageToolbar: React.FC<{ count: number, rows: User[], setRows: React.SetStateAction<React.Dispatch<User[]>>, columns: any[], setColumns: React.SetStateAction<React.Dispatch<any[]>> }> = ({ setRows, count, rows, columns, setColumns }): React.JSX.Element =>
{
    const columnOptions = columns
        .filter(column => column.id !== 'actions' && column.id !== 'photo')
        .map(column => {
            return {
                label: typeof(column?.header) === 'string' ? column?.header : column?.meta?.headerName,
                value: column?.accessorKey || column?.id
            } 
        }
    );

    const [filters, setFilters] = useState([{ selectedColumn: columnOptions[1]?.value || '', inputValue: '' }]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].inputValue = value;
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const handleColumnChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => 
    {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].selectedColumn = value;
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const addFilter = () => 
    {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        const availableColumns = columnOptions.filter(option => !selectedColumns.includes(option.value));
        const defaultColumn = availableColumns.length > 0 ? availableColumns[0].value : '';
        setFilters([...filters, { selectedColumn: defaultColumn, inputValue: '' }]);
    };

    const removeFilter = (index: number) => 
    {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
        // filterFn(newFilters);
    };

    const clearFilters = () => 
    {
        setFilters([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
        // filterFn([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '' }]);
    };

    const getUserRole = (user: User) => { 
        return CONTENT.SETTINGS_PAGE.USERS.USER_ROLE[user.role as keyof typeof CONTENT.SETTINGS_PAGE.USERS.USER_ROLE];
    };

    const customFilterFunctions = {
        firstName: (obj: User, inputValue: string | number) => {
            if (obj.orgStatus === UserOrgStatus.ACTIVE)
            {
                const fullName = `${obj?.firstName || ''} ${obj?.lastName || ''}`.toLowerCase();
                return fullName.includes(inputValue?.toString()?.toLowerCase()) || false;
            }
            else
            {
                return false;
            }
        },
        lastActive: (obj: User, inputValue: string | number) => { 
            const lastActive = obj?.lastActive;
            return (moment(lastActive).format('DD MMM, YYYY')?.toString()?.toLowerCase()?.includes(inputValue?.toString()?.toLowerCase())) || false;
        },
        role: (obj: User, inputValue: string | number) => {
            const role = getUserRole(obj);
            return role && role?.toString()?.toLowerCase()?.includes(inputValue?.toString()?.toLowerCase()) || false;
        },
    };

    return (
        <div className="members-page-toolbar">
            <FormTabs 
                tabs={[{
                    tabName: 'All',
                    count: count,
                    navigation: () => {},
                    selectedTab: true,
                }]}
            />
            <div className="toolbar-actions">
                <TableFilter 
                    rows={rows}
                    columnOptions={columnOptions}
                    setRows={setRows}
                    filters={filters}
                    handleColumnChange={handleColumnChange}
                    handleInputChange={handleInputChange}
                    // filterFn={() => filterFn(filters)}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    clearFilters={clearFilters}
                    customFilterFunctions={customFilterFunctions}
                />
                <ColumnVisibilityDropdown 
                    columns={columns}
                    setColumns={setColumns}
                />
            </div>
        </div>
    )
};

export default MembersPageToolbar;