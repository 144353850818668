import React, { useEffect, useState } from 'react';
import { Box, Stack, Tooltip } from '@mui/material';
import { CONTENT } from '../../../scripts/i18n';
import eventBus from '../../../scripts/event-bus';
import { getEventById, publishEvent, updateEvent } from '../../../scripts/apis/events';
import _ from 'lodash';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { addEvent } from '../../../redux/events/Events';
import './styles.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import APP_CONSTANTS from '../../../scripts/constants';
import Ticket, { RegistrationTypes } from './Registrations/Tickets/Ticket';
import TicketsPageProvider from '../../../contexts/EventDetailsRegistration/TicketsPageContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LocalStorage } from '../../../scripts/LocalStorage';
import { ticketsCount } from '../../../scripts/apis/eventTickets';
import { uniqueTimezoneArr } from '../../../components/Events/timezoneGenerateFunction';
import { FormControlComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { IEventReduxValues, IEventsDispatch } from '../../../components/Events/interfaces/create-events_interface';
import SideBarTabsNavigation from '../../../common/SideBarTabsNavigation/SideBarTabsNavigation';
import { CustomButton } from '../../../common/FormComponents/Buttons';
import MuiChip from '../../../common/FormComponents/MuiChip';
import { Event } from '../interfaces';
import { getAllEventCategories } from '../../../scripts/apis/eventCategory';
import { EventRegistrationTypes } from '../enum';
import toast from 'react-hot-toast';
import NotFoundPage from '../../Registration/Preview/NotFoundPage';


const EventDetailsBar: React.FC<{ eventId: number; secondaryNavbar?: boolean; tab?: string; subTab?: string; }> = (props: { eventId?: number; secondaryNavbar?: boolean; tab?: string; subTab?: string }): React.JSX.Element => 
{

    const navigate = useNavigate();
    const tab = props.tab;

    const eventId = String(props.eventId);

    const { sessionId, stageId, expoId, scheduleId, ticketId, eventSponsorId, eventSpeakerId, eventVendorId, eventRegistrantId } = useParams();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    // const presentEventData = useSelector((state: IEventData) => 
    // {
    //     return state?.eventsData?.value;
    // });

    const secondaryNavBar = props.secondaryNavbar;
    const dispatch = useDispatch();
    const currentPath = useLocation().pathname;

    const [isPublishEventEnabled, setPublishedEventEnabled] = useState(false);
    const [publishEventsRequiredData, setPublishEventsRequiredData] = useState([]);
    const [isEventPublished, setEventPublished] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [updateEventName, setUpdateEventName] = useState(false);
    const [eventName, setEventName] = useState(eventReduxData.title);

    const [eventData, setEventData] = useState<Event | null>(null);
    const [eventCategory, setEventCategory] = useState<string>('');


    const regTicketPaths = {
        TICKETS: '/events/' + eventId + '/registrations/tickets',
        TICKETS_BY_ID: '/events/' + eventId + '/registrations/tickets/' + ticketId,
        TICKET_GROUPS: '/events/' + eventId + '/registrations/tickets/groups',
    };

    const regPagePaths = {
        DETAILS: '/events/' + eventId + '/registrations/details',
        TICKETS: '/events/' + eventId + '/registrations/tickets',
        TICKETS_BY_ID: '/events/' + eventId + '/registrations/tickets/' + ticketId,
        TICKET_GROUPS: '/events/' + eventId + '/registrations/tickets/groups',
        FORM: '/events/' + eventId + '/registrations/form',
    };

    const venuePagePaths = {
        SESSIONS: '/events/' + eventId + '/venue/sessions',
        SESSION_BY_ID: '/events/' + eventId + '/venue/sessions/' + sessionId,
        STAGE: '/events/' + eventId + '/venue/stage',
        STAGE_BY_ID: '/events/' + eventId + '/venue/stage/' + stageId,
        EXPO: '/events/' + eventId + '/venue/expo',
        EXPO_BY_ID: '/events/' + eventId + '/venue/expo/' + expoId,
        SCHEDULE: '/events/' + eventId + '/venue/schedule',
        SCHEDULE_BY_ID: '/events/' + eventId + '/venue/schedule/' + scheduleId,
    };

    const peoplePagePaths = {
        SPONSORS: '/events/' + eventId + '/people/sponsors',
        SPONSOR_BY_ID: '/events/' + eventId + '/people/sponsors/' + eventSponsorId,
        // SPONSOR_GROUPS: '/events/' + eventId + '/people/sponsors/tiers',
        SPEAKERS: '/events/' + eventId + '/people/speakers',
        SPEAKER_BY_ID: '/events/' + eventId + '/people/speakers/' + eventSpeakerId,
        REGISTRANTS: '/events/' + eventId + '/people/registrants',
        REGISTRANT_BY_ID: '/events/' + eventId + '/people/registrants/' + eventRegistrantId,
        VENDORS: '/events/' + eventId + '/people/vendors',
        VENDOR_BY_ID: '/events/' + eventId + '/people/vendors/' + eventVendorId,
    };

    const registrationsSecondaryNav = [
        // {
        //     title: 'Details',
        //     to: '/events/' + eventId + '/registrations/details',
        //     data: <Details data-id={eventId} eventReduxData={eventReduxData} />,
        //     selected: tab === 'details',
        // },
        {
            title: 'Ticket',
            to: '/events/' + eventId + '/registrations/tickets',
            data: <TicketsPageProvider eventId={Number(eventId)} ><Ticket eventReduxData={eventReduxData} tab={tab} eventId={Number(eventId)} /></TicketsPageProvider>,
            selected: tab === 'tickets',
            disabled: eventReduxData?.registrationType === RegistrationTypes.OPEN
        },
        // {
        //     title: 'Registration Form',
        //     to: '/events/' + eventId + '/registrations/form',
        //     data: <RegistrationForm reduxData={eventReduxData} data-id={eventId} />,
        //     selected: tab === 'regForm',
        // }
    ];

    if (eventReduxData?.registrationType === RegistrationTypes.OPEN && Object.values(regTicketPaths).includes(currentPath)) 
    {

        const ticketsTabObj = {
            title: 'Ticket',
            to: '/events/' + eventId + '/registrations/tickets',
            data: <NotFoundPage />,
            selected: tab === 'tickets',
            disabled: false
        };

        registrationsSecondaryNav.splice(1, 0, ticketsTabObj);
    }

    const PublishtheEvent = async (): Promise<void> => 
    {
        const publishSuccess = true;
        eventBus.on('publish-event', async (object): Promise<void> => 
        {
            const acceptObj = object.acceptObj;
            if (publishSuccess) 
            {
                if (acceptObj.id) 
                {
                    try 
                    {
                        const published = await publishEvent(Number(eventId));
                        if (published) 
                        {
                            setEventPublished(true);
                            toast.success('Event Published Successfully');

                            dispatch(addEvent({
                                status: published.status
                            }));
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                        toast.error(_.startCase((error as Error).message.toLowerCase()));
                    }

                }
            }

        });

    };

    const orgLink = LocalStorage.get('@Org')?.link;

    const handlePublish = async (): Promise<void> => 
    {
        try 
        {
            if ((Number(eventReduxData.status) === 2)) 
            {
                if (isDateinPast(eventReduxData.eventStartDateTime)) 
                {
                    toast.error('Event is already published');
                }
                else 
                {
                    toast.error('Fill up the required details');
                }

            }
            else 
            {
                if (isPublishEventEnabled) 
                {
                    eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
                        open: true,
                        title: 'Publish Event',
                        content: `Event link cannot be updated once the event is published.  https://${orgLink}.eventhq.com/${eventReduxData?.link} ?`,
                        acceptBtn: 'Publish',
                        acceptEvent: 'publish-event',
                        rejectBtn: CONTENT.EVENTS_PAGE.DELETE_POPUP.CANCEL_BTN,
                        acceptObj: {
                            id: eventId
                        },
                    });
                    PublishtheEvent();
                }
                else 
                {
                    if (publishEventsRequiredData.length > 0) 
                    {
                        toast.error('Fill up the required details');
                    }
                    else 
                    {
                        toast.error('Fill up the required details');
                    }
                }


            }

        }
        catch (error) 
        {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
        }
    };



    const handleCopyLink = (): void => 
    {
        const orgLink = LocalStorage.get('@Org')?.link;
        navigator.clipboard.writeText(`${APP_CONSTANTS.PROTOCOL}://${orgLink}.${APP_CONSTANTS.APP_MAIN_DOMAIN}/${eventData?.link}`);

        toast.success('Event Link Copied');
    };

    const fetchData = async (): Promise<void> => 
    {
        //    const  eventsData = CheckEventsById(eventId);
        try 
        {
            const eventsData = await getEventById(String(eventId));
            if (eventsData) 
            {
                // eventBus.on('event-details-bar', (data) => {
                //     setEventStatus(data.eventStatus);
                //     setEventHeading(data.heading);
                //     setTopBtn(data.topButton);
                // });

                setEventData(eventsData);

                const eventCategoryData = await getAllEventCategories();
                if (eventCategoryData) 
                {
                    const defaultCategory = _.filter(eventCategoryData, function (elem): boolean 
                    {
                        return elem.id === eventsData?.eventCategoryId;
                    });
                    if (defaultCategory && defaultCategory[0]?.name !== '') 
                    {
                        setEventCategory(defaultCategory[0]?.name);
                    }
                }

                setEventName(eventsData.title);

                const defaultTimezone = _.filter(uniqueTimezoneArr, function (elem): boolean 
                {
                    return elem.value === eventsData.timezone;
                });

                if (defaultTimezone) 
                {
                    dispatch(addEvent({
                        timezoneDisplayName: defaultTimezone[0]?.name ? defaultTimezone[0]?.name : ''
                    }));
                }

                dispatch(addEvent({
                    title: eventsData.title,
                    description: eventsData.description,
                    link: eventsData.link,
                    timeZone: eventsData.timezone,
                    type: eventsData.type,
                    registrationType: eventsData.registrationType,
                    registrationFormId: eventsData.registrationFormId,
                    eventStartDate: moment.unix(eventsData.eventStartDateTime).format('YYYY-MM-DD'),
                    eventStartTime: moment.unix(eventsData.eventStartDateTime).format('HH:mm'),
                    eventEndDate: moment.unix(eventsData.eventEndDateTime).format('YYYY-MM-DD'),
                    eventEndTime: moment.unix(eventsData.eventEndDateTime).format('HH:mm'),
                    eventStartDateTime: eventsData.eventStartDateTime,
                    eventEndDateTime: eventsData.eventEndDateTime,
                    closingDateTime: eventsData.closingDateTime,
                    logo: eventsData.eventLogo,
                    banner: eventsData.eventBanner,
                    brandColor: eventsData.brandColor,
                    country: eventsData.country ? eventsData.country : '',
                    state: eventsData.state ? eventsData.state : '',
                    status: eventsData.status,
                    registerOptions: eventsData?.registerOptions,
                    eventCategoryId: eventsData?.eventCategoryId,
                    landingPageType: eventsData?.landingPageType,
                    eventFooterLinks: eventsData?.eventFooterLinks,
                    landingPageLayout: eventsData?.landingPageLayout,
                    bannerPreviewImg: eventsData?.eventBanner,
                    about: eventsData?.about,
                    enableWaitlistStatus: eventsData?.enableWaitlistStatus,
                    locationName: eventsData?.locationDetails?.name,
                    locationURL: eventsData?.locationDetails?.url,
                    integrationType: eventsData?.integrationType,
                    leadCaptureFormId: eventsData?.leadCaptureFormId,
                    invoiceDetails: eventsData?.invoiceDetails,
                    vcardSettings: eventsData?.vcardSetting
                }));

                if (eventsData.registrationFormId && eventsData.closingDateTime && eventsData.eventBanner) 
                {
                    // if (eventsData.registrationType === 2) 
                    // {
                    try 
                    {
                        const eventTicketCount = await ticketsCount(Number(eventId));
                        if (eventTicketCount) 
                        {
                            if (eventTicketCount !== 0) 
                            {
                                setPublishedEventEnabled(true);
                                setRefresh(false);
                            }
                            else 
                            {
                                setPublishedEventEnabled(false);
                                setRefresh(false);
                            }
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }
                    // }
                    // else 
                    // {
                    //     setPublishedEventEnabled(true);
                    //     setRefresh(false);
                    // }

                }
                else 
                {
                    const requiredDetails: string[] = [];
                    if (!eventsData.registrationFormId) 
                    {
                        requiredDetails.push('registrationFormId');
                    }
                    if (!eventsData.closingDateTime) 
                    {
                        requiredDetails.push('closingDateTime');
                    }
                    // if (!eventsData.maximumRegistration) 
                    // {
                    //     requiredDetails.push('maximumRegistration');
                    // }
                    if (!eventsData.eventBanner) 
                    {
                        requiredDetails.push('eventBanner');
                    }
                    // if (eventsData.registrationType === 2) 
                    // {
                    try 
                    {
                        const eventTicketCount = await ticketsCount(Number(eventId));
                        if (eventTicketCount === 0) 
                        {
                            requiredDetails.push('ticketGroup');
                        }
                    }
                    catch (error) 
                    {
                        console.log(error);
                    }

                    // }
                    setPublishEventsRequiredData(requiredDetails);
                    setPublishedEventEnabled(false);
                    setRefresh(false);
                }

                if (eventsData.status === 2) 
                {
                    if (isDateinPast(eventsData.eventStartDateTime)) 
                    {
                        setEventPublished(true);
                    }
                    else 
                    {
                        setEventPublished(false);
                    }
                }
                else 
                {
                    setEventPublished(false);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect((): void => 
    {
        eventBus.on('event-details-refreshed', (data): void => 
        {
            if (data.refresh) 
            {
                setRefresh(data.refresh);
            }
        });
        fetchData();
    }, []);

    const fetcheventDetails = async (): Promise<void> => 
    {
        if (!isPublishEventEnabled) 
        {
            setPublishEventsRequiredData([]);
            try 
            {
                const eventsData = await getEventById(String(eventId));
                if (eventsData) 
                {
                    if (eventsData.registrationFormId && eventsData.closingDateTime && eventsData.maximumRegistration && eventsData.eventBanner) 
                    {
                        try 
                        {
                            const eventTicketCount = await ticketsCount(Number(eventId));
                            if (eventTicketCount) 
                            {
                                if (eventTicketCount !== 0) 
                                {
                                    setPublishedEventEnabled(true);
                                    setRefresh(false);
                                }
                                else 
                                {
                                    setPublishedEventEnabled(false);
                                    setRefresh(false);
                                }
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }
                    }
                    else 
                    {
                        const requiredDetails: string[] = [];
                        if (!eventsData.registrationFormId) 
                        {
                            requiredDetails.push('registrationFormId');
                        }
                        if (!eventsData.closingDateTime) 
                        {
                            requiredDetails.push('closingDateTime');
                        }
                        if (!eventsData.maximumRegistration) 
                        {
                            requiredDetails.push('maximumRegistration');
                        }
                        if (!eventsData.eventBanner) 
                        {
                            requiredDetails.push('eventBanner');
                        }

                        try 
                        {
                            const eventTicketCount = await ticketsCount(Number(eventId));
                            if (eventTicketCount === 0) 
                            {
                                requiredDetails.push('ticketGroup');
                            }
                        }
                        catch (error) 
                        {
                            console.log(error);
                        }

                        setPublishEventsRequiredData(requiredDetails);
                        setPublishedEventEnabled(false);
                        setRefresh(false);
                    }
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
        else 
        {
            fetchData();
        }
    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetcheventDetails();
        }
    }, [refresh]);

    const editEventsFromTable = async (): Promise<void> => 
    {
        const eventDetails = {
            title: eventName,
        };
        try 
        {
            const eventsDataUpdated = await updateEvent(String(eventId), eventDetails);
            if (eventsDataUpdated) 
            {
                setRefresh(true);

                eventBus.dispatch('event-overview-refreshed', {
                    refresh: true
                });

                eventBus.dispatch('Refresh-events-top-bar', {
                    refresh: true
                });

                if (!(eventsDataUpdated instanceof Error)) 
                {
                    dispatch(addEvent({
                        title: eventsDataUpdated.title
                    }));
                }

                setUpdateEventName(!updateEventName);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    return (
        <Box id="subEventDetailsContainer" sx={{
            flex: `${secondaryNavBar ? 1 : 'none'}`
        }}>
            {/* <BreadCrumbBar /> */}
            <Box id="eventDetailsBar">

                {/* <Grid container className="event-details-bar">
                    <Grid item xl={10} lg={10} md={10} sm={12} className="event-nav-bar-container">
                    </Grid>
                    <Grid item xl={2} lg={2} md={2} sm={12} className="action-btn-container">
                        <Stack direction={'row'} className="preview-btn-stack" spacing={2}>
                        <Button
                                className="preview-btn"
                            >
                                {CONTENT.EVENTS_MODULE.EVENT_INFO.BUTTONS.PREVIEW}
                            </Button>
                            <Button
                                className="btn-style"
                                variant="contained"
                                size="large"
                                onClick={() => { handlePublish() }}
                            >
                                {CONTENT.EVENTS_MODULE.EVENT_INFO.BUTTONS.PUBLISH}
                            </Button>
                        </Stack>
                    </Grid>
                </Grid> */}
                <div className="events-name-block">
                    <Box className="events-name-redirect-icon-container">
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {updateEventName ?
                                <Box className='update-name'>
                                    <Box className="icon-box" onClick={(): void => 
                                    {
                                        setUpdateEventName(!updateEventName); setEventName(eventReduxData.title);
                                    }}>
                                        <FontAwesomeIcon icon={['fal', 'xmark']} color="#E2341D" />
                                    </Box>

                                    <FormControlComponent
                                        type="text"
                                        value={eventName}
                                        onChange={(event): void => 
                                        {
                                            setEventName(event.target.value);
                                        }}
                                        required
                                        placeholder="Enter Event Name"
                                    />

                                    <Box className="icon-box" onClick={(): void => 
                                    {
                                        editEventsFromTable();
                                    }}>
                                        <FontAwesomeIcon icon={['fal', 'circle-check']} />
                                    </Box>

                                </Box> :
                                <div className="event-name-title" onClick={(): void => 
                                {
                                    setUpdateEventName(!updateEventName);
                                }}>{eventReduxData.title}</div>
                            }

                            {
                                isEventPublished && eventReduxData.type === 1 ?
                                    <Tooltip placement="right-end" disableInteractive title="Copy Event Public Link">
                                        <FontAwesomeIcon icon={['fal', 'copy']} className="event-link-copy-icon" onClick={(): void => 
                                        {
                                            handleCopyLink();
                                        }} />
                                    </Tooltip>
                                    : null
                            }
                        </Stack>

                        <Stack direction={'row'} spacing={0.5}>
                            <Tooltip
                                title="Event Category"
                                arrow
                                disableInteractive
                                placement="bottom"
                            >
                                <div>
                                    <MuiChip label={eventCategory} chipColor='grey' />
                                </div>
                            </Tooltip>

                            <Tooltip
                                title={eventData?.registrationType === EventRegistrationTypes.INVITE ? "Requires Approval to attend" : "Approval not Required"}
                                arrow
                                disableInteractive
                                placement="bottom"
                            >
                                <div>
                                    <MuiChip label={eventData?.registrationType === EventRegistrationTypes.INVITE ? 'Approval Required' : 'Open'} chipColor='grey' />
                                </div>
                            </Tooltip>
                        </Stack>
                    </Box>
                    <div className="action-buttons">
                        {/* {isPublishEventEnabled ? ( */}
                        <a href={APP_CONSTANTS.APP_DOMAIN + '/' + orgLink + '/' + eventReduxData.link + '/preview'} target='_blank' rel="noreferrer">
                            <CustomButton btnType='secondary' name='Preview Event' type='button' />
                        </a>

                        <CustomButton disabled={isEventPublished} btnType='primary' name={isEventPublished ? 'Published' : 'Publish'} onClick={() => 
                        {
                            if(!isEventPublished)
                            {
                                handlePublish();
                            }
                        }} />
    
                        {/*  ) : ( */}
                        {/* <div
                                className="publish-event-btn-disabled"
                            >
                                <Box> <FontAwesomeIcon icon={['fal', 'upload']} /></Box>
                                <Box>{CONTENT.EVENTS_MODULE.EVENT_INFO.BUTTONS.PUBLISH}</Box>

                            </div> */}
                        {/* )} */}

                    </div>
                </div>
                {/* <div className="event-details-bar-1">
                    <div className="event-nav-bar-container-1">
                    </div>
                </div> */}

                {/* {
                    secondaryNavBar ?
                        <Box width={'100%'} className="secondary-navbar">
                            {Object.values(regPagePaths).includes(currentPath) ?
                                <Box className="tabs-container"><NavigationTabs tabData={registrationsSecondaryNav} /></Box>
                                : null}
                            {Object.values(venuePagePaths).includes(currentPath) ?
                                <Box className="tabs-container"><NavigationTabs tabData={venueSecondaryNav} /></Box>
                                : null}
                            {Object.values(peoplePagePaths).includes(currentPath) ?
                                <Box className="tabs-container"><NavigationTabs tabData={peopleSecondaryNav} /></Box>
                                : null}
                        </Box>
                        :
                        null
                } */}
            </Box>
            <Box className="event-details-block-with-tab">

                {
                    secondaryNavBar &&
                    <SideBarTabsNavigation
                        navigationBlocks={[
                            {
                                title: 'Overview',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/overview'); 
                                },
                                selected: ['overview', 'speakers', 'sponsors', 'vendors'].some(path => currentPath?.includes(path)),
                            },
                            // {
                            //     title: 'Forms',
                            //     navigation: ():void => 
                            //     {
                            //         return navigate('/events/' + eventId + '/registrations/details'); 
                            //     },
                            //     selected: currentPath === '/events/' + eventId + '/registrations/details'
                            // },
                            {
                                title: 'Registrations',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/registrations'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/registrations'
                            },
                            {
                                title: 'Agenda',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/agenda'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/agenda',
                            },
                            // {
                            //     title: 'Tickets',
                            //     navigation: ():void => 
                            //     {
                            //         return  navigate('/events/' + eventId + '/tickets'); 
                            //     },
                            //     selected: currentPath === '/events/' + eventId + '/tickets'
                            // },
                            {
                                title: 'Registrants',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/registrants'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/registrants'
                            },
                            {
                                title: 'Sponsors',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/sponsors'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/sponsors'
                            },
                            {
                                title: 'Speakers',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/speakers'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/speakers'
                            },
                            {
                                title: 'Vendors',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/people/vendors'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/people/vendors'
                            },
                            {
                                title: 'Budget',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/budget'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/budget'
                            },
                            {
                                title: 'Marketing',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/marketing'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/marketing'
                            },
                            {
                                title: 'Analytics',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/analytics'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/analytics'
                            },
                            {
                                title: 'Access Control',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/access-control'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/access-control',
                                //   icon: 'chart-simple'
                            },
                            {
                                title: 'Connect',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/connect'); 
                                },
                                selected: currentPath === '/events/' + eventId + '/connect',
                                //   icon: 'chart-simple'
                            },
                            {
                                title: 'Photo Sharing',
                                navigation: ():void => 
                                {
                                    return navigate('/events/' + eventId + '/photo-sharing'); 
                                },
                                selected: currentPath?.includes('photo-sharing'),
                                //   icon: 'chart-simple'
                            },
                        ]}
                    />
                }
                {
                    // (secondaryNavBar && eventReduxData?.registrationType === RegistrationTypes.OPEN && Object.values(regTicketPaths).includes(currentPath)) ? <NotFoundPage /> : secondaryNavBar ? (<Box id="secondaryNav">
                    //     <Box width={'100%'} className="secondary-navbar">
                    //         {Object.values(venuePagePaths).includes(currentPath) ?
                    //             <Box className="tabs-container"><NavigationTabs tabData={venueSecondaryNav} /></Box>
                    //             : null}
                    //         {Object.values(peoplePagePaths).includes(currentPath) ?
                    //             eventReduxData.type === 1 ? <Box className="tabs-container"><NavigationTabs tabData={peopleSecondaryNavHost} /></Box> :
                    //                 <Box className="tabs-container"><NavigationTabs tabData={peopleSecondaryNav} /></Box>

                    //             : null}
                    //     </Box>
                    // </Box>) : null
                }

            </Box>
          
        </Box>
    );
};

export default EventDetailsBar;



const isDateinPast = (date): boolean => 
{
    const currentDate = moment.now();
    const difference = currentDate - (date * 1000);

    if (difference < 0) 
    {
        return true;
    }
    else if (difference <= 5 * 60 * 1000) 
    {
        return false;
    }
    else if (difference >= 5 * 60 * 1000) 
    {
        return true;
    }
    else 
    {
        return false;
    }
};