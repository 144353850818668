import React, { useState, useEffect, useRef } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { CONTENT } from '../scripts/i18n';
import eventBus from '../scripts/event-bus';
import APP_CONSTANTS from '../scripts/constants';
import { userMe } from '../scripts/apis/users';
import { logoutUser } from '../scripts/main';
import './styles.scss';
import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import { buildInitialsForPicture } from '../scripts/helpers';
import { updateGeneralInfo } from '../redux/general/General';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { general } from '../contexts/Settings/PersonalPageContext';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { SidebarProps } from './ISideBarNavigation';

// When devrev is required use this in index.html
{/* <script type="text/javascript" src="https://plug-platform.devrev.ai/static/plug.js"></script> */ }


// import LogoImg from '../../public/light/eventhq_light.png';
//import LogoImgClosed from '../assets/images/eventhq_logo_icon.svg';

/**
 * @returns
 * Varible to store the drawer width
 * Function to store the style of the dynamic side bar while it is opened
 * Function to store the style of the dynamic side bar while it is closed
 * Function to store the style of the side bar
 * Functional component to render a side bar
 * Variable to store the local storage data of the logged in user
 * Variable to store the local storage data of the logged in user's organisation data
 * Variable to store the navigation event using a react hook
 * Variable to store an object of the initial user data
 * Variable to store an object of the initial organisation data
 * Variable to store the userObject which is the initial user's data
 * Variable to store the state of the organisation image in the side drawer
 * Variable to store the current path of the browser tab
 * Function to handle logout event
 * Function to handle the navigation to homepage when the organisation image is clicked
 * Function to handle the tab change event when clicking on the side bar's list item
 */

// import * as eventHqLogo from '../assets/eventhq.png'; 

import eventHqLogo from '../assets/eventhq.png';
import DeletePopup from './DeletePopup';
import { registrantMe, unauthorizeSponsor } from '../scripts/apis/sponsorPortal/sponsorPortal';
import { LocalStorage } from '../scripts/LocalStorage';
import { EventRegistrant } from '../pages/Events/interfaces/event-registrant_interface';
import SwitchOrgMenu from './SwitchOrgMenu';
import { getUsersOrganizations, orgMe } from '../scripts/apis/organisation';
import { CurrentUserOrgInfo, Organization } from '../interfaces/settings/organization_interface';
import { IBillingInfo } from '../redux/billing/billing-redux_interface';
import { logoutWithWorkOS } from '../scripts/apis/authentication';

const SideBarNavigation = (props): React.JSX.Element => 
{

    const { sidebarcontent } = props;

    const drawerWidth = APP_CONSTANTS.SIDE_DRAWER_WIDTH;

    const [open, setOpen] = useState(true);
    const containerRef = useRef<HTMLDivElement>();
    const [userOnline, setUserOnline] = useState(null);
    const [registrantInfo, setRegistrantInfo] = useState<EventRegistrant | undefined>(undefined);
    const [showSponsorLogoutPopup, setShowSponsorLogoutPopup] = useState<boolean>(false);
    const [usersOrg, setUsersOrg] = useState<CurrentUserOrgInfo[]>([]);
    const [currentOrg, setCurrentOrg] = useState<Organization>();

    const handleLogout = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
            open: true,
            title: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.TITLE,
            content: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.CONFIRMATION_MESSAGE,
            acceptEvent: APP_CONSTANTS.EVENTS.POPUP_EVENT.RESULT,
            acceptBtn: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.ACCEPT,
            rejectBtn: CONTENT.SIDE_NAV_BAR.LOGOUT_CONTENT.CANCEL,
        });
    };

    const openedMixin = (theme: Theme): CSSObject => 
    {
        return {
            width: drawerWidth,
            backgroundColor: '#ffffff',
            borderRight: '1px solid #e3e3e8',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            paddingTop: import.meta.env.VITE_NODE_ENV === 'demo' && '56px',
            overflowX: 'hidden',
            zIndex: 'unset',
        };
    };
    const collapsedOpenedMixin = (theme: Theme): CSSObject => 
    {
        return {
            width: drawerWidth,
            backgroundColor: '#ffffff',
            borderRight: '1px solid #e3e3e8',
            // maxWidth: '49px !important',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            paddingTop: import.meta.env.VITE_NODE_ENV === 'demo' && '56px',
            overflowX: 'hidden',
            boxShadow: '0px 10px 30px 0px #00000033, 0px 30px 70px 0px #1A224026, 0px 0px 0px 1px #888FAA1A !important',
        };
    };
    const closedMixin = (theme: Theme): CSSObject => 
    {
        return {
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            backgroundColor: '#ffffff',
            borderRight: '1px solid #e3e3e8',
            width: 'calc(64px + 1px)',
            zIndex: 'unset',
            paddingTop: import.meta.env.VITE_NODE_ENV === 'demo' && '56px',
            // marginLeft: '-4px',
            [theme.breakpoints.up('sm')]: {
                width: 'calc(64px + 1px)',
            },
        };
    };


    const DrawerHeader = styled('div')(({ theme }) => 
    {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: theme.spacing(0, 1),
            ...theme.mixins.toolbar,
        };
    });

    const Drawer = styled(MuiDrawer, {
        shouldForwardProp: (prop: string): boolean => 
        {
            return prop !== 'open';
        }
    })(
        ({ theme, open }) => 
        {
            return {
                width: drawerWidth,
                flexShrink: 0,
                whiteSpace: 'nowrap',
                boxSizing: 'border-box',
                ...(open && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': openedMixin(theme),
                }),
                ...(open && props['collapsedSideBar'] && {
                    ...openedMixin(theme),
                    '& .MuiDrawer-paper': collapsedOpenedMixin(theme),
                }),
                ...(!open && {
                    ...closedMixin(theme),
                    '& .MuiDrawer-paper': closedMixin(theme),
                }),
            };
        },
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currentPath = useLocation().pathname;

    const generalData = useSelector((state: general): SidebarProps => 
    {
        return state.general.value;
    });

    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    // const [devrevToggled, isDevrevToggled] = useState(false);
    // const [devrevReady, isDevrevReady] = useState(false);

    const handleSponsorPortalLogout = async (): Promise<void> =>
    {
        try 
        {
            const isLoggedOut = await unauthorizeSponsor();
            if(isLoggedOut)
            {
                LocalStorage.removeAll();
                navigate(0);
            }
        } 
        catch (error) 
        {
            
        }
    };

    const userOrgInformation = async (): Promise<void> => 
    {
        try 
        {
            const user = await userMe();
            if (user) 
            {
                setUserOnline(user);
                dispatch(updateGeneralInfo({
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                    image: user?.additionalInfo?.image ? user.additionalInfo.image : null,
                    role: user?.role,
                }));
            }
            // const org = await orgMe();
            // if (org) {
            //     setOrgImage(org);
            // }

        }
        catch (error) 
        {
            console.log(error);
        }

    };

    const fetchRegistrantMe = async (): Promise<void> =>
    {
        try 
        {
            const registrant = await registrantMe();
            if(registrant)
            {
                setRegistrantInfo(registrant);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchUsersOrg = async (): Promise<void> => 
        {
            try 
            {
                const userOrg = await getUsersOrganizations();
                setUsersOrg(userOrg);
            }
            catch (error) 
            {
                console.log(error);
            }
        };
    
        const fetchCurrentOrg = async (): Promise<void> => 
        {
            try 
            {
                const org = await orgMe();
                setCurrentOrg(org);
            }
            catch (error) 
            {
                console.log(error);
            }
        };

    useEffect((): void => 
    {
        if(!currentPath?.includes('sponsor-portal'))
        {
            userOrgInformation();
            fetchUsersOrg();
            fetchCurrentOrg();
        }
        else if(currentPath?.includes('sponsor-portal'))
        {
            fetchRegistrantMe();
        }

        eventBus.on(APP_CONSTANTS.EVENTS.POPUP_EVENT.RESULT, async (data): Promise<void> => 
        {
            if (data.result) 
            {
                await (window as any).plugSDK.shutdown();
                await logoutWithWorkOS();
                LocalStorage.removeAll();
                navigate('/login');
            }
        });
    }, []);


    useEffect((): () => void => 
    {
        if (props['collapsedSideBar']) 
        {
            setOpen(false);
            const navElement = containerRef.current;
            if (containerRef.current) 
            {
                navElement.addEventListener('mouseenter', handleMouseEnter);
                navElement.addEventListener('mouseleave', handleMouseLeave);
                navElement.addEventListener('touchstart', handleTouchStart);
                navElement.addEventListener('touchend', handleTouchEnd);
                return (): void => 
                {
                    navElement.removeEventListener('mouseenter', handleMouseEnter);
                    navElement.removeEventListener('mouseleave', handleMouseLeave);
                    navElement.removeEventListener('touchstart', handleTouchStart);
                    navElement.removeEventListener('touchend', handleTouchEnd);
                };
            }


        }
        else 
        {
            setOpen(true);
        }

    }, [props]);

    useEffect(() => 
    {
        (window as any).plugSDK.init({
          app_id: import.meta.env.VITE_DEVREV_APP_ID,
          spacing: {
            // side: "50px",
            // bottom: "50px"
          }
        });

        (window as any).plugSDK.onEvent((payload: any) => 
        {
            if (payload.type === 'ON_PLUG_WIDGET_READY') 
            {
                (window as any).plugSDK.initSearchAgent();
            }
        });
    }, []);

    // useEffect((): void => 
    // {
    //     const setupDevrev = async (): Promise<void> => 
    //     {
    //         const sessionTokenObj = await getDevrevSessionToken();
    //         if (sessionTokenObj) 
    //         {
    //             (window as any).plugSDK.init({
    //                 app_id: import.meta.env.VITE_DEVREV_APP_ID,
    //                 session_token: sessionTokenObj.access_token,
    //                 enable_default_launcher: false,
    //                 custom_launcher_selector: '.devrev-launcher',
    //                 widget_alignment: 'right',
    //                 spacing: {
    //                     side: '-464px',
    //                     bottom: '0px'
    //                 }
    //             });
    //             (window as any).plugSDK.onEvent((payload): void => 
    //             {
    //                 if (payload.type === 'ON_PLUG_WIDGET_READY') 
    //                 {
    //                     isDevrevReady(true);
    //                 }
    //             });
    //         }
    //     };
    //     setupDevrev();
    // }, []);

    // Function to handle mouse hover
    const handleMouseEnter = (): void => 
    {
        // setOpen(true);
    };

    // Function to handle mouse leave
    const handleMouseLeave = (): void => 
    {
        // setOpen(false);
    };
    const handleTouchStart = (): void => 
    {
        // setOpen(true);
    };

    // Function to handle touch end
    const handleTouchEnd = (): void => 
    {
        // setOpen(false);
    };


    const DropdownMenu = (): React.JSX.Element => 
    {
        return (<>
            <Dropdown.Item onClick={(): void => 
            {
                navigate('/settings/general');
            }}>
                <Box className="user-account-menu-container">
                    {/* {userOnline.additionalInfo && userOnline.additionalInfo.image && userOnline.additionalInfo.image !== null || '' ? <Avatar className="user-picture-avatar" src={userOnline.additionalInfo.image}></Avatar> : <Avatar className="user-empty-avatar">{buildInitialsForPicture(userOnline.firstName, userOnline.lastName, userOnline.email)}</Avatar>} */}
                    {generalData && generalData?.image && generalData?.image !== null || '' ? <Avatar className="user-picture-avatar" src={generalData.image}></Avatar> : <Avatar className="user-empty-avatar">{buildInitialsForPicture(userOnline?.email || '', userOnline?.firstName || '', userOnline?.lastName || '')}</Avatar>}
                    <Tooltip placement="top-end" disableInteractive title={<Stack direction={'column'}><Typography variant="subtitle2">{userOnline?.firstName + ' ' + userOnline?.lastName}</Typography><Typography variant="subtitle2">{userOnline?.email}</Typography></Stack>}>
                        <Box className="user-name-email-container">
                            <Typography className="user-name">{userOnline?.firstName + ' ' + userOnline?.lastName}</Typography>
                            <Typography className="user-email">{userOnline?.email}</Typography>
                        </Box>
                    </Tooltip>
                </Box>
            </Dropdown.Item><Dropdown.Divider /><Dropdown.Item onClick={(): void => 
            {
                navigate('/settings/users');
            }}>
                <Box className="invite-member-container">
                    <FontAwesomeIcon className="invite-icon" icon={['fal', 'user-plus']} />
                    <Typography className="invite-text">{'Invite Members'}</Typography>
                </Box>
            </Dropdown.Item>
            {orgBillingInfo?.billingModules?.integrations && <Dropdown.Item onClick={(): void => 
            {
                navigate('/settings/integrations');
            }}>
                <Box className="settings-container">
                    <FontAwesomeIcon icon={['fal', 'gear-complex']} className="settings-icon" />
                    <Typography className="settings-text">{'Settings'}</Typography>
                </Box>
            </Dropdown.Item>}
            <Dropdown.Divider /><Dropdown.Item onClick={handleLogout}>
                <Box className="logout-container">
                    <FontAwesomeIcon className="logout-icon" icon={['fal', 'arrow-right-from-bracket']} />
                    <Typography className="logout-text">{'Logout'}</Typography>
                </Box>
            </Dropdown.Item>
        </>);
    };

    return (
        <div id="SideBarNavigation" style={{
            position: `${props['collapsedSideBar'] ? 'absolute' : 'relative'}`
        }} ref={containerRef}>
            <Box>
                <CssBaseline />
                <Drawer open={open} variant="permanent" className="side-bar">
                    {/* <hr className="m-0" /> */}
                    <Box className="drawer-header-box" sx={open? null : {
                        marginLeft: '4px !important',
                    }}>
                        <DrawerHeader className="drawer-header" sx={open? null : {
                            width: '42px !important',
                            overflow: 'hidden',
                        }}>
                            <Box className="org-menu">
                                
                                {
                                    !currentPath?.includes('sponsor-portal') && <SwitchOrgMenu usersOrg={usersOrg} currentOrg={currentOrg} />
                                }
                            </Box>
                            {/* } */}
                           
                        </DrawerHeader>
                    </Box>

                    {/* {import.meta.env.VITE_NODE_ENV !== 'production' ? <Box>
                        <CmdKButton width={'100%'} height={'28px'} margin={'16px 12px 16px 8px'} />
                        {open ? <Box 
                        onClick={CommandKOpen} 
                            className="cmdk-container">
                            <Stack direction={'row'} alignItems={'center'}>
                                <FontAwesomeIcon icon={['fal', 'magnifying-glass']} />
                                {open ? <Typography className="search-text">{'Search'}</Typography> : null}
                            </Stack>
                            <Box>
                                {open ? <Typography className="command-text">{'cmd+k'}</Typography> : null}
                            </Box>
                        </Box> : <Box 
                            onClick={CommandKOpen} 
                            className="cmdk-container-close">
                            <FontAwesomeIcon icon={['fal', 'magnifying-glass']} />
                        </Box>}
                    </Box> : ''} */}
                    {/* onClick={CommandKOpen} */}

                    <List className="side-bar-list">
                        {
                            sidebarcontent && sidebarcontent?.mainContent && sidebarcontent?.mainContent?.length > 0 && sidebarcontent?.mainContent.map((item, index: number) => {
                                return (
                                    <ListItem key={index} disablePadding component={Link} className={item?.selected ? 'activeTabs' : ''} to={item?.navigate} onClick={item?.onClick}>
                                        <ListItemButton className="link-btn" sx={open? null : {
                                            justifyContent: 'center !important'
                                        }}>
                                            {open ?
                                                <ListItemIcon className="item-icon">
                                                    <FontAwesomeIcon className="icon" icon={['fal', item?.icon]} />
                                                </ListItemIcon> :
                                                <Tooltip placement="right" disableInteractive title={<Typography variant="subtitle1" className="tool-tip-text">{item?.title}</Typography>}>
                                                    <ListItemIcon className="item-icon">
                                                        <FontAwesomeIcon className="icon" icon={['fal', item?.icon]} />
                                                    </ListItemIcon>
                                                </Tooltip>}

                                            {open ? <Box>
                                                <ListItemText primary={item?.title} className={item?.selected ? 'active item-text' : 'item-text'} />
                                            </Box> : null}
                                        </ListItemButton>
                                    </ListItem>
                                )
                            })
                        }
                    </List>

                    <List className="footer-style" sx={open?{
                        borderTop: '1px solid #EAECF0 !important',
                        alignItems: 'center !important',
                    }: {
                        width: '44px !important',
                        overflow: 'hidden !important'
                    }}>
                        <ListItem disablePadding sx={{
                            height: '60px !important'
                        }}>
                            {!currentPath?.includes('sponsor-portal') && <Box className="account-dropdown-btn">
                                <DropdownButton
                                    title={
                                        <Box className="account-drop-down-title-container">
                                            {generalData && generalData?.image && generalData?.image !== null || '' ? <Avatar className="user-picture-menu-avatar" src={generalData.image}></Avatar> : <Avatar className="menu-icon-user-empty-avatar">
                                                {(userOnline?.email || userOnline?.firstName || userOnline?.lastName) &&
                                                    buildInitialsForPicture(userOnline?.email || '', userOnline?.firstName || '', userOnline?.lastName || '')
                                                }

                                            </Avatar>}
                                            {open &&
                                               <Box className='user-details-block-bar'>
                                                   <Typography className="user-name-bar">
                                                        {(userOnline?.firstName || '-') + ' ' + (userOnline?.lastName || '-')}
                                                    </Typography>
                                                    <Typography className="user-email-bar">
                                                        {userOnline?.email || '-'}
                                                    </Typography>
                                               </Box>
                                            }
                                           
                                        </Box>
                                    }>
                                    <DropdownMenu />
                                </DropdownButton>
                            </Box>}

                            {/* sponsor portal sidebar footer */}
                            {currentPath?.includes('sponsor-portal') &&
                                <div className="registrant-me-container">
                                    <div className="registrant-info-div">
                                        <p className="sponsor-logo-avatar">{registrantInfo && (`${registrantInfo?.firstName?.charAt(0) || ''} ${registrantInfo?.lastName?.charAt(0) || ''}`)}</p>
                                        <div>
                                            <h3 className="registrant-name">{`${registrantInfo?.firstName || ''} ${registrantInfo?.lastName || ''}`}</h3>
                                            <p className="registrant-email">{registrantInfo?.email || ''}</p>
                                        </div>
                                    </div>
                                    <FontAwesomeIcon className="logout-icon" icon={['fal', 'arrow-right-from-bracket']} onClick={() => setShowSponsorLogoutPopup(true)} />
                                </div>
                            }
                            {/* <Box className='account-footer-action-icons'> */}
                                {/* <FontAwesomeIcon className="icon footer-action-icons" icon={['fal', 'bell']} /> */}
                                {/* <FontAwesomeIcon className="icon footer-action-icons devrev-launcher" onClick={(): void => 
                                {
                                    if (devrevReady) 
                                    {
                                        const devrevToggle = !devrevToggled;
                                        isDevrevToggled(devrevToggle);
                                        (window as any).plugSDK.toggleWidget(devrevToggle);
                                    }
                                }} icon={['fal', 'message']} /> */}
                            {/* </Box> */}
                        </ListItem>
                    </List>
                </Drawer>
            </Box>

            {
                showSponsorLogoutPopup &&
                <DeletePopup 
                    acceptBtn='Logout' 
                    acceptClick={handleSponsorPortalLogout} 
                    cancelClick={() => { 
                        setShowSponsorLogoutPopup(false);
                    }} 
                    modalContent={`Are you sure you want to logout ?`}
                    modalTitle='Logout'
                    show={showSponsorLogoutPopup}
                    rejectBtn='Cancel'
                />
            }
        </div>
    );
};
export default SideBarNavigation;