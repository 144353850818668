import { EventCoupon } from '../../pages/Events/interfaces/event-coupon_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET API's

export const getCouponCount = async (eventId: string | number): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-coupon/' + eventId + '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getCoupons = async (eventId: string | number, pageSize: number, offset: number, queryParams: [string, any][] = []): Promise<EventCoupon[]> =>
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({ 
        pageSize,
        offset,
        ...filteredParams
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-coupon/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getCouponById = async (eventId: string | number, couponId: string | number): Promise<EventCoupon> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-coupon/' + eventId + '/' + couponId;
    return triggerApi(API_URL, 'GET', '');
};

// POST API's

export const createCoupon = async (eventId: string | number, couponData: EventCoupon | any, csrfTokenData: string): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-coupon/' + eventId;
    return triggerApi(API_URL, 'POST', couponData, csrfTokenData);
};

// PUT API's

export const updateCoupon = async (eventId: string | number, couponId: string | number, couponData: EventCoupon | any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-coupon/' + eventId + '/' + couponId;
    return triggerApi(API_URL, 'PUT', couponData);
};

// DELETE API's

export const deleteCoupon = async (eventId: string | number, couponId: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-coupon/' + eventId + '/' + couponId;
    return triggerApi(API_URL, 'DELETE', '');
};