import { Form, Formik } from "formik";
import * as Yup from 'yup';
import { FormControlComponent } from "../../../common/FormComponents/ReusableFormComponents";
import { CustomButton } from "../../../common/FormComponents/Buttons";
import { useState } from "react";
import { updateEvent } from "../../../scripts/apis/events";
import DeletePopup from "../../../common/DeletePopup";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../interfaces/create-events_interface";
import _ from "lodash";
import eventBus from "../../../scripts/event-bus";
import APP_CONSTANTS from "../../../scripts/constants";
import { useDispatch } from "react-redux";
import { addEvent } from "../../../redux/events/Events";

const InvoiceSettings: React.FC<{eventId: string | number; isInvoiceDetailsPresent?: boolean }> = ({ eventId, isInvoiceDetailsPresent }): React.JSX.Element =>
{

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const dispatch = useDispatch();

    const [spinner, setSpinner] = useState<boolean>(false);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState<boolean>(false);

    const validationSchema = Yup.object({ 
        invoicePrefix: Yup.string()
            .required('Please fill in this field')
            .min(2)
            .max(8)
            .matches(/^[a-zA-Z0-9]+$/, 'No special characters allowed'),
        invoiceNumber: Yup.number()
            .required('Please fill in this field')
            .min(1)
            .max(9999),
    });

    const handlePopupclose = (): void =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, {
            open: false,
        });
    };

    const handleFormSubmit = async (values: { invoicePrefix: string; invoiceNumber: number }) => {
        const data = {
            invoiceDetails: {
                invoicePrefix: values.invoicePrefix,
                invoiceNumber: String(values.invoiceNumber),
            }
        };

        try 
        {
            const updateInvoiceSettings = await updateEvent(eventId, data);
            if(updateInvoiceSettings)
            {
                dispatch(addEvent({
                    ...eventReduxData,
                    invoiceDetails: {
                        invoicePrefix: values.invoicePrefix,
                        invoiceNumber: String(values.invoiceNumber),
                    }
                }))
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setSpinner(false);
            handlePopupclose();
        }
    };

    return (
        <div id="invoiceSettings">
            <Formik
                initialValues={{
                    invoicePrefix: eventReduxData?.invoiceDetails?.invoicePrefix || '',
                    invoiceNumber: eventReduxData?.invoiceDetails?.invoiceNumber || 1
                }}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={async () => { 
                    setShowConfirmationPopup(true);
                }}
            >
                {({ handleSubmit, handleChange, setFieldValue, values, errors, touched }): React.ReactElement =>
                    {
                        return(
                            <Form onSubmit={handleSubmit}>
                                <div className="form-container">
                                    <div className="invoice-prefix">
                                        <div className="invoice-prefix-input">
                                            <FormControlComponent
                                                type="text"
                                                disabled={isInvoiceDetailsPresent}
                                                name="invoicePrefix"
                                                placeholder="INVOICE1"
                                                value={values.invoicePrefix}
                                                onChange={(event) => setFieldValue('invoicePrefix', event.target.value.toUpperCase())}
                                                maxLength={8}
                                                isInvalid={(errors.invoicePrefix && touched.invoicePrefix) as boolean}
                                            />
                                            <p>-</p>
                                        </div>
                                        {(errors.invoicePrefix && touched.invoicePrefix) && <div className="error-msg">{errors.invoicePrefix}</div>}
                                    </div>
                                    <div className="invoice-number">
                                        <FormControlComponent
                                            type="text"
                                            disabled={isInvoiceDetailsPresent}
                                            name="invoiceNumber"
                                            placeholder="0001"
                                            value={values.invoiceNumber}
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                if (/^\d*$/.test(value)) {
                                                    setFieldValue('invoiceNumber', value);
                                                }
                                            }}
                                            maxLength={4}
                                            isInvalid={(errors.invoiceNumber && touched.invoiceNumber) as boolean}
                                        />
                                        {(errors.invoiceNumber && touched.invoiceNumber) && <div className="error-msg">{errors.invoiceNumber}</div>}
                                    </div>
                                </div>

                                {!isInvoiceDetailsPresent && 
                                    <div className="button-container">
                                        <CustomButton name="Cancel" btnType="secondary" onClick={handlePopupclose} />
                                        <CustomButton name="Save" loading={spinner} disabled={spinner} btnType="primary" onClick={handleSubmit} />
                                    </div>
                                }

                                {
                                    (showConfirmationPopup && !isInvoiceDetailsPresent) &&
                                    <DeletePopup
                                        show={showConfirmationPopup} 
                                        modalTitle="Confirmation"
                                        modalContent="Once you save the invoice details, it cannot be changed. Are you sure you want to save?"
                                        cancelClick={() => setShowConfirmationPopup(false)}
                                        extraActions={[
                                            <CustomButton key="no" name="No" btnType="secondary" onClick={() => setShowConfirmationPopup(false)} />,
                                            <CustomButton key="yes" name="Yes" btnType="primary" onClick={() => 
                                                { 
                                                    setShowConfirmationPopup(false); 
                                                    handleFormSubmit(values); 
                                                }} 
                                            />
                                        ]}
                                    />
                                }
                            </Form>
                        )
                    } 
                }
            </Formik>
        </div>
    )
};

export default InvoiceSettings;