import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Stack, Typography, Box } from '@mui/material';
// eslint-disable-next-line import/named
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { addBuyerPersona } from '../../redux/settings/BuyerPersona';
import { createBuyerPersona, updateBuyerPersona } from '../../scripts/apis/buyerPersona';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AutocompletewithTags, CloseIconComponent, FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import toast from 'react-hot-toast';
import { createBuyerPersonaSponsorPortal, deleteBuyerPersonaSponsorPortal, updateBuyerPersonaSponsorPortal } from '../../scripts/apis/sponsorPortal/sponsorPortal';
import DeletePopup from '../../common/DeletePopup';
import { IBuyerPersona } from '../../pages/Settings/BuyerPersona/interfaces';
import { IBuyerPersonaState, IBuyerPersonaValue } from '../../pages/Settings/interface/buyer-persona_interface';
import { ROLE, SENIORITY } from '../../pages/Settings/BuyerPersona/buyer-persona-dataset';

interface ICreateBuyerPersona {
    buyerPersonaData?: IBuyerPersona;
    setRefresh: (value) => void;
    sponsorPortal?: boolean;
    handleDrawerClose?: () => void;
}

const BuyerPersona = (props:ICreateBuyerPersona):React.JSX.Element => 
{
    const { sponsorPortal, buyerPersonaData } = props;

    const defaultBuyerPersonaInfo = {
        name: '',
        description: '',
        role: '',
        subRole: '',
        seniority: '',
    };

    const buyerPersonaReduxData = useSelector((state: IBuyerPersonaState):IBuyerPersonaValue => 
    {
        return state.buyerPersona.value;
    });

    const csrfTokenData = useSelector((state):string => 
    {
        return state['csrfTokenValue'].value.csrfToken; 
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [buyerPersonaInfo, setBuyerPersonaInfo] = useState(defaultBuyerPersonaInfo);
    const [spinner, setSpinner] = useState(false);
    const [buyerPersonaPropertyCombination, setBuyerPersonaPropertyCombination] = useState('');
    const [selectedRolePosition, setSelectedRolePosition] = useState([]);
    const [matchingSubRole, setMatchingSubRole] = useState([]);
    const [roles, setRoles] = useState([]);
    const [subRoles, setSubRoles] = useState([]);
    const [seniority, setSeniority] = useState([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    useEffect(():void => 
    {
        if (buyerPersonaData?.role) 
        {
            setRoleChanges(buyerPersonaData.role);
        }
    }, []);

    const setSubRoleChanges = (value):void => 
    {
      
        if (value) 
        {
            formik.setFieldValue('subRole', value.toString());
            dispatch(addBuyerPersona({
                subRole: value.toString()
            }));
        }
        // else 
        // {
        //     formik.setFieldValue('subRole', null);
        //     dispatch(addBuyerPersona({
        //         subRole: null
        //     }));
        // }

    };

    const setRoleChanges = (value):void => 
    {

        if (typeof (value) === 'string') 
        {
            const newValue = value.split(',');
            const rolePositions = [];
            newValue.forEach((element):void => 
            {
                const rolePosition = ROLE.indexOf(element);
                rolePositions.push(rolePosition);
            });

            if (rolePositions.length > 0) 
            {
                setMatchingSubRole([]);
                rolePositions.forEach((item):void => 
                {
                    setSelectedRolePosition([...selectedRolePosition, item]);
                    if (SUB_ROLE[item].length > 0) 
                    {
                        SUB_ROLE[item].map((subRole):void => 
                        {
                            setMatchingSubRole((prevMatchingSubRole):string[] => 
                            {
                                return [...prevMatchingSubRole, subRole]; 
                            });
                        });
                    }

                });
            }
        }
        else 
        {
            const rolePositions = [];
            value.forEach((element):void => 
            {
                const rolePosition = ROLE.indexOf(element);
                rolePositions.push(rolePosition);
            });

            if (rolePositions.length > 0) 
            {
                setMatchingSubRole([]);
                rolePositions.forEach((item):void => 
                {
                    setSelectedRolePosition([...selectedRolePosition, item]);
                    if (SUB_ROLE[item].length > 0) 
                    {
                        SUB_ROLE[item].map((subRole):void => 
                        {
                            setMatchingSubRole((prevMatchingSubRole):string[] => 
                            {
                                return [...prevMatchingSubRole, subRole]; 
                            });
                        });
                    }

                });
            }
        }

    };

    const handleRoleChange = (event, newValue):void => 
    {

        setRoles([...newValue]);
        const selectedRoles = newValue.length > 0 ? newValue.map((elem: string):string => 
        {
            return _.find(ROLE, (roles):boolean => 
            {
                return elem === roles; 
            }); 
        }) : [];

        if (selectedRoles.length > 0) 
        {
            setSubRoleChanges(null);
            setRoleChanges(selectedRoles);
        }
        else 
        {
            setMatchingSubRole([]);
            setSelectedRolePosition([]);
        }
        formik.setFieldValue('role', selectedRoles.toString());
        dispatch(addBuyerPersona({
            role: selectedRoles.toString()
        }));
    };

    const handleSubRoleChange = (event, newValue):void => 
    {
        setSubRoles(newValue);
        setSubRoleChanges(newValue);
    };

    const handleSeniorityChange = (event, newValue):void => 
    {
        setSeniority(newValue);
        formik.setFieldValue('seniority', newValue.toString());
        dispatch(addBuyerPersona({
            seniority: newValue.toString()
        }));
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        description: Yup.string(),
        role: Yup.string(),
        subRole: Yup.string().test('isValidSubRoles', 'Select Valid SubRoles', (value):boolean => 
        {
            if (value) 
            {
                const x = value.split(',');
                if (x.length > 0) 
                {
                    return x.every((item):boolean => 
                    {
                        return matchingSubRole.includes(item); 
                    });
                }
                else 
                {
                    return true;
                }
            }
            else 
            {
                return true;
            }

        }),
        seniority: Yup.string(),
    }).test('at-least-one-property', null, function (value):true | Yup.ValidationError 
    {
        const { role } = value;
        if (!(role)) 
        {
            setBuyerPersonaPropertyCombination('At least one property must be selected');
            return new Yup.ValidationError(
                'At least one property must be selected',
                null,
                'at-least-one-property'
            );
        }
        if (role) 
        {
            setBuyerPersonaPropertyCombination('');
        }
        return true;
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: buyerPersonaReduxData.name ? buyerPersonaReduxData.name : '',
            description: buyerPersonaReduxData.description ? buyerPersonaReduxData.description : '',
            role: buyerPersonaReduxData.role ? buyerPersonaReduxData.role : '',
            subRole: buyerPersonaReduxData.subRole ? buyerPersonaReduxData.subRole : '',
            seniority: buyerPersonaReduxData.seniority ? buyerPersonaReduxData.seniority : undefined,
        },
        validationSchema,

        onSubmit: async ():Promise<void> => 
        {
            setSpinner(true);

            const buyerPersonaDetails = {
                name: formik.values.name,
                description: formik.values.description,
                role: roles ? roles?.toString() : null,
                subRole: subRoles ? subRoles?.toString() : null,
                seniority: seniority ? seniority?.toString() : null,
            };

            if(sponsorPortal)
            {
                return handleSponsorPortalBpSubmit(buyerPersonaDetails);
            }

            if ((_.isEmpty(buyerPersonaData))) 
            {
                try 
                {
                    setSpinner(true);

                    const buyerPersonaCreated = await createBuyerPersona(buyerPersonaDetails, csrfTokenData);
                    if (buyerPersonaCreated) 
                    {
                        props.setRefresh(true);
                        handleDrawerClose();
                    }
                }
                catch (error) 
                {
                    setBuyerPersonaPropertyCombination(error.message);
                    console.log(error);
                    setSpinner(false);
                }
            }
            else 
            {
                setSpinner(true);
                if (buyerPersonaInfo?.name !== buyerPersonaReduxData?.name || buyerPersonaDetails?.role || buyerPersonaDetails?.subRole || buyerPersonaDetails?.seniority) 
                {
                    try 
                    {
                        const buyerPersonaUpdated = await updateBuyerPersona(buyerPersonaData.id, buyerPersonaDetails);
                        if(buyerPersonaUpdated)
                        {
                            props.setRefresh(true);
                            handleDrawerClose();
                            setSpinner(false);
                        }
                    }
                    catch (error) 
                    {
                        setBuyerPersonaPropertyCombination(error.message);
                        console.log(error);
                        setSpinner(false);
                    }
                }
                
            }
        }
    });

    const handleSponsorPortalBpSubmit = async (data: any): Promise<void> =>
    {
        if(buyerPersonaData)
        {
            try 
            {
                const buyerPersonaUpdated = await updateBuyerPersonaSponsorPortal(buyerPersonaData?.id, data);
                if(buyerPersonaUpdated)
                {
                    props.setRefresh(true);
                    props?.handleDrawerClose() || null;
                    toast.success('Buyer Persona Updated Successfully');
                }
            } 
            catch (error) 
            {
                toast.error('Error in updating Buyer Persona');
                console.log(error);
            }
            finally
            {
                setSpinner(false);
            }
        }
        else
        {
            try 
            {
                const buyerPersonaCreated = await createBuyerPersonaSponsorPortal(data);
                if (buyerPersonaCreated) 
                {
                    props.setRefresh(true);
                    props?.handleDrawerClose() || null;
                    toast.success('Buyer Persona Created Successfully');
                }
            }
            catch (error) 
            {
                toast.error('Error in creating Buyer Persona');
                console.log(error);
            }
            finally
            {
                setSpinner(false);
            }
        }
    };

    const handleDeleteSponsorPortalBp = async (): Promise<void> =>
    {
        try 
        {
            const icpDeleted = await deleteBuyerPersonaSponsorPortal(buyerPersonaData?.id);
            if(icpDeleted)
            {
                props.setRefresh(true);
                props?.handleDrawerClose() || null;
                toast.success('Buyer Persona Deleted Successfully');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const handleDrawerClose = ():void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });
    };

    useEffect(():void => 
    {
        eventBus.on(APP_CONSTANTS.EVENTS.SOFT_RELOAD, ():void => 
        {
            navigate(0);
        });

        eventBus.on(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, (data):void => 
        {
            setSpinner(data.spinner);
        });

        if (buyerPersonaData) 
        {
            setBuyerPersonaInfo(buyerPersonaData);
            if (buyerPersonaData.role) 
            {
                setRoles(buyerPersonaData.role.split(','));
            }
            if (buyerPersonaData.subRole) 
            {
                setSubRoles(buyerPersonaData.subRole.split(','));
            }
            if (buyerPersonaData.seniority) 
            {
                setSeniority(buyerPersonaData.seniority.split(','));
            }

            dispatch(addBuyerPersona({
                name: buyerPersonaData.name,
                description: buyerPersonaData.description,
                role: buyerPersonaData.role,
                subRole: buyerPersonaData.subRole,
                seniority: buyerPersonaData.seniority,
            }));
        }
    }, []);

    return (
        <Box id="createBuyerPersona">
            {!props?.sponsorPortal && <CloseIconComponent onClick={handleDrawerClose} />}
            {/* {!props.addEventBuyerPersona && <Stack className="required-icon-stack" direction={'row'}><Typography className="required-icon">*</Typography><Typography className="required-field-text">{CONTENT.SIDE_DRAWER.FORM_REQUIRED_TEXT}</Typography></Stack>} */}
            <Form noValidate autoComplete="off" onSubmit={(values):void => 
            {
                return formik.handleSubmit(values);
            }}>

                {/* Buyer Persona name */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.LABEL.NAME} />
                    <FormControlComponent
                        type='text'
                        onChange={(event):void => 
                        {
                            dispatch(addBuyerPersona({
                                name: event.target.value
                            }));
                            formik.setFieldValue('name', event.target.value);
                        }}
                        required
                        value={formik.values.name}
                        placeholder={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.PLACEHOLDER}
                        isInvalid={
                            formik.touched.name && formik.errors.name ? true : false
                        }
                    />
                    {formik.errors.name && formik.touched.name ? <Typography className="error-msg">{formik.errors.name}</Typography> : null}
                </Box>

                {/* Description */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.LABEL.DESCRIPTION} />
                    <FormControlComponent
                        as='textarea'
                        rows={3}
                        onChange={(event):void => 
                        {
                            formik.setFieldValue('description', event.target.value);
                            dispatch(addBuyerPersona({
                                description: event.target.value
                            }));
                        }}
                        value={formik.values.description}
                        placeholder={'Description'}
                        type='text'
                        isInvalid={
                            formik.touched.description && formik.errors.description ? true : false
                        }
                    />
                    {formik.errors.description && formik.touched.description ? <Typography className="error-msg">{formik.errors.description}</Typography> : null}
                </Box>

                {/* Role */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.LABEL.ROLE} />
                    <AutocompletewithTags
                        defaultValue={roles}
                        value={roles}
                        onChange={handleRoleChange}
                        options={ROLE.map((role):string => 
                        {
                            return role;
                        })}
                        placeholder={'Select Role'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedRoles = roles.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setRoles(updatedRoles);
                        }}
                    />
                    {buyerPersonaPropertyCombination ? <Typography className="error-msg">{buyerPersonaPropertyCombination}</Typography> : null}
                </Box>

                {/* Sub Roles */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.LABEL.SUB_ROLE} />
                    <AutocompletewithTags
                        defaultValue={subRoles}
                        value={subRoles}
                        onChange={handleSubRoleChange}
                        options={matchingSubRole.map((subRole):string => 
                        {
                            return subRole; 
                        })}
                        placeholder={'Select Sub Role'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedSubRoles = subRoles.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setSubRoles(updatedSubRoles);
                        }}
                    />
                    {formik.errors.subRole && formik.touched.subRole ? buyerPersonaPropertyCombination ? (<Typography className="error-msg">{buyerPersonaPropertyCombination}</Typography>) : <Typography className="error-msg">{formik.errors.subRole}</Typography> : null}
                </Box>

                {/* Seniority */}
                <Box className="sidebar-container-spacing">
                    <FormLabelComponent label={CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.LABEL.SENIORITY} />
                    <AutocompletewithTags
                        defaultValue={seniority}
                        value={seniority}
                        onChange={handleSeniorityChange}
                        options={SENIORITY.map((seniority):string => 
                        {
                            return seniority;
                        })}
                        placeholder={'Select Seniority'}
                        onRemoveClick={(index: number): void => 
                        {
                            const updatedSeniority = seniority.filter((_, i): boolean => 
                            {
                                return i !== index;
                            });
                            setSeniority(updatedSeniority);
                        }}
                    />
                </Box>

                {!props?.sponsorPortal ? <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-start'}>
                        <CustomButton name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' onClick={() => { props?.sponsorPortal ? props?.handleDrawerClose() : handleDrawerClose() }} />
                        <CustomButton name={buyerPersonaData ? CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.BUTTON.UPDATE : CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.BUTTON.CREATE}
                            btnType='primary' type='submit' 
                        />
                    </Stack>
                </Box>:
                <Box className="submit-btn-container">
                    <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'space-between'} width={'100%'}>
                        <Stack direction={'row'} spacing={1}>
                            <CustomButton name={CONTENT.SIDE_DRAWER.CLOSE_BTN} btnType='secondary' onClick={() => props?.handleDrawerClose()} />
                            <CustomButton loading={spinner} name={buyerPersonaData ? CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.BUTTON.UPDATE : CONTENT.SETTINGS_PAGE.BUYER_PERSONA.FORMS.BUTTON.CREATE}
                                btnType='primary' type='submit' 
                            />
                        </Stack>
                        <FontAwesomeIcon className="sidebar-delete-icon" icon={['fal', 'trash']} onClick={() => setShowDeletePopup(true)} />
                    </Stack>
                </Box>
                }
            </Form>

            {
                <DeletePopup 
                    acceptBtn='Delete' 
                    acceptClick={handleDeleteSponsorPortalBp} 
                    cancelClick={() => { 
                        setShowDeletePopup(false);
                        eventBus.dispatch('selected-row-id', null); 
                    }} 
                    modalContent={`Are you sure you want to delete ${buyerPersonaData?.name}?`}
                    modalTitle='Delete ICP'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                />
            }
        </Box >
    );
};

export default BuyerPersona;