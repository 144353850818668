import React, { useContext } from 'react';
import RegistrationsTopBar from './Common/RegistrationsTopBar';
// import RegistrationFormTabs from './Common/RegistrationFormTabs';
import { FormComponentsContext, IFormComponentsContext } from '../../contexts/FormComponentsContext';
import RegistrationFormContent from './RegistrationFormContent/RegistrationFormContent';

const Registrations = (props): React.JSX.Element => 
{

    const { setRefresh, formName, formId, registrationData } = useContext<IFormComponentsContext>(FormComponentsContext);

    return (
        <>
            <RegistrationsTopBar name={formName} setSelectedModule={undefined} formId={formId} setRefresh={setRefresh} registrationData={registrationData} />
            <RegistrationFormContent formId={formId} setRefresh={setRefresh} RegistrationFormData={registrationData}/>
            {/* <FormComponentTabs formId={formId} setRefresh={setRefresh} RegistrationFormData={registrationData} /> */}
            {/* <RegistrationFormTabs tab={props.tab} formId={formId} setRefresh={setRefresh} /> */}
        </>
    );
};

export default Registrations;