import React, { createContext, useEffect, useState } from 'react';
import { useTablePagination } from '../TablePaginationContext';
import { deleteMarketing, getAllEventMarketing, getAllEventMarketingCount } from '../../scripts/apis/eventMarketing';
import { EventMarketing } from '../../pages/Events/interfaces';
import toast from 'react-hot-toast';

interface IEventMarketingContext {
    isEmpty: boolean;
    marketingCount: number;
    setIsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
    setRows: React.Dispatch<React.SetStateAction<EventMarketing[]>>;
    rows: EventMarketing[];
    marketingData: EventMarketing[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (currentPage: number) => void;
    updatePageSize: (pageSize: number) => void;
    showSpinner: boolean;
    eventId: number;
    deleteMarketingDataFromTable: (marketingData: EventMarketing) => Promise<void>;
    showDeleteDialog: boolean;
    setShowDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
    selectedMarketingData: EventMarketing | null;
    setSelectedMarketingData: React.Dispatch<React.SetStateAction<EventMarketing | null>>;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const eventMarketingMailFilterOptions =
{
    1: 'Invite',
    2: 'Approved',
    3: 'Rejected',
    4: 'Waitlist',
    5: 'Deleted',
    6: 'Confirmed'
};

export const MarketingPageContext = createContext<IEventMarketingContext>({
    isEmpty: false,
    marketingCount: 0,
    setIsEmpty: () => {},
    setRows: () => {},
    rows: [],
    marketingData: [],
    setRefresh: () => {},
    pageSize: 0,
    currentPage: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    eventId: 0,
    deleteMarketingDataFromTable: async () => {},
    showDeleteDialog: false,
    setShowDeleteDialog: () => {},
    selectedMarketingData: null,
    setSelectedMarketingData: () => {},
    setCurrentUrl: () => {}
});

const EventMarketingProvider: React.FC<{ children: React.ReactElement, eventId: number }> = ({ children, eventId }): React.JSX.Element => 
{
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [rows, setRows] = useState<EventMarketing[]>([]);
    const [marketingData, setMarketingData] = useState<EventMarketing[]>([]);
    const [marketingCount, setMarketingCount] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false);
    const [selectedMarketingData, setSelectedMarketingData] = useState<EventMarketing | null>(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const count = await getAllEventMarketingCount(eventId);
            if (count)
            {
                setMarketingCount(count);
                setIsEmpty(count == 0);
                const params: [string, any][] = [];
                const query = new URLSearchParams(currentUrl.split('?')[1]);
                query?.forEach((value, key) => {
                    params.push([key, value]);
                });

                const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                const eventMarketing: EventMarketing[] = await getAllEventMarketing(pageSize, Number(currentPage - 1), eventId, 2, encodedParams);
                if (eventMarketing) 
                {
                    setRows([...eventMarketing]);
                    setMarketingData([...eventMarketing]);
                    setRefresh(false);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }   
        finally
        {
            setShowSpinner(false);
        }
    };

    const fetchPaginationData = async (): Promise<void> => 
    {
        try 
        {
            const params: [string, any][] = [];
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query?.forEach((value, key) => {
                params.push([key, value]);
            });

            const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

            const eventMarketing: EventMarketing[] = await getAllEventMarketing(pageSize, Number(currentPage - 1), eventId, 2, encodedParams);
            if (eventMarketing) 
            {
                setRows([...eventMarketing]);
                setMarketingData([...eventMarketing]);
                setRefresh(false);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const deleteMarketingDataFromTable = async (marketingInfo: EventMarketing): Promise<void> => 
    {
        try 
        {
            const marketingDeleted: boolean = await deleteMarketing(marketingInfo.id as string, eventId);
            if (marketingDeleted) 
            {
                setShowDeleteDialog(false);
                toast.success(`${marketingInfo?.name} has been deleted successfully`);
                setSelectedMarketingData(null);
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }       
    };

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
        }
    }, [refresh]);

    useEffect((): void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            fetchData();
        }
        else if (currentPage > 1 && pageSize) 
        {
            fetchPaginationData();
        }
    }, [currentPage, pageSize, currentUrl]);


    return (
        <MarketingPageContext.Provider 
            value={{
                isEmpty, 
                marketingCount, 
                setIsEmpty, 
                setRows, 
                rows, 
                setRefresh, 
                pageSize, 
                currentPage,
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                eventId, 
                marketingData,
                selectedMarketingData,
                setSelectedMarketingData,
                setShowDeleteDialog,
                showDeleteDialog,
                deleteMarketingDataFromTable,
                setCurrentUrl
            }}
        >
            {children}
        </MarketingPageContext.Provider>
    );
};

export default EventMarketingProvider;