import { createContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteBuyerPersona, getAllBuyerPersona, buyerPersonaCount, updateBuyerPersona } from '../../scripts/apis/buyerPersona';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { useTablePagination } from '../TablePaginationContext';
import { ROLE, SUB_ROLE } from '../../pages/Settings/BuyerPersona/buyer-persona-dataset';
import _ from 'lodash';
import { BuyerPersona } from '../../pages/Settings/interface/buyer-persona_interface';
import toast from 'react-hot-toast';

interface BuyerPersonaPageContextInterface {
    rows: BuyerPersona[];
    isEmpty: boolean;
    buyerPersonaDataCount: number;
    buyerPersona: BuyerPersona[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setRows: React.Dispatch<React.SetStateAction<BuyerPersona[]>>;
    deleteBuyerPersonaFromTable: (buyerPersona: BuyerPersona) => void;
    refresh: boolean;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (value: number) => void;
    updatePageSize: (value: number) => void;
    showSpinner: boolean;
    editBuyerPersonaFromTable: any;
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedBuyerPersona: BuyerPersona | null;
    setSelectedBuyerPersona: React.Dispatch<React.SetStateAction<BuyerPersona | null>>;
}

export const BuyerPersonaPageContext = createContext<BuyerPersonaPageContextInterface>({
    rows: [],
    isEmpty: false,
    buyerPersonaDataCount: 0,
    buyerPersona: [],
    setRefresh: () => {},
    setRows: () => {},
    deleteBuyerPersonaFromTable: () => {},
    refresh: false,
    pageSize: 0,
    currentPage: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    editBuyerPersonaFromTable: () => {},
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    selectedBuyerPersona: null,
    setSelectedBuyerPersona: () => {},
});

const BuyerPersonaPageProvider: React.FC<{ children: React.ReactNode }> = ({ children }): React.JSX.Element => 
{
    const [rows, setRows] = useState<BuyerPersona[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [buyerPersonaDataCount, setBuyerPersonaDataCount] = useState<number>(0);
    const [buyerPersona, setBuyerPersona] = useState<BuyerPersona[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedBuyerPersona, setSelectedBuyerPersona] = useState<BuyerPersona | null>(null);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    useEffect((): void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            fetchData();
        }
        else if (currentPage > 1 && pageSize) 
        {
            fetchPaginationData();
        }
    }, [currentPage, pageSize]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
        }
    }, [refresh]);

    const fetchData = async (): Promise<void> => 
    {
        try 
        {
            const count = await buyerPersonaCount();
            setShowSpinner(true);
            if (count) 
            {
                try 
                {
                    const buyerPersonaData = await getAllBuyerPersona(pageSize, currentPage - 1);
                    
                    if (buyerPersonaData) 
                    {
                        setRows([...buyerPersonaData]);
                        setBuyerPersonaDataCount(count);
                        setBuyerPersona([...buyerPersonaData]);
                        setIsEmpty(buyerPersonaData.length === 0);
                        setShowSpinner(false);
                        setRefresh(false);
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchPaginationData = async (): Promise<void> => 
    {
        if (buyerPersonaDataCount) 
        {
            try 
            {
                const buyerPersonaData = await getAllBuyerPersona(pageSize, currentPage - 1);
                setShowSpinner(true);
                if (buyerPersonaData) 
                {
                    setRows([...buyerPersonaData]);
                    setBuyerPersona([...buyerPersonaData]);
                    setIsEmpty(buyerPersonaData.length === 0);
                    setShowSpinner(false);
                    setRefresh(false);
                }

            }
            catch (error) 
            {
                console.log(error);
                setShowSpinner(true);
            }
        }
    };

    const deleteBuyerPersonaFromTable = async (buyerPersona: BuyerPersona): Promise<void> => 
    {
        try 
        {
            const buyerPersonaDeleted = await deleteBuyerPersona(buyerPersona?.id);
            if (buyerPersonaDeleted) 
            {
                setShowDeletePopup(false);
                setSelectedBuyerPersona(null);
                toast.success('Buyer Persona Deleted Successfully');
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
            toast.error((error as Error)?.message);
        }
    };

    const editBuyerPersonaFromTable = async (buyerPersonaData): Promise<void> => 
    {
        const seniority = typeof buyerPersonaData?.seniority !== 'string' ? buyerPersonaData.seniority ? buyerPersonaData.seniority.toString() : [] : buyerPersonaData.seniority;
        const role = typeof buyerPersonaData?.role !== 'string' ? buyerPersonaData?.role?.toString() : buyerPersonaData?.role;
        const subRole = typeof buyerPersonaData?.subRole !== 'string' ? buyerPersonaData?.subRole?.toString() : buyerPersonaData?.subRole;

        const buyerPersonaDetails = {
            name: buyerPersonaData?.name,
            description: buyerPersonaData.description ? buyerPersonaData.description : null,
            role: buyerPersonaData.role ? role : null,
            subRole: buyerPersonaData.subRole ?role ? subRole : null : null,
            seniority: seniority,
        };
        if (checkifSubRoleisValid(buyerPersonaData?.subRole?.toString(), buyerPersonaData?.role)) 
        {
            try 
            {
                const buyerPersonaUpdated = await updateBuyerPersona(buyerPersonaData.id, buyerPersonaDetails);
                if (buyerPersonaUpdated) 
                {
                    setRefresh(true);
                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error(_.startCase((error as Error).message.toLowerCase()))
            }
        }
        else 
        {
            toast.error('Select Valid Sub Roles for the Role selected');
            return;
        }


    };

    const checkifSubRoleisValid = (subRole, role): boolean => 
    {
        const matchingRole = [];
      
        if (role) 
        {
            const rolesPositions = [];
            if (typeof role === 'string') 
            {
                const roles = role.split(',');
                roles.forEach((element): void => 
                {
                    const rolesPosition = ROLE.indexOf(element);
                    rolesPositions.push(rolesPosition);
                });

                if (rolesPositions.length > 0) 
                {
                    rolesPositions.forEach((item): void => 
                    {
                        if (SUB_ROLE[item].length > 0) 
                        {
                            SUB_ROLE[item].map((tech): void => 
                            {
                                matchingRole.push(tech);
                            });
                        }

                    });
                }
            }
            else 
            {
                if (role.length > 0) 
                {
                    role.forEach((element): void => 
                    {
                        const rolesPosition = ROLE.indexOf(element);
                        rolesPositions.push(rolesPosition);
                    });

                    if (rolesPositions.length > 0) 
                    {
                        rolesPositions.forEach((item): void => 
                        {
                            if (SUB_ROLE[item].length > 0) 
                            {
                                SUB_ROLE[item].map((tech): void => 
                                {
                                    matchingRole.push(tech);
                                });
                            }

                        });
                    }
                }
                else 
                {
                    return true;
                }
            }

        }
        else 
        {
            return true;
        }
        if (subRole) 
        {
            const x = subRole.split(',');
            if (x.length > 0) 
            {
                return x.every((item): boolean => 
                {
                    return matchingRole.includes(item);
                });
            }
            else 
            {
                return true;
            }
        }
        else 
        {
            return true;
        }
    };
    return (
        <BuyerPersonaPageContext.Provider 
            value={{
                rows, 
                isEmpty, 
                buyerPersonaDataCount, 
                buyerPersona, 
                setRefresh, 
                setRows, 
                deleteBuyerPersonaFromTable, 
                refresh, 
                pageSize, 
                currentPage,
                updateCurrentPage, 
                updatePageSize, 
                showSpinner, 
                editBuyerPersonaFromTable,
                showDeletePopup,
                setShowDeletePopup,
                selectedBuyerPersona,
                setSelectedBuyerPersona,
            }}
        >
            {children}
        </BuyerPersonaPageContext.Provider>
    );
};

export default BuyerPersonaPageProvider;