import React, { useContext, useEffect, useState } from 'react';
import { Avatar, Box, Grid, Tooltip, Typography } from '@mui/material';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
import { CONTENT } from '../../scripts/i18n';
import _ from 'lodash';
import './styles.scss';
import Sponsor from '../../components/Sponsors/Sponsor';
import { resetSponsors } from '../../redux/sponsors/Sponsors';
// eslint-disable-next-line import/named
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import DataImport from '../../common/DataImport';
import { SponsorsPageContext } from '../../contexts/SponsorsPageContext';
import { getSponsorById } from '../../scripts/apis/sponsors';
import SponsorsGraph from './SponsorsGraph';
import { ActionsDropdown } from '../Events/EventBudget/EventBudgetDataGrid';
import { GridColDef } from '@mui/x-data-grid-pro';
import MuiChip from '../../common/FormComponents/MuiChip';
import sponsorEmptyImg from '../../assets/icons/empty-state/speakerEmpty.svg';
import { createColumnHelper } from '@tanstack/react-table';
import { ISponsor } from './interfaces';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { generateSponsorsColumnsForTanstackTable } from './sponsorColumn.helper';
import { CustomButton } from '../../common/FormComponents/Buttons';
import SponsorsPageToolbar from '../../components/Sponsors/SponsorsPageToolbar';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddSponsor from '../Events/EventDetails/People/EventSponsors/AddSponsor';
import toast from 'react-hot-toast';
import CardComponent from '../../components/Events/Registrations/CardComponent';

import mailIcon from '../../assets/icons/envelope-light.svg';
import twitterIcon from '../../assets/icons/twitter-icon.svg';
import linkedInIcon from '../../assets/icons/linkedin-solid.svg';
import { useSelector } from 'react-redux';
import { IEventReduxValues, IEventsDispatch } from '../../components/Events/interfaces/create-events_interface';
import { EventStatus } from '../Events/enum';
import EventsCardHeaderComponent from '../../components/Events/EventComponents/EventsCardHeaderComponent';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';

const SponsorsPage: React.FC<{ eventId?: string | number }> = (props): React.JSX.Element => 
{
    const { eventId } = props;
    const { sponsorId } = useParams();

    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [chartVisibility, setChartVisibility] = useState(false);
    const { 
        isEmpty, 
        sponsorDataCount, 
        sponsors, 
        deleteSponsorsFromTable, 
        setRefresh, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner, 
        currentPage, 
        isChartReady, 
        sponsorsIcpData, 
        sponsorFundingData, 
        sponsorRevenueData, 
        trendData, 
        rows,
        selectedSponsor,
        setSelectedSponsor,
        showDeletePopup,
        setShowDeletePopup,
        isTableView,
        cardView,
        orgLink,
        setCurrentUrl
    } = useContext(SponsorsPageContext);

    const columnHelper = createColumnHelper<ISponsor>();
    
    const generateInitialColumns = () => [ 
        columnHelper.accessor('logo', {
            cell: ({ row }) => {
                const sponsorLogoImg = row?.original?.logo && row?.original?.logo !== null ? row?.original?.logo : '';
                const emptyImgSrc = row.original?.name.charAt(0).toUpperCase();
                return sponsorLogoImg !== '' ? <Avatar className="sponsor-logo" src={sponsorLogoImg}></Avatar> : <Avatar className="empty-logo-img"><p>{emptyImgSrc}</p></Avatar>;
            },
            header: 'Logo',
            id: 'logo',
            size: 40,
            enableSorting: false,
        }),
        columnHelper.accessor('name', {
            cell: ({ getValue }) => <p className="bolded-cellContent">{getValue()}</p>,
            header: 'Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name',
        }),
        columnHelper.accessor('pocName', {
            cell: ({ getValue }) => <p className='cellContent'>{getValue()}</p>,
            header: 'POC Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'pocName',
        }),
        columnHelper.accessor('pocEmail', {
            cell: ({ getValue }) => <p className='cellContent'>{getValue()}</p>,
            header: 'POC Email',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'pocEmail',
        }),
        columnHelper.accessor('websiteLink', {
            cell: ({ getValue }) => <p className='cellContent'>{getValue()}</p>,
            header: 'Website',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'websiteLink',
        }),
        columnHelper.accessor('actions' as any, {
            cell: ({ row }) => {
                const editClick = (): void => 
                {
                    if (eventId)
                    {
                        editEventSponsor(row.original, undefined, true, false);
                    }
                    else
                    {
                        handleViewEdit(row.original);
                    }
                };
                const deleteClick = (): void => 
                {
                    setSelectedSponsor(row.original);
                    setShowDeletePopup(true);
                };
                return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
            },
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        }),
    ];

    const sponsorColumns = [...generateInitialColumns(), ...generateSponsorsColumnsForTanstackTable()].flat();
    const [allColumns, setAllColumns] = useState(sponsorColumns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'POC Name',
            id: 'pocName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Email',
            id: 'pocEmail',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Website Link',
            id: 'websiteLink',
            meta: {
                type: 'string'
            }
        },
    ];

    const openDrawer = (): void => 
    {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPONSORS_PAGE.SIDE_DRAWER.HEADING.CREATE,
            hideCloseButton: true,
            component: < Sponsor setRefresh={setRefresh} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const createSponsor = (): void => 
    {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Create Sponsor',
            event: 'create-sponsor',
            hideCloseButton: true,
            component: <Sponsor data-id={eventId} addEventSponsor={true} eventSponsor={true} setRefresh={setRefresh} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const addSponsor = (componentHeading?: string, select?: boolean): void => 
    {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: componentHeading || 'Select Sponsor',
            event: 'add-sponsor',
            hideCloseButton: true,
            component: <AddSponsor select={select} data-id={eventId} setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleViewEdit = (sponsorInfo: ISponsor, routeFromId?: boolean): void => 
    {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.SPONSORS_PAGE.SIDE_DRAWER.HEADING.EDIT,
            hideCloseButton: true,
            component: <Sponsor sponsor-data={sponsorInfo} is-editable={true} setRefresh={setRefresh} routeFromId={routeFromId} />
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const editEventSponsor = (sponsorInfo: ISponsor, routeFromEventSponsor?: boolean, select?: boolean, editTier?: boolean): void => 
    {
        dispatch(resetSponsors());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Edit Sponsor Tier',
            hideCloseButton: true,
            component: <AddSponsor isTableView={isTableView} select={select} editTier={editTier} sponsor-data={sponsorInfo} data-id={eventId} setRefresh={setRefresh} routeFromEventSponsor={routeFromEventSponsor} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true
        });
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    useEffect((): void => 
    {
        const fetchDataFromRoute = async (): Promise<void> => 
        {
            if (sponsors && sponsors.length && sponsorId) 
            {
                try 
                {
                    const sponsorDataFromId = await getSponsorById(Number(sponsorId));
                    if (sponsorDataFromId) 
                    {
                        handleViewEdit(sponsorDataFromId, true);
                    }
                    else if (!sponsorDataFromId) 
                    {
                        toast.error('The sponsor you\'re trying to access doesn\'t exist');
                        navigate('/sponsors');
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        };
        fetchDataFromRoute();
    }, [sponsors]);

    useEffect((): void => 
    {
        if(!pageSize && cardView)
        {
            updatePageSize(25);
        }
    }, [pageSize]);

    return (
        <div style={{ height: (isTableView && eventId) ? 'calc(100vh - 57px)' : '', overflow: (isTableView && eventId) ? 'hidden' : '' }} id={eventId ? "eventSponsorsPage" : "sponsorsPage"}>
            {(isTableView || !cardView) && 
                <>
                    <HeaderBar 
                        title={eventId ? <p><FontAwesomeIcon onClick={() => navigate('/events/' + eventId + '/overview')} cursor="pointer" style={{ marginRight: '8px' }} icon={['fal', 'arrow-left']} /> Sponsors</p> : <p>Sponsors</p>}
                        buttons={
                            eventId ?
                                [
                                    (eventReduxData?.status === EventStatus.PUBLISHED && <Tooltip placement='top' arrow title={'Sponsor Portal Link'}><FontAwesomeIcon cursor={'pointer'} onClick={() => {
                                        navigator.clipboard.writeText(`${APP_CONSTANTS.APP_DOMAIN}/${orgLink}/${eventReduxData?.link}/sponsor-portal`);
                                        toast.success('Link copied');
                                    }} icon={['fal', 'copy']} /></Tooltip>),
                                    <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                                    <DataImport licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} eventId={eventId} />,
                                    <CustomButton btnType='secondary' name='Select Sponsor' onClick={() => addSponsor(undefined, true)} />,
                                    <CustomButton btnType='secondary' name='Add Sponsor' onClick={createSponsor}/>
                                ] :
                                [
                                    <CustomButton name={chartVisibility ? 'Close Charts' : 'Open Charts'} btnType='secondary' onClick={() => setChartVisibility(!chartVisibility)} />,
                                    <DataImport licenseKey={import.meta.env.VITE_SPONSOR_SHEET_ID} />,
                                    <CustomButton btnType='primary' name='Add Sponsor' onClick={openDrawer}/>
                                ]
                        }
                    />
                    {chartVisibility && <SponsorsGraph trendData={trendData} sponsorsCount={sponsorDataCount} isChartReady={isChartReady} sponsorsIcpData={sponsorsIcpData} sponsorFundingData={sponsorFundingData} sponsorRevenueData={sponsorRevenueData} />}
                    <div
                        style={{
                            height: chartVisibility ? '60%' : 'calc(100vh - 60px)',
                            maxHeight: !chartVisibility ? 'calc(100vh - 60px)' : '',
                            flex: 1
                        }}
                    >
                        {isEmpty ? 
                        (
                            <TableEmptyComponent 
                                emptyImg={sponsorEmptyImg} 
                                openDrawer={openDrawer} 
                                infoText={'No Sponsor'} 
                                subInfoText={'Add your first Sponsor'} 
                                buttonName={'Add Sponsor'} 
                            />
                        ) :
                        (
                            <div className="h-100">
                                {/* <SponsorsPageToolbar 
                                    count={sponsorDataCount} 
                                    rows={sponsors} 
                                    setRows={setRows} 
                                    columns={sponsorColumns} 
                                    setColumns={setAllColumns}
                                /> */}
                                <TanstackToolbarWithServerFilter 
                                    columns={sponsorColumns}
                                    setColumns={setAllColumns}
                                    handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                    setCurrentUrl={setCurrentUrl}
                                    tabs={[{
                                        tabName: 'All',
                                        count: sponsorDataCount,
                                        navigation: () => {},
                                        selectedTab: true
                                    }]}
                                    filterColumns={filterColumns}
                                />
                                <TanstackTable 
                                    data={rows}
                                    initialColumns={allColumns}
                                    pageSize={pageSize}
                                    updatePageSize={updatePageSize}
                                    updateCurrentPage={updateCurrentPage as any}
                                    currentPage={currentPage - 1}
                                    showSpinner={showSpinner}
                                    rowCount={sponsorDataCount}
                                    height={chartVisibility ? 
                                        eventId ? `calc(100% - 120px)` : `calc(100% - 120px)` :
                                        eventId ? `calc(100% - 184px)` : `calc(100% - 124px)`
                                    }
                                    rightPinnedColumns={['actions']}
                                    onRowClick={(row): void => 
                                        {
                                            if (eventId)
                                            {
                                                editEventSponsor(row, undefined, true, false); 
                                            }
                                            else
                                            {
                                                handleViewEdit(row);
                                            }
                                        }}
                                />
                            </div>
                        )}
                    </div>
                </>
            }

            {
                (!isTableView && cardView) &&
                    <div className="sponsors-container">
                        <EventsCardHeaderComponent 
                            heading='Sponsors' 
                            count={sponsorDataCount} 
                            extraActions={eventReduxData?.status === EventStatus.PUBLISHED && [<Tooltip placement='top' arrow title={'Sponsor Portal Link'}><FontAwesomeIcon cursor={'pointer'} onClick={() => {
                                navigator.clipboard.writeText(`${APP_CONSTANTS.APP_DOMAIN}/${orgLink}/${eventReduxData?.link}/sponsor-portal`);
                                toast.success('Link copied');
                            }} icon={['fal', 'copy']} /></Tooltip>]}
                            buttonAction={() => navigate('/events/' + eventId + '/people/sponsors')} 
                        />

                        <Grid container spacing={2}>
                            {rows?.map((sponsor: ISponsor, index: number) => {

                                const profilePicture = sponsor?.logo ? sponsor?.logo : '';
                                const sponsorWebsite = sponsor?.websiteLink ? sponsor?.websiteLink : '';
                                const nameSplitted = sponsor?.name.split(' ');
                                let firstNameInitial: string;
                                let lastNameInitial: string;
                                let emptyImagesrc: string;
                                let sponsorSocialsArr: {icon: string, link: string}[] = [];

                                if (nameSplitted.length >= 2) 
                                {
                                    firstNameInitial = nameSplitted[0][0];
                                    const lastName = nameSplitted.slice(1).join(' ');
                                    lastNameInitial = lastName[0];
                                    emptyImagesrc = firstNameInitial + lastNameInitial;
                                }
                                else 
                                {
                                    emptyImagesrc = sponsor?.name.charAt(0);
                                }

                                sponsorSocialsArr?.unshift({
                                    icon: mailIcon,
                                    link: sponsor?.pocEmail
                                });

                                if(sponsor?.social?.twitter)
                                {
                                    sponsorSocialsArr.push({
                                        icon: twitterIcon,
                                        link: sponsor?.social?.twitter
                                    });
                                }
                                if(sponsor?.social?.linkedIn)
                                {
                                    sponsorSocialsArr.push({
                                        icon: linkedInIcon,
                                        link: sponsor?.social?.linkedIn
                                    });
                                }
        

                                // 628,131:     const fetchOrgLogo = props?.org?.social && props?.org?.social?.website && props?.org?.social?.website !== null ? `https://www.google.com/s2/favicons?sz=64&domain_url=${props.org?.social?.website}` : null;
                                // const sponsorImage = (<div>{profilePicture !== '' ? <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px' }} className="sponsor-avatar" src={profilePicture}></Avatar> : <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px', height: '40px !important', width: '40px !important' }} className="empty-logo-img" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
                                const sponsorImage = (<div>{sponsorWebsite ? <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px' }} className="sponsor-card-avatar" src={`https://www.google.com/s2/favicons?sz=64&domain_url=${sponsorWebsite}`}></Avatar> : <Avatar sx={{ border: '1px solid var(--Component-colors-Alpha-alpha-black-10, rgba(0, 0, 0, 0.10))', borderRadius: '8px', height: '40px !important', width: '40px !important' }} className="empty-logo-img" src={emptyImagesrc}><p>{emptyImagesrc.toUpperCase()}</p></Avatar>}</div>);
                                const sponsorName = (<div className="sponsor-name-container"><p>{sponsor?.name}</p><FontAwesomeIcon icon={['fal', 'arrow-up-right']} onClick={(event) => { event?.stopPropagation(); window.open(sponsor?.websiteLink, '_blank'); }} /></div>);
                                const sponsorPocEmail = (<div className="sponsor-poc-container"><p className="name">{sponsor?.pocName}</p><FontAwesomeIcon icon={['fal', 'envelope']} /></div>);

                                const sponsorSocials = (<div className="sponsor-socials-icons">{sponsorSocialsArr.map((social, index) => { return <Box component={'img'} key={index} src={social.icon} height={'1rem'} width={'1rem'} onClick={(event) => {
                                    if(social.link !== sponsor?.pocEmail)
                                    {
                                        window.open(social.link, '_blank');
                                        event.stopPropagation();
                                    }
                                    else
                                    {
                                        navigator.clipboard.writeText(`${sponsor?.pocEmail}`);
                                        toast.success('Email copied to clipboard');
                                        event.stopPropagation();
                                    }
                                }} /> })}</div>);

                                const footerContent = (
                                    <div className="sponsor-social-contents">
        
                                        <p>{sponsor?.pocName}</p>
                                        
                                        {sponsorSocials}
                                    </div>
                                );

                                return (
                                    <Grid key={index} item xl={4} lg={4} md={6} sm={12} xs={12}>
                                        <CardComponent 
                                            header={sponsorImage}
                                            cardMinHeight='144px'
                                            contentHeading={sponsorName}
                                            contentHeadingBold
                                            gapNotRequired
                                            footerLeft={footerContent}
                                            onClick={() => { editEventSponsor(sponsor, undefined, true, true) }}
                                        />
                                    </Grid>
                                )
                            })}

                                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                                    <CardComponent 
                                        emptyText='+ Add Sponsor'
                                        onClick={() => addSponsor('Select or Create Sponsor')}
                                        emptyCardHeight='116px'
                                    />
                                </Grid>
                        </Grid>
                    </div>
            }
        
            {
                showDeletePopup &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteSponsorsFromTable(selectedSponsor as ISponsor)} 
                        cancelClick={() => { 
                            setShowDeletePopup(false);
                            setSelectedSponsor(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedSponsor?.name}?`}
                        modalTitle='Delete Sponsor'
                        show={showDeletePopup}
                        rejectBtn='Cancel'
                    />
            }
        </div>
    );
};
export default SponsorsPage;

export const generateSponsorColumns = (data, setAnchorEl, anchorEl): GridColDef[] => 
{

    const excludedFields = ['id', 'status', 'orgId', 'created', 'modified', 'clearbitEnrichedId', 'hubspotContactId', 'hubspotContactData', 'clearbitData', 'clearbitData.clearbitId', 'clearbitId'];

    const columns = [];

    data.forEach((item: {
        field?: string;
        hide?: boolean;
        headerName?: string;
        disableColumnMenu?: boolean;
    }): void => 
    {
        if (item.field === 'icp' || item.field === 'buyerPersona') 
        {

            columns.push({
                field: item.field,
                hide: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.JSX.Element => 
                {
                    const icpChipColor = item.field === 'icp' ? 'blue' : 'pink';

                    const handlePopoverOpen = (event, params): void => 
                    {
                        setAnchorEl({
                            id: params.id, field: params.field
                        });
                    };
                    const handlePopoverClose = (): void => 
                    {
                        setAnchorEl(false);
                    };

                    return (
                        <Box
                            onMouseEnter={(event): void => 
                            {
                                handlePopoverOpen(event, params);
                            }}
                            onMouseLeave={handlePopoverClose}
                        >
                            {anchorEl?.id === params?.id && anchorEl?.field === params?.field ? (
                                <Box className="popupBlock">
                                    <Box className="tooltip-chip-cont">
                                        {params.value.map((tag, index): React.JSX.Element => 
                                        {
                                            return (
                                                <MuiChip chipColor={icpChipColor} key={index} label={tag} />
                                            );
                                        })}
                                    </Box>
                                </Box>
                            ) : (
                                <Box onMouseLeave={handlePopoverClose}>
                                    {params.value.length > 0 ? (
                                        params.value.length > 1 ? (
                                            <Box className="table-chip-cell">
                                                <MuiChip chipColor={icpChipColor} label={params.value[0]} />
                                                <Typography className="table-chip-count">
                                                    +{params.value.length - 1}
                                                </Typography>
                                            </Box>
                                        ) : (
                                            <MuiChip chipColor={icpChipColor} label={params.value[0]}/>
                                        )
                                    ) : null}
                                </Box>
                            )}
                        </Box>
                    );
                },
                valueGetter: (params): string => 
                {
                    return params.row[item.field];
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                width: 105,
                minWidth: 105
            });
        }
        else 
        {
            columns.push({
                field: item.field,
                hide: item.hide,
                headerName: _.startCase(item.headerName),
                renderCell: (params): React.JSX.Element => 
                {
                    return <Tooltip disableInteractive placement='top' title={<Typography variant="subtitle1">{params.value}</Typography>}><Box className="MuiDataGrid-cellContent">{params.value}</Box></Tooltip>;
                },
                valueGetter: (params): string => 
                {
                    if (params.row?.clearbitData) 
                    {
                        if (item.field.split('.').length === 2) 
                        {
                            const subKey = item.field.split('.');
                            return params.row?.clearbitData?.[subKey[0]]?.[subKey[1]];
                        }
                        return params.row?.clearbitData[item.field];
                    }
                    else 
                    {
                        return params.row[item.field];
                    }
                },
                disableColumnMenu: item.disableColumnMenu,
                flex: 1,
                minWidth: 140
            });
        }
    });

    const filteredColumns = columns.filter((column): boolean => 
    {
        return !excludedFields.includes(column.field);
    });
    return filteredColumns;
};