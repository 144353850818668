/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Popover, Stack, Tooltip, Typography } from '@mui/material';
import _, { debounce, isEmpty } from 'lodash';
import eventBus from '../../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../../scripts/constants';
import { Form } from 'react-bootstrap';
import { checkUpdatedRegistrantData, updateEventRegistrant, updateEventRegistrantComments } from '../../../../../scripts/apis/eventRegistrants';
import { useEffect } from 'react';
// eslint-disable-next-line import/named
import { useSelector } from 'react-redux';
import { EnrichmentComponent } from '../../../../Audience/ViewAudienceInfo';
import { CloseIconComponent, FormControlComponent, FormLabelComponent, SelectComponent } from '../../../../../common/FormComponents/ReusableFormComponents';
import { IEventReduxValues, IEventsDispatch } from '../../../../../components/Events/interfaces/create-events_interface';
import { EnableApprovalStatus, TicketType } from '../../../enum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getRegistrationFormById } from '../../../../../scripts/apis/registrationForm';
import { renderCheckBoxComponent, renderDateComponent, renderDropDownComponent, renderInputComponent, renderRadioGroupComponent } from '../../../../Settings/CustomField/CustomTypeComponent';
import { EventGraphObject, EventTicket } from '../../../interfaces';
import { getAlleventTickets, ticketsCount } from '../../../../../scripts/apis/eventTickets';
import { EventRegistrantPaymentStatus, EventRegistrantStatus } from '../../../enum/event-registrant.enum';
import { CONTENT } from '../../../../../scripts/i18n';
import MuiChip from '../../../../../common/FormComponents/MuiChip';
import { CustomButton } from '../../../../../common/FormComponents/Buttons';
import { CustomField } from '../../../../Registration/interfaces/custom-field_interface';
import { CustomFieldType } from '../../../../Registration/enum/custom-field.enum';
import { getSimilarKey } from '../../../../../scripts/helpers';
import { EventRegistrant } from '../../../interfaces/event-registrant_interface';
import toast from 'react-hot-toast';

const RegistrantDetails = ({ registrant, eventId, statusCount, setRefresh }: { registrant: EventRegistrant; eventId: string | number; statusCount?: EventGraphObject, setRefresh: React.Dispatch<React.SetStateAction<boolean>> }): React.JSX.Element => {

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    // const csrfTokenData = useSelector((state) => 
    // {
    //     return state['csrfTokenValue'].value.csrfToken; 
    // });

    const name = registrant.firstName;
    // const email = registrant.email;
    // const audienceId = registrant?.audienceId;
    let additionalData;

    if (typeof (registrant.additionalData.gdprData) === 'object') {
        additionalData = _.omit(registrant.additionalData, ['gdprData']);
    }
    else {
        additionalData = registrant.additionalData;
    }

    // const registrationType = eventReduxData.registrationType;
    // const inviteOnlyRegistrationType = registrationType === RegistrationTypes.INVITE_ONLY;

    const [approvalStatus, setApprovalStatus] = useState<number | null>(null);
    const [spinner, setSpinner] = useState(false);
    // const [audienceData, setAudienceData] = useState<Audience>(null);
    const clearbitData = registrant?.clearbitData;
    const [accordionExpansion, setAccordionExpansion] = React.useState<string | boolean>(false);
    const [isAttendeeEnabled, setIsAttendeeEnabled] = useState(false);
    const [approvalRequired, isApprovalRequired] = useState(false);
    const [paidTicket, isPaidTicket] = useState(false);
    const [ticketData, setTicketData] = useState(null);
    const [registrantComment, setRegistrantComment] = useState('');
    const [resendEmailActive, setResendEmailActive] = useState(false);
    const [attended, setAttended] = useState(null);
    const [formComponents, setFormComponents] = useState<CustomField[]>([]);
    const [customFormState, setCustomFormState] = useState({
    });
    const [eventTickets, setEventTickets] = useState<EventTicket[]>([]);
    const [selectedTicket, setSelectedTicket] = useState<number>();

    const eventTicketsOptionLabel = eventTickets?.length > 0 && eventTickets?.reduce((obj, ticket) => {
        return {
            ...obj,
            [ticket?.id]: ticket.name,
        };
    }, {});

    const handleAccoridon = (propertyId) => {
        return (event: React.SyntheticEvent, newExpanded: boolean): void => {
            setAccordionExpansion(newExpanded ? propertyId : false);
        };
    };

    // const additionalInfoComponent = ():React.JSX.Element[] => 
    // {
    //     return Object.keys(additionalData).map((element, index):React.JSX.Element => 
    //     {
    //         const formTitle = _.startCase(element);
    //         return (
    //             <Box key={index} className="content-div">
    //                 <Typography className="field-name">{formTitle}</Typography>
    //                 <Typography className="field-value">{additionalData[element]}</Typography>
    //             </Box>
    //         );
    //     });
    // };

    const convertKeyToNormalWords = (data: string): string => {
        return data
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            .toLowerCase()
            .replace(/(^|\s)[a-z]/g, (match: string): string => {
                return match.toUpperCase();
            })
            .replace(/<a[^>]*>(.*?)<\/a>/g, (match, group): string => {
                return ` ${group} `;
            })
            .replace(/<span[^>]*>(.*?)<\/span>/g, (match, group): string => {
                return ` ${group} `;
            })
            .trim();
    };

    const renderGDPRComponent = (): React.JSX.Element[] => {
        const gdprData = [];
        const gdprKey = registrant.additionalData?.gdprData;
        for (const key in gdprKey) {
            gdprData.push({
                key,
                value: Number(gdprKey[key])
            });
        }

        return gdprData && gdprData.length > 0 && gdprData.map((element: { key: string, value: number }, index): React.JSX.Element => {
            return (
                <Box key={index} className="registrant-gdpr-component">
                    <Form.Check type="checkbox" checked={element.value ? true : false} label={<Typography className="gdpr-checkbox-label">{
                        <div dangerouslySetInnerHTML={{
                            __html: convertKeyToNormalWords(element.key)
                        }} />
                    }</Typography>} />
                </Box>
            );
        });
    };

    const handleDrawerClose = (): void => {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.CLOSE_EVENT, {
            open: false,
        });

        eventBus.dispatch('selected-row-id', null);

        // navigate('/events/' + eventId + '/people/registrants');
    };

    const handleSubmit = async (): Promise<void> => {
        setSpinner(true);

        const validFormStatePayload = {
            'firstName': customFormState['firstName'],
            'lastName': customFormState['lastName'],
            'email': customFormState['email'],
            additionalData: _.omit(customFormState, ['email']),
            eventTicketId: selectedTicket,
        };

        let updateApprovalStatus, updateComments;

        if (registrant?.status !== approvalStatus || registrant?.attendeeStatus !== attended) {
            try {
                const updateData: { status?: number, attendeeStatus?: number } = {
                };
                if (registrant?.status !== approvalStatus) {
                    updateData.status = approvalStatus;
                }

                if (registrant?.attendeeStatus !== attended) {
                    updateData.attendeeStatus = attended;
                }

                if (Object.keys(updateData).length > 0) {
                    updateApprovalStatus = await updateEventRegistrant(eventId, registrant.id, updateData);
                }
            }
            catch (error) {
                console.log(error);
            }
            finally {
                setSpinner(false);
            }
        }

        if ((registrant?.comments !== registrantComment) && registrantComment !== '') {
            try {
                updateComments = await updateEventRegistrantComments(eventId, registrant.id, {
                    comments: registrantComment,

                });
            }
            catch (error) {
                console.log(error);
            }
            finally {
                setSpinner(false);
            }
        }

        if (updateApprovalStatus || updateComments) {
            setRefresh(true);
            toast.success('Registrant Details updated');
        }

        if (validFormStatePayload) {
            try {
                const updateRegistrantFormData = await checkUpdatedRegistrantData(eventId, registrant.id, validFormStatePayload);
                if (updateRegistrantFormData) {
                    toast.success('Registrant Details updated');
                    setRefresh(true);
                }
            }
            catch (error) {
                console.log(error);
            }
        }
        setSpinner(false);
        handleDrawerClose();
    };

    const handleResendEmail = async (): Promise<void> => {
        try {
            setResendEmailActive(true);
            const resendEmail = await updateEventRegistrant(eventId, registrant.id, {
                status: approvalStatus,
            });
            if (resendEmail) {
                setRefresh(true);
                toast.success('Email sent successfully');
                setResendEmailActive(false);
                handleDrawerClose();
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const debouncedHandleResendEmail = debounce(handleResendEmail, 150);

    // INVITE = 1,
    // APPROVED = 2,
    // REJECTED = 3,
    // DELETED = 4,
    // WAITLIST = 5,
    const registrantStatus = {
        1: 'In Review',
        2: 'Approved',
        3: 'Rejected',
        4: 'Waitlist',
        6: 'Confirmed'
    };

    const registrantAttendedStatus = {
        1: 'Not Attended',
        2: 'Attended',
    };

    const registrantStatusOptionsRequiredApproval = [
        {
            id: 1, name: 'In Review',
        },
        {
            id: 2, name: 'Approved',
        },
        {
            id: 3, name: 'Rejected',
        },
        {
            id: 4, name: 'Waitlist',
        },
        {
            id: 6, name: 'Confirmed',
        },
    ];

    const registrantStatusOptionsNoApproval = [
        {
            id: 1, name: 'In Review',
        },
        {
            id: 3, name: 'Rejected',
        },
        {
            id: 4, name: 'Waitlist',
        },
        {
            id: 6, name: 'Confirmed',
        },
    ];

    const fetchRegistrationFormData = async (): Promise<void> => {
        try {
            const regFormData = await getRegistrationFormById(Number(eventReduxData?.['registrationFormId']));
            if (regFormData?.formFields?.length > 0) {
                setFormComponents(regFormData?.formFields);
            }
        }
        catch (error) {
            console.log(error);
            toast.error(_.startCase((error as Error).message.toLowerCase()));
        }
    };

    const fetchTickets = async () =>
    {
        try 
        {
            const count = await ticketsCount(eventId);
            if(count)
            {
                const tickets = await getAlleventTickets(count, 0, eventId);
                if(tickets && tickets.length > 0)
                {
                    setEventTickets(tickets);
                }
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const currentTimestamp = Math.floor(new Date().getTime() / 1000.0);

    const attendedStatusOptions = [
        {
            id: 2, name: 'Yes',
        },
        {
            id: 1, name: 'No',
        },
    ];

    const attendedStatus = {
        1: 'No',
        2: 'Yes',
    };

    useEffect((): void => {
        if (registrant && registrant.status) {
            setApprovalStatus(registrant.status);
        }
        if (registrant && registrant.attendeeStatus) {
            setAttended(registrant.attendeeStatus);
        }

        if (registrant && registrant?.comments) {
            setRegistrantComment(registrant?.comments);
        }

        fetchTickets();

        const eventStartDateTime = Number(eventReduxData.eventStartDateTime);
        const eventEndDateTime = Number(eventReduxData.eventEndDateTime);

        if (registrant && registrant?.ticket && (!(isEmpty(registrant?.ticket)))) {

            setSelectedTicket(registrant?.ticket?.id);
            const eventTicketData = registrant?.ticket;
            if (eventTicketData) {
                setTicketData(eventTicketData);
            }

            if ((eventTicketData && eventTicketData.enableApproval === EnableApprovalStatus.ENABLE) || (statusCount && statusCount?.APPROVED && Number(statusCount?.APPROVED) > 0)) {
                isApprovalRequired(true);
            }

            if (eventTicketData && eventTicketData.type === TicketType.PAID) {
                isPaidTicket(true);
            }
        }
        if (registrant && registrant?.additionalData) {
            let x = {
            };
            Object.keys(registrant?.additionalData).map((key): void => {
                x = {
                    ...x,
                    [key]: registrant.additionalData[key]
                };
            });

            setCustomFormState({
                ['firstName']: registrant['firstName'],
                ['lastName']: registrant['lastName'],
                ['email']: registrant['email'],
                ...x,
            });
        }

        eventBus.on('confirm-approval-status', async (): Promise<void> => {
            setApprovalStatus(EventRegistrantStatus.CONFIRMED);
        });

    }, []);

    useEffect((): void => {
        if (eventReduxData && eventReduxData?.['registrationFormId']) {
            fetchRegistrationFormData();
        }

        const currentDate = new Date();
        currentDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), 0);

        const eventStartDate = new Date(Number(eventReduxData?.eventStartDateTime) * 1000);
        eventStartDate.setHours(eventStartDate.getHours(), eventStartDate.getMinutes(), eventStartDate.getSeconds(), 0);

        const seventyTwoHoursBeforeEvent = new Date(eventStartDate);
        seventyTwoHoursBeforeEvent.setHours(eventStartDate.getHours() - 72);

        if (currentDate.getTime() >= seventyTwoHoursBeforeEvent.getTime()) {
            setIsAttendeeEnabled(true);
        }

    }, [eventReduxData]);

    const handleInputChange = (event): void => {
        const { name, value } = event.target;

        if(name === 'firstName' || name === 'lastName')
        {
            setCustomFormState((prevState): any => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        }
        else
        {
            setCustomFormState((prevState): any => {
                return {
                    ...prevState,
                    [name]: value,
                };
            });
        }
    };

    const handleDropDownInputChange = (name, value): void => {
        setCustomFormState((prevState): any => {
            const similarKey = getSimilarKey(prevState, name);
            const keyToUpdate = similarKey ? similarKey : name;

            return {
                ...prevState,
                [keyToUpdate]: value,
            };
        });
    };

    const handleBoxChange = (name, value): void => {
        setCustomFormState((prevState): any => {
            return {
                ...prevState,
                [name]: value,
            };
        });
    };

    const handleCheckboxChange = (item, data): void => {

        if (customFormState[data?.propertyKey]) {
            const selectedIndex = customFormState[data?.propertyKey].indexOf(item.name);
            const updatedSelectedOptions = [...customFormState[data?.propertyKey]];

            if (selectedIndex === -1) {
                updatedSelectedOptions.push(item.name);
            }
            else {
                updatedSelectedOptions.splice(selectedIndex, 1);
            }

            setCustomFormState((prevState): any => {
                return {
                    ...prevState,
                    [data?.propertyKey]: updatedSelectedOptions,
                };
            });
        }
        else {
            setCustomFormState((prevState): any => {
                return {
                    ...prevState,
                    [data?.propertyKey]: [item.name],
                };
            });
        }
    };

    const isStatusDisabled = (option): boolean => {
        if (option.id === 7 && !isAttendeeEnabled) return true;
        // if(option.id === 6 && paidTicket) return true;
        return false;
    };

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (): void => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const PopoverButton = (): React.ReactElement => {
        return (
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography sx={{
                    p: 1, fontSize: '12px'
                }}>Resend Transactional Email</Typography>
            </Popover>
        );
    };

    const registrantStatusClass = {
        1: 'grey',
        2: 'green',
        3: 'red',
        4: 'yellow',
        5: 'deleted',
        6: 'blue'
    };

    const registrantAttendeeClass = {
        1: 'red',
        2: 'green'
    };

    const paymentStatusClass = {
        1: 'none',
        2: 'paid',
        3: 'yet_to_pay',
        4: 'cancelled',
        5: 'expored'
    };

    return(
        <div id="registrantDetails">
            <CloseIconComponent onClick={handleDrawerClose} />

            {/* Image and other basic details */}
            <div className="sidebar-container-spacing registrant-basic-details-cont">
                <div>
                    {clearbitData && clearbitData?.companyLogo ? <Avatar src={clearbitData.companyLogo} className="registrant-logo" /> : <Avatar className="registrant-logo">{name.charAt(0).toUpperCase()}</Avatar>}
                </div>

                <div className="registrant-personal-details">
                    <div className="registrant-name">{`${registrant?.firstName ?? ''} ${registrant?.lastName ?? ''}`}</div>
                    <div className="registrant-companyAnd-email">{clearbitData?.companyName}</div>
                    <div className="registrant-companyAnd-email">
                        {registrant?.email}
                        <FontAwesomeIcon cursor={'pointer'} onClick={() => {
                            navigator.clipboard.writeText(registrant?.email);
                            toast.success('Email copied to clipboard');
                        }} icon={['fal', 'copy']} />
                    </div>

                    <div className="icp-bp-cont">
                        {registrant?.icp && registrant?.icp?.length > 0 && <MuiChip label={registrant?.icp?.toString()} chipColor='blue' />}
                        {registrant?.buyerPersona && registrant?.buyerPersona?.length > 0 && <MuiChip label={registrant?.buyerPersona?.toString()} chipColor='red' />}
                    </div>    
                </div>  

                <div className="registrant-status-container">
                    <MuiChip label={registrantStatus[registrant?.status]} chipColor={registrantStatusClass[Number(registrant?.status)]} />
                    <MuiChip label={registrantAttendedStatus[registrant?.attendeeStatus]} chipColor={registrantAttendeeClass[Number(registrant?.attendeeStatus)]} />
                    {registrant?.ticket && <MuiChip label={registrant?.ticket?.name} chipColor='blue' />}
                    {registrant?.coupon && <MuiChip label={registrant?.coupon?.name} chipColor='green' />}
                    {registrant?.utm && <MuiChip label={registrant?.utm?.name} chipColor='green' />}
                </div>
            </div>

            {/* status and ticket dropdowns */}
            <div className="sidebar-container-spacing registrant-statusAnd-ticket">
                <div>

                {/* Registrant approval status update */}
                {approvalRequired &&
                    <>
                        <Box className="">
                            <Stack direction='row' spacing={2}>
                                <FormLabelComponent label='Approval status' />
                                <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} className="resend-email-button">
                                    <FontAwesomeIcon onClick={!resendEmailActive ? debouncedHandleResendEmail : undefined} icon={['fal', 'paper-plane']} />
                                </Box>
                                <PopoverButton />
                            </Stack>
                            <SelectComponent
                                disabled={currentTimestamp > Number(eventReduxData?.eventEndDateTime)}
                                value={approvalStatus || ''}
                                options={paidTicket ? registrantStatusOptionsRequiredApproval : registrantStatusOptionsNoApproval}
                                defaultPlaceholder='Select a status'
                                optionLabels={registrantStatus}
                                onChange={(event): void => {
                                    if (Number(event.target.value) === EventRegistrantStatus.CONFIRMED && paidTicket) {
                                        const confirmationText = 'This is a paid event. Changing the status to Confirmed will override the default payment status.\nDo you want to proceed?';
                                        const formatted = confirmationText.split('\n').map((item, i): React.JSX.Element => {
                                            return <React.Fragment key={i}>{item}<br /></React.Fragment>;
                                        });

                                        eventBus.dispatch(APP_CONSTANTS.EVENTS.POPUP_EVENT.RENDER, {
                                            open: true,
                                            title: 'Confirmation',
                                            content: formatted,
                                            acceptBtn: 'Yes',
                                            acceptEvent: 'confirm-approval-status',
                                            rejectBtn: 'No',
                                        });
                                    }
                                    else setApprovalStatus(Number(event.target.value));
                                }}
                            />
                        </Box>
                    </>
                }

                {/* Registrant attended status update */}
                <div className="sidebar-container-spacing">
                    <Box className="registrant-attendee-dropdown">
                        <FormLabelComponent label='Attended status' />
                        {!isAttendeeEnabled &&
                            <Tooltip placement='top' title={'This will be enabled three days before the event'}><FontAwesomeIcon icon={['fal', 'info-circle']} /></Tooltip>}
                    </Box>
                    <SelectComponent
                        disabled={!(isAttendeeEnabled && approvalStatus === EventRegistrantStatus.CONFIRMED)}
                        value={attended || ''}
                        options={attendedStatusOptions}
                        defaultPlaceholder='Select an Option'
                        optionLabels={registrantAttendedStatus}
                        onChange={(event): void => {
                            setAttended(Number(event.target.value));
                        }}
                    />
                </div>

                {ticketData && (!(isEmpty(ticketData))) ? <>
                    {
                        (approvalStatus !== EventRegistrantStatus.CONFIRMED && (registrant?.paymentStatus && registrant?.paymentStatus !== EventRegistrantPaymentStatus.YET_TO_PAY)) &&
                            <>
                                <Box className="sidebar-container-spacing">
                                    <FormLabelComponent label='Ticket' />
                                    <SelectComponent options={eventTickets} value={String(selectedTicket)} onChange={(event) => setSelectedTicket(event.target.value)} defaultPlaceholder='Select a ticket' optionLabels={eventTicketsOptionLabel} />
                                </Box>
                            </>
                    }
                    </> : null}
                </div>

                {/* Comments */}
                <div className='sidebar-container-spacing'>
                    <FormLabelComponent label='Comments' />
                    <FormControlComponent
                        as='textarea'
                        type='text'
                        value={registrantComment}
                        onChange={(event): void => setRegistrantComment(event.target.value)}
                        rows={4}
                        placeholder='Enter a description'
                    />
                </div>
            </div>

            {/* Registrant form container */}
            <div className="custom-field-container">
                <Accordion expanded={accordionExpansion === 'formAccordian'} id={'formAccordian'} className="form-accordian" onChange={handleAccoridon('formAccordian')}>
                    <AccordionSummary
                        className="form-accordian-summary"
                        aria-controls="panel1a-content"
                        id="formAccordian-header"
                        expandIcon={<FontAwesomeIcon className="dropdown-icon" icon={['fal', accordionExpansion === 'formAccordian' ? 'chevron-up' : 'chevron-right']} />}
                    >
                        <Box className="heading-container">
                            <Typography className="accordian-title">{'Form Details'}</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails className="form-accordian-details">
                        <Box className="custom-field-container">
                        {formComponents && formComponents.length > 0 && formComponents.map((item): React.ReactElement => {
                            const similarKey = getSimilarKey(customFormState, item?.default || item?.propertyKey || '');

                            if (item?.type === CustomFieldType.NUMBER) {
                                return renderInputComponent(item, 'number', handleInputChange, customFormState?.[similarKey], null);
                            }
                            else if (item?.type === CustomFieldType.SINGLE_LINE) {
                                return renderInputComponent(item, 'text', handleInputChange, customFormState?.[similarKey], item?.default === 'email');
                            }
                            else if (item?.type === CustomFieldType.MULTI_LINE) {
                                return renderInputComponent(item, 'textarea', handleInputChange, customFormState?.[similarKey], null);
                            }
                            else if (item?.type === CustomFieldType.CHECKBOXES) {
                                return renderCheckBoxComponent(item, handleCheckboxChange, customFormState?.[similarKey], null);
                            }
                            else if (item?.type === CustomFieldType.DROPDOWN) {
                                return renderDropDownComponent(item, handleDropDownInputChange, customFormState?.[similarKey], null);
                            }
                            else if (item?.type === CustomFieldType.RADIO) {
                                return renderRadioGroupComponent(item, handleBoxChange, customFormState?.[similarKey], null);
                            }
                            else if (item?.type === CustomFieldType.DATE) {
                                return renderDateComponent(item, handleInputChange, customFormState?.[similarKey], null);
                            }
                        })}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>

            {/* Clearbit container */}
            {clearbitData && <Box className="registrant-enrichment-container"><EnrichmentComponent fromRegistrant={true} accordionExpansion={accordionExpansion} handleAccoridon={handleAccoridon} clearbitData={clearbitData} /></Box>}
        
            <Box className="submit-btn-container">
                <Stack direction={'row'} spacing={2} display={'flex'} justifyContent={'flex-end'}>
                    <CustomButton onClick={handleDrawerClose} btnType={'secondary'} name={CONTENT.SIDE_DRAWER.CLOSE_BTN} />
                    <CustomButton type="submit" loading={spinner} btnType='primary' onClick={handleSubmit} name='Save' />
                </Stack>
            </Box>
        </div>
    )
};

export default RegistrantDetails;

export const ConvertStringtoCamelCase = (data: string): string => {
    // const datawithNoHtml = data.replace(/<[^>]*>/g, '');
    // if(datawithNoHtml === 'LinkedIn URL (Personal Account)')
    // {
    //     return 'linkedinUrl(personalAccount)';
    // }
    // else 
    // {
        // return datawithNoHtml.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index): string => {
        //     return index === 0 ? word.toLowerCase() : word.toUpperCase();
        // })
        // .replace(/\(([^)]+)\)/g, (_, match) => {
  
        //     if (match) {
        //         return `(${match.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index): string => {
                   
        //             return  index === 0 ? word.toLowerCase() : word.toUpperCase();
        //         })})`
        //         .replace(/\s+/g, '');
        //     }
        // })
        // .replace(/\s+/g, '');
    // }

    const keyWithoutTags = data.replace(/<\/?p>/g, '');

    const camelCaseKey = keyWithoutTags
        .split(' ')
        .map((word, index):string => 
        {
            if (index === 0) 
            {
                return word.toLowerCase();
            }
            else 
            {
                return (
                    word.charAt(0).toUpperCase() +
                    word.slice(1).toLowerCase()
                );
            }
        })
        .join('');
    
    return camelCaseKey;
    
};