import { useEffect, useState } from "react";
import ClosedWon from "../components/Revenue/ClosedWon";
import Opportunities from "../components/Revenue/Opportunities";
import RevenueOverview from "../components/Revenue/RevenueOverview";
import { IRevenueGraphReady, IRevenueOverview } from "../components/Revenue/revenueAnalytics.interface";
import RevenuePerformance from "../components/Revenue/Performance";
import LeadsRevenue from "../components/Revenue/LeadsRevenue";
import { getEventAnalyticsGraph } from "../../../../../scripts/apis/events";

const RevenueAnalytics: React.FC<{ eventId: string | number }> = ({ eventId }): React.JSX.Element =>
{

    const [revenueOverviewData, setRevenueOverviewData] = useState<IRevenueOverview>({});
    const [tableReady, setTableReady] = useState<boolean>(false);
    const [graphReady, setGraphReady] = useState<IRevenueGraphReady>({
        openStages: false,
        leadStages: false,
        closedWonBuyerPersona: false,
        closedWonICP: false,
        opportunitiesStage: false,
        opportunitiesBuyerPersona: false,
        opportunitiesICP: false,
        leadsBuyerPersona: false,
        leadsICP: false
    });
    const [revenueOverviewDataReady , setRevenueOverviewDataReady] = useState<boolean>(true);

    const fetchAllOverviewRelatedData = async (): Promise<void> =>
    {
        try 
        {
            const revenueOverviewData = await getEventAnalyticsGraph(eventId);

            setRevenueOverviewData(revenueOverviewData);
        } 
        catch (error) 
        {
            
        }
        finally
        {
            setRevenueOverviewDataReady(false);
            setGraphReady({
                ...graphReady,
                openStages: true,
                leadStages: true,
                closedWonBuyerPersona: true,
                closedWonICP: true,
                opportunitiesStage: true,
                opportunitiesBuyerPersona: true,
                opportunitiesICP: true,
                leadsBuyerPersona: true,
                leadsICP: true
            });
        }
    };

    useEffect(() => {
        fetchAllOverviewRelatedData();
    }, []);

    return (
        <div>
            <RevenueOverview 
                revenueOverviewData={revenueOverviewData} 
                revenueOverviewDataReady={revenueOverviewDataReady} 
                graphReady={graphReady}
            />
            <RevenuePerformance 
                performanceDataInDays={revenueOverviewData?.performanceDataInDays || []}
                performanceDataInPercentage={revenueOverviewData?.performanceDataInPercentage || []}
            />
            <ClosedWon 
                closedWonOverviewData={revenueOverviewData}
                graphReady={graphReady}
            />
            <Opportunities 
                opportunitiesOverview={revenueOverviewData}
                graphReady={graphReady}
            />
            <LeadsRevenue 
                leadsRevenueData={revenueOverviewData}
                graphReady={graphReady}
            />
        </div>
    )
};

export default RevenueAnalytics;