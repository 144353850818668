import { createContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteSpeaker, getSpeakerGraphData, getSpeakers, speakersCount, updateSpeaker } from '../scripts/apis/speakers';
import { useTablePagination } from './TablePaginationContext';
import _ from 'lodash';
import { Speaker } from '../pages/Speakers/interfaces';
import toast from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { eventSpeakersCount, getEventSpeakerGraphData, getEventSpeakers, removeEventSpeaker } from '../scripts/apis/eventSpeakers';

interface ISpeakersContext { 
    isEmpty: boolean;
    speakerDataCount: number;
    setIsEmpty: (isEmpty: boolean) => void;
    setSpeakers: (speakers: any) => void;
    speakers: any;
    deleteSpeakerFromTable: (speakerData: Speaker) => void;
    setRefresh: (refresh: boolean) => void;
    pageSize: number;
    updateCurrentPage: (currentPage: number) => void;
    updatePageSize: (pageSize: number) => void;
    showSpinner: boolean;
    setShowSpinner: React.Dispatch<React.SetStateAction<boolean>>;
    isChartReady: {
        speakersIcpGraphReady?: boolean;
        speakersBuyerPersonaGraphReady?: boolean;
        speakerRevenueGraphReady?: boolean;
    };
    speakersIcpData: any;
    speakerBuyerPersonaData: any;
    speakerRevenueData: any;
    currentPage: number;
    trendData: any;
    editSpeakerFromTable: (eventSpeakerData: any) => void;
    speakerData: Speaker[];
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedSpeaker: Speaker | null;
    setSelectedSpeaker: React.Dispatch<React.SetStateAction<Speaker | null>>;
    isTableView?: boolean;
    cardView?: boolean;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const SpeakersContext = createContext<ISpeakersContext>({
    isEmpty: false,
    speakerDataCount: 0,
    setIsEmpty: () => {},
    setSpeakers: () => {},
    speakers: [],
    deleteSpeakerFromTable: () => {},
    setRefresh: () => {},
    pageSize: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    setShowSpinner: () => {},
    isChartReady: {
        speakersIcpGraphReady: false,
        speakersBuyerPersonaGraphReady: false,
        speakerRevenueGraphReady: false,
    },
    speakersIcpData: [],
    speakerBuyerPersonaData: [],
    speakerRevenueData: [],
    currentPage: 0,
    trendData: null,
    editSpeakerFromTable: () => {},
    speakerData: [],
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    selectedSpeaker: null,
    setSelectedSpeaker: () => {},
    cardView: false,
    setCurrentUrl: () => {},
});

const SpeakersProvider: React.FC<{ children: React.ReactNode, eventId?: string | number, cardView?: boolean }> = ({ children, eventId, cardView }): React.JSX.Element => 
{
    const [speakerDataCount, setSpeakerDataCount] = useState(0);
    const [isEmpty, setIsEmpty] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [speakers, setSpeakers] = useState<Speaker[]>([]);
    const [speakerData, setSpeakerData] = useState<Speaker[]>([]);
    const [showSpinner, setShowSpinner] = useState(true);
    const [isChartReady, setChartReady] = useState<{
        speakersIcpGraphReady?: boolean,
        speakersBuyerPersonaGraphReady?: boolean,
        speakerRevenueGraphReady?: boolean
    }>({
        speakersIcpGraphReady: false,
        speakersBuyerPersonaGraphReady: false,
        speakerRevenueGraphReady: false
    });
    const [speakersIcpData, setSpeakersIcpData] = useState<{ name: string; percentage: number; value: string; }[]>([]);
    const [speakerBuyerPersonaData, setSpeakerBuyerPersonaData] = useState<{ name: string; percentage: number; value: string; }[]>([]);
    const [speakerRevenueData, setSpeakerRevenueData] = useState<{ name: string; percentage: number; value: string; }[]>([]);
    const [trendData, setTrendData] = useState<{[key: string]: string | number} | number | null>(null);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedSpeaker, setSelectedSpeaker] = useState<Speaker | null>(null);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const currentpath = useLocation().pathname;
    const isTableView = ((currentpath?.includes('speakers') && eventId && !cardView)) as boolean;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchData = async (): Promise<void> => 
    {
        setShowSpinner(true);
        try 
        {
            const params: [string, any][] = [];
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query?.forEach((value, key) => {
                params.push([key, value]);
            });

            const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);
            const count = await speakersCount();
            if (count) 
            {
                setSpeakerDataCount(count);
                setIsEmpty(count == 0);
                try 
                {
                    const speakersData = await getSpeakers(pageSize, currentPage - 1, encodedParams);
                    if (speakersData) 
                    {
                        setSpeakers([...speakersData]);
                        setSpeakerData([...speakersData]);
                        setShowSpinner(false);
                        setRefresh(false);
                    }

                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
        finally {
            setShowSpinner(false);
        }
    };

    const fetchEventSpeakerData = async ():Promise<void> => 
    {
        setShowSpinner(true);
        try 
        {
            const params: [string, any][] = [];
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query?.forEach((value, key) => {
                params.push([key, value]);
            });

            const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

            const eventSpeakerCount = await eventSpeakersCount(eventId as string);
            if (eventSpeakerCount) 
            {
                setSpeakerDataCount(eventSpeakerCount);
                setIsEmpty(eventSpeakerCount == 0);
                try 
                {
                    if(!isTableView)
                    {
                        const eventSpeakers = await getEventSpeakers(8, 0, eventId as string);
                        if (eventSpeakers) 
                        { 
                            setSpeakers([...eventSpeakers]);
                            setSpeakerData([...eventSpeakers]);
                            setShowSpinner(false);
                            setRefresh(false);
                        }
                    }
                    else
                    {
                        const eventSpeakers = await getEventSpeakers(pageSize, currentPage - 1, eventId as string, undefined, undefined, encodedParams);
                        if (eventSpeakers) 
                        { 
                            setSpeakers([...eventSpeakers]);
                            setSpeakerData([...eventSpeakers]);
                            setShowSpinner(false);
                            setRefresh(false);
                        }
                    }
                }
                catch (error) 
                {
                    console.log(error);
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchEventSpeakerPaginationData = async ():Promise<void> => 
    {
        if (speakerDataCount) 
        {
            try 
            {
                const params: [string, any][] = [];
                const query = new URLSearchParams(currentUrl.split('?')[1]);
                query?.forEach((value, key) => {
                    params.push([key, value]);
                });

                const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                const eventSpeakers = await getEventSpeakers(pageSize, currentPage - 1, eventId as string, undefined, undefined, encodedParams);
                setShowSpinner(true);
                if (eventSpeakers) 
                { 
                    setSpeakers([...eventSpeakers]);
                    setSpeakerData([...eventSpeakers]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    const fetchPaginationData = async (): Promise<void> => 
    {
        if (speakerDataCount) 
        {
            try 
            {
                const params: [string, any][] = [];
                const query = new URLSearchParams(currentUrl.split('?')[1]);
                query?.forEach((value, key) => {
                    params.push([key, value]);
                });

                const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);

                
                setShowSpinner(true);
                const speakersData = await getSpeakers(pageSize, currentPage - 1, encodedParams);
                if (speakersData) 
                {
                    // const updatedSpeakersData = await UpdatedDataWithDecompressedImage(speakersData);
                    // if (updatedSpeakersData) {
                    setSpeakers([...speakersData]);
                    setSpeakerData([...speakersData]);
                    setShowSpinner(false);
                    setRefresh(false);
                    // }
                }

            }
            catch (error) 
            {
                console.log(error);
                setShowSpinner(true);
            }
        }
    };

    const fetchSpeakersGraphData = async (): Promise<void> => 
    {
        try 
        {
            const graphData = await getSpeakerGraphData();
            
            if (graphData) 
            {
                if (graphData.icp) 
                {
                    const icpsum = _.sumBy(_.values(graphData.icp));
                    const icpData = Object.entries(graphData.icp)
                        .filter(([, value]): boolean => 
                        {
                            return value !== 0;
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name,
                                percentage: Math.round((Number(value) / icpsum) * 100),
                                value,
                            };
                        });

                    setSpeakersIcpData(icpData);

                }
                if (graphData.buyerPersona) 
                {
                    const buyerPersonaSum = _.sumBy(_.values(graphData.buyerPersona));
                    const buyerPersonaData = Object.entries(graphData.buyerPersona)
                        .filter(([, value]): boolean => 
                        {
                            return value !== 0;
                        })
                        .map(([name, value]): {
                            name: string;
                            percentage: number;
                            value: string
                        } => 
                        {
                            return {
                                name,
                                percentage: Math.round((Number(value) / buyerPersonaSum) * 100),
                                value,
                            };
                        });

                    setSpeakerBuyerPersonaData(buyerPersonaData);
                }
                if (graphData.arr) 
                {
                    const revenueSum = _.sumBy(_.values(graphData.arr));
                    const revenueData = Object.entries(graphData.arr)
                        .filter(([, value]): boolean => 
                        {
                            return value !== 0;
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / revenueSum) * 100),
                                value,
                            };
                        });

                    setSpeakerRevenueData(revenueData);
                }

                if (import.meta.env.VITE_NODE_ENV === 'demo' && graphData?.trend) 
                {
                    setTrendData(graphData?.trend);
                }

                setChartReady({
                    ...isChartReady, speakersIcpGraphReady: true, speakerRevenueGraphReady: true, speakersBuyerPersonaGraphReady: true
                });

            }
            else 
            {
                setChartReady({
                    ...isChartReady, speakersIcpGraphReady: true, speakerRevenueGraphReady: true, speakersBuyerPersonaGraphReady: true
                });
            }
        }
        catch (error) 
        {
            console.log(error);
            setChartReady({
                ...isChartReady, speakersIcpGraphReady: true, speakerRevenueGraphReady: true, speakersBuyerPersonaGraphReady: true
            });
        }
    };

    const fetchEventSpeakersGraphData = async ():Promise<void> => 
    {
        try 
        {
            const graphData = await getEventSpeakerGraphData(eventId as string);
            if (graphData) 
            {
                if (graphData.icp) 
                {
                    const icpsum = _.sumBy(_.values(graphData.icp));
                    const icpData = Object.entries(graphData.icp)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name,
                                percentage: Math.round((Number(value) / icpsum) * 100),
                                value: String(value),
                            }; 
                        });

                    setSpeakersIcpData(icpData);

                }
                if (graphData.buyerPersona) 
                {
                    const buyerPersonaSum = _.sumBy(_.values(graphData.buyerPersona));
                    const buyerPersonaData = Object.entries(graphData.buyerPersona)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name,
                                percentage: Math.round((Number(value) / buyerPersonaSum) * 100),
                                value: String(value),
                            }; 
                        });

                    setSpeakerBuyerPersonaData(buyerPersonaData);
                }
                if (graphData.arr) 
                {
                    const revenueSum = _.sumBy(_.values(graphData.arr));
                    const revenueData = Object.entries(graphData.arr)
                        .filter(([, value]):boolean => 
                        {
                            return value !== 0; 
                        })
                        .map(([name, value]):{
                            name: string;
                            percentage: number;
                            value: string;
                        } => 
                        {
                            return {
                                name: _.capitalize(name),
                                percentage: Math.round((Number(value) / revenueSum) * 100),
                                value: String(value)
                            }; 
                        });

                    setSpeakerRevenueData(revenueData);
                }

                if (import.meta.env.VITE_NODE_ENV === 'demo' && graphData?.trend) 
                {
                    setTrendData(graphData?.trend);
                }

                setChartReady({
                    ...isChartReady, speakersIcpGraphReady: true, speakerRevenueGraphReady: true, speakersBuyerPersonaGraphReady: true 
                });

            }
            else 
            {
                setChartReady({
                    ...isChartReady, speakersIcpGraphReady: true, speakerRevenueGraphReady: true, speakersBuyerPersonaGraphReady: true 
                });
            }
        }
        catch (error) 
        {
            console.log(error);
            setChartReady({
                ...isChartReady, speakersIcpGraphReady: true, speakerRevenueGraphReady: true, speakersBuyerPersonaGraphReady: true 
            });
        }
    };

    const deleteSpeakerFromTable = async (speakerData?: Speaker): Promise<void> => 
    {
        if (eventId)
        {
            try 
            {
                const speakerDeleted = await removeEventSpeaker(eventId as string, speakerData?.id as string);
                if (speakerDeleted) 
                {
                    toast.success(`Speaker removed from event.`);
                    setShowDeletePopup(false);
                    setSelectedSpeaker(null);
                    setRefresh(true);
                }
            } 
            catch (error) 
            {
                console.log(error);
                toast.error(error?.message);
            }
        }
        else
        {
            try 
            {
                const speakerDeleted = await deleteSpeaker(speakerData?.id);
                if (speakerDeleted) 
                {
                    toast.success(`${speakerData?.name} has been deleted successfully`);
                    setShowDeletePopup(false);
                    setSelectedSpeaker(null);
                    setRefresh(true);
                }
            }
            catch (error) 
            {
                console.log(error);
                toast.error(error?.message);
            }           
        }
    };

    const editSpeakerFromTable = async (eventSpeakerData: Speaker): Promise<void> => 
    {
        const speakerInfo = {
            name: eventSpeakerData.name,
            email: eventSpeakerData.email,
            additionalInfo: {
                headline: eventSpeakerData?.additionalInfo?.headline,
                bio: eventSpeakerData?.additionalInfo?.bio,
                socialLink: eventSpeakerData?.additionalInfo?.socialLink,
            }
        };
        try 
        {
            const speakerDetailsUpdated = await updateSpeaker(speakerInfo, eventSpeakerData.id);
            if (speakerDetailsUpdated) 
            {
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(():void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            setShowSpinner(true);
            if (eventId)
            {
                fetchEventSpeakerData();
                fetchEventSpeakersGraphData();
            }
            else
            {
                fetchData();
                fetchSpeakersGraphData();
            }
        }
        else if (currentPage > 1 && pageSize) 
        {
            setShowSpinner(true);
            if (eventId)
            {
                fetchEventSpeakerPaginationData();
            }
            else
            {
                fetchPaginationData();
            }
        }
    }, [currentPage, pageSize, currentUrl]);

    useEffect(():void => 
    {
        if (refresh) 
        {
            if (eventId)
            {
                fetchEventSpeakerData();
                fetchEventSpeakersGraphData();
            }
            else
            {
                fetchData();
                fetchSpeakersGraphData();
            }
        }
    }, [refresh]);

    // const UpdatedDataWithDecompressedImage = async (data) => {
    //     if (data) {
    //         const updatedData = await Promise.all(data.map(async (element) => {
    //             if (element.additionalInfo && element.additionalInfo.image) {
    //                 element.additionalInfo.image = await decompressImage(element.additionalInfo.image, 1500, 1500);
    //             }
    //             return element;
    //         }));

    return (
        <SpeakersContext.Provider value={{
            isEmpty, 
            speakerDataCount, 
            setIsEmpty, 
            setSpeakers, 
            speakers, 
            deleteSpeakerFromTable, 
            setRefresh, 
            pageSize, 
            updateCurrentPage, 
            updatePageSize, 
            showSpinner, 
            setShowSpinner, 
            isChartReady, 
            speakersIcpData, 
            speakerBuyerPersonaData, 
            speakerRevenueData, 
            currentPage, 
            trendData, 
            editSpeakerFromTable,
            speakerData,
            showDeletePopup,
            setShowDeletePopup,
            selectedSpeaker,
            setSelectedSpeaker,
            isTableView,
            cardView,
            setCurrentUrl, 
        }}>
            {children}
        </SpeakersContext.Provider>
    );
};

export default SpeakersProvider;


