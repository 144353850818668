import { Box, Typography } from '@mui/material';
// eslint-disable-next-line import/named
import React from 'react';
import CustomSpinner from '../../../common/CustomSpinner';
import { CONTENT } from '../../../scripts/i18n';
import { leastRoiEventsTableProps } from '../dashboard_interface';
import MuiChip from '../../../common/FormComponents/MuiChip';
import { createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../../common/TanstackTable/TanstackTable';

import './styles.scss';

const LeastRoiEventsTable: React.FC<leastRoiEventsTableProps> = (props: leastRoiEventsTableProps): React.JSX.Element => 
{
    const columnHelper = createColumnHelper();

    const columns = [
        columnHelper.accessor('title', { 
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return (<p>{CONTENT.DASHBOARD_PAGE.EVENT_TYPES[row.getValue()]}</p>)
            },
            header: 'Type',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('locationType', {
            cell: (row) => {
                return (<p>{CONTENT.DASHBOARD_PAGE.EVENT_LOCATION_TYPES[row.getValue()]}</p>)
            },
            header: 'Location',
            sortingFn: 'alphanumericCaseSensitive'
        }),
        columnHelper.accessor('roi', {
            cell: (row) => {
                const roiChipColor = row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi >= 1 ? 'green' : row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi < 1 ? 'red' : 'null';
                return (
                    <Box className="dashboard-roi-container">
                        <MuiChip chipColor={roiChipColor} label={row?.row?.original?.roi?.roi && row?.row?.original?.roi?.roi !== null ? row?.row?.original?.roi?.roi : '-'} />
                    </Box>
                );
            },
            header: 'RoI',
            size: 50,
            maxSize: 50,
            sortingFn: 'alphanumericCaseSensitive'
        })
    ]

    return (
        <Box className={'bottom-events-container'}>
            {props.isTableReady ?
                <>
                    <Box className="bottom-events-title-container">
                        <Typography className="bottom-events-title">{'Events with Least RoI'}</Typography>
                    </Box>
                    <Box className="least-roi-container">
                        <TanstackTable 
                            data={props?.data}
                            initialColumns={columns}
                            rowCount={props?.data?.length}
                            hidePagination
                            showSpinner={false}
                            height='195px'
                            fullWidth={props?.data?.length === 0}
                            showNoRowsOverlay={props?.data?.length === 0}
                        />
                    </Box>
                </>
                // : <TableEmptyComponent height={`${135}px`} heading={'Events with Least RoI'} />
                :
                <Box className="bottom-events-container">
                    <Box className="bottom-events-title-container">
                        <Typography className="bottom-events-title">{'Events with Least RoI'}</Typography>
                    </Box>
                    <CustomSpinner height={'169px'} />
                </Box>
            }
        </Box>
    );
};

export default LeastRoiEventsTable;