import { useEffect, useMemo, useState } from "react";
import FormTabs from "../../../../common/FormTabs";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAllTargetList, removeTargetsFromTargetList } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import toast from "react-hot-toast";
import _ from "lodash";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import { registrantInitialColumnData, useRegistrantColumnHelper } from "../../../Events/EventDetails/People/EventRegistrants/registrantColumn.helper";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";

const TargetListPage: React.FC = (): React.JSX.Element =>
{
    const [targetRows, setTargetRows] = useState<any[]>([]);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [selectedTargets, setSelectedTargets] = useState<string[]>([]);
    const [removeTargetListSpinner, setRemoveTargetListSpinner] = useState<boolean>(false);
    const [selectedTab, setSelectedTab] = useState<'all' | 'myList'>('all');
    const [refresh, setRefresh] = useState<boolean>(false);

    const columns =
        useRegistrantColumnHelper(
        registrantInitialColumnData.filter(
            item => !['email', 'status', 'paymentStatus', 'attendeeStatus', 'ticket', 'shareLink', 'actions'].includes(item.field)
        )
        );

    const removeFromTargetList = async (): Promise<void> =>
    {
        setRemoveTargetListSpinner(true);
        try 
        {
            const initialTargets = targetRows;
            const initialTargetIds = initialTargets?.map((target: any) => target.id);
            
            const updatedTargetListIds = initialTargetIds.filter(id => !selectedTargets.includes(id));

            const targetListUpdated = await removeTargetsFromTargetList( { registrantIds: selectedTargets?.length > 0 ? selectedTargets : null } );
            if(targetListUpdated)
            {
                toast.success('Removed from Target list');
                setSelectedTargets([]);
                setRefresh(true);
            }
        } 
        catch (error) 
        {
            toast.error('Failed to remove from Target list');
        }
        finally
        {
            setRemoveTargetListSpinner(false);
        }
    };

    const TargetListToolbar = () => 
    {
        const tabs = [
            {
                tabName: 'All',
                selectedTab: selectedTab === 'all',
                count: targetRows?.length,
                navigation: () => 
                { 
                    setSelectedTab('all');
                    // fetchSelectedTabData();
                }
            },
            // {
            //     tabName: 'My List',
            //     selectedTab: selectedTab === 'myList',
            //     count: myListCount,
            //     navigation: () => 
            //     { 
            //         setSelectedTab('myList');
            //         fetchSelectedTabData(true);
            //     }
            // }
        ];
    
        return (
            <div className="target-list-toolbar">
                <FormTabs tabs={tabs} />
                {selectedTargets?.length > 0 && 
                    <div className="button-container">
                        <CustomButton name='Cancel' btnType="secondary" onClick={() => setSelectedTargets([]) } />
                        <CustomButton name='Remove from Target List' btnType="danger" loading={removeTargetListSpinner} onClick={() => removeFromTargetList() } startIcon={<FontAwesomeIcon icon={['fal', 'trash-can']} />} />
                    </div>
                }
            </div>
        )
    };

    const fetchSelectedTabData = async (myList?: boolean): Promise<void> =>
    {
        try 
        {
            const targets = await getAllTargetList();

            if(targets)
            {
                setIsEmpty(targets.length === 0);
                setTargetRows(targets);
            }
        } 
        catch (error) 
        {
            
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const fetchData = async (): Promise<void> =>
    {
        try 
        {
            const allTargets = await getAllTargetList();

            // const myList = await getAllTargetList(true);

            if(allTargets)
            {
                setIsEmpty(allTargets.length === 0);
                setTargetRows(allTargets);
            }
            
            // if(myList)
            // {
            //     setMyListCount(myList.length);
            //     setIsEmpty(myList.length === 0);
            // }
        } 
        catch (error) 
        {
            
        }
        finally
        {
            setShowSpinner(false);
        }
    }; 

    useEffect(() => {
        fetchData();
    }, []);

    useMemo(() => {
        if(refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh]);

    return (
        <div id="targetListPage">
            <div style={{ height: 'calc(100vh - 120px)', maxHeight: 'calc(100vh - 120px)' }}>
                {!isEmpty ? (
                    <div style={{ height: 'calc(100% - 56px)' }}>
                        <TargetListToolbar />
                        <TanstackTable
                            data={targetRows}
                            initialColumns={columns}
                            checkboxSelection
                            selectedRows={selectedTargets}
                            setSelectedRows={setSelectedTargets}
                            rowCount={targetRows.length}
                            leftPinnedColumns={['companyLogo', 'firstName']}
                            showSpinner={showSpinner}
                            hidePagination
                        />
                    </div>
                ) : (
                        <TableEmptyComponent 
                            infoText={'No Targets available'}
                        />
                    )
                }
            </div>
        </div>
    );
};

export default TargetListPage;