import { Col, Row } from "react-bootstrap";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";

import './styles.scss';
import { NumberFormatter } from "../../../../../../scripts/helpers";
import CardComponent from "../../../../../../components/Events/Registrations/CardComponent";
import MuiChip from "../../../../../../common/FormComponents/MuiChip";
import { convertObjectToArrays, generateOpenOpportunitiesGraphOption } from "./barChartGenerator.helper";
import { GraphComponent } from "../Common/GraphComponent";
import { IRevenueGraphReady, IRevenueOverview } from "./revenueAnalytics.interface";

interface ClosedWonProps {
    closedWonOverviewData: IRevenueOverview;
    graphReady: IRevenueGraphReady;
}

const ClosedWon: React.FC<ClosedWonProps> = (props): React.JSX.Element =>
{

    const { closedWonOverviewData, graphReady } = props;

    const closedWonCardData =
    [
        {
            name: 'Closed Won',
            value: closedWonOverviewData?.closedWonOverview?.closedWon && Number(closedWonOverviewData?.closedWonOverview?.closedWon) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedWon)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.closedWonCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedWonCount) || '-',
            chipColor: 'green'
        },
        {
            name: 'New Revenue',
            value: closedWonOverviewData?.closedWonOverview?.newRevenue && Number(closedWonOverviewData?.closedWonOverview?.newRevenue) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.newRevenue)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.newRevenueCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.newRevenueCount) || '-',
            chipColor: 'grey'
        },
        {
            name: 'Influenced Revenue',
            value: closedWonOverviewData?.closedWonOverview?.influencedRevenue && Number(closedWonOverviewData?.closedWonOverview?.influencedRevenue) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.influencedRevenue)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.influencedRevenueCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.influencedRevenueCount) || '-',
            chipColor: 'grey'
        },
        {
            name: 'Closed Lost',
            value: closedWonOverviewData?.closedWonOverview?.closedLost && Number(closedWonOverviewData?.closedWonOverview?.closedLost) > 0 ? `$${NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedLost)}` : '-',
            chipLabel: closedWonOverviewData?.closedWonOverview?.closedLostCount && NumberFormatter(closedWonOverviewData?.closedWonOverview?.closedLostCount) || '-',
            chipColor: 'grey'
        }
    ];

    const closedWonIcpGraphData = convertObjectToArrays(closedWonOverviewData?.closedWonICP);
    const closedWonBpGraphData = convertObjectToArrays(closedWonOverviewData?.closedWonBuyerPersona);

    const closedWonIcpGraphOption = generateOpenOpportunitiesGraphOption(closedWonIcpGraphData as any);
    const closedWonBpGraphOption = generateOpenOpportunitiesGraphOption(closedWonBpGraphData as any);

    return (
        <div id="closedWon">
            <AnalyticsPageHeader 
                header='Closed Won'
                description='Understand your deal performance and win rates'
            />

            <div className="content-div">
                {/* Cards containing closed won, new revenue, influenced revenue and closed lost */}
                <Row className="custom-row">
                    {
                        closedWonCardData?.map((info, index) => {
                            return (
                                <Col className="px-0" key={index}>
                                    <CardComponent 
                                        header={info.name}
                                        footerLeft={info.value}
                                        footerStringBoldLeft
                                        footerRight={info.chipLabel && <MuiChip label={info.chipLabel} chipColor={info.chipColor as 'grey' | 'green'} />}
                                        cardMinHeight="82px"
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>

                {/* Closed Won icp and bp graphs */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <GraphComponent 
                            title="Closed Won ICP"
                            chartOption={closedWonIcpGraphOption}
                            height="240px"
                            graphReady={graphReady?.closedWonICP}
                            isDataAvailable={closedWonIcpGraphData && closedWonIcpGraphData[1]?.length > 0 && closedWonIcpGraphData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>

                    <Col className="px-0">
                        <GraphComponent 
                            title="Closed Won Buyer Persona"
                            chartOption={closedWonBpGraphOption}
                            height="240px"
                            graphReady={graphReady?.closedWonBuyerPersona}
                            isDataAvailable={closedWonBpGraphData && closedWonBpGraphData[1]?.length > 0 && closedWonBpGraphData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default ClosedWon;