import React, { useContext, useEffect, useState } from "react";
import { EventAccessControlContext } from "../../../../contexts/EventAccessControl/EventAccessControlContext";
import { Box, Stack, Typography } from "@mui/material";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddEventAccessControl from "../../../../components/Events/AccessControl/AddEventAccessControl";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import { EventAccessControl } from "../../interfaces/event-access-control_interface";
import _ from "lodash";
import { ActionsDropdown } from "../../EventBudget/EventBudgetDataGrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './styles.scss';
import { refreshAccessControlPin } from "../../../../scripts/apis/eventAccessControl";
import { EventAccessControlStatus } from "../../enum/event-access-control.enum";
import AddEventBadgeSettings from "../../../../components/Events/AccessControl/AddEventBadgeSettings";
import AddCardSettings from "../../../../components/Events/CheckinApp/AddCardSettings";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../../../components/Events/interfaces/create-events_interface";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import { createColumnHelper } from "@tanstack/react-table";
import TanstackToolbar from "../../../../common/TanstackTable/TanstackToolbar";

const EventAccessControlPage: React.FC = (): React.JSX.Element => { 

    const { 
        isEmpty, 
        rows, 
        eventAccessControlData,
        setRows, 
        setRefresh, 
        showSpinner, 
        eventId, 
        pageSize, 
        currentPage, 
        updatePageSize, 
        updateCurrentPage, 
        eventAccessControlCount, 
        eventStages, 
        deleteDeviceFromAccessControl, 
        event 
    } = useContext(EventAccessControlContext);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const columnHelper = createColumnHelper<EventAccessControl>();
    const columns = [
        columnHelper.accessor('deviceName', {
            cell: (row) => {
                return accessControlName(row.row.original);
            },
            header: 'Device Name',
            size: 300,
            id: 'deviceName',
        }),
        columnHelper.accessor('stageName' as any, {
            cell: (row) => {
                return stageName(row.row.original);
            },
            header: 'Stage Name',
            size: 250,
            id: 'stageName',
        }),
        columnHelper.accessor('viewPin' as any, {
            cell: (row) => {
                return viewPin(row.row.original);
            },
            header: '',
            size: 250,
            id: 'viewPin',
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return actions(row.row.original);
            },
            header: '',
            size: 40,
            id: 'actions',
        })
    ];

    const [allColumns, setAllColumns] = useState([...columns]);

    const accessControlName = (row: EventAccessControl): React.ReactElement => {
        return (
            <div>
                <Typography className='bolded-cellContent'>{row?.deviceName}</Typography>
            </div>
        );
    };

    const stageName = (row: EventAccessControl): React.ReactElement => {

        const defaultSpeakerIds = row?.stageIds && row.stageIds?.length > 0 ? row.stageIds?.map((item) => 
        {
            const ids = _.find(eventStages, ['id', String(item)]);
            if (ids) 
            {
                return ids?.title;
            }
        }) : [];

        return (
            <div>
                {defaultSpeakerIds ? <Typography className='cellContent'>{defaultSpeakerIds.join(', ')}</Typography> : <p>-</p>}
            </div>
        );
    };

    const viewPin = (row: EventAccessControl): React.ReactElement => {

        const viewPinHeaderClass: React.CSSProperties = {
            padding: '12px',
            borderRadius: 'var(--radius-lg, 10px)',
            border: '1px solid var(--Component-colors-Components-Icons-Featured-icons-Modern-featured-icon-modern-border, #EAECF0)',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            height: '48px',
            width: '48px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        };

        return (
            <div>
                {row?.status === EventAccessControlStatus.ACTIVE && <CustomButton btnType="infoBlue" name="View PIN" type="button" onClick={(event) => 
                    {
                        setTimeout((): void => 
                        {
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                                heading: <Box sx={viewPinHeaderClass}><FontAwesomeIcon icon={['fal', 'lock']} /></Box>,
                                component: <AccessPinActionsComponent data={row} componentType={'viewPin'} />,
                                componentWidth: '512px',
                                componentHeight: '262px'
                            });
            
                            eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                                open: true
                            });
                        });
                    }} />}
            </div>
        );
    };

    const AccessPinActionsComponent: React.FC<{componentType: string, data?: EventAccessControl}> = ({componentType, data}): React.JSX.Element =>
    {
        const [accessPin, setAccessPin] = useState<string | number>(data?.accessControlOtp || '');

        const getTitleContent = (): string =>
        {
            if(componentType === 'viewPin') return `${data?.deviceName} Pin`;
            else if(componentType === 'disableDevice') return 'Disable Device';
            return `Delete ${data?.deviceName} ?`;
        };

        const getSubTitleContent = (): string =>
        {
            if(componentType === 'viewPin') return 'Enter this OTP in you device to get access control to stages';
            else if(componentType === 'disableDevice') return 'Do you want disable this device?';
            return 'Are you sure you want to delete this Device? This action cannot be undone.';
        };

        const refreshAccessPin = async (data: EventAccessControl): Promise<void> =>
        {
            try 
            {
                const refreshedPin = await refreshAccessControlPin(eventId, data?.id);
                if(refreshedPin)
                {
                    setAccessPin(refreshedPin.accessControlOtp);
                }
            } 
            catch (error) 
            {
                console.log('Error in refreshing pin', error);
            }
            
        };

        useEffect(() => {
            if(componentType === 'viewPin')
            {
                refreshAccessPin(data);
            }
        }, [componentType === 'viewPin']);

        return (
            <div id="accessPinAccessComponent">
                <Box className="header-container">
                    <Typography className="title">{getTitleContent()}</Typography>
                    <Typography className="sub-title">{getSubTitleContent()}</Typography>
                </Box>
                <Box paddingTop={componentType === 'viewPin' ? '24px' : '40px'} className="access-actions-container">
                    {
                        componentType === 'viewPin' ?
                        <Box className="verification-code-container">
                            <Typography className="title">{'Verification code'}</Typography>
                            <Box className="code-and-refresh">
                                {<Typography className="code">{String(accessPin)?.split('').join("  ")}</Typography>}
                                <CustomButton btnType="primary" name='' type="button" startIcon={<FontAwesomeIcon icon={['fal', 'rotate']} />} onClick={() => {refreshAccessPin(data)}} />
                            </Box>
                        </Box> :
                        <Box className="disable-delete-container">
                            <CustomButton btnType="secondary" name="Cancel" type="button" style={{ width: '170px', fontSize: '16px', fontWeight: 600 }} onClick={() => {eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, true)}} />
                            <CustomButton onClick={(event) => {
                                if(componentType === 'disableDevice') 
                                {
                                    // handleDisable(data);
                                }
                                else
                                {
                                    handleDelete(data);
                                }
                            }} btnType={componentType === 'disableDevice' ? 'primary' : 'danger'} style={{ width: '170px', fontSize: '16px', fontWeight: 600 }} name={componentType === 'disableDevice' ? 'Disable' : 'Delete'} type="button" />
                        </Box>
                    }
                </Box>
            </div>
        );
    };

    const actions = (row: EventAccessControl): React.ReactElement => {
        const editClick = (): void => 
        {
            eventBus.dispatch('selected-row-id', row.id);
            openEventAccessControlSidedrawer(row);
        };

        const deleteClick = (): void => 
        {
            const deleteHeaderClass: React.CSSProperties = { 
                padding: '12px',
                borderRadius: '50%',
                background: 'var(--Colors-Background-bg-error-secondary, #FEE4E2)',
                height: '48px',
                width: '48px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            };

            setTimeout((): void => 
            {
                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
                    heading: <Box sx={deleteHeaderClass}><FontAwesomeIcon color={'#D92D20'} icon={['fal', 'trash']} /></Box>,
                    component: <AccessPinActionsComponent data={row} componentType={'delete'} />,
                    componentWidth: '400px',
                    componentHeight: '260px'
                });

                eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
                    open: true
                });
            });
        };

        // const disableClick = (): void =>
        // {
        //     const disableHeaderClass: React.CSSProperties = { 
        //         padding: '12px',
        //         borderRadius: '50%',
        //         background: 'var(--Colors-Background-bg-warning-secondary, #FFF8E5)',
        //         height: '48px',
        //         width: '48px',
        //         display: 'flex',
        //         justifyContent: 'center',
        //         alignItems: 'center',
        //     };

        //     setTimeout((): void => 
        //     {
        //         eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.UPDATE_EVENT, {
        //             heading: <Box sx={disableHeaderClass}><FontAwesomeIcon color={'#F5A623'} icon={['fal', 'triangle-exclamation']} /></Box>,
        //             component: <AccessPinActionsComponent componentType={'disableDevice'} data={row} />,
        //             componentWidth: '400px',
        //             componentHeight: '240px'
        //         });

        //         eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.OPEN_EVENT, {
        //             open: true
        //         });
        //     });
        // };

        // extraActions={[{ name: row?.status === EventAccessControlStatus.ACTIVE ? 'Disable' : 'Enable', onClick: disableClick }]}
        return <ActionsDropdown onEditClick={editClick} onDeleteClick={deleteClick} />;
    };

    // const handleDisable = (row: EventAccessControl) => 
    // {        
    //     if(row?.status === EventAccessControlStatus.ACTIVE)
    //     {
    //         disableAndEnableAccessForDevice(row.id, EventAccessControlStatus.DISABLED);
    //     }
    //     else if(row?.status === EventAccessControlStatus.DISABLED)
    //     {
    //         disableAndEnableAccessForDevice(row.id, EventAccessControlStatus.ACTIVE);
    //     }
    // };

    const handleDelete = (row: EventAccessControl) => 
    {        
        deleteDeviceFromAccessControl(row.id);
    };

    const openEventAccessControlSidedrawer = (accessControlData?: EventAccessControl) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: accessControlData ? 'Update User Access' : 'Create User Access',
            hideCloseButton: true,
            component: <AddEventAccessControl eventId={eventId} setRefresh={setRefresh} accessControlData={accessControlData} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const openEventBadgeSettingsSidedrawer = (accessControlData?: EventAccessControl) =>
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Badge Settings',
            hideCloseButton: true,
            component: <AddEventBadgeSettings eventId={eventId} setRefresh={setRefresh} eventData={event} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const openEventCardSettings = () => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'VCF Card Settings',
            hideCloseButton: true,
            component: <AddCardSettings eventId={eventId} setRefresh={setRefresh} formId={eventReduxData?.registrationFormId as string} existingVCardSettings={eventReduxData?.vcardSettings} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '50vw !important'
        });
    };

    const customFilterFunctions = {
        stageName: (obj: EventAccessControl, inputValue: string | number): boolean => {
            const stageNames = obj?.stageIds && obj?.stageIds?.length > 0 ? obj.stageIds.map((id) => {
                const stage = eventStages.find(stage => stage.id === String(id));
                return stage ? stage.title : '';
            }).filter(Boolean).join(', ') : '';
            return stageNames.toLowerCase().includes(inputValue.toString().toLowerCase());
        },
    };

    useEffect(() => {
        setAllColumns([...columns]);
    }, [eventStages]);

    useEffect(() => 
    {
        eventBus.on('access-control-row-click', (row: { rowData: EventAccessControl }): void => 
        {
            openEventAccessControlSidedrawer(row.rowData);
        });
    }, []);

    return(
        <div id="eventAccessControlPage">
            <Box id='inside-event-module-name-section' sx={isEmpty ? null : {
                borderBottom: 'none !important'
            }}>{'Check In App'}
                <Stack className="marketing-action-button-container" direction={'row'} spacing={1}>
                    <CustomButton 
                        btnType="secondary"
                        name="VCF Card Settings"
                        onClick={() => openEventCardSettings()}
                    />
                    <CustomButton btnType='secondary' name='Badge Settings' type='button' onClick={(): void =>
                    {
                        openEventBadgeSettingsSidedrawer();
                    }} />
                    <CustomButton btnType='secondary' name='Add Users' type='button' onClick={(): void =>
                    {
                        openEventAccessControlSidedrawer();
                    }} />
                </Stack>
            </Box>

            <Box sx={{
                flex: 1,
                height: 'calc(100% - 60px)', 
            }}>
                {isEmpty ? (<TableEmptyComponent emptyImg={''} infoText={'No Users Yet'} subInfoText={''} />) :
                    (
                        <div style={{ height: 'calc(100% - 60px)' }}>
                            <TanstackToolbar 
                                columns={columns}
                                setColumns={setAllColumns}
                                rows={eventAccessControlData}
                                setRows={setRows}
                                tabs={[{
                                    tabName: 'All',
                                    count: eventAccessControlCount,
                                    navigation: () => { },
                                    selectedTab: true
                                }]}
                                customFilterFunctions={customFilterFunctions}
                            />
                            <TanstackTable 
                                data={rows}
                                rowCount={eventAccessControlCount}
                                showSpinner={showSpinner}
                                pageSize={pageSize}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                currentPage={currentPage - 1}
                                initialColumns={allColumns}
                                height={'calc(100% - 48px)'}
                            />
                        </div>
                    )}
            </Box>

        </div>
    )
};

export default EventAccessControlPage;