export const CONTENT = {
    PATHS: {
        ROOT: {
            PATH: '/',
            TO: '/dashboard',
        },
        LOGIN: {
            PATH: '/login',
            TO: '/events',
        },
        SIGNUP: {
            PATH: '/signup',
            TO: '/events',
        },
        EVENTS: {
            PATH: '/events',
            TO: '/all-events',
            ALL_EVENTS: {
                PATH: 'all-events',
                TAB: 'all-events',
            },
            FAVOURITE_EVENTS: {
                PATH: 'favourite-events',
                TAB: 'favourite-events'
            },
            DATA_TAB: {
                TAB1: {
                    PATH: 'events-tab1',
                    TAB: 'events-tab1',
                    NAVIGATE: '/events-tab1',
                },
                TAB2: {
                    PATH: 'events-tab2',
                    TAB: 'events-tab2',
                    NAVIGATE: '/events-tab2',
                },
            },
        },
        AUDIENCE: {
            PATH: '/audience',
            TO: '/all-audience',
            ALL_AUDIENCE: {
                PATH: 'all-audience',
                TAB: 'all-audience',
            },
            FAVOURITE_AUDIENCE: {
                PATH: 'favourite-audience',
                TAB: 'favourite-audience',
            },
            DATA_TAB: {
                TAB1: {
                    PATH: 'audience-tab1',
                    TAB: 'audience-tab1',
                    NAVIGATE: '/audience-tab1'
                },
                TAB2: {
                    PATH: 'audience-tab2',
                    TAB: 'audience-tab2',
                },
            },
        },
        SPEAKERS: {
            PATH: '/speakers',
            TO: '/all-speakers',
            ALL_SPEAKERS: {
                PATH: 'all-speakers',
                TAB: 'all-speakers',
            },
            FAVOURITE_SPEAKERS: {
                PATH: 'favourite-speakers',
                TAB: 'favourite-speakers',
            },
            DATA_TAB: {
                TAB1: {
                    PATH: 'speakers-tab1',
                    TAB: 'speakers-tab1',
                    NAVIGATE: '/speakers-tab1'
                },
                TAB2: {
                    PATH: 'speakers-tab2',
                    TAB: 'speakers-tab2',
                },
            },
        },
        SPONSORS: {
            PATH: '/sponsors',
            TO: '/all-sponsors',
            ALL_SPONSORS: {
                PATH: 'all-sponsors',
                TAB: 'all-sponsors',
            },
            FAVOURITE_SPONSORS: {
                PATH: 'favourite-sponsors',
                TAB: 'favourite-sponsors',
            },
            DATA_TAB: {
                TAB1: {
                    PATH: 'sponsors-tab1',
                    TAB: 'sponsors-tab1',
                    NAVIGATE: '/sponsors-tab1'
                },
                TAB2: {
                    PATH: 'sponsors-tab2',
                    TAB: 'sponsors-tab2',
                },
            },
        },
        REGISTRATION: {
            PATH: '/registration',
        },
        SETTINGS: {
            PATH: '/settings',
            GENERAL: {
                PATH: 'general',
                TAB: 'general',
            },
            USERS: {
                PATH: 'users',
                TAB: 'users',
            },
            TEAMS: {
                PATH: 'teams',
                TAB: 'teams',
            },
            ORGANIZATION: {
                PATH: 'organization',
                TAB: 'organization',
            },
            BILLING: {
                PATH: 'billing',
                TAB: 'billing',
            },
            INTEGRATIONS: {
                PATH: 'integrations',
                TAB: 'integrations'
            },
            INTEGRATIONS_SUB_TAB: {
                ALL: 'all-integrations',
                CRM: 'crm-integrations',
                MARKETING_TOOLS: 'marketing-tools-integrations',
                EVENT_REGISTRATION_TOOLS: 'event-registration-tools',
                CSM_TOOLS: 'csm-tools',
                ENRICHMENT: 'enrichment-tools',
                PAYMENTS: 'payments',
                OTHERS: 'others',
            },
            ICP: {
                PATH: 'icp',
                TAB: 'icp'
            },
            BUYER_PERSONA: {
                PATH: 'buyer-persona',
                TAB: 'buyer-persona'
            },
            CUSTOM_FIELD: {
                PATH: 'custom-field',
                TAB: 'custom-field'
            }

        },

    },
    AUTHENTICATION: {
        DISPLAY_TEXT: 'Welcome to EventHQ, Event Life cycle Management (ELM) is the ability to identify, track, and take appropriate action on an event at the right time and in the right sequence.',
        LOGIN: {
            HEADING: 'Login for EventHQ',
            ACKNOWLEDGEMENT_TEXT_1: 'By continuing, you acknowledge that you have read and understood, and agree to EventHQ’s ',
            TERMS_CONDITIONS: 'Terms & Conditions',
            ACKNOWLEDGEMENT_TEXT_2: ' and ',
            PRIVACY_POLICY: 'Privacy Policy.',
            DONT_HAVE_ACCOUNT: 'Don’t have an account ?'
        },
        REGISTER: {
            HEADING: 'Register for EventHQ',
            BUTTON: 'Register'
        },
        ONBOARD: {
            HEADING: 'Onboard for EventHQ',
            BUTTON: 'Onboard'
        },
        RESET_PASSWORD: {
            HEADING: 'Reset password',
            BUTTON: 'Update'
        }
    },
    SIDE_NAV_BAR: {
        LINKS: {
            HEADING: 'Links',
            LINK: '/home',
        },
        FAVOURITES: {
            HEADING: 'Favourites',
            // LINK: '/favourites',
        },
        USERS_TEAMS: {
            HEADING: 'Users & Teams',
            TAB1: {
                NAME: 'Users',
                LINK: '/users-teams/users',
            },
            TAB2: {
                NAME: 'Teams',
                LINK: '/users-teams/teams',
            },
        },
        SETTINGS: {
            HEADING: 'Account',
            TAB1: {
                NAME: 'General',
                // LINK: '/settings/personal',
                // LINK: '/settings',
                LINK: '/general',
            },
            TAB2: {
                NAME: 'Organization',
                // LINK: '/settings/organization'
                LINK: '/settings',
            },
            TAB3: {
                NAME: 'Billing',
                LINK: '/billing',
            },
            TABS: ['/settings/general', '/settings/users', '/settings/teams', '/settings/organization', '/settings/billing']
        },
        NOTIFICATIONS: {
            HEADING: 'Notifications',
        },
        MAIN_SETTINGS: {
            HEADING: 'Settings'
        },
        SUPPORT: {
            HEADING: 'Support',
        },
        LOGOUT_CONTENT: {
            TITLE: 'Logout',
            CONFIRMATION_MESSAGE: 'Are you sure you want to logout?',
            ACCEPT: 'Yes',
            CANCEL: 'No'
        },
        DASHBOARD: {
            TITLE: 'Dashboard',
            PAGES: {
                DASHBOARD_PAGE: '/dashboard',
            },
        },
        EVENTS: {
            TITLE: 'Events',
            PAGES: {
                EVENTS: '/events',
                ALL_EVENTS: '/events',
                FAVOURITE_EVENTS: '/events/favourites',
                TAB1: '/events-tab1',
                TAB2: '/events-tab2',
            },
        },
        AUDIENCE: {
            TITLE: 'Audience',
            PAGES: {
                AUDIENCE: '/audiences',
                ALL_AUDIENCE: '/all-audience',
                FAVOURITE_AUDIENCE: '/favourite-audience',
                TAB1: '/audience-tab1',
                TAB2: '/audience-tab2',
            },
        },
        SPEAKERS: {
            TITLE: 'Speakers',
            PAGES: {
                SPEAKERS: '/speakers',
                ALL_SPEAKERS: '/all-speakers',
                FAVOURITE_SPEAKERS: '/favourite-speakers',
                TAB1: '/speakers-tab1',
                TAB2: '/speakers-tab2',
            },
        },
        SPONSORS: {
            TITLE: 'Sponsors',
            PAGES: {
                SPONSORS: '/sponsors',
                ALL_SPONSORS: '/all-sponsors',
                FAVOURITE_SPONSORS: '/favourite-sponsors',
                TAB1: '/sponsors-tab1',
                TAB2: '/sponsors-tab2',
            },
        },
        VENDORS: {
            TITLE: 'Vendors',
            PAGES: {
                SPONSORS: '/vendors',
                FAVOURITE_SPONSORS: '/vendors/favourites',

            },
        },
        REGISTRATION: {
            TITLE: 'Registration',
            PAGES: {
                REGISTRATION: '/registrations',
            }
        },
        DYNAMIC_INPUT: {
            TITLE: 'Form Inputs',
            PAGES: {
                FORMS: '/registrations',
            }
        },
        BUDGET: {
            TITLE: 'Budget',
            PAGES: {
                BUDGET_PAGE: '/budget',
            }
        },
        ROI_CALCULATOR: {
            TITLE: 'RoI Dashboard',
            PAGES: {
                ROI_PAGE: '/roi'
            },
        },
        ANALYTICS_PAGE: {
            TITLE: 'Analytics',
            PAGES: {
                ANALYTICS: '/analytics',
                AUDIENCE_ANALYTICS: '/analytics/audience',
                ROI: '/analytics/roi',
                BUDGETS: '/analytics/budget',
                EVENTS: '/analytics/events'
            }
        }
    },
    SIDE_DRAWER: {
        FORM_REQUIRED_TEXT: 'Required Field',
        CLOSE_BTN: 'Cancel',
    },
    TABLE_DATA_GRID: {
        NO_DATA_TEXT: 'No data to display',
    },
    TOP_BUTTON_LOADER: {
        SPINNER_TEXT: 'Loading...'
    },
    TOP_NAV_BAR: {
        SEARCH_PLACEHOLDER: 'SEARCH',
        SEARCH_TEXT: 'cmd+k',
    },
    BREADCRUMB_BAR: {
        BACK_BTN: 'Go back Home',
    },
    CSV_IMPORT: {
        UPLOAD_BTN: 'Upload CSV of your Audience List',
        MODAL_UPLOAD_BTN: 'Upload CSV',
        SUBMIT_BTN: 'Import',
    },
    SUBTABS: {
        1: 'All',
        2: 'Favourites',
    },
    AUTHENTICATION_ERROR_MESSAGES: {
        INCORRECT_AUTHENTICATION_MESSAGE: 'Unable to login.',
        INVITATION_EXPIRED: 'Invitation is either expired or deleted',
        TRY_AGAIN: 'Please try again',
        ID_EXISTS: 'ID Exists',
        ID_IN_USE: 'The email ID used is already part of an organization',
        INVITATION_ACCEPTED: 'Invitation already accepted.',
        CONTINUE_TO_LOGIN:
            'If you wish to continue to login. <a class="click-here">Click here</a>.',
        ANOTHER_EMAIL: 'Please use another email ID to accept the invitation',
        DOMAIN_EXISTS: 'Looks like your organization already exists',
        REQ_SUPPORT: 'Contact your super admin or reach out to our support team',
        NEW_USER_DOMAIN_EXISTS: 'Looks like the user\'s organization already exists',
        NEW_USER_REQ_SUPPORT:
            'Contact the user\'s super admin or reach out to our support team',
    },
    LINK_PAGE: {
        HEADING: 'QuickLinks',
        SEARCH_PLACEHOLDER: 'All Shortcuts',
        BUTTONS: {
            CREATE: '+ Create Link',
        },
        CHIP: {
            1: 'Workspace',
            2: 'Private',
            3: 'Teams',
            4: 'Public',
        },
        ACTION_NAMES: {
            1: 'Workspace',
            2: 'Copy link',
            3: 'Favourites',
        },
        DROPDOWN_ITEM: {
            1: 'Pin',
            2: 'Edit',
            3: 'View',
            4: 'Go To Link',
            5: 'Delete',
        },
        SIDE_DRAWER_CONTENT: {
            CREATE: {
                HEADING: 'Create Link',
                BUTTON: 'Create Link',
            },
            EDIT: {
                HEADING: 'Edit Link',
                BUTTON: 'Edit Link',
            },
            VIEW: {
                HEADING: 'View Link',
            },
        },
        DELETE_POPUP_CONTENT: {
            TITLE: 'Delete Link',
            CONFIRMATION_MESSAGE: 'Are you sure you want to remove the link - q/',
            ACCEPT: 'Delete',
            CANCEL: 'Cancel',
        },
        FORMS: {
            LABEL: {
                PATH: 'Destination Url',
                LINK_NAME: 'Link Name',
                DESCRIPTION: 'Description',
                SHARING: 'Sharing',
                MULTISELECT_TITLE: 'Select',

            },
            PLACEHOLDER: {
                PATH: 'Paste your URL',
                LINK_NAME: 'Enter Name',
                TEAM: 'Select..',
            },
            NAME: {
                PATH: 'Path',
                LINK_NAME: 'shortUrl',
                DESCRIPTION: 'description',
            },
            ERRORS: {
                PATH: 'Link entered is invalid!',
                NAME: 'Link name invalid!',
                TEAM: 'Select a Team!',
            },
        },
    },
    FAVOURITES_PAGE: {
        HEADING: 'Favourites',
        SEARCH_PLACEHOLDER: 'All Shortcuts',
        BUTTONS: {
            CREATE: '+ Create Link',
        },
        CHIP: {
            1: 'Workspace',
            2: 'Private',
            3: 'Teams',
            4: 'Public',
        },
        ACTION_NAMES: {
            1: 'Workspace',
            2: 'Copy link',
            3: 'Favourites',
        },
        ACCESS_TYPES: {
            EDIT_ACCESS: 'edit-access',
            VIEW_ACCESS: 'view-access',
        },
        DROPDOWN_ITEM: {
            1: 'Pin',
            2: 'Edit',
            3: 'View',
            4: 'Go to Link',
            5: 'Delete',
        },
        SIDE_DRAWER_CONTENT: {
            CREATE: {
                HEADING: 'Create Link',
                EVENT: 'create-link',
                BUTTON: 'Create Link',
            },
            EDIT: {
                HEADING: 'Edit Link',
                EVENT: 'edit-link',
                BUTTON: 'Edit Link',
            },
            VIEW: {
                HEADING: 'View Link',
            },
        },
        DELETE_POPUP_CONTENT: {
            TITLE: 'Delete Link',
            CONFIRMATION_MESSAGE: 'Are you sure you want to remove the link - q/',
            ACCEPT: 'Delete',
            CANCEL: 'Cancel',
        },
    },
    SETTINGS_PAGE: {
        HEADING: 'Settings',
        TABS: {
            1: 'General',
            2: 'Users',
            3: 'Teams',
            4: 'Organization',
            5: 'Billing',
        },
        PATH: {
            GENERAL: 'general',
            USERS: 'users',
            TEAMS: 'teams',
            ORGANIZATION: 'organization',
            BILLING: 'billing',
        },
        GENERAL: {
            HEADING: 'General',
            FORMS: {
                LABEL: {
                    FIRST_NAME: 'First Name',
                    LAST_NAME: 'Last Name',
                    LOGGED_IN_EMAIL: 'Logged In Email',
                    ORG_NAME: 'Organisation Name',
                },
                PLACEHOLDER: {
                    FIRST_NAME: 'First Name',
                    LAST_NAME: 'Last Name',
                    LOGGED_IN_EMAIL: 'Logged In Email',
                },
            },
            BUTTONS: {
                SAVE: 'Update',
                UPLOAD_IMAGE: 'Select profile photo',
            },
            EVENTBUS: {
            },
        },
        ORGANIZATION: {
            HEADING: 'Organization',
            FORMS: {
                NAME: {
                    ORG_LINK: 'organizationLink',
                },
                LABEL: {
                    ORG_NAME: 'Organization Name',
                    ORG_LINK: 'Organization Link',
                    DELETE_ACCOUNT: 'Delete Account',
                },
                PLACEHOLDER: {
                    ORG_NAME: 'Organization Name',
                    ORG_LINK: 'Organization Link',
                },
                FEEDBACK: 'Link name invalid!',
                DISCLAIMER_TEXT:
                    'This is a permenant action and cannot be undone. All data will be permenantly deleted',
                BUTTONS: {
                    SAVE: 'Save',
                    UPLOAD_IMAGE: 'Upload Image',
                    CHECK: 'CHECK',
                    DELETE_ORGANIZATION: 'Delete Org',
                    LEAVE_ORGANIZATION: 'Leave Org',
                },
            },
            POPUP: {
                DELETE: {
                    DELETE_CONTENT_TITLE: 'Delete Organization',
                    DELETE_CONTENT_TEXT: 'Deleting the organization will lead to removal of all the members part of the organization and they\'ll have to create a new organization to continue using q/links.',
                    DELETE_CONTENT_CONFIRMATION: 'Are you sure you want to delete the organization?',
                },
                LEAVE: {
                    LEAVE_CONTENT_TITLE: 'Leave Organization',
                    LEAVE_CONTENT_TEXT: 'Your email address is associated to an organization, if you leave the organization the application access will be lost.',
                    LEAVE_CONTENT_CONFIRMATION: 'Are you sure you want to leave the organization?',
                },
                BUTTONS: {
                    ACCEPT: 'Delete',
                    CANCEL: 'Cancel'
                }
            }
        },
        USERS: {
            HEADING: 'Members',
            BUTTONS: {
                CREATE: 'Add Members',
            },
            DROPDOWN_ITEM: {
                1: 'Edit',
                2: 'View',
                3: 'Remove',
                4: 'Convert to Admin',
                5: 'Convert to Member',
                6: 'Convert to Owner',
                7: 'Cancel Request',
            },
            SIDE_DRAWER_CONTENT: {
                CREATE: {
                    HEADING: 'Create User',
                    EVENT: 'create-user',
                    BUTTON: 'Create User',
                },
                EDIT: {
                    HEADING: 'Edit User',
                    EVENT: 'edit-user',
                    BUTTON: 'Update',
                },
                VIEW: {
                    HEADING: 'View Info',
                }
            },
            POPUP: {
                TITLE: 'Delete User',
                CONFIRMATION_MESSAGE: 'Are you sure you want to remove the user - ',
                ACCEPT: 'Delete',
                CANCEL: 'Cancel'
            },
            USER_STATUS: {
                1: 'Pending',
                2: 'Today',
                3: 'Yesterday',
            },
            USER_ROLE: {
                1: 'Member',
                2: 'Admin',
                3: 'Owner',
            },
            MANUAL: {
                FORMS: {
                    LABEL: {
                        FIRST_NAME: 'First Name',
                        LAST_NAME: 'Last Name',
                        EMAIL: 'Email',
                        ROLE: 'Role',
                    },
                    PLACEHOLDER: {
                        FIRST_NAME: 'eg., John',
                        LAST_NAME: 'eg., Doe',
                        EMAIL: 'Invite by email',
                        ROLE: 'Roles',
                    },
                    ROLES: {
                        1: 'Admin',
                        2: 'Member',
                    }
                },
                BUTTONS: {
                    ADD_MORE: 'Add More',
                    SAVE: 'Invite',
                    CREATE: 'Create',
                }
            },
            USER_INFO: {
                FORMS: {
                    LABEL: {
                        FIRST_NAME: 'First Name',
                        LAST_NAME: 'Last Name',
                        EMAIL_ADDRESS: 'Email Address',
                        ROLE: 'Role',
                    }
                },
                CARD: {
                    CARD_TITLE: 'Owner Privilege',
                    CARD_CONTENT: 'Setting the user as owner gives privilege to change the role of other users.',
                    CARD_BUTTON: 'Proceed',
                }
            },
            UPLOAD: {
                HEADING: 'Upload CSV',
                UPLOAD_CONTENT: 'Drop your file here or Click to browse',
                LINK_TEXT: 'Download csv Sample',
                UPLOAD_ERROR: 'Please upload csv document!',
                POPOVER: {
                    TITLE: 'Role',
                },
                BUTTONS: {
                    CREATE: 'Create User'
                }
            }
        },
        ICP: {
            HEADING: 'Ideal Customer Profile (ICP)',
            BUTTON: 'Create ICP',
            FILTER_PLACEHOLDER: 'ICP',
            SIDE_DRAWER: {
                HEADING: {
                    CREATE: 'Create ICP',
                    EDIT: 'Edit ICP',
                }
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            DELETE_POPUP: {
                TITLE: 'Delete ICP',
                CONFIRMATION_TEXT: 'Are you sure you want to delete the icp - ',
                ACCEPT_BTN: 'Delete',
                CANCEL_BTN: 'Cancel',
            },
            TAB: {
                TITLE: {
                    ALL: 'All',
                    FAVOURITES: 'Favourites',
                },
                PATHS: {
                    ALL: 'all-icps',
                    FAVOURITES: 'favourite-icps',
                },
                PROPS_DATA: {
                    ALL: 'all',
                    FAVOURITES: 'favourite',
                },
            },
            DATA_TAB: {
                TITLE: {
                    TAB1: 'Icp data',
                    TAB2: 'Icp info',
                },
                PROPS_DATA: {
                    TAB1: 'tab1',
                    TAB2: 'tab2',
                },
                PATHS: {
                    TAB1: 'icp-tab1',
                    TAB2: 'icp-tab2',
                },
            },
            DATA_TAB_INFO: {
                TAB1: {
                    TITLE: 'Icp Data Tab Page 1',
                },
                TAB2: {
                    TITLE: 'Icp Data Tab Page 2'
                }
            },
            FORMS: {
                LABEL: {
                    NAME: 'ICP Name',
                    DESCRIPTION: 'ICP Description',
                    INDUSTRY: 'Industry',
                    EMPLOYEE: 'Employee',
                    ARR: 'ARR',
                    GEOGRAPHY: 'Geography (Company HQ)'
                },
                BUTTON: {
                    CREATE: 'Create ICP',
                    UPDATE: 'Update ICP',
                },
                PLACEHOLDER: 'ICP 1',
            }
        },
        BUYER_PERSONA: {
            HEADING: 'Buyer Persona (BP)',
            BUTTON: 'Create Buyer Persona',
            FILTER_PLACEHOLDER: 'Buyer Persona',
            SIDE_DRAWER: {
                HEADING: {
                    CREATE: 'Create Buyer Persona',
                    EDIT: 'Edit Buyer Persona',
                }
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            DELETE_POPUP: {
                TITLE: 'Delete Buyer Persona',
                CONFIRMATION_TEXT: 'Are you sure you want to delete the buyer persona - ',
                ACCEPT_BTN: 'Delete',
                CANCEL_BTN: 'Cancel',
            },
            TAB: {
                TITLE: {
                    ALL: 'All',
                    FAVOURITES: 'Favourites',
                },
                PATHS: {
                    ALL: 'all-buyer-personas',
                    FAVOURITES: 'favourite-buyer-personas',
                },
                PROPS_DATA: {
                    ALL: 'all',
                    FAVOURITES: 'favourite',
                },
            },
            DATA_TAB: {
                TITLE: {
                    TAB1: 'Buyer Persona data',
                    TAB2: 'Buyer Persona info',
                },
                PROPS_DATA: {
                    TAB1: 'tab1',
                    TAB2: 'tab2',
                },
                PATHS: {
                    TAB1: 'icp-tab1',
                    TAB2: 'icp-tab2',
                },
            },
            DATA_TAB_INFO: {
                TAB1: {
                    TITLE: 'Buyer Persona Data Tab Page 1',
                },
                TAB2: {
                    TITLE: 'Buyer Persona Data Tab Page 2'
                }
            },
            FORMS: {
                LABEL: {
                    NAME: 'Buyer Persona Name',
                    DESCRIPTION: 'Buyer Persona Description',
                    ROLE: 'Role',
                    SUB_ROLE: 'Sub Role',
                    SENIORITY: 'Seniority',
                    TECH_CATEGORIES: 'Tech Categories',
                    TECH: 'Tech'
                },
                BUTTON: {
                    CREATE: 'Create Buyer Persona',
                    UPDATE: 'Update Buyer Persona',
                },
                PLACEHOLDER: 'Buyer Persona 1',
            }
        },
        CUSTOM_FIELD: {
            HEADING: 'Custom Fields',
            BUTTON: 'Create Custom Field',
            FILTER_PLACEHOLDER: 'Custom Field',
            SIDE_DRAWER: {
                HEADING: {
                    CREATE: 'Create Custom Field',
                    EDIT: 'Edit Custom Field',
                }
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            DELETE_POPUP: {
                TITLE: 'Delete Custom Field',
                CONFIRMATION_TEXT: 'Are you sure you want to delete Custom Field - ',
                ACCEPT_BTN: 'Delete',
                CANCEL_BTN: 'Cancel',
            },
            CUSTOM_FIELD_LOCATIONS: {
                1: 'Event',
                2: 'Sponsor',
                3: 'Speaker',
                4: 'Vendor',
                5: 'Registrations'
            },
            CUSTOM_FIELD_TYPE: {
                1: 'Single Line',
                2: 'Multi-line text',
                3: 'Checkboxes',
                4: 'Dropdown',
                5: 'Date',
                6: 'Number',
                // 7: 'Header Text',
                // 8: 'Paragraph',
                9: 'Radio',
                // 10: 'Image',
                // 11: 'Gdpr Communication Processing',
                // 12: 'Gdpr Legitimate Interest',
                // 13: 'Gdpr form submit as Consent'
            },

        },
        TEAMS: {
            HEADING: 'Teams',
            BUTTONS: {
                CREATE: '+ Create Team',
                ADD_MEMBERS: 'Add Members',
            },
            DROPDOWN_ITEM: {
                1: 'Edit',
                2: 'View',
                3: 'Delete',
            },
            SIDE_DRAWER_CONTENT: {
                CREATE: {
                    HEADING: 'Create Team',
                    BUTTON: 'Create Team',
                },
                EDIT: {
                    HEADING: 'Edit Team',
                    BUTTON: 'Edit Team',
                },
                VIEW: {
                    HEADING: 'View Team',
                },
                FORM: {
                    LABEL: {
                        NAME: 'Team Name',
                        DESCRIPTION: 'Description',
                    },
                    NAME: {
                        NAME: 'name',
                        DESCRIPTION: 'description',
                    },
                    BUTTON: {
                        HEADING: 'Members',
                        NAME: 'Manage Members'
                    }
                },
                MEMBERS: {
                    RESULT: 'No results found'
                }
            },
            SIDE_DRAWER_SWITCH_CONTENTS: {
                MEMBERS: {
                    HEADING: 'Members',
                    BUTTON: 'Manage Members',
                },
                TEAM: {
                    HEADING: 'Create Team',
                    BUTTON: 'Create Team',
                },
            },
            VIEW_TEAM_INFO: {
                FORMS: {
                    LABEL: {
                        NAME: 'Team Name',
                        DESCRIPTION: 'Description',
                        MEMBERS: 'Members'
                    }
                }
            },
            POPUP: {
                TITLE: 'Delete Team',
                CONFIRMATION_MESSAGE: 'Are you sure you want to remove the team - ',
                ACCEPT: 'Delete',
                CANCEL: 'Cancel'
            }
        },
        BILLING: {
            HEADING: 'Billing',
            FORMS: {
                LABEL: {
                    FIRST_NAME: 'First Name',
                    LAST_NAME: 'Last Name',
                    LOGGED_IN_EMAIL: 'Logged In Email',
                },
                PLACEHOLDER: {
                    FIRST_NAME: 'First Name',
                    LAST_NAME: 'Last Name',
                    LOGGED_IN_EMAIL: 'Logged In Email',
                },
            },
            BUTTONS: {
                MANAGE: 'Manage',
                UPLOAD_IMAGE: 'Upload Image'
            },
        },
    },
    DASHBOARD_PAGE: {
        EVENT_TYPES: {
            1: 'Host',
            3: 'Sponsored',
            4: 'Speaker',
            5: 'Attendee',
        },

        EVENT_LOCATION_TYPES: {
            1: 'Onsite',
            2: 'Virtual',
            3: 'Hybrid',
        }
    },
    EVENTS_PAGE: {
        HEADING: 'Events',
        BUTTON: 'Create Event',
        TYPE_FILTER_PLACEHOLDER: 'Event Type',
        STATUS_FILTER_PLACEHOLDER: 'Status',
        EVENT_LOCATION_FILTER_OPTIONS: {
            ON_SITE: {
                TYPE: 1,
                NAME: 'On-site'
            },
            WEBINAR: {
                TYPE: 2,
                NAME: 'Virtual',
            },
            HYBRID: {
                TYPE: 3,
                NAME: 'Hybrid',
            },
        },
        EVENT_INTEGRATION_TYPES: {
            1: 'None',
            2: 'Luma',
            3: 'Zoom'
        },
        EVENT_STATUS: {
            1: 'Draft',
            2: 'Published',
        },
        EVENTS_SIDE_BAR_NAV: {
            PAGES: {
                DETAILS: '/events/:eventId/registrations/details',
                TICKETS: '/events/:eventId/registrations/tickets',
                GROUPS: '/events/:eventId/registrations/tickets/groups',
                FORM: '/events/:eventId/registrations/form',
            }
        },
        EVENT_STATUS_FILTER_OPTIONS: {
            UPCOMING: {
                // STATUS: 1,
                NAME: 'Upcoming',
            },
            CANCELLED: {
                // STATUS: 2,
                NAME: 'Cancelled',
            },
            ONGOING: {
                // STATUS: 3,
                NAME: 'Ongoing',
            },
            PAUSED: {
                // STATUS: 4,
                NAME: 'Paused',
            },
            DRAFT: {
                // STATUS: 5,
                NAME: 'Draft',
            },
            ENDED: {
                // STATUS: 6,
                NAME: 'Ended',
            },
        },
        EVENT_LOCATION_CHIP_LABEL: {
            1: 'In Person',
            2: 'Virtual',
            3: 'Hybrid',
        },
        EVENT_TYPE_CHIP_LABEL: {
            1: 'Host',
            2: 'Co-Host',
            3: 'Sponsored',
            4: 'Speaker',
            5: 'Attendee'
        },
        EVENT_SIZE_CHIP_LABEL: {
            1: 'Basic',
            2: 'Advance'
        },
        EVENT_STATUS_LABEL: {
            // 1: 'Upcoming',
            // 2: 'Cancelled',
            // 3: 'Ongoing',
            // 4: 'Paused',
            // 5: 'Draft',
            // 6: 'Ended',
            1: 'Upcoming',
            2: 'Ended',
        },
        EVENT_DATA_GRID_ACTIONS: {
            EDIT: 'Edit',
            COPY: 'Copy',
            DELETE: 'Delete',
            VIEW: 'View',
            FAVOURITES: 'Favourites',
        },
        TOP_APP_BAR_BUTTONS: {
            IMPORT: 'Import',
            EXPORT: 'Export',
        },
        DELETE_POPUP: {
            TITLE: 'Delete Event',
            CONFIRMATION_TEXT: 'Are you sure you want to delete the event - ',
            ACCEPT_BTN: 'Delete',
            CANCEL_BTN: 'Cancel',
        },
        SIDE_DRAWER_CONTENT: {
            CREATE: {
                HEADING: 'Create Event',
                EVENT: 'create-event',
                BUTTON: 'Create Event',
            },
            CREATE_EVENT_CATEGORY: {
                LABEL: 'Event Category',
                PLACEHOLDER: 'Create Event Category',
                BUTTON: 'Create Event Category',
            },
            EDIT: {
                HEADING: 'Edit Event',
                EVENT: 'edit-event',
                BUTTON: 'Update',
            },
            VIEW: {
                HEADING: 'View Info',
            },
            FORMS: {
                LABEL: {
                    TITLE: 'Event Title',
                    SHORT_NAME: 'Event Public Link',
                    DESCRIPTION: 'Event Description',
                    SHARING: 'Sharing',
                    EVENT_TYPE: 'Event Type',
                    EVENT_LOCATION: 'Event Location',
                    DESCRIPTION_TEXT: 'Write Description',
                    DESCRIPTION_LENGTH: '/100',
                    ADD_DESCRIPTION_BTN: '+ Add Description',
                    EVENT_START_TIMING: 'Event Start Date & time',
                    EVENT_END_TIMING: 'Event End Date & time',
                    TIMEZONE: 'Timezone',
                    EVENT_CATEGORY: 'Event Category'
                },
                PLACEHOLDER: {
                    EVENT_NAME: 'Event Title',
                    EVENT_DESCRIPTION: 'Event Description',
                    TEAM: 'Select..',
                    TIMEZONE: 'Select a Timezone',
                    EVENT_CATEGORY_GROUP_EMPTY_SELECT: 'Type something to create an Event Category..',
                    EVENT_CATEGORY_GROUP_SELECT: 'Choose Event Category..',
                },
                NAME: {
                    EVENT_NAME: 'Event',
                    LINK_NAME: 'shortUrl',
                    DESCRIPTION: 'description',
                },
                ERRORS: {
                    // PATH: 'Link entered is invalid!',
                    NAME: 'Event name invalid!',
                    DESCRIPTION: 'Enter event description',
                    // TEAM: 'Select a Team!',
                },
                SUBMIT_BUTTON: {
                    CREATE: 'Create Event',
                    UPDATE: 'Update Event',
                },
                TIME_ZONE_SELECT_BTN_LABEL: 'Time Zone',
                EVENT_TYPE_SELECT_BTN_LABEL: 'Event Type',
                EVENT_LOCATION_SELECT_BTN_LABEL: 'Event Location',
                BANNER_UPLOAD: {
                    UPLOAD_TITLE: 'Attach banner',
                    UPLOAD_BOX_LABEL: 'Upload Your Images',
                    UPLOAD_BOX_SUB_LABEL: 'PNG and JPG are allowed',
                    DRAG_DROP_TEXT: 'Drag and drop here',
                    DRAG_DROP_OR: 'or',
                    BROWSE_FILES_BTN: 'Browse Files',
                    BANNER_INFO_TEXT: 'Max Upload Size: 2 MB',
                    BANNER_INFO_SUB_TEXT: 'For the best result, use an image size of at least 1500px X 600px with a 5:2 aspect ratio.',
                }
            },
            EVENT_LOCATION_OPTIONS: {
                1: 'In Person',
                2: 'Virtual',
                3: 'Hybrid'
            },
            EVENT_TYPE_OPTIONS: {
                1: 'Host',
                2: 'Co-Host',
                3: 'Sponsored',
                4: 'Speaker',
                5: 'Attendee'
            }
        },
        TAB: {
            TITLE: {
                ALL: 'All',
                FAVOURITES: 'Favourites',
            },
            PATHS: {
                ALL: 'all-events',
                FAVOURITES: 'favourite-events',
            },
            PROPS_DATA: {
                ALL: 'all',
                FAVOURITES: 'favourite',
            },
        },
        DATA_TAB: {
            TITLE: {
                TAB1: 'Events Info',
                TAB2: 'Events Data',
            },
            PROPS_DATA: {
                TAB1: 'events-tab-1',
                TAB2: 'events-tab-2',
            },
            PATHS: {
                TAB1: 'events-tab1',
                TAB2: 'events-tab2',
            },
        },
        EVENTS_TAB_INFO: {
            TAB1: {
                TITLE: 'Events Data Page 1',
            },
            TAB2: {
                TITLE: 'Events Data Page 2'
            },
        },
    },
    ROI_PAGE: {
        VELOCITY_IMPACT: {
            IMPACT_TYPE: {
                1: 'Lead to Opportunity',
                2: 'Opportunity to stage change',
                3: 'Opportunity to closed one'
            }
        }
    },
    EVENTS_MODULE: {
        PLACEHOLDER: {
            TICKET_GROUP_EMPTY_SELECT: 'Add a Ticket Group...',
            TICKET_GROUP_SELECT: 'Choose Ticket Group..',
        },
        OVERVIEW: {
            TITLE: 'Overview',
            ALERT_CONTENT: {
                SUCCESS: 'Link Copied!',
            },
            TITLE_INFO_TEXT: 'Your event is almost ready to publish!',
            TITLE_INFO_SUB_TEXT: 'Below are some other things you might want to add to your event, or preview your event to see how it looks',
            BUTTONS: {
                COPY_LINK: 'Copy Registration Link',
            },
        },
        LANDING_PAGE: {
            HEADING: 'Landing Page',
            ALERT_CONTENT: {
                SUCCESS: 'Event details updated',
            },
            FORMS: {
                LABEL: {
                    TITLE: 'Event Name',
                    SHORT_NAME: 'Event Public Link',
                    DESCRIPTION: 'Event Description',
                    DESCRIPTION_TEXT: 'Write Description',
                    DESCRIPTION_LENGTH: '/1000',
                    EVENT_TYPE: 'Event Type',
                    EVENT_LOCATION: 'Event Location',
                    REGISTRATION_TYPE: 'Who Can Access',
                    TIMEZONE: 'Timezone',
                    EVENT_START_DATE: 'Start Date',
                    EVENT_START_TIME: 'Starts at',
                    EVENT_END_DATE: 'End Date',
                    EVENT_END_TIME: 'Ends at',
                    EVENT_AREAS: 'Event Areas',
                    EVENT_BRANDING: 'Event Branding',
                    EVENT_BRANDING_TEXT: 'Define the design, theme and branding for your event.',
                    LOGO: 'Event Logo',
                    IMAGE_DRAG_DROP_TEXT: 'Drag and Drop here',
                    IMAGE_DRAG_DROP_SUB_TEXT: 'or',
                    BROWSE_FILES: 'Browse Files',
                    BRAND_COLOR: 'Enter your brand Colour',
                    BANNER: 'Event Banner',
                    LOGO_ERROR_IMG: 'Please upload a Event Logo',
                    BANNER_ERROR_IMG: 'Please upload a Event Banner',
                    REGISTRATION_TYPE_DEFAULT: 'Please select a registration type'
                },
                BUTTONS: {
                    SAVE: 'Save',
                },
                EVENT_AREAS: {
                    STAGE: {
                        LABEL: 'Stage',
                        INFO_TEXT: 'The main stage for keynotes',
                    },
                    SESSION: {
                        LABEL: 'Sessions',
                        INFO_TEXT: 'Interactive roundtables',
                    },
                    EXPO: {
                        LABEL: 'Expo',
                        INFO_TEXT: 'Virtual booths for sponsors',
                    },
                },
                EVENT_TYPE_OPTIONS: {
                    1: 'On-site',
                    2: 'Virtual',
                    3: 'Hybrid'
                },
                REGISTRATION_TYPE_OPTIONS: {
                    1: 'Open',
                    2: 'Invite-Only',
                    3: 'Custom'
                }
            }
        },
        EVENT_INFO: {
            BUTTONS: {
                PREVIEW: 'Preview Event',
                PUBLISH: 'Publish Event',
            }
        },
        EVENT_NAV_BAR: {
            BUTTONS: {
                OVERVIEW: 'Overview',
                LANDING_PAGE: 'Landing Page',
                REGISTRATIONS: {
                    TITLE: 'Registrations',
                    SECONDARY_NAV: {
                        REG_DETAILS: 'Details',
                        TICKET: 'Tickets',
                        REG_FORM: 'Form',
                    },
                },
                UTM: 'UTM',
                TICKET:'Tickets',
                VENUE: {
                    TITLE: 'Venue',
                    SECONDARY_NAV: {
                        SESSIONS: 'Sessions',
                        STAGE: 'Stage',
                        EXPO: 'Expo',
                        SCHEDULE: 'Schedule',
                    },
                },
                PEOPLE: {
                    TITLE: 'People',
                    SECONDARY_NAV: {
                        SPONSORS: 'Sponsors',
                        SPEAKERS: 'Speakers',
                        REGISTRANTS: 'Registrants',
                        VENDORS: 'Vendors',
                    }
                },
                BUDGET: 'Budget',
                MARKETING: 'Marketing (Beta)',
                ANALYTICS: 'Analytics',
            }
        },
        SESSION: {
            MAIN_HEADING: 'Venue',
            SUB_HEADING: 'Sessions',
            BUTTONS: {
                ADD_SESSION: 'Add Session',
            },
            DELETE_POPUP: {
                TITLE: 'Delete Session',
                CONFIRMATION_TEXT: 'Are you sure you want to remove the session - ',
                ACCEPT_BTN: 'Delete',
                CANCEL_BTN: 'Cancel',
            },
            SIDE_DRAWER: {
                CREATE: 'Create Session',
                EDIT: 'Edit Session',
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            FORMS: {
                LABEL: {
                    TITLE: 'Session Title',
                    DESCRIPTION: 'Description',
                    DESCRIPTION_TEXT: 'Write Description',
                    DESCRIPTION_LENGTH: '/100',
                    PICTURE: 'Session Picture',
                    PICTURE_ALT_TEXT: 'Session Picture Alternate Text',
                    STAGE_SELECT: 'Select or Add a Stage',
                    SPEAKER_SELECT: 'Speaker',
                    TICKET_GROUP_SELECT: 'Ticket Group',
                    START_TIMING: 'Start Date & Time',
                    END_TIMING: 'End Date & Time',
                    MAX_PARTICIPANTS: 'Maximum Participants',
                    ADD_SCHEDULE_LABEL: 'Session is linked to schedule segment and is displayed on schedule',
                },
                PLACEHOLDER: {
                    TITLE: 'Enter the title',
                    DESCRIPTION: 'Description ...',
                    SPEAKER_SELECT: 'Select a Speaker',
                    TICKET_GROUP_SELECT: 'Choose Which Ticket Group can have access',
                },
                BUTTON: {
                    CREATE: 'Add Session',
                    UPDATE: 'Update Session',
                    ADD_DESCRIPTION: '+ Add Description',
                    ADD_PICTURE: '+ Add Picture',
                },
            }
        },
        STAGE: {
            MAIN_HEADING: 'Venue',
            SUB_HEADING: 'Stage',
            BUTTONS: {
                ADD_STAGE: 'Add Stage',
            },
            DELETE_POPUP: {
                TITLE: 'Delete Stage',
                CONFIRMATION_TEXT: 'Are you sure you want to remove the stage - ',
                ACCEPT_BTN: 'Delete',
                CANCEL_BTN: 'Cancel',
            },
            SIDE_DRAWER: {
                CREATE: 'Create Stage',
                EDIT: 'Edit Stage',
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            FORMS: {
                LABEL: {
                    TITLE: 'Stage Title',
                    DESCRIPTION: 'Stage Description',
                    DESCRIPTION_TEXT: 'Write Description',
                    DESCRIPTION_LENGTH: '/100',
                    START_TIMING: 'Start Date and Time',
                    END_TIMING: 'End Date and Time',
                    PICTURE_ALT_TEXT: 'Stage Picture Alternate Text',
                    SPEAKER_SELECT: 'Speaker',
                    TICKET_GROUP_SELECT: 'Ticket Group',
                    MAX_PARTICIPANTS: 'Maximum Participants',
                    ADD_SCHEDULE_LABEL: 'Stage is linked to schedule segment and is displayed on schedule',
                },
                PLACEHOLDER: {
                    TITLE: 'Enter the title',
                    DESCRIPTION: 'Stage Description ...',
                    SPEAKER_SELECT: 'Select a Speaker',
                    TICKET_GROUP_SELECT: 'Choose Which Ticket Group can have access',
                },
                BUTTON: {
                    CREATE: 'Add Stage',
                    UPDATE: 'Update Stage',
                    ADD_DESCRIPTION: '+ Add Description',
                    ADD_PICTURE: '+ Add Picture',
                },
            }
        },
        EXPO: {
            MAIN_HEADING: 'Venue',
            SUB_HEADING: 'Expo',
            BUTTONS: {
                ADD_EXPO: 'Add Expo',
            },
            SIDE_DRAWER: {
                HEADING: {
                    CREATE: 'Create Booth',
                    EDIT: 'Edit Booth',
                },
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            DELETE_POPUP: {
                TITLE: 'Delete Booth',
                CONFIRMATION_TEXT: 'Are you sure you want to remove the booth - ',
                ACCEPT_BTN: 'Delete',
                CANCEL_BTN: 'Cancel',
            },
            FORMS: {
                LABEL: {
                    TITLE: 'Exhibitor Title',
                    HEADLINE: 'Exhibitor Headline',
                    DESCRIPTION: 'Booth Description',
                    DESCRIPTION_TEXT: 'Write Description',
                    DESCRIPTION_LENGTH: '/100',
                    START_TIMING: 'Start Date and Time',
                    END_TIMING: 'End Date and Time',
                    LOGO: 'Add Logo',
                    BANNER: 'Add Banner',
                    ADD_SCHEDULE_LABEL: 'Expo is linked to schedule segment and is displayed on schedule',
                },
                PLACEHOLDER: {
                    DESCRIPTION: 'Description',
                    NAME: 'Enter booth Name',
                    HEADLINE: 'eg. Discussion on the future of AI',
                },
                BUTTON: {
                    CREATE: 'Create Expo',
                    UPDATE: 'Update Expo',
                    ADD_DESCRIPTION: '+ Add Description',
                },
            },
        },
        SCHEDULE: {
            MAIN_HEADING: 'Venue',
            SUB_HEADING: 'Schedule',
            BUTTONS: {
                ADD_SCHEDULE: 'Add To Schedule',
            },
            SIDE_DRAWER: {
                HEADING: {
                    CREATE: 'Add Schedule Segment',
                    EDIT: 'Edit Schedule Segment',
                },
                SEGMENT_TYPE_OPTIONS: {
                    1: 'Session',
                    2: 'Stage',
                    3: 'Expo'
                },
            },
            DELETE_POPUP: {
                TITLE: 'Delete Schedule',
                CONFIRMATION_TEXT: 'Are you sure you want to remove the schedule - ',
                ACCEPT_BTN: 'Delete',
                CANCEL_BTN: 'Cancel',
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            FORMS: {
                LABEL: {
                    TITLE: 'Title',
                    DESCRIPTION: 'Description',
                    DESCRIPTION_TEXT: 'Write Description',
                    DESCRIPTION_LENGTH: '/100',
                    TITLE_LENGTH: '/256',
                    START_TIMING: 'Start Date and Time',
                    END_TIMING: 'End Date and Time',
                    SPEAKER_SELECT: 'Speaker',
                    TYPE: 'Segment Type',
                    TYPE_OPTIONS: {
                        1: 'Session',
                        2: 'Stage',
                        3: 'Expo',
                    }
                },
                PLACEHOLDER: {
                    SPEAKER_SELECT: 'Select a Speaker'
                },
                BUTTON: {
                    CREATE: 'Add to Schedule',
                    UPDATE: 'Update Schedule',
                    ADD_DESCRIPTION: '+ Add Description',
                },
            }
        },
        REGISTRATION_DETAILS: {
            MAIN_HEADING: 'Registrations',
            SUB_HEADING: 'Details',
            FORMS: {
                LABEL: {
                    MAX_REGISTRATION: 'Maximum Registrations',
                    REG_CLOSING_DATE: 'Registration Closing Date',
                    REG_CLOSING_TIME: 'Closing at',
                    REG_CLOSED_MSG: 'What message would you like to show when registrations are closed',
                    MAX_TICKETS: 'Maximum Tickets Per Order',
                    WAITLIST_DISABLE_LABEL: 'Disable Waitlist',
                    WAITLIST_ENABLE_LABEL: 'Enable Waitlist',
                    MAX_WAITLIST: 'Waitlist Count',
                    WAITLIST_CLOSE_DATE: 'Waitlist Close Date',
                    WAITLIST_CLOSE_TIME: 'Closes at',
                    WAITLIST_SUCCESS_MSG: 'Waitlist Success Message',
                    WAITLIST_UNSUCCESS_MSG: 'Unsuccessfully Added to Waitlist Message',
                    WIDGET_AUTH: 'Widget Authentication',
                    ENABLE_WIDGET_AUTH: 'Enable Widget Authentication',
                    WIDGET_AUTH_TEXT: 'When this is disabled, authentication isn\'t possible and people can only checkout as guests' +
                        '(' + 'unless they\'re already signed in and you offer a single public ticket) - more info in the Help Centre'
                },
                PLACEHOLDER: {
                    REG_CLOSED_MSG: 'Enter Your Message',
                    WAITLIST_SUCCESS_MSG: 'Enter Your Message',
                    WAITLIST_UNSUCCESS_MSG: 'E.g., This event is sold out',
                },
                BUTTON: 'Save'
            },
        },
        EVENT_REG_FORM: {
            MAIN_HEADING: 'Registrations',
            SUB_HEADING: 'Registration Form',
            FORM_INFO_TEXT: 'Create questions you want to ask your attendees when they register. You can assign a question to specific ticket types if you want to customize the experience even more.',
            FORM_SELECT_PLACEHOLDER: 'Select a Registration Form',
            BUTTON: 'Save',
        },
        TICKET: {
            MAIN_HEADING: 'Registrations',
            SUB_HEADING: {
                TICKET: 'Tickets',
                TICKET_GROUP: 'Ticket Groups',
            },
            BUTTONS: {
                TICKET: {
                    EXPORT: 'Export',
                    CREATE: 'Create Ticket',
                },
                TICKET_GROUP: {
                    CREATE: 'Create Ticket Group',
                },
            },
            SIDE_DRAWER: {
                TICKET: {
                    HEADING: {
                        CREATE: 'Create Ticket',
                        EDIT: 'Update Ticket'
                    },
                },
                TICKET_GROUP: {
                    HEADING: {
                        EDIT: 'Update Ticket Group',

                    }
                }
            },
            DELETE_POPUP: {
                TICKET: {
                    TITLE: 'Delete Ticket',
                    CONFIRMATION_TEXT: 'Are you sure you want to delete the ticket - ',
                    ACCEPT_BTN: 'Delete',
                    CANCEL_BTN: 'Cancel',
                },
                TICKET_GROUP: {
                    TITLE: 'Delete Ticket Group',
                    CONFIRMATION_TEXT: 'Are you sure you want to delete the ticket group - ',
                    ACCEPT_BTN: 'Delete',
                    CANCEL_BTN: 'Cancel',
                }
            },
            DATA_GRID_ACTIONS: {
                EDIT: 'Edit',
                DELETE: 'Delete',
            },
            FORMS: {
                TICKET: {
                    LABEL: {
                        TITLE: 'Ticket Name',
                        DESCRIPTION: 'Ticket Description',
                        DESCRIPTION_TEXT: 'Write Description',
                        DESCRIPTION_LENGTH: '/50',
                        TICKET_GROUP_SELECT: 'Ticket Group',
                        TICKET_TYPE: 'Ticket Type',
                        TOTAL_TICKETS: 'Total Tickets Available',
                        SALE_START_TIMING: 'Start Date',
                        SALE_END_TIMING: 'End Date',
                        MAX_PEOPLE_WAITLIST: 'Maximum people on waitlist'
                    },
                    PLACEHOLDER: {
                        TICKET_GROUP_EMPTY_SELECT: 'Add a Ticket Group...',
                        TICKET_GROUP_SELECT: 'Choose Ticket Group..',
                    },
                    BUTTON: {
                        ADD_DESCRIPTION: '+ Add Description',
                        CREATE: 'Create Ticket',
                        UPDATE: 'Update Ticket',
                    },
                    TICKET_OPTION: {
                        1: 'All'
                    }
                },
                TICKET_GROUP: {
                    LABEL: {
                        TITLE: 'Ticket Group Name',
                    },
                    BUTTON: {
                        CREATE: 'Create Ticket Group',
                        UPDATE: 'Update Ticket Group',
                    },
                    PLACEHOLDER: {
                        TITLE: 'eg. VVIP'
                    }
                },
            }
        },
        BUDGET: {
            TITLE_TEXT: 'Budgeting',
            CREATE_CATEGORY_BTN: 'Create Category',
            EVENT_BUDGET_TEXT: 'Event Budget =',
            TOTAL_SPEND_TEXT: 'Total Spend =',
            SIDE_DRAWER_CONTENT: {
                SIDE_DRAWER_HEADING: 'Create Category',
                UPDATE_SIDE_DRAWER_HEADING: 'Update Category',
                TAGS_LABEL: 'Tags',
                CATEGORY_NAME_LABEL: 'Category Name',
                SUB_CATEGORY_LABEL: 'Sub-Category',
                BUDGET_LABEL: 'Budget',
                ACTUAL_SPEND_LABEL: 'Actual Spend',
                ADD_NEW_SUB_CATEGORY_LABEL: 'Add New Sub-Category',
                TAG_TYPE_OPTIONS: {
                    1: 'Marketing',
                    2: 'Sales',
                    3: 'Design',
                    4: 'Booths',
                    5: 'Vendors'
                }
            },

        },
        MARKETING: {
            MARKETING_TYPE: {
                1: 'Transactional',
                2: 'Marketing',
            }
        },
        REGISTRANTS: {
            APPROVALTYPE: {
                1: 'Invited',
                2: 'Approved',
                3: 'Rejected',
                4: 'Waitlist',
                5: 'Deleted',
                6: 'Confirmed'
            },

            ATTENDEE_STATUS: {
                1: 'No',
                2: 'Yes'
            },

            STATUS: {
                1: 'In Review',
                2: 'Approved',
                3: 'Rejected',
                4: 'Waitlist',
                5: 'Deleted',
                6: 'Confirmed'
            },

            REGISTRANT_PAYMENT_STATUS: {
                1: 'None',
                2: 'Paid',
                3: 'Yet to Pay',
                4: 'Cancelled',
                5: 'Expired'
            }
        },
        GOALS: {
            FORMS: {
                GOAL_TYPES: {
                    1: 'Total Registrations',
                    2: 'Attendee',
                    3: 'Pipeline Generated',
                    4: 'Revenue Recognised',
                },
                TYPES: {
                    'Total Registrations': 1,
                    'Attendee': 2,
                    'Pipeline Generated': 3,
                    'Revenue Recognised': 4,
                }
            }
        }
    },
    AUDIENCE_PAGE: {
        HEADING: 'Audience',
        BUTTON: 'Import',
        FILTER_PLACEHOLDER: 'Audience',
        EVENT_SELECT_PLACEHOLDER: 'Select an Event..',
        DELETE_POPUP: {
            TITLE: 'Delete Audience',
            CONFIRMATION_TEXT: 'Are you sure you want to delete the audience - ',
            ACCEPT_BTN: 'Delete',
            CANCEL_BTN: 'Cancel',
        },
        TAB: {
            TITLE: {
                ALL: 'All',
                FAVOURITES: 'Favourites',
            },
            PATHS: {
                ALL: 'all-audience',
                FAVOURITES: 'favourite-audience',
            },
            PROPS_DATA: {
                ALL: 'all',
                FAVOURITES: 'favourite',
            },
        },
        DATA_TAB: {
            TITLE: {
                TAB1: 'Audience Data',
                TAB2: 'Audience Info',
            },
            PROPS_DATA: {
                TAB1: 'tab1',
                TAB2: 'tab2',
            },
            PATHS: {
                TAB1: 'audience-tab1',
                TAB2: 'audience-tab2',
            },
        },
        AUDIENCE_TAB_INFO: {
            TAB1: {
                TITLE: 'Audience Data Tab Page 1',
            },
            TAB2: {
                TITLE: 'Audience Data Tab Page 2'
            },
        }
    },
    SPEAKERS_PAGE: {
        HEADING: 'Speakers',
        BUTTON: 'Add Speaker',
        FILTER_PLACEHOLDER: 'Speaker',
        SPEAKER_STATUS: {
            1: 'Profile Only',
            2: 'Registered',
        },
        DATA_GRID_ACTIONS: {
            EDIT: 'Edit',
            DELETE: 'Delete',
        },
        DELETE_POPUP: {
            TITLE: 'Delete Speaker',
            CONFIRMATION_TEXT: 'Are you sure you want to delete the speaker - ',
            ACCEPT_BTN: 'Delete',
            CANCEL_BTN: 'Cancel',
        },
        SIDE_DRAWER: {
            HEADING: {
                CREATE: 'Create Speaker',
                EDIT: 'Edit Speaker',
            }
        },
        TAB: {
            TITLE: {
                ALL: 'All',
                FAVOURITES: 'Favourites',
            },
            PATHS: {
                ALL: 'all-speakers',
                FAVOURITES: 'favourite-speakers',
            },
            PROPS_DATA: {
                ALL: 'all',
                FAVOURITES: 'favourite',
            },
        },
        DATA_TAB: {
            TITLE: {
                TAB1: 'Speakers Data',
                TAB2: 'Speakers Info',
            },
            PATHS: {
                TAB1: 'speakers-tab1',
                TAB2: 'speakers-tab2',
            },
            PROPS_DATA: {
                TAB1: 'tab1',
                TAB2: 'tab2',
            },
        },
        SPEAKERS_TAB_INFO: {
            TAB1: {
                TITLE: 'Speakers Data Page 1',
            },
            TAB2: {
                TITLE: 'Speakers Data Page 2',
            },
        },
        FORMS: {
            LABEL: {
                NAME: 'Full Name',
                EMAIL: 'Email',
                HEADLINE: 'Headline',
                IMAGE_UPLOAD_INFO: 'For best results use a PNG image, min size 96x96 px.',
                BIO: 'Bio',
                SOCIAL_LINK: {
                    LINKEDIN: 'LinkedIn URL ',
                    TWITTER: 'Twitter URL'
                },
            },
            BUTTON: {
                SUBMIT_BTN: 'Save',
            },
            PLACEHOLDER: {
                NAME: 'Enter your Full Name',
                EMAIL: 'Enter your email',
                HEADLINE: 'eg. Project Manager',
            },
        }
    },
    SPONSORS_PAGE: {
        HEADING: 'Sponsors',
        BUTTON: 'Add Sponsor',
        FILTER_PLACEHOLDER: 'Sponsor',
        SIDE_DRAWER: {
            HEADING: {
                CREATE: 'Create Sponsor',
                EDIT: 'Edit Sponsor',
            }
        },
        DATA_GRID_ACTIONS: {
            EDIT: 'Edit',
            DELETE: 'Delete',
        },
        DELETE_POPUP: {
            TITLE: 'Delete Sponsor',
            CONFIRMATION_TEXT: 'Are you sure you want to delete the sponsor - ',
            ACCEPT_BTN: 'Delete',
            CANCEL_BTN: 'Cancel',
        },
        TAB: {
            TITLE: {
                ALL: 'All',
                FAVOURITES: 'Favourites',
            },
            PATHS: {
                ALL: 'all-sponsors',
                FAVOURITES: 'favourite-sponsors',
            },
            PROPS_DATA: {
                ALL: 'all',
                FAVOURITES: 'favourite',
            },
        },
        DATA_TAB: {
            TITLE: {
                TAB1: 'Sponsors data',
                TAB2: 'Sponsors info',
            },
            PROPS_DATA: {
                TAB1: 'tab1',
                TAB2: 'tab2',
            },
            PATHS: {
                TAB1: 'sponsors-tab1',
                TAB2: 'sponsors-tab2',
            },
        },
        DATA_TAB_INFO: {
            TAB1: {
                TITLE: 'Sponsors Data Tab Page 1',
            },
            TAB2: {
                TITLE: 'Sponsors Data Tab Page 2'
            }
        },
        FORMS: {
            LABEL: {
                TITLE: 'Sponsor Name',
                WEBSITE: 'Sponsor Website',
                LOGO: 'Logo',
                LINKEDIN: 'LinkedIn URL',
                TWITTER: 'Twitter URL',
                POC_NAME: 'POC Name',
                POC_EMAIL: 'POC Email'
            },
            BUTTON: {
                CREATE: 'Create Sponsor',
                UPDATE: 'Update Sponsor',
            },
            PLACEHOLDER: 'eg. Google',
        }
    },
    VENDORS: {
        HEADING: 'Vendors',
        BUTTON: 'Add Vendors',
        SIDE_DRAWER: {
            HEADING: {
                CREATE: 'Create Vendor',
                EDIT: 'Edit Vendor'
            },
        },
        DATA_GRID_ACTIONS: {
            EDIT: 'Edit',
            DELETE: 'Delete',
        },
        DELETE_POPUP: {
            TITLE: 'Delete Vendor',
            CONFIRMATION_TEXT: 'Are you sure you want to delete the vendor - ',
            ACCEPT_BTN: 'Delete',
            CANCEL_BTN: 'Cancel',
        },
        FORMS: {
            LABEL: {
                NAME: 'Name',
                VENDOR_NAME: 'Contact Person',
                EMAIL: 'Email',
                SOCIAL_LINK: {
                    LINKEDIN: 'LinkedIn URL',
                    TWITTER: 'Twitter URL',
                },
                DESCRIPTION: 'Vendor Description',
                DESCRIPTION_TEXT: 'Write Description',
                DESCRIPTION_LENGTH: '/250',
                SPONSOR_WEBSITE: 'Sponsor Website'
            },
            PLACEHOLDER: {
                NAME: 'Enter your Full Name',
                EMAIL: 'Enter your email',
                SPONSOR_WEBSITE: 'www.nancy.com'
            },
            BUTTON: {
                ADD_DESCRIPTION: '+ Add Description',
                CREATE: 'Create Vendor',
                UPDATE: 'Update Vendor',
            },
        }
    },
    REGISTRATION_PAGE: {
        HEADING: 'Registration Forms',
        BUTTON: 'Create Form',
        DATA_GRID_ACTIONS: {
            EDIT: 'Edit',
            DELETE: 'Delete',
        },
        REG_TOP_BAR: {
            BUTTONS: {
                GO_BACK: 'Go to all forms',
                PREVIEW: 'Preview Form',
                PUBLISH: 'Publish Form',
            }
        },
        SIDE_DRAWER: {
            HEADING: {
                CREATE: 'Create Registration Form',
                UPDATE: 'Edit Registration Form',
            },
        },
        DELETE_POPUP: {
            TITLE: 'Delete Form',
            CONFIRMATION_TEXT: 'Are you sure you want to delete the form - ',
            ACCEPT_BTN: 'Delete',
            CANCEL_BTN: 'Cancel',
        },
        FORMS: {
            LABEL: {
                NAME: 'Name',
                LINK: 'Form Short Name',
                DESCRIPTION: 'Description',
            },
            BUTTONS: {
                CREATE: 'Create Form',
                UPDATE: 'Update Form',
            },
        },
        COMPONENTS: {
            HEADING: 'Registration Form',
            SELECT_TYPE: {
                LABEL: 'Select Type',
                OPTION_TYPES: {
                    COUNTRY: 'Country',
                    TIMEZONE: 'Timezone',
                },
                OPTION_PLACEHOLDER: 'Enter an option',
                OPTION_ADD_BTN: '+ Add Answer',
                OPTION_REMOVE_BTN: 'Delete',
            },
            COMPONENT_INFO_BAR: {
                TYPE: 'Type',
                LABEL: 'Label',
                PLACEHOLDER: 'Placeholder Text',
                DESCRIPTION: 'Description',
                REQUIRED_CHECK_BOX_LABEL: 'Require this field',
            },
            INPUT_TYPE_OPTIONS: {
                SINGLE_LINE: 'Single Line',
                PARAGRAPH: 'Paragraph Text',
                MULTI_SELECT: 'Multi Select',
                SINGLE_SELECT: 'Single Select',
                DATE: 'Date',
                HIDDEN: 'Hidden Field',
                LEGAL: 'Legal',
            },
            GDPR_OPTION_HEADING: {
                1: 'Consent checkboxes for communications and processing',
                2: 'Legitimate Interest',
                3: 'Consent checkbox for communications; form submit as consent to process',
            },
            GDPR_OPTIONS_SUBHEADING: {
                3: 'Show consent to communicate checkboxes and consent to process text',
                1: 'I agree to receive other communications from',
                2: 'Show consent to communicate and consent to process checkboxes',
            },
            GDPR_EDITOR_HEADING_TYPE11: {
                0: 'Communication consent',
                1: 'Consent to communication checkbox',
                2: 'Privacy text',
                3: 'Process consent',
                4: 'Consent to process checkbox',
            },
            GDPR_EDITOR_HEADING_TYPE12: {
                0: 'Consent to communication checkbox',
            },
            GDPR_EDITOR_HEADING_TYPE13: {
                0: 'Communication consent',
                1: 'Consent to communication checkbox',
                2: 'Privacy text',
                3: 'Consent to process checkbox',
            },
        }
    },
    FILTERS: {
        SEARCH_PLACEHOLDER: 'Search',
    },
    ERROR_PAGE: {
        LINK_TO: 'go Home'
    },
    COMMON: {
        COMING_SOON: 'Coming Soon !!!!!',
    },
    EVENTSTAB_PAGE: {
        TABS: {
            1: 'Hello',
            2: 'Users',
            3: 'Teams',
        },
    },
    TABS_SELECT: {
        ALL: {
            ID: 1,
            NAME: 'All',
        },
        FAVOURITES: {
            ID: 2,
            NAME: 'Favourites',
        },
    },
    REQUIRED_DETAILS_FOR_PUBLISHING_EVENT: {
        'maximumRegistration': 'Maximum Registrations is Required',
        // 'maximumTickets': 'Maximum Tickets is Required',
        'closingDateTime': 'Registration Closing Date and Closing Time is Required',
        'registrationFormId': 'Please Select the Registration Form',
        'ticketGroup': 'For Invite Events, Ticket Group is mandatory',
        'eventBanner': 'Event banner is Required.'
    },
    BRAND_COLOR_OBJ: {
        '#F2AE40': { //yellow
            pageBackground: '#FFFFF6',
            cardBackground: '#FEF7EC',
            cardBorder: '#FCEFD9',
            title: '#241A0A',
            aboutContent: '#181106',
            companyName: '#241A0A',
            dateLine1: '#241A0A',
            addressLine1: '#241A0A',
            footerLinks: '#241A0A',
            footerInfoText: '#241A0A',
            overviewContent: '#493413',
            dateLine2: '#493413',
            addressLine2: '#493413',
            locationAndCalenderIcon: '#493413',
            registerInfoText: '#493413',
            hostedText: '#493413',
            aboutCardTitle: '#493413',
            ctaColor: '#F2AE40',
            ctaHover: '#B47818',
            description: '#493413',
            disabledCtaColor: '#FEF7EC',
            disabledCtaBorder: '#FBDFB1'
        },
        '#F17B2C': { //orange
            pageBackground: '#FFFCF4',
            cardBackground: '#FEF2EA',
            cardBorder: '#FCE5D5',
            title: '#180C04',
            aboutContent: '#180C04',
            companyName: '#180C04',
            dateLine1: '#180C04',
            addressLine1: '#180C04',
            footerLinks: '#180C04',
            footerInfoText: '#180C04',
            overviewContent: '#48250D',
            dateLine2: '#48250D',
            addressLine2: '#48250D',
            locationAndCalenderIcon: '#48250D',
            registerInfoText: '#48250D',
            hostedText: '#48250D',
            aboutCardTitle: '#48250D',
            ctaColor: '#F17B2C',
            ctaHover: '#C2540A',
            description: '#48250D',
            disabledCtaColor: '#FEF3EB',
            disabledCtaBorder: '#FFDAC2'
        },
        '#DF1C41': { //red
            pageBackground: '#FFF2F6',
            cardBackground: '#FCE8EC',
            cardBorder: '#F9D2D9',
            title: '#160306',
            aboutContent: '#160306',
            companyName: '#160306',
            dateLine1: '#160306',
            addressLine1: '#160306',
            footerLinks: '#160306',
            footerInfoText: '#160306',
            overviewContent: '#430814',
            dateLine2: '#430814',
            addressLine2: '#430814',
            locationAndCalenderIcon: '#430814',
            registerInfoText: '#430814',
            hostedText: '#430814',
            aboutCardTitle: '#430814',
            ctaColor: '#DF1C41',
            ctaHover: '#AF1D38',
            description: '#430814',
            disabledCtaColor: '#FDEDF0',
            disabledCtaBorder: '#F8C9D2'
        },
        '#E255F2': { //pink
            pageBackground: '#FFF8FF',
            cardBackground: '#FCEEFE',
            cardBorder: '#F9DDFC',
            title: '#170818',
            aboutContent: '#170818',
            companyName: '#170818',
            dateLine1: '#170818',
            addressLine1: '#170818',
            footerLinks: '#170818',
            footerInfoText: '#170818',
            overviewContent: '#441A49',
            dateLine2: '#441A49',
            addressLine2: '#441A49',
            locationAndCalenderIcon: '#441A49',
            registerInfoText: '#441A49',
            hostedText: '#441A49',
            aboutCardTitle: '#441A49',
            ctaColor: '#E255F2',
            ctaHover: '#9C23A9',
            description: '#441A49',
            disabledCtaColor: '#FDEBFF',
            disabledCtaBorder: '#F9C2FF'
        },
        '#35B9E9': { //teal
            pageBackground: '#F5FFFF',
            cardBackground: '#EBF8FD',
            cardBorder: '#D7F1FB',
            title: '#051217',
            aboutContent: '#051217',
            companyName: '#051217',
            dateLine1: '#51217',
            addressLine1: '#51217',
            footerLinks: '#051217',
            footerInfoText: '#51217',
            overviewContent: '#103846',
            dateLine2: '#103846',
            addressLine2: '#103846',
            locationAndCalenderIcon: '#103846',
            registerInfoText: '#103846',
            hostedText: '#103846',
            aboutCardTitle: '#103846',
            ctaColor: '#35B9E9',
            ctaHover: '#1F87AD',
            description: '#103846',
            disabledCtaColor: '#EBFAFF',
            disabledCtaBorder: '#C2EFFF'
        },
        '#375DFB': { //blue
            pageBackground: '#F5F9FF',
            cardBackground: '#EBEFFF',
            cardBorder: '#D7DFFE',
            title: '#50919',
            aboutContent: '#050919',
            companyName: '#050919',
            dateLine1: '#50919',
            addressLine1: '#50919',
            footerLinks: '#50919',
            footerInfoText: '#50919',
            overviewContent: '#111C4B',
            dateLine2: '#111C4B',
            addressLine2: '#111C4B',
            locationAndCalenderIcon: '#111C4B',
            registerInfoText: '#111C4B',
            hostedText: '#111C4B',
            aboutCardTitle: '#111C4B',
            ctaColor: '#375DFB',
            ctaHover: '#253EA7',
            description: '#111C4B',
            disabledCtaColor: '#EBF1FF',
            disabledCtaBorder: '#C2D6FF'
        },
        '#6E3EE3': { //purple
            pageBackground: '#FBF6FF',
            cardBackground: '#F1ECFC',
            cardBorder: '#E2D8F9',
            title: '#0B0617',
            aboutContent: '#0B0617',
            companyName: '#0B0617',
            dateLine1: '#0B0617',
            addressLine1: '#0B0617',
            footerLinks: '#0B0617',
            footerInfoText: '#0B0617',
            overviewContent: '#211344',
            dateLine2: '#211344',
            addressLine2: '#211344',
            locationAndCalenderIcon: '#211344',
            registerInfoText: '#211344',
            hostedText: '#211344',
            aboutCardTitle: '#211344',
            ctaColor: '#6E3EE3',
            ctaHover: '#5A36BF',
            description: '#211344',
            disabledCtaColor: '#EEEBFF',
            disabledCtaBorder: '#CAC2FF'
        },
        '#38C793': { //green
            pageBackground: '#F5FFFE',
            cardBackground: '#EBF9F4',
            cardBorder: '#D7F4E9',
            title: '#06140F',
            aboutContent: '#06140F',
            companyName: '#06140F',
            dateLine1: '#06140F',
            addressLine1: '#06140F',
            footerLinks: '#06140F',
            footerInfoText: '#06140F',
            overviewContent: '#113C2C',
            dateLine2: '#113C2C',
            addressLine2: '#113C2C',
            locationAndCalenderIcon: '#113C2C',
            registerInfoText: '#113C2C',
            hostedText: '#113C2C',
            aboutCardTitle: '#113C2C',
            ctaColor: '#38C793',
            ctaHover: '#2D9F75',
            description: '#113C2C',
            disabledCtaColor: '#EFFAF6',
            disabledCtaBorder: '#CBF5E5'
        },
        '#CDD0D5':  { //white
            pageBackground: '#FFFFFF',
            cardBackground: '#FFFFFF',
            cardBorder: '#F6F8FA',
            title: '#0A0D14',
            aboutContent: '#0A0D14',
            companyName: '#0A0D14',
            dateLine1: '#0A0D14',
            addressLine1: '#0A0D14',
            footerLinks: '#0A0D14',
            footerInfoText: '#0A0D14',
            overviewContent: '#525866',
            dateLine2: '#525866',
            addressLine2: '#525866',
            locationAndCalenderIcon: '#525866',
            registerInfoText: '#525866',
            hostedText: '#525866',
            aboutCardTitle: '#525866',
            ctaColor: '#375DFB',
            ctaHover: '#253EA7',
            description: '#0A0D14',
            disabledCtaColor: '#EBF1FF',
            disabledCtaBorder: '#C2D6FF'
        }
    }
};