import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import Charts from '../../../common/ECharts/Charts';
import CustomSpinner from '../../../common/CustomSpinner';
import PopUpView from '../../../common/PopupView';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { constructObjectForPieChart } from '../../../common/ECharts/helpers/charts.helper';
import APP_CONSTANTS from '../../../scripts/constants';
import { EventBudgetGraphObject } from '../interfaces';
import TopPageAnalyticsContainer from '../../../common/TopPageAnalyticsContainer/TopPageAnalyticsContainer';
import TrendsComponent from '../../../common/TrendsComponent';
import { BudgetGraphObject } from '../../Budget/interfaces';
import { NumberFormatter } from '../../../scripts/helpers';

interface IEventBudgetChart {
  chartReady: {
    budgetSummaryGraph: boolean;
    expenditureGraph: boolean;
    budgetCategoryGraph: boolean;
  };
  trend: BudgetGraphObject;
  budgetSummaryGraphData: EventBudgetGraphObject[] | [];
  expenditureGraphData: EventBudgetGraphObject[] | [];
  budgetCategoryGraphData: [string[], number[]] | [];
}

const EventBudgetChartsContainer = (props:IEventBudgetChart):React.JSX.Element => 
{
    // const [totalExpenditureValue, setTotalExpenditureValue] = useState(0);
    const [openEnlargeGraphView, setOpenEnlargeGraphView] = useState({
        expenditureGraph: false,
        budgetCategoryGraph: false,
    });    

    // useEffect(():void => 
    // {
    //     if (props.expenditure) 
    //     {
    //         let expenditureCount = 0;
    //         props.expenditure.map((item):void => 
    //         {
    //             expenditureCount += item.value;
    //         });
    //         setTotalExpenditureValue(expenditureCount);
    //     }

    // }, [props.expenditure]);
    // const eventBudgetValue = props.eventBudgetValue;
    // const actualSpendValue = props.actualSpendValue;

    // const budgetData = [
    //     {
    //         name: 'Spent',
    //         percentage: Math.round((actualSpendValue / eventBudgetValue) * 100),
    //         value: actualSpendValue
    //     },
    //     {
    //         name: 'Remaining',
    //         percentage: Math.round(((eventBudgetValue - actualSpendValue) / eventBudgetValue) * 100),
    //         value: eventBudgetValue - actualSpendValue,
    //     }
    // ];

    const budgetSummaryGraphData = constructObjectForPieChart(props?.budgetSummaryGraphData, ['50%', '72%'], undefined, '$');

    const expenditureGraphData = constructObjectForPieChart(props?.expenditureGraphData, ['50%', '72%'], undefined, '$');

    const allExpenditureGraphData = constructObjectForPieChart(props?.expenditureGraphData, ['50%', '72%'], 'vertical', '$');

    const xAxisKey = props?.budgetCategoryGraphData.length > 0 ? props?.budgetCategoryGraphData[0] : null;

    const budgetCategoryGraphOption = {
        color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT,
        tooltip: {
            trigger: 'axis'
        },
        dataset: {
            source: props?.budgetCategoryGraphData
        },
        legend: {
            show: false,
            orient: 'horizontal',
            left: 'left',
            icon: 'circle',
            padding: [0, 16, 10, 4],
        },
        xAxis: {
            type: 'category',
            nameGap: 35,
            data: xAxisKey,
            axisTick: {
                show: false
            },
            axisLabel: {
                interval: 0,
                rotate: 45,
                textStyle: {
                    color: '#2a2e34',
                    fontSize: 8,
                    fontWeight: 400,
                    width: 50,
                },
            },
        },
        yAxis: {
            nameGap: 35,
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'value',
                },
            },
            axisLabel: {
                formatter: function (value): string 
                {
                    if (value >= 1000000) 
                    {
                        return (value / 1000000) + 'M';
                    }
                    else if (value >= 1000) 
                    {
                        return (value / 1000) + 'K';
                    }
                    else 
                    {
                        return value;
                    }
                },
            }
        },
        series: [{
            type: 'line',
            smooth: true,
            showSymbol: false,
            seriesLayoutBy: 'row',
            barWidth: 15,
            emphasis: {
                focus: 'series',
                label: {
                    show: true,
                    fontSize: 12,
                    fontWeight: 'bold',
                    color: '#2a2e34',
                },
            },
            itemStyle: {
                borderRadius: [4, 4, 0, 0],
                emphasis: {
                    color: APP_CONSTANTS.GRAPH_COLORS.LINE_CHART.DEFAULT[0]
                }
            },
            data: props?.budgetCategoryGraphData[1],
        }]
    };

    return (
        <Box id="eventBudgetChart">
            <TopPageAnalyticsContainer
                TrendsComponent={
                    <TrendsComponent
                        Trends={[{
                            title: 'Categories',
                            value: `${props?.trend?.tags && Number(props?.trend?.tags) !== 0 ? props?.trend?.tags : '-'}`
                        },
                        {
                            title: 'Budget',
                            value: `${props?.trend?.budget && Number(props?.trend?.budget) !== 0 ? `$${NumberFormatter(props?.trend?.budget)}` : '-'}`,
                        },
                        {
                            title: 'Spent',
                            value: `${props?.trend?.spent && Number(props?.trend?.spent) !== 0 ? `$${NumberFormatter(props?.trend?.spent)}` : '-'}`,
                        },
                        {
                            title: 'Remaining',
                            value: `${props?.trend?.remaining && Number(props?.trend?.remaining) !== 0 ? `$${NumberFormatter(props?.trend?.remaining)}` : '-'}`,
                        }
                        ]}
                    />
                }
                GraphComponentsArray={[
                    // {
                    //     GraphComponent: <Box className="budget-summary-chartContainer" key={'budget-summary'}>
                    //         <Box className="title-container">
                    //             <Typography className="graph-title">{'Budget Summary'}</Typography>
                    //         </Box>
                    //         {props?.chartReady?.budgetSummaryGraph ? props?.budgetSummaryGraphData?.length != 0 ? (<Charts height={'100%'} chartOption={budgetSummaryGraphData} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : <Box className="noData" sx={{
                    //             height: '100%'
                    //         }}>{'No data available'}</Box> : (<CustomSpinner height={'100%'} />)}
                    //     </Box>,
                    //     GraphSpacingBlockCSS: {
                    //         flex:'1 !important'
                    //     }
                    // },
                    {
                        GraphComponent: <Box className="expenditure-chartContainer" key={'expenditure'}>
                            <Box className="title-container">
                                <Typography className="graph-title">{'Expenditure'}</Typography>
                                {props?.budgetSummaryGraphData?.length > 0 &&
                                        <Box className="enlarge-view" onClick={(): void => 
                                        {
                                            setOpenEnlargeGraphView({
                                                budgetCategoryGraph: false, expenditureGraph: true
                                            });
                                        }}>
                                            <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                                        </Box>
                                }
                            </Box>
                            {props?.chartReady?.expenditureGraph ? props?.expenditureGraphData?.length != 0 ? (<Charts height={'100%'} chartOption={expenditureGraphData} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData" style={{ height: '100%' }}><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                        </Box>,
                        GraphSpacingBlockCSS: {
                            flex:'1 !important'
                        }
                    },
                    {
                        GraphComponent: <Box className="budget-category-chartContainer" key={'budget-category'}>
                            <Box className="title-container">
                                <Typography className="graph-title">{'Budget Category'}</Typography>
                            </Box>
                            {props?.chartReady?.budgetCategoryGraph ? props?.budgetCategoryGraphData?.length != 0 ? (<Charts height={'100%'} chartOption={budgetCategoryGraphOption} chartHeight={'100%'} chartWidth={'90'} boxMargin="16px" />) : (<div className="noData" style={{ height: '100%' }}><FontAwesomeIcon icon={['fal', 'chart-line']} /><p>No data available</p></div>) : (<CustomSpinner height={'100%'} />)}
                        </Box>,
                        GraphSpacingBlockCSS: {
                            flex:'2 !important'
                        }
                    },
                ]}
            />

            {openEnlargeGraphView.expenditureGraph ? (
                <PopUpView onClose={(): void => 
                {
                    setOpenEnlargeGraphView({
                        budgetCategoryGraph: false, expenditureGraph: false
                    });
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">{'Expenditure'}</Typography>
                    </Box>
                    <Charts height={'100%'} chartOption={allExpenditureGraphData} chartHeight={'100%'} chartWidth={'100'} boxMargin="16px" />
                </PopUpView>
            ) : null}
            {/* <Box className="event-budget-summary-chart-container">
                <Box className="title-container">
                    <Typography className="graph-title">
                        {'Budget Summary'}
                    </Typography>
                    <Typography className="budget-value">
            Budget = ${NumberFormatter(eventBudgetValue)}
                    </Typography>
                </Box>
                {eventBudgetValue && eventBudgetValue !== 0 ? <Charts height={`${calculateContainerHeight()}px`} chartOption={budgetSummaryGraphData} chartHeight={`${calculateContainerHeight()}px`} chartWidth={'100'} /> : <NoDataComponent height={`${calculateContainerHeight()}px`} />}
            </Box>
            <Box className="expenditure-chart-container">
                <Box className="title-container">
                    <Typography className="graph-title">Expenditure</Typography>
                    {props.expenditure && props.expenditure?.length > 1 && <Box className="enlarge-view" onClick={():void => 
                    {
                        setOpenEnlargeGraphView(true); 
                    }}>
                        <FontAwesomeIcon icon={['fal', 'arrows-maximize']} className="graph-expand-icon" />
                    </Box>}
                </Box>

                {props.chartReady ? props.expenditure && props.expenditure?.length > 0 ? <Charts height={`${calculateContainerHeight()}px`} chartOption={expenditureGraphData} chartHeight={`${calculateContainerHeight()}px`} chartWidth={'100'} /> : <NoDataComponent height={`${calculateContainerHeight()}px`} /> : <CustomSpinner height={`${calculateContainerHeight()}px`} />}
            </Box>
            {openEnlargeGraphView ? (
                <PopUpView onClose={():void => 
                {
                    setOpenEnlargeGraphView(false); 
                }}>
                    <Box className="title-container2">
                        <Typography className="graph-title">Expenditure</Typography>
                    </Box>
                    {props.chartReady ? <Charts height={'100%'} chartOption={allExpenditureGraphData} chartHeight={'100%'} chartWidth={'100'} /> : <CustomSpinner height={'100%'} />}
                </PopUpView>
            ) : null} */}
        </Box>
    );
};

export default EventBudgetChartsContainer;


const calculateContainerHeight = ():number => 
{

    const screenHeight = window.innerHeight;

    const seventyPercentHeight = 0.7 * screenHeight;

    const containerHeight = screenHeight - seventyPercentHeight;

    return containerHeight;
};
