import SideBarNavigation from "../common/SideBarNavigation";
import { useContext } from "react";
import { SponsorPortalContext } from "../contexts/SponsorPortal/SponsorPortalContext";
import SponsorPortalAudiencePage from "../pages/SponsorPortal/Pages/Audience/SponsorPortalAudiencePage";
import ICPAndBPPage from "../pages/SponsorPortal/Pages/ICP/ICPAndBPPage";
import LeadCaptureFormPage from "../pages/SponsorPortal/Pages/Form/LeadCaptureFormPage";
import SponsorAuthPage from "../pages/SponsorPortal/Pages/Authentication/SponsorAuthPage";
import { LocalStorage } from "../scripts/LocalStorage";
import { Toaster } from "react-hot-toast";

const SponsorPortalProtectedRoute: React.FC = (): React.JSX.Element =>
{
    const sponsorDetails = LocalStorage.get('@SponsorDetails');

    const { selectedModule, setSelectedModule } = useContext(SponsorPortalContext);

    let component: React.JSX.Element;

    const mainAppSidebarProps = {
        mainContent: [
            // {
            //     title: 'Dashboard',
            //     selected: selectedModule === 'dashboard',
            //     onClick: () => setSelectedModule('dashboard'),
            //     icon: 'clapperboard',
            // },
            {
                title: 'Form',
                selected: selectedModule === 'form',
                onClick: () => setSelectedModule('form'),
                icon: 'file',
            },
            {
                title: 'Audience',
                selected: selectedModule === 'audience',
                onClick: () => setSelectedModule('audience'),
                icon: 'user-group',
            },
            {
                title: 'ICP & BP',
                selected: selectedModule === 'icp',
                onClick: () => setSelectedModule('icp'),
                icon: 'coins',
            }
        ]
    };

    if(sponsorDetails)
    {
        component = (
            <div className="sponsor-portal-layout-wrapper">  
                {!(selectedModule === 'formFieldEdit') && <SideBarNavigation sidebarcontent={mainAppSidebarProps} />}
                <div style={{
                    width: !(selectedModule === 'formFieldEdit') ? `calc(100% - 240px)` : '100%', marginLeft: `0px !important`, display: 'flex', flexDirection: 'column' 
                }}>
                    <div style={{
                        width: '100%',
                        height: '100%'
                    }}>
                        {/* {selectedModule === 'dashboard' && <SponsorsDashboardPage />} */}
                        {(selectedModule === 'form' || selectedModule == 'formFieldEdit') && <LeadCaptureFormPage />}
                        {selectedModule === 'audience' && <SponsorPortalAudiencePage />}
                        {selectedModule === 'icp' && <ICPAndBPPage />}
                    </div>
                </div>
                <Toaster />
            </div>
        );
    }
    else
    {
        component = (
            <SponsorAuthPage />
        );
    }

    const components = (
        <div style={{ height: '100%', width: '100%' }}>
            {component}
        </div>
    )

    return (
        sponsorDetails ? (
            components
        ) : <>
                <SponsorAuthPage />
                <Toaster />
            </>
    )
};

export default SponsorPortalProtectedRoute;