import { useState } from "react";
import SponsorPortalAppBar from "../../Components/Common/SponsorPortalAppbar";
import ButtonGroup from "../../../../common/ButtonGroup";

import './styles.scss';
import RegistrantsPage from "./RegistrantsPage";
import TargetListPage from "./TargetListPage";
import LeadsPage from "./LeadsPage";

const SponsorPortalAudiencePage: React.FC = (): React.JSX.Element => 
{

    const [selectedTab, setSelectedTab] = useState<'registrants' | 'targetList' | 'leads'>('registrants');

    const sponsorPortalAudienceTabs = [
        {
            tabName: 'Registrants',
            selectedTab: selectedTab === 'registrants',
            navigation: (): void => setSelectedTab('registrants') 
        },
        {
            tabName: 'Target List',
            selectedTab: selectedTab === 'targetList',
            navigation: (): void => setSelectedTab('targetList')
        },
        {
            tabName: 'Leads',
            selectedTab: selectedTab === 'leads',
            navigation: (): void => setSelectedTab('leads')
        }
    ];

    return (
        <div id="sponsorPortalAudiencePage">
            <SponsorPortalAppBar title="Audience" />
            <div className="button-group-container">
                <ButtonGroup tabs={sponsorPortalAudienceTabs} />
            </div>
            {
                selectedTab === 'registrants' && <RegistrantsPage />
            }
            {
                selectedTab === 'targetList' && <TargetListPage />
            }
            {
                selectedTab === 'leads' && <LeadsPage />
            }
        </div>
    );
};

export default SponsorPortalAudiencePage;