import React, { useContext } from 'react';
import { IFormComponentsContext } from '../../../../contexts/FormComponentsContext';
import { SponsorPortalFormsContext } from '../../../../contexts/SponsorPortal/SponsorPortalForms';
import RegistrationsTopBar from '../../../Registration/Common/RegistrationsTopBar';
import RegistrationFormContent from '../../../Registration/RegistrationFormContent/RegistrationFormContent';
import { SponsorPortalContext } from '../../../../contexts/SponsorPortal/SponsorPortalContext';
// import RegistrationFormTabs from './Common/RegistrationFormTabs';

const LeadCaptureRegistrations = (props): React.JSX.Element => 
{

    const { setRefresh, formName, formId, registrationData } = useContext<IFormComponentsContext>(SponsorPortalFormsContext);

    const { selectedModule, setSelectedModule } = useContext(SponsorPortalContext);

    return (
        <>
            <RegistrationsTopBar name={formName} setSelectedModule={setSelectedModule} formId={formId} setRefresh={setRefresh} registrationData={registrationData} />
            <RegistrationFormContent leadCaptureFormId={registrationData?.id} setRefresh={setRefresh} RegistrationFormData={registrationData}/>
        </>
    );
};

export default LeadCaptureRegistrations;