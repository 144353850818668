import { EventLeadCapture } from '../../../pages/Events/interfaces/event-lead-capture_interface';
import { EventRegistrant } from '../../../pages/Events/interfaces/event-registrant_interface';
import { CustomField } from '../../../pages/Registration/interfaces/custom-field_interface';
import { RegistrationForm } from '../../../pages/Registration/interfaces/registration-form_interface';
import { BuyerPersona } from '../../../pages/Settings/interface/buyer-persona_interface';
import { ICP } from '../../../pages/Settings/interface/icp_interface';
import APP_CONSTANTS from '../../constants';
import { convertJSONToGETParams, triggerApi } from '../../helpers';

// GET API's

export const registrantMe = async (): Promise<EventRegistrant> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrant/me';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getSponsorPortalRegistrantsCount = async (): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants/count';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getSponsorPortalLeadsCount = async (): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/leads/count';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllRegistrantsInSponsorPortal = async (pageSize: number, offset: number, status?: string, attendeeStatus?: string, paymentStatus?: string): Promise<EventRegistrant[]> =>
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
        attendeeStatus,
        paymentStatus
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllLeadsInSponsorPortal = async (pageSize: number, offset: number, status?: string, sponsorId?: string | number, accessToken?: string): Promise<EventLeadCapture[]> =>
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
        accessToken,
        sponsorId
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/leads?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getIcpSponsorPortalCount = async (): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp/count';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllIcpSponsorPortal = async (pageSize: number, offset: number): Promise<ICP[]> =>
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getIcpSponsorPortalById = async (id: string | number): Promise<ICP> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/icp-sponsor-portal/' + id;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getbuyerPersonaSponsorPortalCount = async (): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona/count';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllBuyerPersonaSponsorPortal = async (pageSize: number, offset: number): Promise<BuyerPersona[]> =>
{
    const query = convertJSONToGETParams({
        pageSize,
        offset,
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona?' + query;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getBuyerPersonaSponsorPortalById = async (id: string | number): Promise<BuyerPersona> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona' + id;
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getLeadCaptureForm = async (): Promise<RegistrationForm> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/lead-capture-form';
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const getAllTargetList = async (registrant?: boolean): Promise<EventRegistrant[]> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/target-list?' + convertJSONToGETParams({ registrant });
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
};

export const sponsorPortalRegistrants = async (email?: string, firstName?: string, lastName?: string): Promise<EventRegistrant[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/search?' + convertJSONToGETParams({ email, firstName, lastName });
    return triggerApi(API_URL, 'GET', '', undefined, undefined,undefined, true);
}; 

// POST API's

export const accessSponsor = async (data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/access';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const authorizeSponsor = async (data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/authorize';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const createIcpSponsorPortal = async (data: ICP): Promise<ICP> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const createBuyerPersonaSponsorPortal = async (data: BuyerPersona): Promise<BuyerPersona> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const createLeadCaptureField = async (formId: string | number | undefined, data: any): Promise<CustomField> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/' + formId + '/fields';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

export const unauthorizeSponsor = async (): Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/unauthorize';
    return triggerApi(API_URL, 'POST', null, undefined);
};

export const addToTargetList = async (data: { registrantIds: string[] | number[] | null }): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants/target-list';
    return triggerApi(API_URL, 'POST', data, undefined, undefined, undefined, true);
};

// PUT API's

export const updateIcpSponsorPortal = async (id: string | number, data: ICP): Promise<ICP> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp/' + id;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateBuyerPersonaSponsorPortal = async (id: string | number, data: BuyerPersona): Promise<BuyerPersona> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona/' + id;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateLeadCaptureField = async (formId: string | number | undefined, fieldId: string | number | undefined, data: any): Promise<CustomField> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/' + formId + '/' + fieldId + '/fields';
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

export const updateLeadCaptureForm = async (formId: string | number, data: any): Promise<RegistrationForm> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/lead-capture-form/' + formId;
    return triggerApi(API_URL, 'PUT', data, undefined, undefined, undefined, true);
};

// DELETE API's

export const deleteIcpSponsorPortal = async (id: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/icp/' + id;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const deleteBuyerPersonaSponsorPortal = async (id: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/buyer-persona/' + id;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const deleteLeadCaptureField = async (formId: string | number | undefined, componentId: string | number | undefined): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/lead-capture-form/' + formId + '/components/' + componentId;
    return triggerApi(API_URL, 'DELETE', '', undefined, undefined, undefined, true);
};

export const removeTargetsFromTargetList = async (data: { registrantIds: string[] | number[] | null }): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + '/sponsor-portal/registrants/target-list';
    return triggerApi(API_URL, 'DELETE', data, undefined, undefined, undefined, true);
};