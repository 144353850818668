import { CustomButton } from "../../../common/FormComponents/Buttons";
import MuiChip from "../../../common/FormComponents/MuiChip";

interface IEventsCardHeaderComponent {
    heading: string;
    count?: number;
    buttonAction?: () => void;
    extraActions?: React.ReactNode;
}

const EventsCardHeaderComponent = (props: IEventsCardHeaderComponent) => 
{

    const { heading, count, buttonAction, extraActions } = props;

    return (
        <div className="events-card-header-component">

            <div className="heading-div">
                <div className="heading-wrapper">
                    <h2 className="heading">{heading}</h2>
                    {count && count > 0 && <MuiChip chipColor='grey' label={count || '-'} />}
                </div>
                <div className="button-container">
                    {extraActions && extraActions}
                    <CustomButton style={{ border: 'unset'}} name='View All' btnType='secondary' onClick={() => { buttonAction ? buttonAction() : undefined } } />
                </div>
            </div>
        </div>
    );
}

export default EventsCardHeaderComponent;