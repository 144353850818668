import { Col, Row } from "react-bootstrap";
import AnalyticsPageHeader from "../Common/AnalyticsPageHeader";
import { IRevenueGraphReady, IRevenueOverview } from "./revenueAnalytics.interface";

import './styles.scss';
import { GraphComponent } from "../Common/GraphComponent";
import { convertObjectToArrays, generateOpenOpportunitiesGraphOption } from "./barChartGenerator.helper";
import { convertToArrayOfObjectsForPieChart } from "../../../../../../scripts/helpers";
import { constructObjectForPieChart } from "../../../../../../common/ECharts/helpers/charts.helper";

interface LeadsRevenueProps {
    leadsRevenueData: IRevenueOverview;
    graphReady: IRevenueGraphReady;
}

const LeadsRevenue: React.FC<LeadsRevenueProps> = (props): React.JSX.Element =>
{

    const { leadsRevenueData, graphReady } = props;

    const leadStagesData = convertObjectToArrays(leadsRevenueData?.leadStages);
    const leadStageGraphOption = generateOpenOpportunitiesGraphOption(leadStagesData as any);

    const icpGraphData = convertToArrayOfObjectsForPieChart(leadsRevenueData?.leadsICP as any);
    const icpGraphOption = constructObjectForPieChart(icpGraphData, ['50%', '72%'], undefined, undefined);

    const bpGraphData = convertToArrayOfObjectsForPieChart(leadsRevenueData?.leadsBuyerPersona as any);
    const bpGraphOption = constructObjectForPieChart(bpGraphData, ['50%', '72%'], undefined, undefined);

    return (
        <div id="leadsRevenue">
            <AnalyticsPageHeader 
                header="Leads"
                description="Understand your deal performance and win rates"
            />

            <div className="content-div">
                {/* Row which renders lead stages, lead icp and bp */}
                <Row className="custom-row">
                    <Col className="px-0">
                        <GraphComponent 
                            chartOption={leadStageGraphOption}
                            height="240px"
                            title="Lead Stages"
                            graphReady={graphReady?.leadStages}
                            isDataAvailable={leadStagesData[1]?.length > 0 && leadStagesData[1]?.some((item: number | string) => item !== 0)}
                        />
                    </Col>

                    <Col className="px-0">
                        <Row className="custom-row">
                            <Col className="px-0">
                                <GraphComponent 
                                    title="ICP Breakdown"
                                    chartOption={icpGraphOption}
                                    height="240px"
                                    graphReady={graphReady?.leadsICP}
                                    isDataAvailable={icpGraphData.length > 0}
                                />
                            </Col>

                            <Col className="px-0">
                                <GraphComponent 
                                    title="Buyer Persona Breakdown"
                                    chartOption={bpGraphOption}
                                    height="240px"
                                    graphReady={graphReady?.leadsBuyerPersona}
                                    isDataAvailable={bpGraphData.length > 0}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default LeadsRevenue;