export enum UserOrgRoles 
    {
    USER = 1,
    ADMIN = 2,
    SUPERADMIN = 3
}

export enum UserOrgStatus 
    {
    ACTIVE = 1,
    REGISTERING = 2,
    DELETED = 3,
    INVITED = 4,
    EXPIRED = 5
}

export enum UserStatus 
    {
    ACTIVE = 1,
    SIGNUP = 2,
    INVITED = 3,
    EXPIRED = 4,
    DELETED = 5,
}
