import axios from 'axios';
import { EventRegistrantAttendedStatus, EventRegistrantPaymentStatus, EventRegistrantStatus } from '../../pages/Events/enum/event-registrant.enum';
import { EventGraphObject, EventRegistrantGraph } from '../../pages/Events/interfaces';
import { BulkEventRegistrant, EventRegistrant } from '../../pages/Events/interfaces/event-registrant_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const getEventRegistrantCount = async (eventId: number):Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId +  '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllEventRegistrants = async (eventId: number, pageSize: number, offset: number, ...queryParams: [string, any][]): Promise<EventRegistrant[]> => 
{
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams(filteredParams);
    const API_URL = `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-registrant/${eventId}?pageSize=${pageSize}&offset=${offset}&${query}`;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventRegistrantById = async (eventId: number, registrantId: number):Promise<EventRegistrant> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/' + registrantId;
    return triggerApi(API_URL, 'GET', '');
};

export const getRegistrantsGraphData = async (eventId: string | number):Promise<EventRegistrantGraph> => 
{
    const query = convertJSONToGETParams({
        icp: true,
        buyerPersona: true,
        arr: true
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/graph?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getEventRegistrantStatusCount = async (eventId: number): Promise<EventGraphObject> => 
    {
        const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId +  '/status-count';
        return triggerApi(API_URL, 'GET', '');
    };

export const updateEventRegistrant = async (eventId: number, registrantId: number, registrantData):Promise<EventRegistrant> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/' + registrantId;
    return triggerApi(API_URL, 'PUT', registrantData);
};

export const updateEventRegistrantComments = async (eventId: number, registrantId: number, data: { comments: string }):Promise<EventRegistrant> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/' + registrantId + '/comment';
    return triggerApi(API_URL, 'PUT', data);
};

export const mapEventRegistrantToSponsor = async (eventId: number, registrantId: number, data: {sponsorId: number | string }):Promise<EventRegistrant> =>  
{ 
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/' + registrantId + '/sponsor';
    return triggerApi(API_URL, 'PUT', data);
};

export const deleteEventRegistrant = async (eventId: string | number, registrantId: string | number):Promise<boolean> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/' + registrantId;
    return triggerApi(API_URL, 'DELETE', '');
};

export const uploadRegistrants = async (EVENT_ID: number, CSV_DATA, csrfToken: string):Promise<BulkEventRegistrant> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/bulk/' + EVENT_ID;
    return triggerApi(API_URL, 'POST', CSV_DATA, csrfToken);
};

export const checkUpdatedRegistrantData = async (eventId: number, registrantId: number, data: { firstName: string, email:string ,additionalData:{[key:string]: string| number| boolean| object} }):Promise<EventRegistrant> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/' + registrantId + '/info';
    return triggerApi(API_URL, 'PUT', data);
};

export const searchEventRegistrant = async (eventId: string, email?: string, firstName?: string, eventTicketIds?: any[], status?: number, paymentStatus?: number, attendeeStatus?: number): Promise<EventRegistrant[]> =>
{
    const statusParams = {
        status,
        attendeeStatus,
        paymentStatus
    };

    const searchParams = {
        email,
        firstName,
        eventTicketIds
    };

    const filteredstatusParams = Object.fromEntries(Object.entries(statusParams).filter(([key, value]) => value !== undefined));
    const filteredSearchParams = Object.fromEntries(Object.entries(searchParams).filter(([key, value]) => value !== undefined));

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/search?' + convertJSONToGETParams(filteredSearchParams) + '&' + convertJSONToGETParams(filteredstatusParams);
    return triggerApi(API_URL, 'GET', '');
}; 

export const bulkUpdateRegistrantStatus = async (eventId: number | string, data: { registrantIds: number[] | string[], status: EventRegistrantStatus }):Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/bulk-update-status';
    return triggerApi(API_URL, 'PUT', data);
};

export const downloadInvoices = async (eventId: number | string):Promise<any> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/invoice-zip';
    return axios.get(API_URL, {
        responseType: 'blob', // Important to get the response as a blob
        withCredentials: true,
    });
};

export const downloadRegistrantCSV = async (eventId: number | string):Promise<any> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-registrant/' + eventId + '/download-csv';
    return axios.get(API_URL, {
        responseType: 'blob', // Important to get the response as a blob
        withCredentials: true,
    });
};