import { useEffect, useMemo, useState } from "react";
import MuiChip from "../../../../common/FormComponents/MuiChip";
import CardComponent from "../../../../components/Events/Registrations/CardComponent";
import SponsorPortalSidedrawer from "../../Components/Common/SponsorPortalSidedrawer";
import ICP from "../../../../components/ICP/AddICP";

import './styles.scss';
import { Grid } from "@mui/material";
import { getAllIcpSponsorPortal, getIcpSponsorPortalCount } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import { useDispatch } from "react-redux";
import { resetICP } from "../../../../redux/settings/ICP";

const SponsorsICPPage: React.FC = (): React.JSX.Element =>
{
    const dispatch = useDispatch();

    const [icpData, setIcpData] = useState<any[]>([]);
    const [icpCount, setIcpCount] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [selectedIcpData, setSelectedIcpData] = useState<any>(undefined);
    const [openIcpSidebar, setOpenIcpSidebar] = useState<boolean>(false);

    const fetchData = async (): Promise<void> =>
    {
        try 
        {

            const icpcount = await getIcpSponsorPortalCount();
            if(icpcount)
            {
                setIcpCount(icpcount);
                const icpData = await getAllIcpSponsorPortal(icpcount, 0);
                setIcpData(icpData);
            }
        } 
        catch (error) 
        {
            
        }
        finally
        {

        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useMemo(() => {
        if(refresh)
        {
            fetchData();
            setRefresh(false);
        }
    }, [refresh])

    return (
        <div id="sponsorsIcpPage">
            <div className="icp-card-container">
                <div className="heading-div">
                    <div className="heading-wrapper">
                        <h2 className="heading">{'ICP'}</h2>
                        <MuiChip chipColor='grey' label={icpCount || '-'} />
                    </div>
                    {/* <CustomButton name='' btnType='secondary' startIcon={<FontAwesomeIcon icon={['fal', 'table-list']} />} onClick={() => { undefined } } /> */}
                </div>
                <Grid container spacing={2}>
                    {
                        icpData && icpData?.length > 0 && icpData?.map((item, index) => {

                            return (
                                <Grid item xl={3} lg={4} md={4} sm={12} xs={12} key={index}>
                                    <CardComponent 
                                        cardMinHeight="84px"
                                        header={item?.name}
                                        headerBold
                                        contentHeading={item?.description}
                                        onClick={() => { 
                                            setSelectedIcpData(item);
                                            dispatch(resetICP());
                                            setOpenIcpSidebar(true);
                                        }}
                                    />
                                </Grid>
                            )
                        })
                    }
                    <Grid item xl={3} lg={4} md={4} sm={12} xs={12}>
                        <CardComponent 
                            emptyText="+ Add ICP"
                            emptyCardHeight="50px"
                            cardMinHeight="62px"
                            onClick={() => { 
                                setOpenIcpSidebar(true); 
                                dispatch(resetICP());
                                setSelectedIcpData(undefined);
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            {
                openIcpSidebar &&
                    <SponsorPortalSidedrawer 
                        component={<ICP setRefresh={setRefresh} icpData={selectedIcpData} sponsorPortal={true} handleDrawerClose={() => setOpenIcpSidebar(false) } />}
                        handleClose={() => { setOpenIcpSidebar(false); }}
                        heading="Add ICP"
                        open={openIcpSidebar}
                        containerWidth="440px"
                    />
            }
        </div>
    )
};

export default SponsorsICPPage;