import React, { useContext } from "react";
import { Box } from "@mui/material";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import './styles.scss';
import { EventStageContext } from "../../../../contexts/EventStage/EventStageContext";
import _ from "lodash";
import eventBus from "../../../../scripts/event-bus";
import APP_CONSTANTS from "../../../../scripts/constants";
import AddStage from "../../../../components/Events/Agenda/AddStage";
import StageAccordian from "./StageAccordian";
import StageDateTabs from "./StageDateTabs";

const StagePage = () => 
{ 
    const isEmpty = false;

    const { daysArr, selectedDate, setSelectedDate, stageData, eventId, setRefresh } = useContext(EventStageContext);

    const openStageSideDrawer = (): void =>
    {   
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Add Stage',
            component: <AddStage eventId={eventId} setRefresh={setRefresh} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };
    
    return(
        <div id="stagePage">
            <Box id='inside-event-module-name-section' sx={isEmpty ? null : {
                    borderBottom: 'none !important'
                }}>{'Agenda'} 
                <Box className="marketing-action-button-container">
                <CustomButton btnType='secondary' name='Add Stage' type='button' onClick={openStageSideDrawer}/>
            </Box></Box>

            <div className="tab-wrapper">
                <div className="stage-date-tabs-container">
                    <StageDateTabs dates={daysArr} selectedDate={selectedDate} handleDateChange={setSelectedDate} />
                </div>
            </div>

            <StageAccordian setRefresh={setRefresh} eventId={eventId} stages={stageData} selectedDate={selectedDate} />

        </div>
    )
};  

export default StagePage;