import { createContext, useEffect, useMemo, useRef, useState } from "react";
import { getAllFolders, getFolderCount, getPhotosCount, getPhotosForFolder, getAllDefaultAndHiddenPhotos, getCountOfAllPhotos } from "../../scripts/apis/eventPhotoSharing";
import { EventPhotoSharing, EventPhotoSharingFolder } from "../../pages/Events/interfaces/event-photo-sharing_interface";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EventPhotoSharingFolderType, EventPhotoSharingStatus, EventPhotoSharingTypeFilter } from "../../pages/Events/enum/event-photo-sharing.enum";
import { LocalStorage } from "../../scripts/LocalStorage";
import { orgMe } from "../../scripts/apis/organisation";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../components/Events/interfaces/create-events_interface";

interface EventPhotoSharingContext {
    eventId: string | number;
    folders: EventPhotoSharingFolder[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    selectedFolder: string;
    setSelectedFolder: React.Dispatch<React.SetStateAction<string>>;
    photos: EventPhotoSharing[];
    isLoading: boolean;
    loaderRef: React.MutableRefObject<any>;
    fetchPhotosForFolder: () => Promise<void>;
    hasMore: boolean;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    setPhotos: React.Dispatch<React.SetStateAction<EventPhotoSharing[]>>; 
    foldersRefresh?: boolean;
    setFoldersRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    orgLink: string;
    eventLink: string;
    folderData: EventPhotoSharingFolder | undefined;
    userUploadFolder: EventPhotoSharingFolder | undefined;
    orgLevelPhotosCount: number;
}

export const EventPhotoSharingContext = createContext<EventPhotoSharingContext>(null);

const EventPhotoSharingProvider: React.FC<{ children: React.ReactNode, eventId: string | number }> = ({ children, eventId }):React.JSX.Element => 
{
    const { folder, hidden } = useParams();
    const navigate = useNavigate();

    const orgData = LocalStorage.get('@Org');

    const currentpath = useLocation().pathname;

    const isDefaultPhotos = currentpath?.includes('default-photos');
    const isUserUploads = currentpath?.includes('user-uploads');
    const isApproved = currentpath?.includes('approved');

    const [refresh, setRefresh] = useState<boolean>(false);
    const [folders, setFolders] = useState<EventPhotoSharingFolder[]>([]);
    const [selectedFolder, setSelectedFolder] = useState<string>('');
    const [photosCount, setPhotosCount] = useState<number>(0);
    const [photos, setPhotos] = useState<EventPhotoSharing[]>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [orgLink, setOrgLink] = useState<string>(orgData?.link || '');
    const [folderData, setFolderData] = useState<EventPhotoSharingFolder | undefined>(undefined);
    const [orgLevelPhotosCount, setOrgLevelPhotosCount] = useState<number>(0);

    const [userUploadFolder, setUserUploadFolder] = useState<EventPhotoSharingFolder | undefined>(undefined);

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => 
    {
        return state.events.value;
    });

    const eventLink = eventReduxData?.link;

    const [foldersRefresh, setFoldersRefresh] = useState<boolean>(false);

    const loaderRef = useRef(null);

    const fetchFolderData = async () => 
    {
        try 
        {
            const count = await getFolderCount(eventId);
            if(count)
            {
                const folderData = await getAllFolders(count, 0, eventId);

                if(folderData)
                {
                    const filteredFolderData = folderData?.filter((item) => item.default !== EventPhotoSharingFolderType.USER_UPLOAD);
                    if(filteredFolderData && filteredFolderData?.length > 0)
                    {
                        setFolders(filteredFolderData);
                        if(!folder)
                        {
                            setSelectedFolder(filteredFolderData[filteredFolderData?.length - 1]?.id);
                            navigate(`/events/${eventId}/photo-sharing/${filteredFolderData[filteredFolderData?.length - 1].id}`)
                        }
                    }
                }

                const userUploadsFolder = await getAllFolders(count, 0, eventId, EventPhotoSharingFolderType.USER_UPLOAD);
                if(userUploadsFolder)
                {
                    setUserUploadFolder(userUploadsFolder[0]);
                }
            }

            if(selectedFolder)
            {
                const photosCount = await getPhotosCount(eventId, selectedFolder || '');
                if(photosCount)
                {
                    setPhotosCount(photosCount);
                }
            }
        }
        catch (error) 
        {
            console.log('Error fetching folders', error);
        }
    };

    const fetchAllPhotosCount = async () => {
        try 
        {
            const count = await getCountOfAllPhotos();
            if (count)
            {
                setOrgLevelPhotosCount(Number(count));
            }
        } 
        catch (error) 
        {
            console.log('Error fetching all photos count', error);
        }
    }

    const fetchOrgData = async () => 
    {
        try 
        {
            const org = await orgMe();
            if(org)
            {
                setOrgLink(org?.link);
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    useEffect(() =>
    {
        fetchFolderData();
        fetchAllPhotosCount();
        if((!isApproved && !isDefaultPhotos && !isUserUploads) && folder)
        {
            setSelectedFolder(folder);
        }

        if(!orgData)
        {
            fetchOrgData();
        }
    }, []);

    const fetchPhotosForFolder = async () =>
    {
        const isHidden = hidden === 'hidden';

        // Define a mapping of conditions to EventPhotoSharingTypeFilter values
        const conditionToFilterType = {
            isDefaultPhotos: EventPhotoSharingTypeFilter.DEFAULT,
            isHidden: EventPhotoSharingTypeFilter.HIDE,
            isUserUploads: EventPhotoSharingTypeFilter.USER, // Assuming this is the correct enum value
            isApproved: EventPhotoSharingTypeFilter.APPROVED, // Assuming this is the correct enum value
        };

        // Find the first condition that is true and get its corresponding filter type
        // const filterType = Object.entries(conditionToFilterType).find(([condition, _]) => eval(condition))?.[1];
        let filterType;
        if (isDefaultPhotos) 
        {
            filterType = conditionToFilterType.isDefaultPhotos;
        } 
        else if (isHidden) 
        {
            filterType = conditionToFilterType.isHidden;
        } 
        else if (isUserUploads) 
        {
            filterType = conditionToFilterType.isUserUploads;
        } 
        else if (isApproved) 
        {
            filterType = conditionToFilterType.isApproved;
        }
        
        try 
        {
            if(isDefaultPhotos || isHidden || isUserUploads || isApproved)
            {
                if(filterType)
                {
                    const images = await getAllDefaultAndHiddenPhotos(eventId, 25, offset, filterType);
                    if(images)
                    {
                        setHasMore(images?.length === 25);
                        setPhotos((prevItems) => { 
                            const data = [...prevItems, ...images];
                            const newData = _.uniqBy(data, 'id');
                            return newData;
                        });
                        let currentOffset = offset;
                        currentOffset = currentOffset + 1;
                        setOffset(currentOffset);
                    }
                }
            }
            else if(selectedFolder)
            {
                const images = await getPhotosForFolder(eventId, selectedFolder || '', 25, offset, EventPhotoSharingStatus.ACTIVE);
                if(images)
                {
                    setHasMore(images?.length === 25);
                    setPhotos((prevItems) => { 
                        const data = [...prevItems, ...images];
                        const newData = _.uniqBy(data, 'id');
                        return newData;
                        // return isHidden ? _.filter(newData, { status: EventPhotoSharingStatus.HIDE }) : _.filter(newData, { status: EventPhotoSharingStatus.ACTIVE });
                    });
                    let currentOffset = offset;
                    currentOffset = currentOffset + 1;
                    setOffset(currentOffset);
                }
            }
        } 
        catch (error) 
        {
            setLoading(false);
        }
        finally
        {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPhotosForFolder();
        fetchAllPhotosCount();
        if(folder && !isDefaultPhotos && !isUserUploads && !isApproved)
        {
            setSelectedFolder(folder);
        }
    }, [selectedFolder, folder, hidden, refresh, isDefaultPhotos, isUserUploads, isApproved]);

    useEffect(() => {
        setOffset(0);
        setPhotos([]);

        fetchPhotosForFolder();

        setRefresh(false);
    }, [hidden, refresh, isDefaultPhotos, isUserUploads, isApproved]);

    useEffect(() => {
        if(foldersRefresh && !isDefaultPhotos && !isUserUploads && !isApproved)
        {
            fetchFolderData();
            setFoldersRefresh(false);
            if(folder)
            {
                setSelectedFolder(folder);
            }
        }
    }, [foldersRefresh]);

    useMemo(() => {
        if(selectedFolder)
        {
            setFolderData(_.find(folders, function(item) { return item.id === selectedFolder }))
        }
    }, [selectedFolder, folders]);

    return (
        <EventPhotoSharingContext.Provider value={{
            eventId, folders, setRefresh, selectedFolder, setSelectedFolder, photos, isLoading, loaderRef, fetchPhotosForFolder, hasMore, setOffset, setPhotos, foldersRefresh, setFoldersRefresh, orgLink, eventLink, folderData, userUploadFolder, orgLevelPhotosCount
        }}>
            {children}
        </EventPhotoSharingContext.Provider>
    );
};

export default EventPhotoSharingProvider;

