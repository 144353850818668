import React, { createContext, useEffect, useState } from 'react';
import { LocalStorage } from '../../scripts/LocalStorage';
import { getUsers, removeUser, userCount, userMe } from '../../scripts/apis/users';
import _ from 'lodash';
import { useTablePagination } from '../TablePaginationContext';
import { UserOrgStatus } from '../../pages/Settings/enum/users.enum';
import { User } from '../../interfaces/settings/user_interface';
import toast from 'react-hot-toast';

interface IMembersPageContext {
    isEmpty: boolean;
    userOnline: User;
    usersCount: number;
    userMeData: User | null;
    rows: User[];
    users: User[];
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    setUsersCount: React.Dispatch<React.SetStateAction<number>>;
    setUsers: React.Dispatch<React.SetStateAction<User[]>>;
    removeMemberFromTeam: (user: User) => void;
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (value: number) => void;
    updatePageSize: (value: number) => void;
    showSpinner: boolean;
    showDeletePopup: boolean;
    selectedUser: User | null;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedUser: React.Dispatch<React.SetStateAction<User | null>>;
}

export const MembersContext = createContext<IMembersPageContext>({
    isEmpty: false,
    userOnline: {} as User,
    usersCount: 0,
    userMeData: {} as User,
    rows: [],
    setRefresh: () => {},
    setUsersCount: () => {},
    removeMemberFromTeam: () => {},
    pageSize: 0,
    currentPage: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    showDeletePopup: false,
    selectedUser: null,
    setShowDeletePopup: () => {},
    setSelectedUser: () => {},
    users: [],
    setUsers: () => {}
});

const MembersPageProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element => 
{
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [rows, setRows] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const userOnline: User = LocalStorage.get('@UserMe');
    const [usersCount, setUsersCount] = useState<number>(0);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [userMeData, setUserMeData] = useState<User | null>(null);
    const [showSpinner, setShowSpinner] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchMembersData = async (): Promise<void> => 
    {
        try 
        {
            const total = Number(await userCount());
            setShowSpinner(true);
            if (total) 
            {
                setUsersCount(total);
                const users = await getUsers(pageSize, currentPage - 1);
                if (users) 
                {
                    // const updatedUsersData = await UpdatedDataWithDecompressedImage(users);
                    // if (updatedUsersData) {
                    setIsEmpty(users.length === 0);
                    setRows([...users]);
                    const user = await userMe();
                    if (user) 
                    {
                        setUserMeData(user);
                        setShowSpinner(false);
                        setRefresh(false);
                    }
                    // }
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchMembersPaginationData = async (): Promise<void> => 
    {
        try 
        {
            if (usersCount) 
            {
                setShowSpinner(true);
                const users = await getUsers(pageSize, currentPage - 1);
                if (users) 
                {
                    // const updatedUsersData = await UpdatedDataWithDecompressedImage(users);
                    // if (updatedUsersData) {
                    setIsEmpty(users.length === 0);
                    setRows([...users]);
                    const user = await userMe();
                    if (user) 
                    {
                        setUserMeData(user);
                    }
                    setShowSpinner(false);
                    setRefresh(false);
                    // }
                }
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    const removeMemberFromTeam = async (user: User): Promise<void> => 
    {
        try 
        {
            const userDeleted = await removeUser(user?.id);
            setShowDeletePopup(false);
            setSelectedUser(null);
            if (userDeleted) 
            {
                if(user.orgStatus === UserOrgStatus.ACTIVE)
                {
                    toast.success(`${user.firstName} ${user.lastName} has been removed successfully.`);
                }
                else
                {
                    toast.success('Request Cancelled');
                }
                setRefresh(true);
            }
        }
        catch (error) 
        {
            console.log(error);
        }           
    };

    // const UpdatedDataWithDecompressedImage = async (data) => {
    //     if (data) {
    //         const updatedData = await Promise.all(data.map(async (element) => {
    //             if (element.additionalInfo && element.additionalInfo.image) {
    //                 element.additionalInfo.image = await decompressImage(element.additionalInfo.image, 1500, 1500);
    //             }
    //             return element;
    //         }));

    //         return updatedData;
    //     }
    // };

    useEffect((): void => 
    {
        if (currentPage === 1 && pageSize) 
        {
            fetchMembersData();
        }
        else if (currentPage > 1 && pageSize) 
        {
            fetchMembersPaginationData();
        }
    }, [currentPage, pageSize]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchMembersData();
        }
    }, [refresh]);

    return (
        <MembersContext.Provider 
            value={{
                isEmpty, 
                userOnline,
                usersCount, 
                userMeData, 
                rows, 
                setRefresh, 
                setUsersCount, 
                removeMemberFromTeam, 
                pageSize, 
                currentPage,
                updateCurrentPage, 
                updatePageSize, 
                showSpinner,
                showDeletePopup,
                selectedUser,
                setShowDeletePopup,
                setSelectedUser,
                users,
                setUsers 
            }}
        >
            {children}
        </MembersContext.Provider>
    );
};

export default MembersPageProvider;