import { useState, useEffect, useRef, MouseEvent, ChangeEvent, FocusEvent } from 'react';
import moment, { Moment } from 'moment';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DatePickerProps {
    value: string | number | null;
    onChange: (date: Moment) => void;
    disablePast?: boolean;
    disableFuture?: boolean;
    disabled?: boolean;
    minDate?: string | number | null;
    maxDate?: string | number | null;
    valueFormat?: string;
    showYearDropdown?: boolean;
}

interface CalendarPosition {
    top: number;
    left: number;
}

const DatePicker: React.FC<DatePickerProps> = ({ value, onChange, disablePast = false, disableFuture = false, minDate, maxDate, showYearDropdown = false, valueFormat = 'ddd, MMM DD', disabled }) => {
    const convertToMoment = (timestamp: string | number | null, startOrEnd: 'start' | 'end' = 'start') => {
        if (!timestamp) return null;
        const ts = Number(timestamp);
        const momentObj = ts > 9999999999 ? moment.unix(Math.floor(ts / 1000)) : moment.unix(ts); // Convert if in milliseconds
        return startOrEnd === 'start' ? momentObj.startOf('day') : momentObj.endOf('day');
    };

    const [selectedDate, setSelectedDate] = useState<Moment | null>(value ? moment.unix(Number(value)) : null);
    const [currentMonth, setCurrentMonth] = useState<Moment>(value ? moment.unix(Number(value)) : moment());
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>(value ? moment(value).format('ddd, MMM DD') : '');
    const [calendarPosition, setCalendarPosition] = useState<CalendarPosition>({ top: 0, left: 0 });
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState<boolean>(false);

    const datePickerRef = useRef<HTMLDivElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const yearDropdownRef = useRef<HTMLDivElement | null>(null);
    const selectedYearRef = useRef<HTMLDivElement | null>(null);

    // Convert minDate and maxDate
    const minDateMoment = minDate && convertToMoment(minDate, 'start');
    const maxDateMoment = maxDate && convertToMoment(maxDate, 'end');

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent<Document>) => {
            if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        // const handleScroll = () => {
        //     setIsOpen(false);  // Close the picker when the user scrolls
        // };

        document.addEventListener('mousedown', handleClickOutside);
        // window.addEventListener('scroll', handleScroll, true); // true ensures it captures scrolling in nested divs

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            // window.removeEventListener('scroll', handleScroll, true);
        };
    }, []);

    // Scroll to the currently selected year when the dropdown opens
    useEffect(() => {
        if (isYearDropdownOpen && selectedYearRef.current) {
        selectedYearRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [isYearDropdownOpen]);

    const handleDateClick = (date: Moment) => {
        if (!isDateDisabled(date)) {
            setSelectedDate(date);
            onChange && onChange(date);
            setIsOpen(false);
        }
    };

    const isDateDisabled = (date: Moment): boolean => {
        const todayMidnight = moment().startOf('day');
        if (disablePast && date.isBefore(todayMidnight)) return true;
        if (disableFuture && date.isAfter(todayMidnight)) return true;
        if (minDateMoment && date.isBefore(minDateMoment)) return true;
        if (maxDateMoment && date.isAfter(maxDateMoment)) return true;
        return false;
    };

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const inputDate = moment(e.target.value, 'ddd, MMM DD', true);
        setInputValue(e.target.value);

        if (inputDate.isValid() && !isDateDisabled(inputDate)) {
            setSelectedDate(inputDate);
            onChange && onChange(inputDate);
        }
    };

    const handleInputFocus = (e: FocusEvent<HTMLInputElement>) => {
        setIsOpen(true);
    };

    const handleButtonClick = () => {
        if (disabled)
        {
            return
        }
        if (!isOpen) {
            const buttonRect = buttonRef.current!.getBoundingClientRect();
            const calendarHeight = 345; // Approximate height of the calendar dropdown
            const calendarWidth = 390;  // Approximate width of the calendar dropdown
            const windowHeight = window.innerHeight;
            const windowWidth = window.innerWidth;

            // Determine available space
            let top = buttonRect.bottom + window.scrollY;
            let left = buttonRect.left + window.scrollX;

            // Check if there's enough space below; if not, position above the button
            if (top + calendarHeight > windowHeight) {
                top = buttonRect.top + window.scrollY - calendarHeight;
            }

            // Check if there's enough space to the right; if not, position to the left
            if (left + calendarWidth > windowWidth) {
                left = buttonRect.right + window.scrollX - calendarWidth;
            }

            setCalendarPosition({ top, left });
        }

        setIsOpen(!isOpen);
    };

    const renderDaysOfWeek = () => {
        const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sat'];
        return (
            <div className="days-of-week">
                {daysOfWeek.map((day, index) => (
                    <div key={index} className="day-of-week">
                        {day}
                    </div>
                ))}
            </div>
        );
    };

    const renderDaysInMonth = () => {
        const startOfMonth = currentMonth.clone().startOf('month');
        const endOfMonth = currentMonth.clone().endOf('month');
        const startDate = startOfMonth.clone().startOf('week');
        const endDate = endOfMonth.clone().endOf('week');
        const day = startDate.clone().subtract(1, 'day');
        const daysArray = [];

        while (day.isBefore(endDate, 'day')) {
            day.add(1, 'day');

            const clonedDay = day.clone();
            const isDisabled = isDateDisabled(clonedDay);
            const isSelected = selectedDate && clonedDay.isSame(selectedDate, 'day');
            const isToday = clonedDay.isSame(moment(), 'day');
            const isCurrentMonth = clonedDay.isSame(currentMonth, 'month');

            daysArray.push(
                <div
                    key={clonedDay.format('DD-MM-YYYY')}
                    className={`day ${isDisabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''} ${isToday ? 'today' : ''} ${isCurrentMonth ? 'current-month' : 'other-month'}`}
                    onClick={() => !isDisabled && handleDateClick(clonedDay)}
                >
                    {clonedDay.date()}
                </div>
            );
        }

        return daysArray;
    };

    const renderYearDropdown = () => {
        return (
            <div className="year-dropdown-container">
                <button 
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsYearDropdownOpen(!isYearDropdownOpen)
                    }} 
                    className="year-toggle"
                >
                    {currentMonth.year()} 
                    <FontAwesomeIcon icon={['fal', isYearDropdownOpen ? 'chevron-up' : 'chevron-down']} />
                </button>
                {isYearDropdownOpen && (
                    <div className={`year-dropdown ${isYearDropdownOpen ? 'dropdown-open' : 'dropdown-close'}`} ref={yearDropdownRef}>
                    {getYearOptions().map((year) => (
                        <div
                            key={year}
                            ref={year === currentMonth.year() ? selectedYearRef : null}
                            className={`dropdown-item ${year === currentMonth.year() ? 'selected' : ''}`}
                            onClick={() => handleYearSelect(year)}
                        >
                        {year}
                        {year === currentMonth.year() && <FontAwesomeIcon icon={['fal', 'check']} />}
                        </div>
                    ))}
                    </div>
                )}
            </div>
        )
    };

    const getYearOptions = () => {
        const currentYear = moment().year();
        const startYear = 1970;
        const endYear = currentYear + 5;
        const years: number[] = [];
        for (let i = startYear; i <= endYear; i++) {
            years.push(i);
        }
        return years;
    };

    const handleYearSelect = (year: number) => {
        setCurrentMonth((prev) => prev.clone().year(year));  // Update the current month to the selected year
        setIsYearDropdownOpen(false); // Close the dropdown after selection
    };

    const toggleYearDropdown = (event: MouseEvent) => {
        event?.stopPropagation(); // Prevent closing the datepicker
        setIsYearDropdownOpen(!isYearDropdownOpen);
    };

    const goToPreviousMonth = (event: MouseEvent) => {
        event?.preventDefault();
        event?.stopPropagation();
        setCurrentMonth((prev) => prev.clone().subtract(1, 'month'));
    };

    const goToNextMonth = (event: MouseEvent) => {
        event?.preventDefault();
        event?.stopPropagation();
        setCurrentMonth((prev) => prev.clone().add(1, 'month'));
    };

    return (
        <div id="date-picker">
            <div onClick={handleButtonClick} className="date-picker" ref={datePickerRef}>
                <button type="button" className="date-display" ref={buttonRef}>
                    {value !== '' ? moment.unix(Number(value)).format(valueFormat) : moment().format(valueFormat)}
                </button>
                {isOpen && (
                    <div 
                        className="calendar" 
                        style={{ position: 'fixed', top: calendarPosition.top + 16, left: calendarPosition.left }}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="header">
                            <FontAwesomeIcon className="month-switch-button" onClick={goToPreviousMonth} icon={['fal', 'chevron-left']} />
                            <div className="header-month-year">
                                <span className="month">{currentMonth.format('MMMM')}</span>
                                {/* {currentMonth?.year() !== moment()?.year() && ` ${currentMonth?.year()}`} */}
                                {showYearDropdown ? renderYearDropdown() : <span className="year">{currentMonth.year()}</span>}
                            </div>
                            <FontAwesomeIcon className="month-switch-button" onClick={goToNextMonth} icon={['fal', 'chevron-right']} />
                        </div>
                        {renderDaysOfWeek()}
                        <div className="days-grid">{renderDaysInMonth()}</div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DatePicker;
