import { useEffect, useState } from "react";
import { CustomButton } from "../../../../../common/FormComponents/Buttons";
import DataImport from "../../../../../common/DataImport";
import APP_CONSTANTS from "../../../../../scripts/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip, Typography } from "@mui/material";
import EventRegistrantGraph from "./EventRegistrantGraph";
import TableEmptyComponent from "../../../../../common/TableEmptyComponent";
import TanstackTable from "../../../../../common/TanstackTable/TanstackTable";
import { EventRegistrant } from "../../../interfaces/event-registrant_interface";
import { registrantFilterColumnOptions, registrantInitialColumnData, useRegistrantColumnHelper } from "./registrantColumn.helper";
import { useTablePagination } from "../../../../../contexts/TablePaginationContext";
import { bulkUpdateRegistrantStatus, deleteEventRegistrant, downloadInvoices, downloadRegistrantCSV, getAllEventRegistrants, getEventRegistrantCount, getEventRegistrantStatusCount } from "../../../../../scripts/apis/eventRegistrants";
import { EventRegistrantStatus } from "../../../enum/event-registrant.enum";
import _, { camelCase } from "lodash";
import { EventGraphObject, EventTicket } from "../../../interfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { IEventReduxValues, IEventsDispatch } from "../../../../../components/Events/interfaces/create-events_interface";
import { getAlleventTickets, ticketsCount } from "../../../../../scripts/apis/eventTickets";
import { saveAs } from 'file-saver';
import { EnableInvoiceStatus, TicketType } from "../../../enum";
import toast from "react-hot-toast";
import { getRegistrationFormById } from "../../../../../scripts/apis/registrationForm";
import { CustomFieldMandatory } from "../../../../Registration/enum/custom-field.enum";
import RegistrantDetails from "./RegistrantDetails";
import eventBus from "../../../../../scripts/event-bus";
import DeletePopup from "../../../../../common/DeletePopup";
import { useRegistrantToolbarTabs } from "../../../../../hooks/registrantToolbarTabs";

import './styles.scss';
import TanstackToolbarWithServerFilter from "../../../../../common/TanstackTable/TanstackToolbarWithServerFilter";

interface EventRegistrantsPageProps {
    eventId: string;
}

const EventRegistrantsPage: React.FC<EventRegistrantsPageProps> = (props): React.JSX.Element => {

    const { eventId } = props;
    const searchParams = new URLSearchParams();
    const status = new URLSearchParams(window.location?.search)?.get('status');
    const navigate = useNavigate();
    const currentpath = useLocation().pathname;

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const eventReduxData = useSelector((state: IEventsDispatch): IEventReduxValues => {
        return state.events.value;
    });

    const csvoptions = {
        sample_template_url: `${APP_CONSTANTS.API_DOMAIN}${APP_CONSTANTS.API_V1}/event-registrant/${eventId}/generate-csv`,
        sample_template_button_text: 'Registrants Template'
    };

    const [chartVisibility, setChartVisibility] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [dynamicColumns, setDynamicColumns] = useState<any>([]);
    const [registrantCount, setRegistrantCount] = useState<number>(0);
    const [csvBoxReady, setCsvBoxReady] = useState<boolean>(false);
    const [rows, setRows] = useState<EventRegistrant[]>([]);
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [registrantStatusCount, setRegistrantStatusCount] = useState<EventGraphObject>();
    const [refresh, setRefresh] = useState<boolean>(false);
    const [tickets, setTickets] = useState<EventTicket[]>([]);
    const [invoiceDownloadSpinner, setInvoiceDownloadSpinner] = useState<boolean>(false);
    const [isEnableInvoiceDownload, setIsEnableInvoiceDownload] = useState<boolean>(false);
    const [csvConversionSpinner, setCsvConversionSpinner] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [selectedRegistrant, setSelectedRegistrant] = useState<EventRegistrant | null>(null);
    const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);
    const [showBulkActionsPopup, setShowBulkActionsPopup] = useState<boolean>(false);
    const [bulkUpdateSpinner, setBulkUpdateSpinner] = useState<boolean>(false);

    const openDrawer = (data: EventRegistrant): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: `${data?.firstName ?? ''} ${data?.lastName || data?.additionalData?.lastName || ''}`,
            hideCloseButton: true,
            component: <RegistrantDetails statusCount={registrantStatusCount} registrant={data} eventId={eventId} setRefresh={setRefresh} />,
            noSidebarPadding: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
        });
    };

    const onRowDelete = (data: EventRegistrant): void => 
    {
        setShowDeletePopup(true);
        setSelectedRegistrant(data);
    };

    const columns = useRegistrantColumnHelper(registrantInitialColumnData, eventReduxData, tickets, rows, openDrawer, onRowDelete);

    const [allColumns, setAllColumns] = useState<any[]>(columns);

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue); 
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);

        // code to append new query along with existing query
        // const currentSearchParams = new URLSearchParams(currentUrl.split('?')[1]);
        // filters.forEach(filter => {
        //     currentSearchParams.append(filter.selectedColumn, filter.inputValue);
        // });
        // setCurrentUrl(`${currentpath}?${currentSearchParams.toString()}`);
        // navigate(`${currentpath}?${currentSearchParams.toString()}`);
    };

    const handleInvoiceDownload = async (): Promise<void> => 
    { 
        setInvoiceDownloadSpinner(true);
        try 
        {
            const response = await downloadInvoices(eventId);
            // Use file-saver to save the blob as a file
            saveAs(response.data, `${eventReduxData.title} invoices.zip`);
            toast.success('Invoices downloaded successfully');
        } 
        catch (error) 
        {
            console.error('Error downloading the file:', error);
            toast.error('Error in downloading invoices');
        }
        finally
        {
            setInvoiceDownloadSpinner(false);
        }
    };

    const handleBulkActions = async (moveToConfirmed?: boolean): Promise<void> =>
    {
        setBulkUpdateSpinner(true);

        const data = {
            registrantIds: selectedRowIds,
            status: moveToConfirmed ? EventRegistrantStatus.CONFIRMED : status as unknown as EventRegistrantStatus
        };

        try 
        {
            const isStatusUpdated = await bulkUpdateRegistrantStatus(eventId, data);
            if(isStatusUpdated)
            {
                setRefresh(true);
                setSelectedRowIds([]);
                toast.success('Registrants updated successfully');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setBulkUpdateSpinner(false);
            setShowBulkActionsPopup(false);
        }
    };

    const handleDownloadCSV = async ():Promise<void> => 
    {
        setCsvConversionSpinner(true);
        try 
        {
            const response = await downloadRegistrantCSV(eventId);
            // Use file-saver to save the blob as a file
            saveAs(response.data, `${eventReduxData.title} registrants.csv`);
        }
        catch (error) 
        {
            console.log(error);
            toast.error('Error downloading the file');
        }
        finally
        {
            setCsvConversionSpinner(false);
        }
    };

    const removeRegistrantFromTable = async (): Promise<void> =>
    {
        try 
        {
            await deleteEventRegistrant(eventId, selectedRegistrant?.id as string)
            .then(() => 
            {
                setShowDeletePopup(false);
                setSelectedRegistrant(null);
                toast.success('Registrant removed successfully');
                setRefresh(true);
            })
            .catch((error) =>
            {
                console.log(error);
                toast.error((error as Error)?.message || 'Error in removing registrant');
            })
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchCounts = async (): Promise<void> => 
    {
        try 
        {
            const count = await getEventRegistrantCount(Number(eventId));
            const allStatusCount = await getEventRegistrantStatusCount(Number(eventId));

            if (count)
            {
                setRegistrantCount(count);
            }

            if (allStatusCount) {
                const regStatusEnumObject = _.mapValues(EventRegistrantStatus, (value) => value.toString());
                setRegistrantStatusCount(_.mapKeys(allStatusCount, (value, key) => regStatusEnumObject[key as unknown as number]));
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
    };

    const fetchData = async (): Promise<void> => {
        try {
            const params: [string, any][] = [];
            
            // Extract the search parameters from the current URL
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query.forEach((value, key) => {
                params.push([key, value]);  // Use values as-is from the URL
            });
    
            // Manually encode the values for the payload
            const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);
    
            // Call API with the encoded parameters
            const registrantData = await getAllEventRegistrants(
                Number(eventId),
                pageSize ? pageSize : 25,
                currentPage - 1,
                ...encodedParams
            );
    
            if (registrantData) {
                setRows(registrantData);
                setIsEmpty(registrantData.length === 0);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setShowSpinner(false);
            setRefresh(false);
        }
    };
    
    
    

    const fetchEventTickets = async (): Promise<void> => {
        try {
            const eventTicketCount = await ticketsCount(eventId);
            if (eventTicketCount) {
                const eventTickets = await getAlleventTickets(eventTicketCount, 0, eventId);
                if (eventTickets) {
                    setTickets(eventTickets);
                    if (eventTickets?.length > 0 && eventTickets?.some((ticket) => ticket.type === TicketType.PAID && ticket.enableInvoice === EnableInvoiceStatus.ENABLE)) 
                    {
                        setIsEnableInvoiceDownload(true);
                    }
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    const fetchRegistrationFormData = async (): Promise<void> => 
    {
        if (eventReduxData?.registrationFormId) 
        {
            try 
            {
                const registrationForm = await getRegistrationFormById(eventReduxData.registrationFormId);
                if (registrationForm) 
                {
                    const formComponents = registrationForm?.formFields;
                    const csvColArray = [];
                    const dynamicComponents = formComponents && formComponents?.length > 0 ? formComponents?.map((component):{
                        column_name: string;
                        display_label: string;
                        info_hint: string;
                        type: string;
                        required: boolean;
                    } => 
                    {
                        const label = component?.propertyKey;
                        csvColArray.push(label);
                        return {
                            column_name: label as string,
                            display_label: label as string,
                            info_hint: component?.placeholder as string,
                            type: 'text',
                            required: component?.fieldMandatory == CustomFieldMandatory.MANDATORY
                        };
                    }) : [];
                    if (eventReduxData.registrationType === 2) 
                    {
                        // const eventTicketsCount = await ticketsCount(eventId);
                        // const eventTickets = await getAlleventTickets(eventTicketsCount, 0, eventId);

                        const colName = 'Ticket Name';
                        const ticketName = {
                            column_name: camelCase(colName),
                            display_label: colName,
                            info_hint: 'Enter the name of the ticket',
                            type: 'text',
                            required: true,
                            // validators: {
                            //     values: eventTickets.map((eventTicket) =>
                            //     {
                            //         return {
                            //             value: eventTicket.name,
                            //             display_label: eventTicket.name
                            //         }
                            //     }),
                            //     case_sensitive: false
                            // },
                        };
                        if (dynamicComponents)
                        {
                            dynamicComponents.push(ticketName);
                        }
                    }
                    setDynamicColumns([...dynamicComponents]);
                }

            }
            catch (error) 
            {
                console.log(error);
            }
            finally
            {
                setCsvBoxReady(true);
            }
        }
    };

    useEffect(() => {
        fetchCounts();
        fetchEventTickets();
    }, []);

    useEffect(() => {
        if (pageSize > 0 && currentPage)
        {
            fetchData();
        }
    }, [currentUrl, pageSize, currentPage]);

    useEffect(() => {
        setAllColumns(columns);
    }, [columns]);

    useEffect(() => {
        fetchRegistrationFormData();
    }, [eventReduxData?.registrationFormId]);

    useEffect(() => {
        if (refresh) {
            fetchData();
            fetchCounts();
        }
        setRefresh(false);
    }, [refresh]);

    return (
        <div id="eventRegistrantsPage">
            <div className="header-container">
                <h2 className="heading">{'Registrants'}</h2>
                <div className="buttons-container">
                    <CustomButton
                        name={chartVisibility ? 'Close Charts' : 'Open Charts'}
                        onClick={(): void => {
                            setChartVisibility(!chartVisibility);
                        }}
                        btnType='secondary'
                    />
                    {csvBoxReady ? <DataImport licenseKey={import.meta.env.VITE_REGISTRANT_SHEET_ID} eventId={eventId} dynamicColumns={dynamicColumns} options={csvoptions} /> :
                        <Tooltip placement='top' title={<Typography className="reg-import-msg">{'Registration Form needs to be added.'}</Typography>}>
                            <CustomButton btnType='secondary' name='Import' startIcon={<FontAwesomeIcon icon={['fal', 'file-import']} />} />
                        </Tooltip>}
                    {isEnableInvoiceDownload && 
                        <CustomButton 
                            name=""
                            btnType='secondary'
                            onClick={handleInvoiceDownload}
                            startIcon={<Tooltip title={<p>{'Download invoices'}</p>} placement='top'><FontAwesomeIcon icon={['fal', 'download']} /></Tooltip>}
                            loading={invoiceDownloadSpinner}
                        />
                    }
                </div>
            </div>

            {chartVisibility ? <EventRegistrantGraph eventId={eventId} registrantCount={registrantCount} /> : null}

            <div
                style={{
                    height: chartVisibility ? '60%' : 'calc(100vh - 132px)',
                    maxHeight: !chartVisibility ? 'calc(100vh - 132px)' : '',
                    flex: 1
                }}
            >
                {
                    (isEmpty && Number(registrantCount) === 0) ? (
                        <TableEmptyComponent
                            infoText={'No Registrants Yet'}
                        />
                    ) : (
                        <div className="h-100">
                            <TanstackToolbarWithServerFilter
                                columns={columns}
                                filterColumns={registrantFilterColumnOptions(tickets, registrantStatusCount, eventReduxData)}
                                setColumns={setAllColumns}
                                tabs={useRegistrantToolbarTabs({eventId, navigate, registrantCount, registrantStatusCount, setCurrentUrl, tickets, eventReduxData})}
                                handleApplyFilters={handleApplyFilters}
                                setCurrentUrl={setCurrentUrl}
                                extraActionButtons={[
                                    <CustomButton loading={csvConversionSpinner} onClick={handleDownloadCSV} name='' btnType='tertiary' startIcon={<FontAwesomeIcon icon={['fal', 'download']} />} />,
                                    (selectedRowIds?.length > 0 && status) && (
                                        <CustomButton
                                            loading={bulkUpdateSpinner}
                                            name={'Actions'}
                                            type='button'
                                            btnType='primary'
                                            onClick={() => setShowBulkActionsPopup(true)}
                                        />
                                    )
                                ]}
                            />
                            <TanstackTable
                                data={rows}
                                initialColumns={allColumns}
                                rowCount={registrantCount}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                updateCurrentPage={updateCurrentPage as any}
                                updatePageSize={updatePageSize}
                                onRowClick={(row) => openDrawer(row as EventRegistrant)}
                                showSpinner={showSpinner}
                                height={chartVisibility ? `calc(100% - 120px)` : `calc(100% - 110px)`}
                                checkboxSelection
                                selectedRows={selectedRowIds}
                                setSelectedRows={setSelectedRowIds}
                                rightPinnedColumns={['actions']}
                                leftPinnedColumns={['companyLogo']}
                            />
                        </div>
                    )
                }
            </div>

            {
                showDeletePopup &&
                <DeletePopup
                    acceptBtn='Delete'
                    acceptClick={() => removeRegistrantFromTable()}
                    cancelClick={() => {
                        setShowDeletePopup(false);
                        setSelectedRegistrant(null);
                    }}
                    modalContent={
                        <>
                            Are you sure you want to remove {selectedRegistrant?.firstName} from this event?
                            <br />
                            Note: Registrant has to register again.
                        </>
                    }
                    modalTitle='Delete Registrant'
                    show={showDeletePopup}
                    rejectBtn='Cancel'
                />
            }

            {
                showBulkActionsPopup && 
                <DeletePopup
                    show={showBulkActionsPopup}
                    modalTitle='Actions'
                    modalContent={`Choose an action to perform on ${selectedRowIds?.length} registrants`}
                    cancelClick={(): void => setShowBulkActionsPopup(false)}
                    extraActions={[
                        <CustomButton btnType='secondary' name='Retrigger Email' onClick={() => handleBulkActions() } disabled={bulkUpdateSpinner} />,
                        !(status && Number(status) === EventRegistrantStatus.CONFIRMED) && <CustomButton btnType='primary' name='Move to confirmed' onClick={() => handleBulkActions(true) } disabled={bulkUpdateSpinner} />,
                    ].filter(Boolean)}
                />
            }

        </div>
    )
};

export default EventRegistrantsPage;