import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getRegistrationForms, registrationFormsCount } from '../../../../scripts/apis/registrationForm';
import _ from 'lodash';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import SelectRegistrationForm from '../../../../components/Events/EventRegistrations/SelectRegistrationForm';
import './styles.scss';
import { RegistrationFormType } from '../../../Registration/interfaces/registration-form_interface';
import CardComponent from '../../../../components/Events/Registrations/CardComponent';


const RegistrationFormsSection: React.FC<{eventId: number; registrationFormId: string}> = (props: {eventId: number; registrationFormId: string}): React.JSX.Element => 
{
    const { eventId } = props;
    const [formName, setFormName] = useState(null);
    const [formDescription, setFormDescription] = useState(null);
    const [registrationForms, setRegistrationForms] = useState([]);

    useEffect((): void => 
    {
        const fetchData = async (): Promise<void> => 
        {
            const count = await registrationFormsCount();
            const forms = await getRegistrationForms(count, 0, RegistrationFormType.REGISTRATION_FORM);
            setRegistrationForms([...forms]);
            if (forms) 
            {
                const defaultForm = _.find(forms, function (elem): boolean 
                {
                    if (props.registrationFormId !== '') 
                    {
                        return elem.id.toString() === props.registrationFormId;
                    }
                    else 
                    {
                        return;
                    }
                });
                setFormName(defaultForm && defaultForm.name ? defaultForm.name : '');
                setFormDescription(defaultForm && defaultForm.description ? defaultForm.description : '');
            }
        };
        fetchData();
    }, []);

    const handleEditRegistrationFormDetail = (): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: 'Registration Forms',
            hideCloseButton: true,
            component: <SelectRegistrationForm formName={formName} regForms={registrationForms} eventId={String(eventId)} registrationFormId={props.registrationFormId} />,
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '630px !important'
        });
    };

    return (
        <Box id="registrationFormsSection">
            <Grid container spacing={2} className="reg-forms-section-container">
                <Grid item xl={3} lg={3} md={3} sm={4} className="selected-form-container">
                    <Typography className="registration-forms-label">
                        {'Registration Form'}
                    </Typography>
                    <CardComponent header={formName ? formName : 'Select Form'} onClick={handleEditRegistrationFormDetail} cardMinHeight='90px' />
                </Grid>
            </Grid>
        </Box>
    );
};

export default RegistrationFormsSection;