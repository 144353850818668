/* eslint-disable import/named */
import React, { useContext, useState } from 'react';
import { Box, Tooltip } from '@mui/material';
import moment from 'moment';
import { MarketingPageContext, eventMarketingMailFilterOptions } from '../../../../contexts/EventMarketing/EventMarketingContext';
import { useDispatch } from 'react-redux';
import { resetMarketing } from '../../../../redux/events/eventMarketing/marketing';
import eventBus from '../../../../scripts/event-bus';
import APP_CONSTANTS from '../../../../scripts/constants';
import MarketingComponent from '../../../../components/Marketing/MarketingComponent';
import TableEmptyComponent from '../../../../common/TableEmptyComponent';
import { EventMarketing } from '../../interfaces';
import { CustomButton } from '../../../../common/FormComponents/Buttons';
import MuiChip from '../../../../common/FormComponents/MuiChip';
import { TableActions } from '../../../../common/TableActions';
import { createMarketing } from '../../../../scripts/apis/eventMarketing';
import { useSelector } from 'react-redux';
import { EventMarketingMailJobStatus, EventMarketingNotificationType, EventMarketingType } from '../../enum';
import TanstackTable from '../../../../common/TanstackTable/TanstackTable';
import { createColumnHelper } from '@tanstack/react-table';
import HeaderBar from '../../../../common/Headerbar';
import DeletePopup from '../../../../common/DeletePopup';
import toast from 'react-hot-toast';
import TanstackToolbarWithServerFilter from '../../../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { useLocation, useNavigate } from 'react-router-dom';

import './styles.scss';

const MarketingPage: React.FC = (): React.JSX.Element => {

    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();
    const navigate = useNavigate();

    const { 
        isEmpty, 
        rows,
        currentPage, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        showSpinner,
        marketingCount, 
        eventId, 
        setRefresh,
        selectedMarketingData,
        setSelectedMarketingData,
        showDeleteDialog,
        setShowDeleteDialog, 
        deleteMarketingDataFromTable,
        setCurrentUrl 
    } = useContext(MarketingPageContext);

    const dispatch = useDispatch();

    const csrfTokenData = useSelector((state: any): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    // const columns: GridColDef[] = [
    //     {
    //         field: 'name', headerName: 'Name', flex: 1, editable: false, disableColumnMenu: true, renderCell: (params): React.ReactElement => {
    //             return marketingName(params.row);
    //         }
    //     },
    //     {
    //         field: 'type', headerName: 'Type', editable: false, flex: 1, disableColumnMenu: true, sortable: true,
    //         renderCell: (params): React.ReactElement => {
    //             return marketingType(params.row);
    //         }, valueGetter(params): string {
    //             return params.row.type === 5 ? 'Marketing' : 'Transactional';
    //         },
    //     },
    //     {
    //         field: 'notificationType', headerName: 'Notification Type', editable: false, flex: 1, disableColumnMenu: true, sortable: true,
    //         renderCell: (params): React.ReactElement => {
    //             const marketingTypeChipLabel = params.row.notificationType === EventMarketingNotificationType.EMAIL ? 'Email Notification' : 'Push Notification';
    //             return <MuiChip label={marketingTypeChipLabel} chipColor={params.row.notificationType === EventMarketingNotificationType.EMAIL ? 'pink' : 'violet'} />;
    //         }, valueGetter(params): string {
    //             const row = params?.row as EventMarketing;
    //             return row.notificationType === EventMarketingNotificationType.EMAIL ? 'Email Notification' : 'Push Notification';
    //         },
    //     },
    //     {
    //         field: 'status', headerName: 'Status', editable: false, flex: 1, disableColumnMenu: true, sortable: true,
    //         renderCell: (params): React.ReactElement => {
    //             let statusLabel, chipColor = '';
    //             const row = params.row as EventMarketing;

    //             if (row?.jobStatus == EventMarketingMailJobStatus.TRIGGER_NOT_SET) {
    //                 statusLabel = 'Trigger Time not set';
    //                 chipColor = 'blue';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.TRIGGER_SET) {
    //                 statusLabel = 'Upcoming';
    //                 chipColor = 'pink';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.IN_PROGRESS) {
    //                 statusLabel = 'In Progress';
    //                 chipColor = 'violet';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.CANCELED) {
    //                 statusLabel = 'Canceled';
    //                 chipColor = 'orange';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.FAILED) {
    //                 statusLabel = 'Failed';
    //                 chipColor = 'red';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.COMPLETED) {
    //                 statusLabel = 'Completed';
    //                 chipColor = 'green';
    //             }

    //             return <MuiChip label={statusLabel || ''} chipColor={chipColor} />;
    //         }, valueGetter(params): string {
    //             const row = params?.row as EventMarketing;
    //             let value = '';
    //             if (row?.jobStatus == EventMarketingMailJobStatus.TRIGGER_NOT_SET) {
    //                 value = 'Trigger Time not set';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.TRIGGER_SET) {
    //                 value = 'Upcoming';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.IN_PROGRESS) {
    //                 value = 'In Progress';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.CANCELED) {
    //                 value = 'Cancelled';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.FAILED) {
    //                 value = 'Failed';
    //             } else if (row?.jobStatus == EventMarketingMailJobStatus.COMPLETED) {
    //                 value = 'Completed';
    //             }
    //             return value;
    //         },
    //     },
    //     {
    //         field: 'filter', headerName: 'Filter', editable: false, flex: 1, disableColumnMenu: true, sortable: true,
    //         renderCell: (params): React.ReactElement => {
    //             return marketingFilter(params);
    //         }, valueGetter(params): string {
    //             return params.row.type === 5 ? 'Marketing' : 'Transactional';
    //         },
    //     },
    //     {
    //         field: 'modified', headerName: 'Updated at', editable: false, sortable: true, flex: 1, disableColumnMenu: true, renderCell: (params): React.ReactElement => {
    //             return updatedTime(params.row);
    //         }, valueGetter(params): string {
    //             return moment(params.row.modified).format('DD MMMM, YYYY');
    //         },
    //     },
    //     {
    //         field: 'actions', headerName: '', editable: false, sortable: false, filterable: false, flex: 1, maxWidth: 80, width: 80, disableColumnMenu: true, renderCell: (params): React.ReactElement => {
    //             return marketingTableActions(params.row);
    //         }
    //     },
    // ];


    const columnHelper = createColumnHelper<EventMarketing>();
    const columns = 
    [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'name'
        }),
        columnHelper.accessor('type', {
            cell: (row) => {
                return (
                    <MuiChip label={row.getValue() === EventMarketingType.MARKETING ? 'Marketing' : 'Transactional'} chipColor={row.getValue() === EventMarketingType.MARKETING ? 'green' : 'yellow'} />
                )
            },
            header: 'Type',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'type'
        }),
        columnHelper.accessor('notificationType', {
            cell: (row) => {
                return (
                    <MuiChip label={row.getValue() === EventMarketingNotificationType.EMAIL ? 'Email Notification' : 'Push Notification'} chipColor={row.getValue() === EventMarketingNotificationType.EMAIL ? 'pink' : 'violet'} />
                )
            },
            header: 'Notification Type',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'notificationType'
        }),
        columnHelper.accessor('jobStatus', {
            cell: (row) => {
                let statusLabel, chipColor = '';
                if (row.getValue() == EventMarketingMailJobStatus.TRIGGER_NOT_SET) {
                    statusLabel = 'Trigger Time not set';
                    chipColor = 'blue';
                } else if (row.getValue() == EventMarketingMailJobStatus.TRIGGER_SET) {
                    statusLabel = 'Upcoming';
                    chipColor = 'pink';
                } else if (row.getValue() == EventMarketingMailJobStatus.IN_PROGRESS) {
                    statusLabel = 'In Progress';
                    chipColor = 'violet';
                } else if (row.getValue() == EventMarketingMailJobStatus.CANCELED) {
                    statusLabel = 'Canceled';
                    chipColor = 'orange';
                } else if (row.getValue() == EventMarketingMailJobStatus.FAILED) {
                    statusLabel = 'Failed';
                    chipColor = 'red';
                } else if (row.getValue() == EventMarketingMailJobStatus.COMPLETED) {
                    statusLabel = 'Completed';
                    chipColor = 'green';
                }

                return <MuiChip label={statusLabel || ''} chipColor={chipColor} />;
            },
            header: 'Status',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'status'
        }),
        columnHelper.accessor('filter', {
            cell: (row) => {
                return marketingFilter(row.row.original);
            },
            header: 'Filter',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'filter'
        }),
        columnHelper.accessor('modified', {
            cell: (row) => {
                return updatedTime(row.row.original);
            },
            header: 'Updated at',
            size: 200,
            sortingFn: (rowA, rowB) => {
                const dateA = moment(rowA.original.modified);
                const dateB = moment(rowB.original.modified);
                return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
            },
            id: 'modified'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return marketingTableActions(row.row.original);
            },
            header: '',
            size: 40,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'actions'
        })
    ];

    const [allColumns, setAllColumns] = useState(columns);

    const filterColumns = [
        {
            header: 'Name',
            id: 'name',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Type',
            id: 'type',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    { 
                        name: 'Marketing', value: EventMarketingType.MARKETING 
                    },
                    { 
                        name: 'Transactional', value: EventMarketingType.TRANSACTIONAL 
                    }
                ]
            }
        },
        {
            header: 'Notification Type',
            id: 'notificationType',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    { 
                        name: 'Email Notification', value: EventMarketingNotificationType.EMAIL 
                    },
                    { 
                        name: 'Push Notification', value: EventMarketingNotificationType.PUSH_NOTIFICATION 
                    }
                ]
            }
        },
        {
            header: 'Job Status',
            id: 'jobStatus',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    { 
                        name: 'Trigger Time not set', value: EventMarketingMailJobStatus.TRIGGER_NOT_SET 
                    },
                    { 
                        name: 'Upcoming', value: EventMarketingMailJobStatus.TRIGGER_SET 
                    },
                    { 
                        name: 'In Progress', value: EventMarketingMailJobStatus.IN_PROGRESS 
                    },
                    { 
                        name: 'Canceled', value: EventMarketingMailJobStatus.CANCELED 
                    },
                    { 
                        name: 'Failed', value: EventMarketingMailJobStatus.FAILED 
                    },
                    { 
                        name: 'Completed', value: EventMarketingMailJobStatus.COMPLETED 
                    }
                ]
            }
        },
        // {
        //     header: 'Filter',
        //     id: 'filter',
        //     meta: {
        //         type: 'string',
        //     }
        // },
    ];

    const customFilterFunctions = {
        type: (obj: EventMarketing, inputValue: string | number): boolean => {
            const marketingType = obj.type === EventMarketingType.MARKETING ? 'Marketing' : 'Transactional';
            return marketingType.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        notificationType: (obj: EventMarketing, inputValue: string | number): boolean => { 
            const marketingTypeChipLabel = obj.notificationType === EventMarketingNotificationType.EMAIL ? 'Email Notification' : 'Push Notification';
            return marketingTypeChipLabel.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        jobStatus: (obj: EventMarketing, inputValue: string | number): boolean => { 
            let statusLabel = '';
            if (obj.jobStatus == EventMarketingMailJobStatus.TRIGGER_NOT_SET) {
                statusLabel = 'Trigger Time not set';
            } else if (obj.jobStatus == EventMarketingMailJobStatus.TRIGGER_SET) {
                statusLabel = 'Upcoming';
            } else if (obj.jobStatus == EventMarketingMailJobStatus.IN_PROGRESS) {
                statusLabel = 'In Progress';
            } else if (obj.jobStatus == EventMarketingMailJobStatus.CANCELED) {
                statusLabel = 'Canceled';
            } else if (obj.jobStatus == EventMarketingMailJobStatus.FAILED) {
                statusLabel = 'Failed';
            } else if (obj.jobStatus == EventMarketingMailJobStatus.COMPLETED) {
                statusLabel = 'Completed';
            }
            return statusLabel.toLowerCase().includes(String(inputValue).toLowerCase());
        },
        filter: (obj: EventMarketing, inputValue: string | number): boolean => { 
            const filterValues = obj?.filter && obj?.filter?.length > 0 && obj?.filter.map((value) => eventMarketingMailFilterOptions[value]).join(', ');
            return filterValues ? filterValues.toLowerCase().includes(String(inputValue).toLowerCase()) : false;
        },
    };

    const marketingFilter = (row: EventMarketing): React.ReactElement => {
        return (
            <Box
                className="marketing-filter-container"
            >
                <Tooltip 
                    disableInteractive 
                    placement='top' 
                    title={row?.filter && row?.filter?.length > 0 && row?.filter.map((value) => eventMarketingMailFilterOptions[value as keyof typeof eventMarketingMailFilterOptions]).join(', ')}
                >
                    <Box>
                        {row?.filter && row?.filter?.length > 0 ? (
                            row?.filter.length > 1 ? (
                                <Box className="table-chip-cell">
                                    <MuiChip chipColor={'blue'} label={row.filter && eventMarketingMailFilterOptions[row.filter[0] as keyof typeof eventMarketingMailFilterOptions]} />
                                    <p className="table-chip-count">
                                        +{row.filter.length - 1}
                                    </p>
                                </Box>
                            ) : (
                                <MuiChip chipColor={'blue'} label={row.filter[0] && eventMarketingMailFilterOptions[row.filter[0] as keyof typeof eventMarketingMailFilterOptions]} />
                            )
                        ) : null}
                    </Box>
                </Tooltip>
                
            </Box>
        );
    };

    const updatedTime = (row: EventMarketing): React.ReactElement => {
        const timing = moment(row.modified);
        const dateTime = timing.format('DD MMMM, YYYY');
        return (
            <p className="cellContent">{dateTime}</p>
        );
    };

    const marketingTableActions = (row: EventMarketing): React.ReactElement => {
        const editClick = (): void => 
            {
                openMarketingSideBar(row, false, true);
            };
        
        const deleteClick = (): void => 
        {
            setSelectedMarketingData(row);
            setShowDeleteDialog(true);
        };

        const duplicateMarketingMail  = async (): Promise<void> => 
        {

            const marketingData = { 
                name: `${row?.name} (Clone)`,
                triggerTime: row?.triggerTime,
                replyTo: row?.replyTo,
                filter: row?.filter,
                attendedStatus: row?.attendedStatus,
                marketingMail: row?.marketingMail,
                marketingJson: row?.marketingJson,
                notificationType: row?.notificationType,
                marketingPushNotification: row?.marketingPushNotification
            };

            try 
            {
                const postMarketingData = await createMarketing(marketingData, eventId, csrfTokenData);
                if (postMarketingData) {
                    toast.success('Created duplicate marketing mail successfully');
                    setRefresh(true);
                }
            } 
            catch (error) 
            {
                console.log('Error in creating duplicate marketing mail', error);
                if (error?.statusCode === 400) {
                    toast.error((error as Error)?.message);
                }
            }
        };
    
        return <TableActions actions={[{ title: 'Edit', onClick: editClick }, { title: 'Delete', onClick: deleteClick }, { title: 'Duplicate', onClick: duplicateMarketingMail }]} />;
    };

    const openMarketingSideBar = (rowData?: EventMarketing, isCreate?: boolean, isEdit?: boolean): void => {
        const propsData = {
            rowData: isEdit && rowData,
            createMarketingData: isCreate,
            eventId,
            marketingId: rowData && rowData.id ? Number(rowData.id) : null,
            setRefresh,
            width: '50vw !important'
        };

        dispatch(resetMarketing());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: isCreate ? 'Create Marketing' : 'Update Marketing',
            hideCloseButton: true,
            component: <MarketingComponent data={propsData} />,
        });
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '80vw !important'
        });
    };

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    return (
        <Box id="eventMarketing">
            <HeaderBar 
                title="Marketing"
                buttons={[
                    <CustomButton btnType='secondary' name='Create Marketing' type='button' onClick={(): void => {
                        openMarketingSideBar(undefined, true);
                    }} />
                ]}
            />

            <Box sx={{
                flex: 1,
                height: 'calc(100% - 60px)', 
            }}>
                {isEmpty ? (<TableEmptyComponent emptyImg={''} infoText={'Setup email campaigns for your event'} subInfoText={'Create your first email'} />) :
                    (
                        <div className="h-100">
                            {/* <TanstackToolbar 
                                columns={columns}
                                setColumns={setAllColumns}
                                rows={marketingData}
                                setRows={setRows}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: marketingCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                                customFilterFunctions={customFilterFunctions}
                            /> */}
                            <TanstackToolbarWithServerFilter 
                                columns={columns}
                                setColumns={setAllColumns}
                                filterColumns={filterColumns}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                setCurrentUrl={setCurrentUrl}
                                tabs={[
                                    {
                                        tabName: 'All',
                                        count: marketingCount as number,
                                        navigation: () => {},
                                        selectedTab: true
                                    }
                                ]}
                            />
                            <TanstackTable 
                                initialColumns={allColumns}
                                data={rows}
                                showSpinner={showSpinner}
                                pageSize={pageSize}
                                currentPage={currentPage - 1}
                                rowCount={marketingCount}
                                updatePageSize={updatePageSize}
                                updateCurrentPage={updateCurrentPage as any}
                                onRowClick={(row) => openMarketingSideBar(row, false, true)}
                                height='calc(100% - 124px)'
                                rightPinnedColumns={['actions']}
                            />
                        </div>
                    )}
            </Box>

            {
                showDeleteDialog &&
                    <DeletePopup 
                        acceptBtn='Delete' 
                        acceptClick={() => deleteMarketingDataFromTable(selectedMarketingData as EventMarketing)} 
                        cancelClick={() => { 
                            setShowDeleteDialog(false);
                            setSelectedMarketingData(null);
                        }} 
                        modalContent={`Are you sure you want to delete ${selectedMarketingData?.name}?`}
                        modalTitle='Delete Marketing'
                        show={showDeleteDialog}
                        rejectBtn='Cancel'
                    />
            }
        </Box>
    );
};

export default MarketingPage;