import { useEffect, useState } from "react";
import { useTablePagination } from "../../../../contexts/TablePaginationContext";
import FormTabs from "../../../../common/FormTabs";
import { CustomButton } from "../../../../common/FormComponents/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addToTargetList, getAllRegistrantsInSponsorPortal, getSponsorPortalRegistrantsCount, sponsorPortalRegistrants } from "../../../../scripts/apis/sponsorPortal/sponsorPortal";
import _ from "lodash";
import toast from "react-hot-toast";
import TableEmptyComponent from "../../../../common/TableEmptyComponent";
import { FormControlComponent } from "../../../../common/FormComponents/ReusableFormComponents";
import TanstackTable from "../../../../common/TanstackTable/TanstackTable";
import { registrantInitialColumnData, useRegistrantColumnHelper } from "../../../Events/EventDetails/People/EventRegistrants/registrantColumn.helper";

const RegistrantsPage: React.FC = (): React.JSX.Element => {

  const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

  const [registrantCount, setRegistrantCount] = useState<number>(0);
  const [registrants, setRegistrants] = useState<any[]>([]);
  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [selectedRegistrants, setSelectedRegistrants] = useState<string[]>([]);
  const [addTargetListSpinner, setAddTargetListSpinner] = useState<boolean>(false);

  const [searchText, setSearchText] = useState<string>('');
  const [componentMounted, setComponentMounted] = useState(false);

  const columns =
    useRegistrantColumnHelper(
      registrantInitialColumnData.filter(
        item => !['email', 'status', 'paymentStatus', 'attendeeStatus', 'ticket', 'shareLink', 'actions'].includes(item.field)
      )
    );

  const RegistrantsToolbar = (): React.JSX.Element => {

    const tabs = [
      {
        tabName: 'All',
        selectedTab: true,
        count: registrantCount,
        navigation: (): void => {

        }
      }
    ];

    const addToTargetListSponsorPortal = async (): Promise<void> => {
      setAddTargetListSpinner(true);
      try {

        // const initialTargets = await getAllTargetList();
        // const initialTargetIds = initialTargets?.map((target: any) => target.id);

        // const updatedTargetListIds = [...initialTargetIds, ...selectedRegistrants]; 

        const targetListUpdated = await addToTargetList({ registrantIds: selectedRegistrants });
        if (targetListUpdated) {
          toast.success('Added to target list');
        }
      }
      catch (error) {
        toast.error('Failed to add to target list');
      }
      finally {
        setAddTargetListSpinner(false);
        setSelectedRegistrants([]);
      }
    };

    return (
      <div className="registrants-toolbar">
        <FormTabs tabs={tabs} />

        <div className="button-container">
          {
            selectedRegistrants?.length > 0 &&
            <>
              <CustomButton name='Cancel' btnType="secondary" onClick={() => setSelectedRegistrants([])} />
              <CustomButton name='Add to Target List' loading={addTargetListSpinner} btnType="primary" onClick={() => addToTargetListSponsorPortal()} startIcon={<FontAwesomeIcon icon={['fal', 'bullseye-arrow']} />} />
            </>
          }
        </div>

      </div>
    )
  };

  const fetchData = async (): Promise<void> => {
    try {

      const count = await getSponsorPortalRegistrantsCount();
      if (count) {
        setRegistrantCount(count);
        const registrantsData = await getAllRegistrantsInSponsorPortal(pageSize, (currentPage - 1));
        
        setIsEmpty(registrantsData?.length === 0);
        setRegistrants(registrantsData);
      }
    }
    catch (error) {

    }
    finally {
      setShowSpinner(false);
    }
  };

  const fetchPaginationData = async (): Promise<void> => {
    try {
      const count = await getSponsorPortalRegistrantsCount();
      if (count) {
        setRegistrantCount(count);
        const registrantsData = await getAllRegistrantsInSponsorPortal(pageSize, (currentPage - 1));
        setIsEmpty(registrantsData?.length === 0);
        setRegistrants(registrantsData);
      }
    }
    catch (error) {

    }
    finally {
      setShowSpinner(false);
    }
  };

  useEffect(() => {
    setComponentMounted(true);
  }, []);

  useEffect((): void => {
    if (currentPage === 1 && pageSize) {
      fetchData();
    }
    else if (currentPage > 1 && pageSize) {
      fetchPaginationData();
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async (): Promise<void> => {
      if (searchText?.length >= 2) {
        try {
          const registrantData = await sponsorPortalRegistrants(searchText);

          if (registrantData) {
            setRegistrants(registrantData);
            setShowSpinner(false);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setShowSpinner(false);
        }
      }
      else if (searchText === '' && componentMounted) {
        fetchData();
      }
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchText, componentMounted]);

  return (
    <div id="sponsorPortalRegistrantsPage" >
      <div style={{ height: 'calc(100vh - 120px)', maxHeight: 'calc(100vh - 120px)' }}>
        {
          !isEmpty &&
          <div className="search-container">
            <FormControlComponent
              placeholder="Search for a registrant"
              type="text"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              startIcon={'search'}
              clearable
            />
          </div>
        }
        {!isEmpty ? (
          <div style={{ height: 'calc(100% - 56px)' }}>
            <RegistrantsToolbar />
            <TanstackTable
              data={registrants}
              initialColumns={columns}
              checkboxSelection
              selectedRows={selectedRegistrants}
              setSelectedRows={setSelectedRegistrants}
              rowCount={registrantCount}
              pageSize={pageSize}
              currentPage={currentPage - 1}
              updatePageSize={updatePageSize}
              updateCurrentPage={updateCurrentPage as any}
              leftPinnedColumns={['companyLogo', 'firstName']}
              showSpinner={showSpinner}
              hidePagination={searchText?.length >= 2}
            />
          </div>
        ) : (
          <TableEmptyComponent infoText={'No Registrants available'} />
        )}
      </div>
    </div>
  )
};

export default RegistrantsPage;