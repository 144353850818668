import { Box } from '@mui/material';
import React from 'react';

interface ITrendsBlock {
    title: string;
    value: string | number;
    trendsStatus?: {
        trendsStatusValue: 'Upward' | 'Downward'
        trendsStatusPercentage: string;
    };
}

interface ITrends {
    Trends: ITrendsBlock[]
}

const TrendsComponent = (props: ITrends): React.JSX.Element => 
{
    return (
        <Box id="trends">
            {
                props.Trends.length > 0 ? props.Trends.map((item, index): React.JSX.Element => 
                {
                    return (
                        <Box className="trendsBlock" key={index}>
                            <Box className="trendsTitleBlock">
                                <Box className="trendsTitle">{item.title}</Box>
                            </Box>
                            <Box className="trendsValueBlock">
                                <Box className="trendsValue">{item.value}</Box>
                                {item.trendsStatus ?
                                    (<TrendsPercentage trendsStatusValue={item.trendsStatus.trendsStatusValue} trendsStatusPercentage={item.trendsStatus.trendsStatusPercentage} />)
                                    : null}
                            </Box>

                        </Box>
                    );
                }) : null
            }
        </Box>
    );
};

export default TrendsComponent;

const TrendsPercentage = (props) : React.JSX.Element => 
{
    // const svgStyle = {
    //     transform: 'rotate(180deg)',
    // };
    return (
        <Box className="trends-percentage">
            <Box className="trends-status-icon-block">
                {/* {props.trendsStatusValue === "Upward" ?
                   <Box component='img' src={upwardTrendsIcon} className='trends-status-icon'/>
                    : <Box component='img' src={downwardTrendsIcon} className='trends-status-icon'/>} */}
            </Box>
            <Box className="trends-status-value" style={{
                color: `${props.trendsStatusValue === 'Upward' ? '#14804A' : '#E2341D'}` 
            }}>
                {props.trendsStatusPercentage}
            </Box>
        </Box>
    );
};