import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

export const eventVendorCount = async (eventId: string | number, vendorId?: number, status?: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-vendors/' + eventId + '/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllEventVendors = async (pageSize: number, offset: number, eventId: string | number, vendorId?: number, status?: number, queryParams: [string, any][] = []) => {
    
    const filteredParams = Object.fromEntries(queryParams.filter(([key, value]) => value !== undefined));
    const query = convertJSONToGETParams({
        pageSize,
        offset,
        status,
        vendorId,
        ...filteredParams
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-vendors/' + eventId + '?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const createEventVendor = async (EVENT_VENDOR_DATA, EVENT_ID: string | number, csrfToken: string) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-vendors/' + EVENT_ID;
    return triggerApi(API_URL, 'POST', EVENT_VENDOR_DATA, csrfToken);
};

export const updateEventVendor = async (VENDOR_ID: string | number, VENDOR_DATA) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/vendor/' + VENDOR_ID;
    return triggerApi(API_URL, 'PUT', VENDOR_DATA);
};

export const removeEventVendor = (EVENT_ID: string | number, VENDOR_ID: string | number) => {
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-vendors/' + EVENT_ID + '/' + VENDOR_ID;
    return triggerApi(API_URL, 'DELETE', '');
};