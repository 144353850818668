import React, { useRef, useEffect, useState } from 'react';
import { CSVBoxButton } from '@csvbox/react';
import { LocalStorage } from '../scripts/LocalStorage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CustomButton } from './FormComponents/Buttons';

const DataImport = ({ licenseKey, dynamicColumns = [], eventId = null, options = null }: { licenseKey: string; dynamicColumns: any[]; eventId: string | number | null; options: any }): React.JSX.Element => 
{
    const userMe = LocalStorage.get('@UserMe');
    const org = LocalStorage.get('@Org');

    const user = {
        user_id: userMe.id,
        orgId: org.id
    };

    if (eventId) 
    {
        user['eventId'] = eventId;
    }

    const csvBoxButtonRef = useRef(null);

    const [triggerLaunch, setTriggerLaunch] = useState(false);
    const [currentLicenseKey, setCurrentLicenseKey] = useState(licenseKey);
    const [keyForRemount, setKeyForRemount] = useState(0);

    useEffect((): void => 
    {
        setCurrentLicenseKey(licenseKey);
        csvBoxButtonRef.current = null;
        setKeyForRemount((prevKey): number => 
        { 
            return prevKey + 1;
        });
    }, [licenseKey]);

    useEffect(() : void => 
    {
        if (triggerLaunch && csvBoxButtonRef.current) 
        {
            csvBoxButtonRef.current.openModal();
            setTriggerLaunch(false);
        }
    }, [triggerLaunch]);

    useEffect(() => 
    {
        return (): void => 
        {
            csvBoxButtonRef.current = null;
        };
    }, [licenseKey]);

    return (
        <>
            <CSVBoxButton
                key={keyForRemount}
                ref={(el: CSVBoxButton): void => 
                {
                    csvBoxButtonRef.current = el;
                }}
                licenseKey={currentLicenseKey}
                user={user}
                dynamicColumns={dynamicColumns}
                onImport={(result, data): void => 
                {
                    if (result) 
                    {
                        console.log('success');
                        console.log(data.row_success + 'rows uploaded');
                    }
                    else 
                    {
                        console.log('fail');
                    }
                }}
                options={options ? options : ''}
                render={(launch, isLoading): React.JSX.Element => 
                {  
                    return (
                        <CustomButton 
                            btnType='secondary' 
                            name='Import' 
                            startIcon={<FontAwesomeIcon icon={['fal', 'cloud-arrow-up']} />} 
                            disabled={isLoading} 
                            onClick={(): void => 
                            {
                                setTriggerLaunch(true);
                            }}
                        />
                    );
                }}
            >
                Import
            </CSVBoxButton>
        </>
    );
};

export default DataImport;
