import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import './styles.scss';
import { useDispatch } from 'react-redux';
import { resetFormComponents } from '../../../redux/registrationsForms/FormComponents';
import eventBus from '../../../scripts/event-bus';
import { updateRegistrationForm } from '../../../scripts/apis/registrationForm';
import { resetFormField } from '../../../redux/registrationsForms/FormComponentDisplayField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormControlComponent } from '../../../common/FormComponents/ReusableFormComponents';
import { addRegistrationForms } from '../../../redux/registrationsForms/RegistrationForms';


const RegistrationsTopBar = (props): React.JSX.Element => 
{
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentpath = useLocation().pathname;

    // const currentPath = useLocation().pathname;

    const formId = props.formId;
    const [formName, setFormName] = useState(props.name ? props.name : '');
    const [updateName, setUpdateName] = useState(false);
    const [topButton, setTopButton] = useState();
    
    useEffect((): void => 
    {
        const fetchData = (): void => 
        {
            eventBus.on('submit-form-options', (data): void => 
            {
                setTopButton(data.submitButtonComponent);
            });
        };
        fetchData();
    }, []);

    useEffect((): void => 
    {
        if (props.name) 
        {
            setFormName(props.name);
        }
    }, [props?.name]);



    const editRegistrationsFormDataFromTable = async (): Promise<void> => 
    {
        const registrationFormDetails = {
            name: formName,
            description: props.registrationData.description,
            link: props.registrationData.link,
        };
        try 
        {
            const registrationFormUpdated = await updateRegistrationForm(formId, registrationFormDetails);
            if (registrationFormUpdated) 
            {
                dispatch(addRegistrationForms({
                    name: formName
                }));
                props.setRefresh(true);
                setUpdateName(!updateName);
            }
        }
        catch (error) 
        {
            console.log(error);
        }
    };

    return (
        <Box id="registrationsTopBar">
            {/* //     <TopBar /> */}
            <Box className="back-btn-box" onClick={(): void => 
            {
                if(currentpath?.includes('sponsor-portal'))
                {
                    props?.setSelectedModule('form');
                }
                else
                {
                    navigate('/registrations');
                    dispatch(resetFormComponents());
                    dispatch(resetFormField());
                }
            }} >
                <FontAwesomeIcon icon={['fal', 'arrow-left']} className="back-icon" />
                <Typography className="back-text">
                    {currentpath?.includes('sponsor-portal') ? 'Back' : 'Back to Forms'}
                </Typography>
                {/* <Button startIcon={<NavigateBefore />} onClick={() => {
                        navigate('/registrations');
                        dispatch(resetFormComponents());
                        dispatch(resetFormField());
                        // navigate(0);
                    }} className="back-btn">Back to all forms</Button> */}
            </Box>
            <Box className="name-box">
                {updateName ?
                    <Box className='update-name'>
                        <Box className="icon-box" onClick={(): void => 
                        {
                            setUpdateName(!updateName); setFormName(props.name);
                        }}>
                            <FontAwesomeIcon icon={['fal', 'xmark']} color="#E2341D" />
                        </Box>

                        <FormControlComponent
                            type="text"
                            value={formName}
                            onChange={(event): void => 
                            {
                                setFormName(event.target.value);
                            }}
                            required
                            placeholder="Enter Form Name"
                        />

                        <Box className="icon-box" onClick={(): void => 
                        {
                            editRegistrationsFormDataFromTable();
                        }}>
                            <FontAwesomeIcon icon={['fal', 'circle-check']} />
                        </Box>

                    </Box>
                    : <Typography className="name" onClick={(): void => 
                    {
                        if(!currentpath?.includes('sponsor-portal'))
                        {
                            setUpdateName(!updateName);
                        }
                    }}>{props.name}</Typography>}

            </Box>
            {/* <Box className="action-btn-box">
                    {
                        (currentPath === '/registrations/' + formId + '/options' && topButton) ? topButton : <div className="top-button-spacing"></div>
                    }
                </Box> */}

        </Box>
    );
};
export default RegistrationsTopBar;