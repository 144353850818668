import React, { useEffect, useState } from 'react';
import { Box, Drawer, Slide } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SponsorPortalSidedrawer: React.FC<{ component: React.JSX.Element; heading: string; handleClose: () => void; open: boolean; noSidebarPadding?: boolean; containerWidth?: string; description?: string; }> = (props): React.JSX.Element => 
{
    
    const { component, heading, handleClose, open, containerWidth, noSidebarPadding, description } = props;
    const [isOpen, setIsOpen] = useState(false);

    const drawerBackdropStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
    };

    useEffect(() => setIsOpen(open), [open]);
    
    return (
        <Drawer slotProps={
            {
                backdrop: {
                    sx: drawerBackdropStyle 
                } 
            }
        } id="sponsorPortalSidedrawer" variant='temporary' anchor="right" open={isOpen} onClose={handleClose}>
                <div className="box" style={containerWidth ? {
                    width: containerWidth, height: '100%' 
                } : null}>
                    <div className="heading-grid" style={containerWidth ? {
                        width: containerWidth 
                    } : null}>
                        <div className="header-container">
                            <h2 className="side-drawer-heading">{heading}</h2>
                            <FontAwesomeIcon className="sidebar-close-icon" icon={['fal', 'xmark']} onClick={handleClose} />
                        </div>
                    </div>

                    <div className={noSidebarPadding ? 'sidebar-component-wrapper-noPadding' : "sidebar-component-wrapper"}>{component}</div>
                </div>
        </Drawer>

    );
};
export default SponsorPortalSidedrawer;

