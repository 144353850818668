import { Box } from '@mui/material';
import React from 'react';
import { Spinner } from 'react-bootstrap';

const CustomSpinner = ({ height, loadingText }: { height: string; loadingText?: string; }) : React.JSX.Element => 
{
    return (
        <Box sx={{
            height: height 
        }} className="spinner-container">
            <Spinner animation="border" role="status" />
            {loadingText && <p>{loadingText}</p>}
        </Box>
    );
};

export default CustomSpinner;