import React, { useEffect } from 'react';
import eventBus from '../../scripts/event-bus';
import APP_CONSTANTS from '../../scripts/constants';
//import AudienceAnalyticsGraph from './AudienceAnalytics/AudienceAnalyticsGraphComponent';
import BudgetAnalytics from './BudgetAnalytics';
import { Box } from '@mui/material';
import './styles.scss';
import EventAnalytics from './EventsAnalytics';
import RoiAnalytics from './RoiAnalytics';
import AudienceAnalyticsMain from './AudienceAnalytics/AudienceAnalyticsMain';
import { useLocation, useNavigate } from 'react-router-dom';
import FormTabs from '../../common/FormTabs';
//import AudienceAnalyticsGraphComponent from './AudienceAnalytics/AudienceAnalyticsGraphComponent';


const AnalyticsPage = (props: { page: string }): React.JSX.Element => 
{

    const currentpath = useLocation().pathname;
    const navigate = useNavigate();

    const analyticsTabObj = [
        {
            tabName: 'Audience',
            selectedTab: currentpath === '/analytics/audience',
            navigation: (): void => 
            {
                return navigate('/analytics/audience');
            }
        },
        {
            tabName: 'RoI',
            selectedTab: currentpath === '/analytics/roi',
            navigation: (): void => 
            {
                return navigate('/analytics/roi');
            }
        },
        {
            tabName: 'Budget',
            selectedTab: currentpath === '/analytics/budget',
            navigation: (): void => 
            {
                return navigate('/analytics/budget');
            }
        },
        {
            tabName: 'Events',
            selectedTab: currentpath === '/analytics/events',
            navigation: (): void => 
            {
                return navigate('/analytics/events');
            }
        },
    ];

    useEffect((): void => 
    {
        eventBus.dispatch(APP_CONSTANTS.EVENTS.TOP_NAV_BAR.UPDATE_NAME_EVENT, {
            heading: 'Analytics',
            closedChartsButton: false,
        });
    }, []);

    return (
        <Box id='AnalyticsMainCont'>
            <div className="analytics-tab-container">
                <FormTabs tabs={analyticsTabObj} />
            </div>
            <Box className='analytics-graph-cont'>
                {props.page === 'Audience' && <AudienceAnalyticsMain />}
                {props.page === 'Budget' && <BudgetAnalytics />}
                {props.page === 'Events' && <EventAnalytics />}
                {props.page === 'roi' && <RoiAnalytics />}
            </Box>
        </Box>
    );
};

export default AnalyticsPage;