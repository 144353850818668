import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import './styles.scss';
import { NumberFormatter } from '../../scripts/helpers';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import MuiChip from '../../common/FormComponents/MuiChip';
import { createColumnHelper } from '@tanstack/react-table';
import { Budget } from './interfaces';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';
import { useLocation, useNavigate } from 'react-router-dom';
import { EventBudgetAnalysis } from '../Events/enum';

interface BudgetAnalysisTableProps { 
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
    chartVisibility: boolean;
    budgetCount: number;
    tableReady: boolean;
    rows: Budget[];
    pageSize: number;
    currentPage: number;
    updateCurrentPage: (page: number) => void;
    updatePageSize: (size: number) => void;
    showSpinner: boolean;
    totalBudgetCount: number;
    isEmpty: boolean;
}

const BudgetAnalysisTable: React.FC<BudgetAnalysisTableProps> = (props): React.JSX.Element => {

    const currentpath = useLocation().pathname;
    const searchParams = new URLSearchParams();
    const navigate = useNavigate();

    const [rows, setRows] = useState<Budget[]>([]);
    const [budgetData, setBudgetData] = useState<Budget[]>([]);
    const chartVisibility = props?.chartVisibility;

    const columnHelper = createColumnHelper<Budget>();
    const budgetColumns = [
        columnHelper.accessor('name', {
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Event Name',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'eventName'
        }),
        columnHelper.accessor('category', {
            cell: (row) => {
                return (<p className="cellContent">{row.getValue()}</p>)
            },
            header: 'Event Category',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'category'
        }),
        columnHelper.accessor('budget', {
            cell: (row) => {
                return (<p className="cellContent">{row.row?.original?.budget && row.row?.original?.budget !== 0 ? `$${NumberFormatter(Number(row.row?.original?.budget))}` : '-'}</p>)
            },
            header: 'Budget',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'budget'
        }),
        columnHelper.accessor('actualSpend', {
            cell: (row) => {
                let actualSpent;
                if (row.row.original?.actualSpend !== 0) {
                    actualSpent = row.row.original?.actualSpend;
                }
                return (<p className="cellContent">{!actualSpent ? '-' : `$${NumberFormatter(row.getValue())}`}</p>)
            },
            header: 'Expense',
            size: 200,
            sortingFn: 'alphanumericCaseSensitive',
            id: 'actualSpend'
        }),
        columnHelper.accessor('analysis' as any, {
            cell: ({ row }) => {
                let chipLabel: string = '';
                let chipColor: 'green' | 'red' | 'yellow' | 'null' = 'null';
                if (Number(row?.original?.actualSpend) < Number(row?.original?.budget)) {
                    chipLabel = 'Underspend';
                    chipColor = 'green';
                }
                else if (Number(row?.original?.actualSpend) > Number(row?.original?.budget)) {
                    chipLabel = 'Overspend';
                    chipColor = 'red';
                }
                else {
                    chipLabel = 'In Budget';
                    chipColor = 'yellow';
                }

                return (
                    <MuiChip
                        chipColor={chipColor}
                        label={chipLabel}
                    />
                );
            },
            header: 'Analysis',
            size: 200,
            sortingFn: (rowA, rowB) => {
                const valueA = Number(rowA.original?.budget) > Number(rowA.original?.actualSpend) ? 'Overspend' :
                    Number(rowA.original?.actualSpend) < Number(rowA.original?.budget) ? 'Underspend' : 'In Budget';
                const valueB = Number(rowB.original?.budget) > Number(rowB.original?.actualSpend) ? 'Overspend' :
                    Number(rowB.original?.actualSpend) < Number(rowB.original?.budget) ? 'Underspend' : 'In Budget';
                return valueA.localeCompare(valueB);
            },
            id: 'analysis'
        }),
    ];
    const [allColumns, setAllColumns] = useState(budgetColumns);

    const filterColumns = [
        {
            header: 'Event Name',
            id: 'eventName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Category Name',
            id: 'categoryName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Analysis',
            id: 'analysis',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    { 
                        name: 'Underspend', value: EventBudgetAnalysis.UNDER_SPEND 
                    },
                    { 
                        name: 'Overspend', value: EventBudgetAnalysis.OVER_SPEND
                    },
                    { 
                        name: 'In Budget', value: EventBudgetAnalysis.IN_BUDGET
                    },
                ]
            }
        },
        {
            header: 'Budget',
            id: 'budget',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Expense',
            id: 'actualSpend',
            meta: {
                type: 'string'
            }
        },
    ];

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        props?.setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);
    };

    useEffect(() => {
        if (props?.rows)
        {
            setRows(props?.rows);
            setBudgetData(props?.rows);
        }
    }, [props?.rows]);

    return (
        <div id='budgetTable'>
            {
                (Number(props?.totalBudgetCount) > 0) ?
                    (
                        <div style={{ height: 'calc(100% - 58px)' }}>
                            <TanstackToolbarWithServerFilter 
                                columns={budgetColumns}
                                setColumns={setAllColumns}
                                tabs={[{
                                    count: props?.totalBudgetCount,
                                    navigation: () => { },
                                    selectedTab: true,
                                    tabName: 'All'
                                }]}
                                setCurrentUrl={props?.setCurrentUrl}
                                handleApplyFilters={(filters) => handleApplyFilters(filters)}
                                filterColumns={filterColumns}
                            />
                            <TanstackTable
                                data={budgetData}
                                initialColumns={allColumns}
                                rowCount={props?.totalBudgetCount}
                                pageSize={props?.pageSize}
                                currentPage={props?.currentPage - 1}
                                updateCurrentPage={props?.updateCurrentPage as any}
                                updatePageSize={props?.updatePageSize}
                                showSpinner={props?.showSpinner}
                                height={chartVisibility ? `calc(100% - 58px)` : `calc(100% - 58px)`}
                            />
                        </div>
                    ) :
                    (<TableEmptyComponent emptyImg={''} openDrawer={null} infoText={'No Budget Data'} subInfoText={''} buttonName={''} />)
                //  :
                // (<CustomSpinner height={'100%'} />)
            }
        </div>
    );
};

export default BudgetAnalysisTable;